import type { ColorPickerTheme } from './ColorPicker.theme.types'

const colorPickerTheme = (): ColorPickerTheme => {
  return {
    appearance: {
      primary: {
        inverseColorSwatch: false,
        inversePickerItem: false,
        inverse: {
          inverseColorSwatch: true,
          inversePickerItem: true,
        },
      },
    },
  }
}

export default colorPickerTheme
