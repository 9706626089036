/* eslint-disable camelcase */
import axios from "axios";
import { application, apiUrl, apigeeConfigApiUrl, apigeeConfigApiKey, envId, ingressURL } from "../settings.json";
import { getMiddlewareBaseUrl } from "utils/helpers";

const REQUEST_TIME_OUT = 15000; // only wait for 15s

class ConfigService {
  fetchConfigFromAppBuilder = async () => {
    const headers = apigeeConfigApiUrl
      ? {
          "content-type": "application/json",
          Authorization: `Basic ${apigeeConfigApiKey}`,
        }
      : {
          "content-type": "application/json",
        };

    const url = apigeeConfigApiUrl
      ? `${apigeeConfigApiUrl}/${window.REACT_APP_ENV_ID || envId}/${application}`
      : `${apiUrl}/coreapp/configuration/${window.REACT_APP_ENV_ID || envId}/${application}`;
    const { data } = await axios.create()({
      method: "get",
      url,
      headers,
    });
    return {
      ...data,
      fetchDate: Date.now(),
    };
  };

  fetchConfig = async () => {
    const middlewareBaseUrl = getMiddlewareBaseUrl({ apiUrl, ingressURL });

    try {
      const { data } = await axios.create()({
        method: "get",
        url: `${middlewareBaseUrl}/api/coreapp/configuration/${window.REACT_APP_ENV_ID || envId}/${application}`,
        timeout: REQUEST_TIME_OUT,
        headers: {
          "content-type": "application/json",
          Authorization: `Basic ${apigeeConfigApiKey}`,
        },
      });
      console.log("Getting config form the middleware", { data });
      return {
        ...data,
        fetchDate: Date.now(),
      };
    } catch (error) {
      console.error("Error getting config from The Middleware, fallback to the app-builder's", { error });
      return this.fetchConfigFromAppBuilder();
    }
  };

  checkSamlAuthorization = async () => {
    try {
      const url = `${apiUrl}/coreapp/saml2/authorize`;
      const Response = await axios.create()({
        method: "get",
        url,
        withCredentials: true,
      });
      return Response.status;
    } catch (error) {
      return 401;
    }
  };

  fetchTaggingSheet = (url) => {
    fetch(`${url}`, {
      method: "GET",
      withCredentials: true,
      headers: { "content-type": "application/json" },
      mode: "no-cors",
    })
      .then((response) => {
        console.log("response from fetch ", response);
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

const inst = new ConfigService();
export default inst;
