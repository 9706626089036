import type { IconRules } from '@vfuk/core-themes'
import SourceSystemIcons from '@vfuk/source-system-icons/dist/ruleSet'
import SourceHiFiDarkIcons from '@vfuk/source-hifi-dark-icons/dist/ruleSet'
import SourceHiFiLightIcons from '@vfuk/source-hifi-light-icons/dist/ruleSet'
import SourceMidRenderLightIcons from '@vfuk/source-mid-render-light-icons/dist/ruleSet'
import SourceMidRenderDarkIcons from '@vfuk/source-mid-render-dark-icons/dist/ruleSet'
import SourceTobiIcons from '@vfuk/source-tobi-icons/dist/ruleSet'
import BrandLoFiIcons from '@vfuk/brand-lofi-icons/dist/ruleSet'
import SourceStateIcons from '@vfuk/source-state-icons/dist/ruleSet'
import SourceFlagIcons from '@vfuk/source-flag-icons/dist/ruleSet'
import UKLegacyIcons from '@vfuk/uk-legacy-icons/dist/ruleSet'
import BrandHiFiIcons from '@vfuk/brand-hifi-icons/dist/ruleSet'
import SourcePaymentIcons from '@vfuk/source-payment-icons/dist/ruleSet'
import UkBrandIcons from '@vfuk/uk-brand-icons/dist/ruleSet'

const iconRules: IconRules = {
  system: {
    assetLocation: 'SourceSystemIcons',
    properties: SourceSystemIcons,
  },
  state: {
    assetLocation: 'SourceStateIcons',
    properties: SourceStateIcons,
  },
  hifiDark: {
    assetLocation: 'SourceHiFiDarkIcons',
    properties: SourceHiFiDarkIcons,
  },
  hifiLight: {
    assetLocation: 'SourceHiFiLightIcons',
    properties: SourceHiFiLightIcons,
  },
  midLight: {
    assetLocation: 'SourceMidRenderLightIcons',
    properties: SourceMidRenderLightIcons,
  },
  midDark: {
    assetLocation: 'SourceMidRenderDarkIcons',
    properties: SourceMidRenderDarkIcons,
  },
  brandLofi: {
    assetLocation: 'BrandLoFiIcons',
    properties: BrandLoFiIcons,
  },
  chatbot: {
    assetLocation: 'SourceTobiIcons',
    properties: SourceTobiIcons,
  },
  flag: {
    assetLocation: 'SourceFlagIcons',
    properties: SourceFlagIcons,
  },
  ukLegacy: {
    assetLocation: 'UkLegacyIcons',
    properties: UKLegacyIcons,
  },
  brandHifi: {
    assetLocation: 'BrandHiFiIcons',
    properties: BrandHiFiIcons,
  },
  payment: {
    assetLocation: 'SourcePaymentIcons',
    properties: SourcePaymentIcons,
  },
  ukBrand: {
    assetLocation: 'UkBrandIcons',
    properties: UkBrandIcons,
  },
}

export default iconRules
