import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import Icon from '@vfuk/core-icon'
import { useLocalTheme } from '@vfuk/core-themes'

import LoadingSpinner from '@vfuk/core-loading-spinner'

import defaultTheme from './themes/IconButton.theme'

import * as Styled from './styles/IconButton.style'

import type { IconButtonProps } from './IconButton.types'
import type { Appearance } from './themes/IconButton.theme.types'

const IconButton: FC<IconButtonProps> = ({
  shape = 'square',
  size = 6,
  appearance = 'primary',
  htmlAttributes = {},
  dataAttributes,
  dataSelectorPrefix,
  localTheme,
  state,
  loading,
  href,
  inverse,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  srText,
  id,
  customRouterProps,
  icon,
}: IconButtonProps): ReactElement => {
  const componentName = 'IconButton'
  const iconButtonTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  let localThemeAppearance = iconButtonTheme.appearance[appearance!]
  if (inverse) {
    localThemeAppearance = localThemeAppearance.inverse as Appearance
  }

  const { loadingSpinnerAppearance, inverseIcon } = localThemeAppearance

  const isDisabledOrLoading = state === 'disabled' || loading!

  return (
    <Styled.IconButton
      appearance={appearance!}
      target={htmlAttributes!.target}
      type={htmlAttributes!.type}
      componentName={componentName}
      dataAttributes={dataAttributes}
      size={size}
      shape={shape}
      inverse={inverse}
      state={state}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      disabled={isDisabledOrLoading}
      label={srText}
      href={!isDisabledOrLoading ? href : undefined}
      id={id}
      customRouterProps={customRouterProps}
      iconButtonTheme={iconButtonTheme}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      localTheme={iconButtonTheme}
    >
      {loading ? (
        <Styled.LoadingIcon>
          <LoadingSpinner
            appearance={loadingSpinnerAppearance}
            inverse={inverseIcon}
            size={iconButtonTheme.size[size!].loadingSpinnerSize}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'loading-spinner')}
          />
        </Styled.LoadingIcon>
      ) : (
        <Icon
          name={icon.name}
          size={iconButtonTheme.size[size!].iconSize}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, icon.name)}
        />
      )}
    </Styled.IconButton>
  )
}

export default IconButton
