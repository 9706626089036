import React, { PureComponent } from 'react'

import * as Styled from './styles/Grid.style'
import type { GridProps } from './Grid.types'

export class Grid extends PureComponent<GridProps> {
  public static defaultProps: Partial<GridProps> = {
    center: true,
    maxWidth: 'full',
  }

  public render(): JSX.Element {
    return (
      <Styled.GridContainer fluid maxWidth={this.props.maxWidth!} center={this.props.center!} className={this.props.className}>
        {this.props.children}
      </Styled.GridContainer>
    )
  }
}

export default Grid
