import type { ReactElement, FC } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import Icon from '@vfuk/core-icon'

import defaultTheme from './themes/IconWithBackground.theme'
import * as Styled from './styles/IconWithBackground.style'

import type { IconWithBackgroundProps } from './IconWithBackground.types'
import type { AppearanceProps } from './themes/IconWithBackground.theme.types'

const IconWithBackground: FC<IconWithBackgroundProps> = ({
  icon,
  appearance = 'primary',
  size = 5,
  shape = 'circle',
  inverse = false,
  dataAttributes,
  dataSelectorPrefix,
  id,
  localTheme,
}: IconWithBackgroundProps): ReactElement => {
  const componentName = 'IconWithBackground'
  const iconWithBackgroundTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  let localThemeAppearance = iconWithBackgroundTheme.appearance[appearance]
  if (inverse) {
    localThemeAppearance = localThemeAppearance.inverse as AppearanceProps
  }

  const { inverseIcon } = localThemeAppearance
  const { iconSize } = iconWithBackgroundTheme.size[size]

  return (
    <Styled.IconWithBackground
      id={id}
      iconWithBackgroundTheme={iconWithBackgroundTheme}
      inverse={inverse}
      appearance={appearance}
      size={size}
      shape={shape}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
    >
      <Icon
        name={icon.name}
        appearance='primary'
        size={iconSize}
        inverse={inverseIcon}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, icon.name)}
      />
    </Styled.IconWithBackground>
  )
}

export default IconWithBackground
