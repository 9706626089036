import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledSelectInputWithLabelProps } from './SelectInputWithLabel.style.types'

export const Container = styled.div(
  (props: StyledSelectInputWithLabelProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
  `,
)
