import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledSpanProps } from './Span.style.types'

export const Span = styled.span<StyledSpanProps>((props: StyledSpanProps): FlattenSimpleInterpolation => {
  return css`
    color: ${props.spanTheme.appearance[props.appearance].color};

    ${props.inverse &&
    `
      color: ${props.spanTheme.appearance[props.appearance].inverse.color};
    `}

    ${props.weight &&
    `
      font-weight: ${props.theme.fontWeight[props.weight]};
    `}
  `
})
