/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import settings from 'settings';
import styled, { css } from 'styled-components';
import { IconButton, Icon, Mixins } from 'r10-source-library';
import { logout } from '../services/Liveness';
const LoginButton = styled.a(
  props => css`
    background-color: ${props.theme.color.primary1.default};
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    ${Mixins.elevation(true)};
    &:hover {
      background-color: ${props.theme.color.primary1.hover};
    }
  `
);

const LoginLogout = ({ greeting, auth, size }) => {
  const authentication = JSON.parse(localStorage.getItem('auth'));
  if (settings.authProtocol && settings.authProtocol == 'SAML2') {
    if (authentication) {
      auth = { ...authentication, name: authentication.user_info.name };
    }
  }
  return (
    <>
      {auth && auth.name ? (
        <>
          {greeting && greeting.length ? (
            <span>
              {greeting}, {auth.name}
            </span>
          ) : null}
          <IconButton
            onClick={logout}
            appearance="alt1"
            size={size === 'sm' ? 2 : 3}
            icon={{ name: 'log-out' }}
            srText="logout button"
          />
        </>
      ) : window.REACT_APP_TENANT_ID || settings.tenantId ? (
        <LoginButton
          style={{
            height: size === 'sm' ? '32px' : '56px',
            width: size === 'sm' ? '32px' : '56px'
          }}
          href={
            settings.authProtocol && settings.authProtocol == 'SAML2'
              ? encodeURI(
                  `${settings.apiUrl}/coreapp/saml2/login?redirectUrl=${
                    window.location.href
                  }&locale=${settings.locale}&envId=${window.REACT_APP_ENV_ID ||
                    settings.envId}`
                )
              : encodeURI(`https://login.microsoftonline.com/${window.REACT_APP_TENANT_ID ||
                  settings.tenantId}/oauth2/v2.0/authorize?client_id=${window.REACT_APP_CLIENT_ID ||
                  settings.clientId}
                &response_type=code
                &redirect_uri=${window.REACT_APP_AUTH_URI || settings.authUri}
                &scope=${window.REACT_APP_AZURE_CORE_APP_SCOPE ||
                  settings.azureCoreAppScope}
                &response_mode=query
                &state=${window.location.origin}/oauthcode&Prompt=login`)
          }
          rel="noopener noreferrer"
        >
          <Icon
            name="arrow-right"
            size={size === 'sm' ? 1 : 2}
            appearance="primary"
            inverse
          />
        </LoginButton>
      ) : (
        <span>Initializing...</span>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.authentication
});

export default connect(mapStateToProps)(LoginLogout);
