import type { FC, ReactElement } from 'react'
import React from 'react'

import Button from '@vfuk/core-button'
import Icon from '@vfuk/core-icon'
import Paragraph from '@vfuk/core-paragraph'
import Heading from '@vfuk/core-heading'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/BannerBody.style'

import type { BannerBodyProps } from './BannerBody.types'

const BannerBody: FC<BannerBodyProps> = ({
  justify = 'center',
  inverse = false,
  heading,
  text,
  primaryButton,
  secondaryButton,
  footnote,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: BannerBodyProps): ReactElement => {
  const componentName = 'BannerBody'

  return (
    <Styled.BannerBody
      justify={justify!}
      inverse={inverse!}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.BannerContent>
        <Heading
          level={heading.level}
          text={heading.text}
          weight={heading.weight}
          inverse={inverse!}
          size={3}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'heading')}
        />
        {text && (
          <Paragraph inverse={inverse!} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'paragraph')}>
            {text}
          </Paragraph>
        )}
      </Styled.BannerContent>
      {(primaryButton?.text || secondaryButton?.text) && (
        <Styled.BannerButtons justify={justify!}>
          {primaryButton?.text && (
            <Button
              text={primaryButton!.text}
              onMouseDown={primaryButton!.onMouseDown}
              onMouseUp={primaryButton!.onMouseUp}
              onTouchStart={primaryButton!.onTouchStart}
              onTouchEnd={primaryButton!.onTouchEnd}
              href={primaryButton!.href}
              onClick={primaryButton!.onClick}
              appearance={primaryButton!.appearance}
              inverse={primaryButton!.inverse}
              htmlAttributes={primaryButton!.htmlAttributes}
              dataAttributes={primaryButton!.dataAttributes}
              customRouterProps={primaryButton?.customRouterProps}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'primary-button')}
            />
          )}
          {secondaryButton?.text && (
            <Button
              text={secondaryButton!.text}
              onMouseDown={secondaryButton!.onMouseDown}
              onMouseUp={secondaryButton!.onMouseUp}
              onTouchStart={secondaryButton!.onTouchStart}
              onTouchEnd={secondaryButton!.onTouchEnd}
              href={secondaryButton!.href}
              onClick={secondaryButton!.onClick}
              appearance={secondaryButton!.appearance}
              inverse={secondaryButton!.inverse}
              htmlAttributes={secondaryButton!.htmlAttributes}
              dataAttributes={secondaryButton!.dataAttributes}
              customRouterProps={secondaryButton?.customRouterProps}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'secondary-button')}
            />
          )}
        </Styled.BannerButtons>
      )}
      {footnote && (
        <Styled.BannerFootnote>
          {footnote!.icon && footnote!.icon!.name && (
            <Icon
              name={footnote!.icon!.name}
              appearance={footnote!.icon!.appearance!}
              inverse={footnote!.icon!.inverse!}
              size={5}
              group={footnote!.icon!.group}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, footnote!.icon!.name)}
            />
          )}
          <Paragraph size={1} inverse={inverse} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'footnote-paragraph')}>
            {footnote!.text}
          </Paragraph>
        </Styled.BannerFootnote>
      )}
    </Styled.BannerBody>
  )
}

export default BannerBody
