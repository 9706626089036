import type { ReactElement, FC } from 'react'
import React, { useState } from 'react'

import { getCookie, isClient } from '@vfuk/core-helpers'

import type { AnimationContainerProps, AnimationContainerState } from './AnimationContainer.types'

import * as Styled from './styles/AnimationContainer.style'

const AnimationContainer: FC<AnimationContainerProps> = ({ children, disabledCookieName = 'disableAnimation' }): ReactElement => {
  const shouldDisableAnimation = (): boolean => {
    if (isClient()) {
      return getCookie(disabledCookieName!) === 'true'
    }
    return false
  }

  const [disableAnimation] = useState<AnimationContainerState['disableAnimation']>(shouldDisableAnimation())

  if (disableAnimation) {
    return (
      <Styled.AnimationContainer>
        <Styled.AnimationCover />
        {children}
      </Styled.AnimationContainer>
    )
  }

  return <>{children}</>
}

export default AnimationContainer
