/* eslint-disable no-param-reassign */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import jwt from "jsonwebtoken";
import settings from "settings";
import {
  loggedIn,
  loginError,
  loggedInSuccessfully,
  setSamlAuth,
} from "actions/authenticationActions";
import { setGlobalLoading } from "actions/configActions";

const OauthCode = ({
  appConfig,
  location,
  history,
  dispatchloggedIn,
  dispatchloginError,
  dispatchLoggedInSuccessfully,
  dispatchSetSamlAuth,
  dispatchStartLoading,
}) => {
  const [status, setStatus] = useState("idle");

  useEffect(() => {
    if (settings.authProtocol == "SAML2") {
      fetch(
        `${settings.apiUrl}/coreapp/saml2/getCoreAppAgentToken?locale=${settings.locale}&envId=${settings.envId}&appId=${settings.application}`,
        {
          method: 'get',
          headers: {
            'content-type': 'application/json'
          },
          credentials: 'include'
        }
      )
        .then(res => res.json())
        .then(res => {
          if (res?.issued_token_type) {
            localStorage.setItem(
              "auth",
              JSON.stringify({
                ...res,
                name: res["user_info"]?.name || "user",
                agentTokenDetails: { ...res },
              })
            );

            dispatchSetSamlAuth({
              agentTokenDetails: { ...res },
              name: res["user_info"]?.name || "user",
            });

            history.push("/dashboard");
          } else {
            history.push('/');
          }
        })
        .catch(e => {
          dispatchloginError({ error: e?.message });
          history.push('/');
        });
    }
  }, []);

  useEffect(() => {
    if (appConfig) {
      if (status !== 'idle') return;
      setStatus('fetching');

      const params = new URLSearchParams(location.search);

      const code = params.get('code');
      const error = params.get('error');

      if (code) {
        const data = {
          code
        };

        const { storeToken } = appConfig;
        dispatchStartLoading(true);
        fetch(
          `${settings.apiUrl}/coreapp/token?locale=${
            settings.locale
          }&envId=${window.REACT_APP_ENV_ID ||
            settings.envId}&appId=${window.REACT_APP_APP_ID ||
            settings.application}`,
          {
            method: 'post',
            headers: {
              'content-type': 'application/json'
            },
            body: JSON.stringify(data),
            ...(storeToken === 'cookies' ? { credentials: 'include' } : {}),
            mode: 'cors'
          }
        )
          .then(ret => {
            if (!ret.ok) {
              throw Error(ret.statusText);
            }
            return ret.json();
          })
          .then(json => {
            const decoded = jwt.decode(json.id_token);
            json.name = decoded.name;
            json.family_name = decoded.family_name;
            json.given_name = decoded.given_name;
            json.email = decoded.email;
            json.ctry = decoded.ctry;

            if (storeToken === 'cookies') {
              delete json.access_token;
              delete json.id_token;
              delete json.refresh_token;
            }

            dispatchloggedIn(json);
            dispatchLoggedInSuccessfully();
            history.push(`/`);
            setStatus('fetched');
          })
          .catch(e => {
            dispatchloginError({ error: e.message });
            history.push("/");
            setStatus("fetched");
          })
          .finally(() => {
            dispatchStartLoading(false);
          });
      } else if (error) {
        console.log("Got error", error);
        history.push("/");
      }
    }
  }, [
    appConfig,
    location,
    history,
    dispatchloggedIn,
    dispatchloginError,
    status,
  ]);
  return null;
};

const mapStateToProps = state => ({
  auth: state.authentication,
  appConfig: state.config?.data
});

const mapDispatchToProps = (dispatch) => ({
  dispatchloggedIn: (data) => dispatch(loggedIn(data)),
  dispatchloginError: (data) => dispatch(loginError(data)),
  dispatchLoggedInSuccessfully: (data) => dispatch(loggedInSuccessfully()),
  dispatchSetSamlAuth: (data) => dispatch(setSamlAuth(data)),
  dispatchStartLoading: (bool) => dispatch(setGlobalLoading(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OauthCode));
