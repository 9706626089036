import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/FunctionalTabList.style'
import type { FunctionalTabListProps } from './FunctionalTabList.types'

class FunctionalTabList extends PureComponent<FunctionalTabListProps> {
  public render(): React.ReactNode {
    return (
      <Styled.StyledTabList
        role='tablist'
        justifyContent={this.props.justifyContent}
        maxWidth={this.props.maxWidth}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
      >
        {this.props.children}
      </Styled.StyledTabList>
    )
  }
}

export default FunctionalTabList
