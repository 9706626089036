const LANGUAGE_LIST_REQUEST = 'LANGUAGE_LIST_REQUEST';
const LANGUAGE_LIST_REQUEST_SUCCESS = 'LANGUAGE_LIST_REQUEST_SUCCESS';
const LANGUAGE_LIST_REQUEST_FAIL = 'LANGUAGE_LIST_REQUEST_FAIL';
const LANGUAGE_REQUEST = 'LANGUAGE_REQUEST';
const LANGUAGE_REQUEST_SUCCESS = 'LANGUAGE_REQUEST_SUCCESS';
const LANGUAGE_REQUEST_FAIL = 'LANGUAGE_REQUEST_FAIL';

const getLanguageList = () => ({
  type: LANGUAGE_LIST_REQUEST
});

const changeLanguage = payload => ({
  type: LANGUAGE_REQUEST,
  payload
});

export {
  LANGUAGE_LIST_REQUEST,
  LANGUAGE_LIST_REQUEST_SUCCESS,
  LANGUAGE_LIST_REQUEST_FAIL,
  getLanguageList,
  LANGUAGE_REQUEST,
  LANGUAGE_REQUEST_SUCCESS,
  LANGUAGE_REQUEST_FAIL,
  changeLanguage
};
