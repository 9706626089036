import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, backgroundImage, respondTo } from '@vfuk/core-mixins'

import type { StyledSkinnyBannerProps } from './SkinnyBanner.style.types'

export const SkinnyBanner = styled.div(
  (props: StyledSkinnyBannerProps): FlattenSimpleInterpolation => css`
    min-height: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    ${advancedSpacing('padding', [7, 0])};

    ${respondTo.lg(css`
      min-height: 184px;
    `)}

    ${props.backgroundImage &&
    css`
      ${backgroundImage(props.backgroundImage)}
    `}
  `,
)
