import type { SyntheticEvent } from 'react'

/**
 * Util that stops propagation and prevents default behavior on events
 */
function stopDefault<E extends SyntheticEvent<unknown> | Event | CustomEvent>(cb?: (event: E) => unknown): (event: E) => void {
  return (event: E): void => {
    event.preventDefault()
    event.stopPropagation()

    cb?.(event)
  }
}

export default stopDefault
