import type { Theme } from '@vfuk/core-themes'

import type { StateNotificationTheme } from './StateNotification.theme.types'

const defaultTheme = (theme: Theme): StateNotificationTheme => {
  return {
    appearance: {
      primary: {
        containerAppearance: 'primary',
        inverseHeading: false,
        buttonAppearance: 'alt1',
        iconBackgroundState: {
          success: {
            background: theme.color.success.default,
          },
          error: {
            background: theme.color.error.default,
          },
          warn: {
            background: theme.color.warn.default,
          },
          info: {
            background: theme.color.info.default,
          },
        },
      },
      secondary: {
        containerAppearance: 'primary',
        inverseHeading: false,
        buttonAppearance: 'alt1',
        iconBackgroundState: {
          success: {
            background: theme.color.success.default,
          },
          error: {
            background: theme.color.error.default,
          },
          warn: {
            background: theme.color.warn.default,
          },
          info: {
            background: theme.color.info.default,
          },
        },
      },
    },
  }
}

export default defaultTheme
