interface KeyPressConfig {
  [key: string]: (e: KeyboardEvent) => unknown
}
export default class KeyboardInterceptor {
  private container: HTMLElement

  private config: KeyPressConfig

  private fallbackCb: (event: KeyboardEvent) => void

  private handleKeyPress = (event: KeyboardEvent): void => {
    event.preventDefault()
    const code = event.code
    const key = event.key

    const callback = this.config[code] || this.config[key] || this.fallbackCb

    callback(event)
  }

  public setup(ref: HTMLElement, config: KeyPressConfig, fallbackCb: (event: KeyboardEvent) => void): void {
    this.container = ref
    this.config = config
    this.fallbackCb = fallbackCb

    this.container.addEventListener('keydown', this.handleKeyPress)
  }

  public destroy(): void {
    this.container.removeEventListener('keydown', this.handleKeyPress)
  }
}
