import type { Theme } from '@vfuk/core-themes'

import type { PillTheme } from './Pill.theme.types'

const defaultTheme = (theme: Theme): PillTheme => {
  return {
    appearance: {
      primary: {
        background: theme.color.secondary1.default,
        color: theme.inverseColor.monochrome6.default,
      },
      secondary: {
        background: theme.inverseColor.secondary4.default,
        color: theme.color.monochrome6.default,
      },
      alt1: {
        background: theme.color.monochrome6.default,
        color: theme.inverseColor.monochrome6.default,
      },
      alt2: {
        background: theme.color.monochrome2.default,
        color: theme.color.monochrome6.default,
      },
      alt3: {
        background: theme.color.monochrome1.default,
        color: theme.color.monochrome6.default,
      },
    },
    state: {
      success: {
        background: theme.color.success.default,
        color: theme.inverseColor.monochrome6.default,
      },
      error: {
        background: theme.color.error.default,
        color: theme.inverseColor.monochrome6.default,
      },
      warn: {
        background: theme.color.warn.default,
        color: theme.color.monochrome6.default,
      },
      incomplete: {
        background: theme.color.incomplete.default,
        color: theme.color.monochrome6.default,
      },
      info: {
        background: theme.color.info.default,
        color: theme.inverseColor.monochrome6.default,
      },
    },
  }
}

export default defaultTheme
