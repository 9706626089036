const isTextInputValueIncorrect = (
  value: number,
  allowNonIncrementValue: boolean,
  min: number,
  max: number,
  increment: number,
  previousValue: number | number[],
  textInputPosition: 'left' | 'right',
): boolean => {
  const valueLessThanMin = value < min
  const valueMoreThanMax = value > max
  const valueIsNotDividedByIncrement = value % increment !== 0

  // NOTE: Order is important due to the incorrect logic

  if (valueLessThanMin) return true

  if (valueMoreThanMax) return true

  if (!allowNonIncrementValue && valueIsNotDividedByIncrement) return true

  // If a dual slider, checking that the values don't overlap
  if (Array.isArray(previousValue)) {
    return Boolean(textInputPosition === 'left' ? value > previousValue[1] : previousValue[0] > value)
  }

  return false
}

export default isTextInputValueIncorrect
