import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { spacing, srOnly } from '@vfuk/core-mixins'

import type { StyledIconPickerProps } from './IconPicker.style.types'

export const IconWrapper = styled.span<StyledIconPickerProps>(
  (props: StyledIconPickerProps): FlattenSimpleInterpolation => css`
    display: flex;

    ${props.orientation === 'vertical' &&
    css`
      ${spacing('padding-top', 4)};
    `}
  `,
)

export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

export const PickerItemWrapper = styled.span((): FlattenSimpleInterpolation => [])

export const Fieldset = styled.fieldset((): FlattenSimpleInterpolation => [])
