import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/Divider.theme'

import * as Styled from './styles/Divider.style'

import type { DividerProps } from './Divider.types'

const Divider: FC<DividerProps> = ({
  appearance = 'primary',
  orientation = 'horizontal',
  level = 1,
  inverse = false,
  noMargin = false,
  id,
  dataSelectorPrefix,
  dataAttributes,
  localTheme,
}: DividerProps): ReactElement => {
  const componentName = 'Divider'
  const dividerTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  return (
    <Styled.Divider
      appearance={appearance!}
      dividerTheme={dividerTheme}
      orientation={orientation}
      level={level}
      inverse={inverse!}
      noMargin={noMargin}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    />
  )
}

export default Divider
