import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const Acronym = props => {
  const [clickNumber, setClickNumber] = useState(0);

  useEffect(() => {
    if (props.liveness) {
      props.liveness.update();
    }
  });

  return (
    <div
      style={{ border: 'solid', backgroundColor: 'red', color: 'white' }}
      onClick={() => {
        setClickNumber(clickNumber + 1);
        if (props.liveness) {
          props.liveness.update();
        }
      }}
    >
      {props.family_name && props.given_name ? (
        <h5>
          {props.given_name.substr(0, 1)}
          {props.family_name.substr(0, 1)}
        </h5>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  given_name: state.authentication ? state.authentication.given_name : null,
  family_name: state.authentication ? state.authentication.family_name : null
});

export default connect(mapStateToProps)(Acronym);
