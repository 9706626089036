import type { ReactNode, ReactElement } from 'react'
import React, { PureComponent } from 'react'
import { v4 as uuid } from 'uuid'
import isFunction from 'lodash/isFunction'

import { getDataSelector } from '@vfuk/core-base-props'

import animateToggle from './helpers/animateToggle'

import * as Styled from './styles/CollapsibleContainer.style'

import type { CollapsibleContainerProps, CollapsibleContainerState, DerivedState } from './CollapsibleContainer.types'

import { CollapsibleContainerContext } from './contexts/CollapsibleContainerContext'

export class CollapsibleContainer extends PureComponent<CollapsibleContainerProps, CollapsibleContainerState> {
  public componentName = 'CollapsibleContainer'

  public static defaultProps: Partial<CollapsibleContainerProps> = {
    appearance: 'primary',
    width: 'full',
  }

  public static animateTime = 200

  public constructor(props: CollapsibleContainerProps) {
    super(props)
    this.state = {
      id: props.id ? props.id : uuid(),
      isActive: this.props.isActive || false,
      isTransitioning: false,
      prevProps: this.props,
    }
  }

  public componentDidUpdate(previousProps: CollapsibleContainerProps, previousState: CollapsibleContainerState): void {
    if (!previousState.isTransitioning && this.state.isTransitioning) {
      animateToggle(this.state.isActive, this.state.id!, CollapsibleContainer.animateTime, this.endTransition)
    }
  }

  public static getDerivedStateFromProps(nextProps: CollapsibleContainerProps, prevState: CollapsibleContainerState): DerivedState {
    if (nextProps.isActive !== prevState.prevProps.isActive && nextProps.isActive !== undefined) {
      return {
        isActive: nextProps.isActive,
        isTransitioning: true,
        prevProps: nextProps,
      }
    }
    return null
  }

  private onToggle = (): void => {
    this.setState(
      {
        isTransitioning: true,
        isActive: !this.state.isActive,
      },
      (): void => {
        if (isFunction(this.props.onToggle)) {
          this.props.onToggle(this.state.isActive, this.state.id!, this)
        }
      },
    )
  }

  private endTransition = (): void => {
    this.setState({
      isTransitioning: false,
    })
  }

  public render(): ReactNode {
    return (
      <CollapsibleContainerContext.Provider
        value={{
          id: this.state.id,
          isActive: this.state.isActive,
          isTransitioning: this.state.isTransitioning,
          toggle: this.onToggle,
          appearance: this.props.appearance,
          isInverse: this.props.inverse,
          width: this.props.width,
          icons: this.props.icons,
        }}
      >
        <Styled.CollapsibleContainer
          id={this.props.id && `${this.props.id}-wrapper`}
          data-selector={getDataSelector(this.props.dataSelectorPrefix)}
          data-component-name={this.componentName}
          {...this.props.dataAttributes}
        >
          {React.Children.map(this.props.children, (child: ReactElement) => {
            return React.cloneElement(child, {
              isActive: this.state.isActive,
            })
          })}
        </Styled.CollapsibleContainer>
      </CollapsibleContainerContext.Provider>
    )
  }
}
