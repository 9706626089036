/* stylelint-disable property-no-vendor-prefix */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, applyFocus, spacing, typography } from '@vfuk/core-mixins'

import type { StyledTextInputProps } from './TextInput.style.types'

export const TextInputWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
  `,
)

export const TextInput = styled.input<StyledTextInputProps>((props: StyledTextInputProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && props.theme.formFields.state[props.state]

  return css`
    color: ${props.theme.formFields.color};
    transition: 0.4s box-shadow ease;
    border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
    border-radius: ${props.theme.formFields.border.radius};
    background-color: ${props.theme.formFields.backgroundColor};
    width: 100%;

    ${advancedSpacing('padding', [2, 3])}
    ${typography.heading(1, false)}

    ${props.state &&
    stateFromTheme &&
    css`
      color: ${stateFromTheme.color};
      background-color: ${stateFromTheme.backgroundColor};
      border-color: ${stateFromTheme.border.color};
      border-width: ${stateFromTheme.border.width};
    `}

    ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;

      &:focus {
        caret-color: transparent;
      }
    `}

    ${applyFocus()};

    ::-ms-reveal {
      display: none;
    }
  `
})

export const IconWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    ${spacing('padding-right', 3)}
  `,
)
