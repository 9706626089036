import dayjs from 'dayjs'
import IsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import type { PartialDateTextInputProps } from './inputErrorHandler.types'

import isMinDate from '../isMinDate'
import isMaxDate from '../isMaxDate'
import isFilterDate from '../isFilterDate'
import isIncludedDate from '../isIncludedDate'
import isExcludedDate from '../isExcludedDate'
import dateFormatter from '../dateFormatter'
import isParsedDateValid from '../isParsedDateValid'
import createErrorMessages from '../createErrorMessages'

dayjs.extend(IsSameOrAfter)
dayjs.extend(IsSameOrBefore)

export default function inputErrorHandler(rawDateValue: string, props: PartialDateTextInputProps): string[] {
  const parsedDate = dateFormatter(rawDateValue, props.showMonthYearPicker)
  const errors: string[] = []

  const validators: { [key: string]: (...args: unknown[]) => [boolean, string] } = {
    minDate: isMinDate,
    maxDate: isMaxDate,
    filterDate: isFilterDate,
    includeDates: isIncludedDate,
    excludeDates: isExcludedDate,
  }

  // Checks if the date is not in the right format: DD/MM/YYYY or MM/YYYY
  // otherwise, return error message: type date in the format DD/MM/YYYY or MM/YYYY
  const parsedValidDate = isParsedDateValid(parsedDate, props)
  if (parsedValidDate) return parsedValidDate

  // Returns props.errorMessages if provided
  // Returns default error message if props.errorMessages is not provided
  // Return nothing if props.errorMessages===null
  Object.keys(validators).forEach((propName: 'minDate' | 'maxDate' | 'includeDates' | 'excludeDates' | 'filterDate') => {
    if (props[propName]) {
      const [valid, errorMsg] = validators[propName](parsedDate, props[propName])

      const newErrors = createErrorMessages(props.errorMessages, valid, errorMsg, propName)
      if (newErrors !== null) errors.push(newErrors)
    }
  })
  return errors
}
