import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme } from '@vfuk/core-themes'

import type { MixinIconAppearance } from './iconAppearance.types'

import createMixin from '../utils/createMixin'

export default createMixin((theme: Theme, appearance: string, iconSet: string = 'system'): FlattenSimpleInterpolation => {
  const iconTheme = theme.getIconProperties(iconSet)
  return css`
    ${iconTheme.colorAppliesTo}: ${appearance};
  `
}) as MixinIconAppearance
