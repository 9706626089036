import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { colorSwatchTheme } from '@vfuk/core-color-swatch'
import { respondTo } from '@vfuk/core-mixins'

export const ColorSwatchPlaceholder = styled.div((): FlattenSimpleInterpolation => {
  return css`
    width: ${colorSwatchTheme().size[1].swatch.sm}px;
    height: ${colorSwatchTheme().size[1].swatch.sm}px;

    ${respondTo.md(css`
      width: ${colorSwatchTheme().size[1].swatch.md}px;
      height: ${colorSwatchTheme().size[1].swatch.md}px;
    `)}

    ${respondTo.lg(css`
      width: ${colorSwatchTheme().size[1].swatch.lg}px;
      height: ${colorSwatchTheme().size[1].swatch.lg}px;
    `)}
  `
})
