/* eslint-disable max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { responsiveSizing, srOnly } from '@vfuk/core-mixins'

import type { StyledCarouselDotPagerProps } from './CarouselDotPager.style.types'

import localThemes from '../../../themes/FunctionalCarousel.theme'

export const CarouselDotPager = styled.div(
  (props: Pick<StyledCarouselDotPagerProps, 'theme'>): FlattenSimpleInterpolation => css`
    ${responsiveSizing('width', 2)}
    ${responsiveSizing('height', 2)}
  border-radius: ${props.theme.border.radius[3]};
  `,
)

export const SrText = styled.span(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

export const CarouselDotPagerWrapper = styled.li<StyledCarouselDotPagerProps>((props: StyledCarouselDotPagerProps) => {
  const localTheme = localThemes(props.theme)
  const pagerDotAppearance = props.inverse
    ? localTheme.pagerDot.appearance[props.appearance].inverse
    : localTheme.pagerDot.appearance[props.appearance]

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    cursor: pointer;

    &:hover {
      ${CarouselDotPager} {
        background: ${pagerDotAppearance.hover.backgroundColor};
      }
    }

    ${CarouselDotPager} {
      background: ${pagerDotAppearance.backgroundColor};

      ${props.active &&
      css`
        background: ${pagerDotAppearance.active.backgroundColor};
      `}
    }
  `
})
