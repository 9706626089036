import type { FC, ReactElement } from 'react'

import React from 'react'

const inverseBrandLogo: FC = (): ReactElement => {
  return (
    <svg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0c3.052 0 5.943.683 8.531 1.906-.345-.108-.885-.148-1.402-.148-4.163.014-8.752 1.794-12.102 4.568-3.434 2.85-6.121 7.644-6.109 12.624.024 7.531 5.742 12.222 11.237 12.204 6.79-.023 10.802-5.6 10.785-10.684-.016-5.084-2.78-8.759-8.871-10.242a2.093 2.093 0 0 1-.024-.326c-.013-3.84 2.88-7.224 6.515-7.983C35.323 5.127 40 12.017 40 20Z'
        fill='#fff'
      />
    </svg>
  )
}

export default inverseBrandLogo
