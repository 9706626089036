/* eslint-disable max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, typography } from '@vfuk/core-mixins'

import type { StyledPillProps } from './Pill.style.types'

export const Pill = styled.span<StyledPillProps>((props: StyledPillProps): FlattenSimpleInterpolation => {
  return css`
    ${advancedSpacing('padding', [1, 3])};
    background: ${props.pillTheme.appearance[props.appearance!].background};
    color: ${props.pillTheme.appearance[props.appearance!].color};
    border-radius: ${props.theme.border.radius[3]};
    ${typography.paragraph(1, false)};

    ${props.state &&
    css`
      background: ${props.pillTheme.state[props.state].background};
      color: ${props.pillTheme.state[props.state].color};
    `}
  `
})
