import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { FunctionalTabPanel } from '@vfuk/core-functional-tabs'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Tab.style'

import type { TabProps } from './Tab.types'

class Tab extends PureComponent<TabProps> {
  public static defaultProps: Partial<TabProps> = {
    isLazy: true,
  }

  public render(): ReactNode {
    return (
      <Styled.TabContainer data-selector={getDataSelector(this.props.dataSelectorPrefix)}>
        <FunctionalTabPanel
          tabId={this.props.id}
          isLazy={this.props.isLazy}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'functional-tab-panel')}
        >
          {this.props.children}
        </FunctionalTabPanel>
      </Styled.TabContainer>
    )
  }
}

export default Tab
