import React from 'react'

import LinkWithIcon from '@vfuk/core-link-with-icon'
import InteractiveIcon from '@vfuk/core-interactive-icon'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Header.style'
import type { HeaderProps } from './Header.types'

import CloseButton from '../CloseButton'

const Header = ({
  header,
  srName,
  closeOnClick,
  inverse,
  isClosable,
  isSticky,
  dataSelectorPrefix,
  appearance,
  localTheme,
}: HeaderProps): JSX.Element => {
  const { linkWithIconAppearance, inverseLinkWithIcon, interactiveIconAppearance, inverseInteractiveIcon } =
    localTheme!.appearance[appearance!].header

  return (
    <Styled.Header
      isSticky={isSticky}
      data-selector={getDataSelector(dataSelectorPrefix)}
      appearance={appearance!}
      modalTheme={localTheme!}
    >
      <Styled.IconContainer hasLeftIcon={Boolean(header?.iconName)}>
        {header?.iconName && (
          <>
            {header?.text ? (
              <LinkWithIcon
                appearance={linkWithIconAppearance}
                inverse={inverseLinkWithIcon}
                srText={header?.srText}
                text={header!.text!}
                icon={{
                  name: header!.iconName!,
                  justify: 'right',
                }}
                href={header?.href}
                onClick={header?.onClick}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'link')}
              />
            ) : (
              <InteractiveIcon
                appearance={interactiveIconAppearance}
                inverse={inverseInteractiveIcon}
                srText={header!.srText!}
                name={header!.iconName!}
                href={header?.href}
                onClick={header?.onClick}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')}
              />
            )}
          </>
        )}
        {isClosable && (
          <CloseButton
            srName={srName}
            onClick={closeOnClick}
            inverse={inverse}
            isWithinHeader={Boolean(header)}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'close-button')}
          />
        )}
      </Styled.IconContainer>
      {header?.subHeader && header.subHeader}
    </Styled.Header>
  )
}

export default Header
