import type { TextStackTheme } from './TextStack.theme.types'

const defaultTheme = (): TextStackTheme => {
  return {
    appearance: {
      primary: {
        prefixAppearance: 'primary',
        currencyAppearance: 'primary',
        mainTextAppearance: 'primary',
        mainSuffixAppearance: 'primary',
        suffixAppearance: 'primary',
        suffix2Appearance: 'primary',
        negative: {
          currencyAppearance: 'secondary',
          mainTextAppearance: 'secondary',
        },
        inverseText: false,
        inverse: {
          prefixAppearance: 'primary',
          currencyAppearance: 'primary',
          mainTextAppearance: 'primary',
          mainSuffixAppearance: 'primary',
          suffixAppearance: 'primary',
          suffix2Appearance: 'primary',
          negative: {
            currencyAppearance: 'primary',
            mainTextAppearance: 'primary',
          },
          inverseText: true,
        },
      },
      secondary: {
        prefixAppearance: 'primary',
        currencyAppearance: 'primary',
        mainTextAppearance: 'primary',
        mainSuffixAppearance: 'primary',
        suffixAppearance: 'alt1',
        suffix2Appearance: 'primary',
        negative: {
          currencyAppearance: 'secondary',
          mainTextAppearance: 'secondary',
        },
        inverseText: false,
        inverse: {
          prefixAppearance: 'primary',
          currencyAppearance: 'primary',
          mainTextAppearance: 'primary',
          mainSuffixAppearance: 'primary',
          suffixAppearance: 'primary',
          suffix2Appearance: 'primary',
          negative: {
            currencyAppearance: 'primary',
            mainTextAppearance: 'primary',
          },
          inverseText: true,
        },
      },
      alt1: {
        prefixAppearance: 'primary',
        currencyAppearance: 'primary',
        mainTextAppearance: 'primary',
        mainSuffixAppearance: 'primary',
        suffixAppearance: 'secondary',
        suffix2Appearance: 'primary',
        negative: {
          currencyAppearance: 'secondary',
          mainTextAppearance: 'secondary',
        },
        inverseText: false,
        inverse: {
          prefixAppearance: 'primary',
          currencyAppearance: 'primary',
          mainTextAppearance: 'primary',
          mainSuffixAppearance: 'primary',
          suffixAppearance: 'primary',
          suffix2Appearance: 'primary',
          negative: {
            currencyAppearance: 'primary',
            mainTextAppearance: 'primary',
          },
          inverseText: true,
        },
      },
    },
    level: {
      1: {
        prefixWeight: 3,
        currencyWeight: 1,
        mainTextWeight: 1,
        mainSuffixWeight: 3,
        suffixWeight: 3,
        suffix2Weight: 3,
      },
      2: {
        prefixWeight: 2,
        currencyWeight: 3,
        mainTextWeight: 3,
        mainSuffixWeight: 2,
        suffixWeight: 2,
        suffix2Weight: 2,
      },
    },
    size: {
      1: {
        supportingText: {
          element: 'paragraph',
          size: 1,
        },
        currency: {
          element: 'paragraph',
          size: 1,
        },
        sign: {
          element: 'paragraph',
          size: 2,
        },
      },
      2: {
        supportingText: {
          element: 'paragraph',
          size: 2,
        },
        currency: {
          element: 'heading',
          size: 1,
        },
        sign: {
          element: 'heading',
          size: 1,
        },
      },
      3: {
        supportingText: {
          element: 'paragraph',
          size: 2,
        },
        currency: {
          element: 'heading',
          size: 2,
        },
        sign: {
          element: 'heading',
          size: 2,
        },
      },
      4: {
        supportingText: {
          element: 'heading',
          size: 1,
        },
        currency: {
          element: 'heading',
          size: 2,
        },
        sign: {
          element: 'heading',
          size: 2,
        },
      },
      5: {
        supportingText: {
          element: 'heading',
          size: 2,
        },
        currency: {
          element: 'heading',
          size: 3,
        },
        sign: {
          element: 'heading',
          size: 3,
        },
      },
    },
  }
}

export default defaultTheme
