import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import FieldWrapper from '@vfuk/core-field-wrapper'

import CheckboxFieldSet from './components/CheckboxFieldSet'

import type { CheckboxListProps } from './CheckboxList.types'

class CheckboxList extends PureComponent<CheckboxListProps> {
  public componentName = 'CheckboxList'

  public static defaultProps: Partial<CheckboxListProps> = {
    orientation: 'vertical',
  }

  public render(): ReactNode {
    return (
      <FieldWrapper
        id={this.props.id}
        label={this.props.fieldWrapper.label}
        showLabel={this.props.fieldWrapper.showLabel}
        state={this.props.state}
        helpText={this.props.fieldWrapper.helpText}
        helpLink={this.props.fieldWrapper.helpLink}
        subText={this.props.fieldWrapper.subText}
        stateText={this.props.fieldWrapper.stateText}
        optional={this.props.fieldWrapper.optional}
        required={this.props.fieldWrapper.required}
        renderAsLabel={false}
        formElementId={this.props.checkboxes.id}
        width={this.props.fieldWrapper.width}
        dataAttributes={this.props.dataAttributes}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
        componentName={this.componentName}
      >
        <CheckboxFieldSet
          checkboxes={this.props.checkboxes}
          label={this.props.fieldWrapper.label}
          showLabel={this.props.fieldWrapper.showLabel}
          orientation={this.props.orientation}
          inverse={this.props.inverse}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'checkbox-list')}
        />
      </FieldWrapper>
    )
  }
}

export default CheckboxList
