import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

export const CardGridWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
  `,
)

export const ShowMoreButton = styled.div(
  (): FlattenSimpleInterpolation => css`
    text-align: center;
    ${spacing('margin-top', 4)};
  `,
)
