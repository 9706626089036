import type { ReactElement, FC } from 'react'
import React, { Fragment } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import Icon from '@vfuk/core-icon'
import ListGroup from '@vfuk/core-list-group'

import * as Styled from './styles/StateText.style'
import type { StateTextProps } from './StateText.types'

import { STATE_TEXT } from '../../constants'

const StateText: FC<StateTextProps> = ({
  state,
  stateText,
  formElementId,
  validateOn,
  dataSelectorPrefix,
}: StateTextProps): ReactElement => {
  const stateTextId = `${formElementId}-${STATE_TEXT}`

  const getAriaLive = (): 'polite' | 'assertive' | 'off' => {
    if (!validateOn) return 'off'
    if (state === 'error') return 'assertive'
    return 'polite'
  }

  const isMultipleStateText = Array.isArray(stateText) && stateText?.length > 1

  return (
    <Fragment>
      <Styled.StateTextContainer
        id={stateTextId}
        multipleStateText={isMultipleStateText}
        state={state}
        aria-live={getAriaLive()}
        aria-atomic={true}
        data-selector={getDataSelector(dataSelectorPrefix)}
      >
        <Icon name={state} group='state' size={1} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')} />
        {isMultipleStateText ? (
          <ListGroup dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'multiple-state-text')}>
            {(stateText as string[]).map((text, key) => {
              return (
                <Styled.StateText state={state} key={key} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `state-text-${key}`)}>
                  {text}
                </Styled.StateText>
              )
            })}
          </ListGroup>
        ) : (
          stateText
        )}
      </Styled.StateTextContainer>
    </Fragment>
  )
}

export default StateText
