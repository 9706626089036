import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Span from '@vfuk/core-span'
import { spacing, typography } from '@vfuk/core-mixins'
import type { HeadingKeys, ParagraphKeys } from '@vfuk/core-themes'

import type {
  StyledPriceContainerProps,
  StyledMainContentProps,
  StyledTextStackProps,
  StyledTextWrapperProps,
} from './TextStack.style.types'

export const TextStack = styled.div(
  (props: StyledTextStackProps): FlattenSimpleInterpolation => css`
    text-align: ${props.justify};
  `,
)

export const TextWrapper = styled(Span)((props: StyledTextWrapperProps): FlattenSimpleInterpolation => {
  const textElement = props.textStackTheme.size[props.size][props.textType]
  return css`
    ${textElement.element === 'paragraph' &&
    css`
      ${typography.paragraph(textElement.size as ParagraphKeys, false)}
    `}

    ${textElement.element === 'heading' &&
    css`
      ${typography.heading(textElement.size as HeadingKeys, false)}
    `}

    ${props.weight && `font-weight: ${props.theme.fontWeight[props.weight]};`}
  `
})

export const MainContent = styled(Span)(
  (props: StyledMainContentProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: baseline;

    ${typography.heading(props.size, false)}

    ${props.justify === 'right' &&
    css`
      justify-content: flex-end;
    `}
  `,
)

export const PriceContainer = styled.div<StyledPriceContainerProps>(
  (props: StyledPriceContainerProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: baseline;

    ${props.currencyJustify === 'right' &&
    css`
      flex-direction: row-reverse;
    `}
  `,
)

export const MainSuffixWrapper = styled.span(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-left', 1)}
  `,
)
