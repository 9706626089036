import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme, SpacingKeys } from '@vfuk/core-themes'

import type { MixinResponsiveSizing, Properties } from './responsiveSizing.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (theme: Theme, property: Properties, value: SpacingKeys): FlattenSimpleInterpolation => css`
    ${property}:${theme.spacing.responsive[value!].sm}px;

    ${respondTo.md(css`
      ${property}: ${theme.spacing.responsive[value!].md}px;
    `)}

    ${respondTo.lg(css`
      ${property}: ${theme.spacing.responsive[value!].lg}px;
    `)}

    ${respondTo.xl(css`
      ${property}: ${theme.spacing.responsive[value!].xl}px;
    `)}
  `,
) as MixinResponsiveSizing
