import type { ReactNode, ReactElement } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import { v4 as uuid } from 'uuid'

import { withTranslation } from 'react-i18next'

import isFunction from 'lodash/isFunction'

import TextInput from '@vfuk/core-text-input'
import Interaction from '@vfuk/core-interaction'

import { onClickOutside } from '@vfuk/core-helpers'

import * as Styled from './styles/SearchSuggestion.style'

import type { SearchSuggestionProps, SearchSuggestionState, Suggestion } from './SearchSuggestion.types'

export class SearchSuggestion extends PureComponent<SearchSuggestionProps, SearchSuggestionState> {
  private searchSuggestionRef = React.createRef<HTMLDivElement>()

  public componentName = 'SearchSuggestion'

  private removeEventListeners: () => void

  public static defaultProps: Partial<SearchSuggestionProps> = {
    minCharacters: 3,
  }

  public constructor(props: SearchSuggestionProps) {
    super(props)
    this.state = {
      id: props.id ? props.id : uuid(),
      openSuggestions: false,
      suggestedResults: [],
      activeItemIndex: -1,
    }
  }

  componentDidMount(): void {
    this.removeEventListeners = onClickOutside(this.searchSuggestionRef, this.closeSuggestions)
  }

  componentWillUnmount(): void {
    this.removeEventListeners()
  }

  private filterSuggestions = async (value: string): Promise<void> => {
    if (value.length <= this.props.minCharacters - 1) {
      this.closeSuggestions()
      return
    }

    const lowerCaseValue = value.toLowerCase()

    const emptyResults = [
      {
        text: this.props.t!('No results found', { value }),
      },
    ]

    const suggestionsList = this.props.handleFiltering
      ? await this.props.handleFiltering(value)
      : (this.props.suggestions ?? []).filter((suggestion) => {
          return (
            (suggestion.text.toLowerCase().includes(lowerCaseValue) ||
              suggestion.searchAlternatives?.toLowerCase().includes(lowerCaseValue) ||
              suggestion.code?.toLowerCase().includes(lowerCaseValue)) &&
            !suggestion?.default
          )
        })

    const defaultSuggestion = this.props.suggestions?.find((suggestion) => suggestion?.default)

    if (defaultSuggestion) {
      suggestionsList.unshift(defaultSuggestion)
    }

    this.setState({
      suggestedResults: suggestionsList.length ? suggestionsList : emptyResults,
      openSuggestions: true,
    })
  }

  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.state === 'disabled') return

    this.filterSuggestions(event.target.value)
    this.fireOnChange(event.target.value)
  }

  private fireOnChange(value: string): void {
    if (this.props.state !== 'disabled' && this.props.onChange) {
      if (this.props.beforeChange) {
        value = this.props.beforeChange(value)
      }
      this.props.onChange(value)
    }
  }

  private closeSuggestions = (): void => {
    this.setState({
      openSuggestions: false,
    })
  }

  private setSearchTerm = (suggestion: Suggestion): void => {
    this.closeSuggestions()
    this.fireOnChange(suggestion.text)

    if (isFunction(this.props.onSelect)) {
      this.props.onSelect(suggestion)
    }
  }

  /* eslint-disable complexity, sonarjs/cognitive-complexity */
  private arrowKeyPress = (event: React.KeyboardEvent): void => {
    if (!this.state.openSuggestions) return

    let newActiveItemIndex = -1

    // Up arrow press
    if (event.keyCode === 38) {
      event.preventDefault()
      if (this.state.activeItemIndex === 0) {
        const input = document.getElementById(this.state.id)
        if (input) {
          input.focus()
        }
      } else {
        newActiveItemIndex = this.state.activeItemIndex - 1
      }
    }

    // Down arrow press
    if (event.keyCode === 40) {
      event.preventDefault()
      if (this.state.suggestedResults && this.state.activeItemIndex === this.state.suggestedResults.length - 1) return
      newActiveItemIndex = this.state.activeItemIndex + 1
    }

    this.setState(
      {
        activeItemIndex: newActiveItemIndex,
      },
      this.buttonFocus,
    )
  }

  /* eslint-enable complexity, sonarjs/cognitive-complexity */

  private buttonFocus(): void {
    if (this.state.activeItemIndex === -1) return

    const buttonParentId = `${this.state.id}-suggestion-${this.state.activeItemIndex}`
    const listItem = document.getElementById(buttonParentId)
    if (listItem) {
      const buttons = listItem.querySelectorAll('button')
      buttons[0].focus()
    }
  }

  public render(): ReactNode {
    return (
      <Styled.SearchSuggestion
        ref={this.searchSuggestionRef}
        id={`${this.props.id}-search-suggestion`}
        {...this.props.dataAttributes}
        data-component-name={this.componentName}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
      >
        <TextInput
          id={this.state.id}
          name={this.props.name || this.props.id}
          type='text'
          value={this.props.value}
          onKeyDown={this.arrowKeyPress}
          onChange={this.handleOnChange}
          placeholder={this.props.placeholder}
          required={this.props.required}
          dataAttributes={this.props.dataAttributes}
          state={this.props.state}
          autoComplete='off'
          describedBy={this.props.describedBy}
          domRef={this.props.domRef}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, this.props.name)}
        />

        <Styled.SuggestionList
          isOpen={this.state.openSuggestions}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'list-group')}
        >
          {this.state.suggestedResults!.map((suggestion, index): ReactElement => {
            return (
              <Styled.Suggestion
                key={index}
                id={`${this.state.id}-suggestion-${index}`}
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `list-item-${index}`)}
              >
                <Interaction
                  onClick={(): void => this.setSearchTerm(suggestion)}
                  onKeyDown={this.arrowKeyPress}
                  dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `interaction-${index}`)}
                >
                  {suggestion.text}
                  {suggestion.subText && (
                    <Styled.Subtext data-selector={getDataSelector(this.props.dataSelectorPrefix, `sub-text-${index}`)}>
                      {suggestion.subText}
                    </Styled.Subtext>
                  )}
                </Interaction>
              </Styled.Suggestion>
            )
          })}
        </Styled.SuggestionList>
      </Styled.SearchSuggestion>
    )
  }
}

export default withTranslation()(SearchSuggestion)
