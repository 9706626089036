import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { responsiveSizing, spacing, srOnly, typography } from '@vfuk/core-mixins'

import { TextInputStyles } from '@vfuk/core-text-input'

import type { StyledGroupedTextInputProps } from './GroupedTextInput.style.types'

export const TextInputContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
  `,
)

export const TextInputLabelAccessibility = styled.label<StyledGroupedTextInputProps>(
  (props: StyledGroupedTextInputProps): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: flex-start;
    color: ${props.theme.formLabels.color};
    ${typography.paragraph(1, false)}
    ${spacing('margin-bottom', 3)}
    ${!props.showLabel && srOnly()}
  `,
)

export const TextInputWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-right', 2)}
    ${responsiveSizing('width', 10)}

  span {
      display: none;
    }

    ${TextInputStyles.TextInput} {
      text-align: center;
    }

    &:last-child {
      margin-right: 0;
    }
  `,
)

// eslint-disable-next-line
export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)
