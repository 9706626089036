import type { FC, ReactElement } from 'react'
import React from 'react'

import Container from '@vfuk/core-container'
import Icon from '@vfuk/core-icon'
import Paragraph from '@vfuk/core-paragraph'
import InlineLink from '@vfuk/core-inline-link'
import InteractiveIcon from '@vfuk/core-interactive-icon'
import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SimpleNotification.style'
import defaultTheme from './themes/SimpleNotification.theme'

import type { SimpleNotificationProps } from './SimpleNotification.types'

const SimpleNotification: FC<SimpleNotificationProps> = ({
  appearance = 'primary',
  icon,
  link,
  text,
  closeButton,
  children,
  localTheme,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: SimpleNotificationProps): ReactElement => {
  const componentName = 'SimpleNotification'
  const simpleNotificationTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  const { containerAppearance, iconAppearance, inverseIcon, inverseText } = simpleNotificationTheme.appearance[appearance]

  return (
    <Container
      appearance={containerAppearance}
      elevation={true}
      id={id}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName}
      dataAttributes={dataAttributes}
    >
      <Styled.NotificationBody>
        {icon && (
          <Styled.NotificationIcon>
            <Icon
              name={icon!.name}
              group={icon!.group}
              size={4}
              appearance={iconAppearance}
              inverse={inverseIcon}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')}
            />
          </Styled.NotificationIcon>
        )}
        {children || (
          <Paragraph noMargin inverse={inverseText} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'text')}>
            {text}
            {link && (
              <>
                {` `}
                <InlineLink {...link} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'link')}>
                  {link!.text}
                </InlineLink>
              </>
            )}
          </Paragraph>
        )}
        {closeButton && (
          <Styled.CloseButton>
            <InteractiveIcon
              srText={closeButton!.srText}
              name='close'
              appearance={iconAppearance}
              inverse={inverseIcon}
              onClick={closeButton!.onClick}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'close-button')}
            />
          </Styled.CloseButton>
        )}
      </Styled.NotificationBody>
    </Container>
  )
}

export default SimpleNotification
