import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { getIconSize } from '@vfuk/core-icon/dist/styles/mixins/iconSizes'

import { StyledIcon5gProps } from './Icon5g.style.types'

import ruleSet from '../../../ruleSet'

export const IconWrapper = styled.span<StyledIcon5gProps>((props: StyledIcon5gProps): FlattenSimpleInterpolation => {
  const localTheme = ruleSet()

  return css`
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    span {
      display: inline-flex;
    }

    svg {
      ${getIconSize(props.isResponsive, localTheme.size[props.size!])}
    }
  `
})
