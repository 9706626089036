import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing } from '@vfuk/core-mixins'

import type { StyledDividerProps } from './Divider.style.types'

export const Divider = styled.hr<StyledDividerProps>((props: StyledDividerProps): FlattenSimpleInterpolation => {
  const isHorizontal = props.orientation === 'horizontal'
  const borderColor = props.inverse
    ? props.dividerTheme.appearance[props.appearance!].inverse.color
    : props.dividerTheme.appearance[props.appearance!].color

  const variableMargin = props.noMargin ? 0 : 2
  const dividerStyle = `${props.theme.border.width[1]} ${props.dividerTheme.level[props.level!].borderStyle} ${borderColor}`

  return css`
    width: ${isHorizontal ? '100%' : 0};
    height: ${isHorizontal ? 0 : '100%'};

    ${isHorizontal &&
    css`
      ${advancedSpacing('margin', [variableMargin, 0])}
    `}

    ${!isHorizontal &&
    css`
      ${advancedSpacing('margin', [0, variableMargin])}
    `}

    padding: 0;
    border: 0;
    ${isHorizontal ? 'border-top' : 'border-left'}: ${dividerStyle};
  `
})
