import type { ReactNode } from 'react'
import React from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'

import Animate from '@vfuk/core-animate'
import Overlay from '@vfuk/core-overlay'
import { getDataSelector } from '@vfuk/core-base-props'
import { withLocalTheme } from '@vfuk/core-themes'

import * as Styled from './styles/BottomTrayRenderer.style'

import type { BottomTrayRendererProps } from './BottomTrayRenderer.types'

import Renderer from '../Renderer'

import setInitialFocus from '../utils/setInitialFocus'

import type { RendererState } from '../Renderer.types'
import { OverlayCloseSource } from '../constants/constants'

import CloseButton from '../components/CloseButton'
import defaultTheme from './themes/BottomTrayRenderer.theme'
import { BottomTrayRendererTheme } from './themes/BottomTrayRenderer.theme.types'

export class BottomTrayRenderer extends Renderer<BottomTrayRendererProps, RendererState> {
  public static defaultProps: Partial<BottomTrayRendererProps> = {
    overlayBlur: false,
    enableOverlay: false,
    isClosable: true,
    appearance: 'primary',
  }

  public render(): ReactNode {
    const animatedDuration = this.props.animate ? 200 : 0

    return createPortal(
      <Styled.BottomTrayRenderer
        zIndex={this.props.zIndex}
        id={this.props.id}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.props.componentName}
        {...this.props.dataAttributes}
      >
        <Animate
          show={this.state.show}
          enter={{
            animations: this.props.animate ? ['slideFromBottom'] : ['none'],
            duration: animatedDuration,
            delay: 200,
            onDone: (): void => setInitialFocus(this.props.initialFocusId),
          }}
          exit={{
            animations: this.props.animate ? ['slideToBottom'] : ['none'],
            duration: animatedDuration,
            onDone: this.onDoneCallback,
          }}
        >
          <Styled.BottomTrayRendererContent
            zIndex={this.props.zIndex}
            isClosable={this.props.isClosable}
            data-selector={getDataSelector(this.props.dataSelectorPrefix, 'content')}
            appearance={this.props.appearance!}
            bottomTrayRendererTheme={this.props.localTheme!}
          >
            <FocusLock shards={this.props.focusEnabledRefs}>
              {this.props.isClosable && (
                <CloseButton
                  srName={this.props.srName}
                  onClick={this.getOnCloseHandler(OverlayCloseSource.BOTTOMTRAY_CROSS_CLICK)}
                  dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'close-button')}
                />
              )}
              {this.props.children}
            </FocusLock>
          </Styled.BottomTrayRendererContent>
        </Animate>
        {this.props.enableOverlay && (
          <Animate
            show={this.state.show}
            enter={{
              animations: this.props.animate ? ['fadeIn'] : ['none'],
              duration: this.props.animate ? 400 : 0,
            }}
            exit={{
              animations: this.props.animate ? ['fadeOut'] : ['none'],
              duration: animatedDuration,
            }}
          >
            <Overlay
              show
              onClick={this.props.isClosable ? this.getOnCloseHandler(OverlayCloseSource.BOTTOMTRAY_OUTSIDE_CLICK) : undefined}
              position='fixed'
              zIndex={this.props.zIndex - 1}
              blur={this.props.overlayBlur}
              dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'overlay')}
            />
          </Animate>
        )}
      </Styled.BottomTrayRenderer>,
      this.props.rootElement,
    )
  }
}

export default withLocalTheme<BottomTrayRendererProps, BottomTrayRendererTheme>(BottomTrayRenderer, defaultTheme)
