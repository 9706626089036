import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Span from '@vfuk/core-span'
import { typography } from '@vfuk/core-mixins'
import type { HeadingKeys, ParagraphKeys } from '@vfuk/core-themes'

import type { MainContentProps, StyledSandwichProps, StyledTextWrapperProps } from './Sandwich.style.types'

export const Sandwich = styled.div(
  (props: StyledSandwichProps): FlattenSimpleInterpolation => css`
    text-align: ${props.justify};
  `,
)

export const TextWrapper = styled(Span)((props: StyledTextWrapperProps): FlattenSimpleInterpolation => {
  const textElement = props.sandwichTheme.size[props.size][props.textType]
  return css`
    ${textElement.element === 'paragraph' &&
    css`
      ${typography.paragraph(textElement.size as ParagraphKeys, false)}
    `}

    ${textElement.element === 'heading' &&
    css`
      ${typography.heading(textElement.size as HeadingKeys, false)}
    `}

    ${props.weight && `font-weight: ${props.theme.fontWeight[props.weight]};`}
  `
})

export const MainContent = styled(Span)(
  (props: MainContentProps): FlattenSimpleInterpolation => css`
    ${typography.heading(props.size, false)}
  `,
)
