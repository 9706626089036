import type { RendererProps } from '../Renderer.types'

const setInitialFocus = (initialFocusId: RendererProps['initialFocusId']): void => {
  if (!initialFocusId) return

  const targetElement: HTMLElement | null = document.getElementById(initialFocusId)
  if (targetElement) {
    setTimeout(() => {
      targetElement.focus()
    })
  }
}

export default setInitialFocus
