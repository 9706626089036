import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import Icon from '@vfuk/core-icon'
import Span from '@vfuk/core-span'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/Tag.theme'
import * as Styled from './styles/Tag.style'

import type { TagProps } from './Tag.types'
import type { Appearance } from './themes/Tag.theme.types'

const Tag: FC<TagProps> = ({
  appearance = 'primary',
  id,
  dataAttributes,
  inverse = false,
  onClick,
  icon,
  text,
  dataSelectorPrefix,
  localTheme,
}): ReactElement => {
  const componentName = 'Tag'
  const tagTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  let themeAppearance = tagTheme.appearance[appearance]
  if (inverse) {
    themeAppearance = themeAppearance.inverse as Appearance
  }

  const { inverseText, inverseIcon } = themeAppearance

  return (
    <Styled.Tag
      tagTheme={tagTheme}
      appearance={appearance}
      onClick={onClick}
      inverse={inverse}
      id={id}
      componentName={componentName}
      dataAttributes={dataAttributes}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
    >
      {icon && (
        <Icon
          name={icon!.name}
          group='system'
          size={1}
          appearance='primary'
          inverse={inverseIcon}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, icon?.name)}
        />
      )}
      <Span weight={3} inverse={inverseText} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'text')}>
        {text}
      </Span>
      <Icon
        name='close'
        group='system'
        size={1}
        appearance='primary'
        inverse={inverseIcon}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'close')}
      />
    </Styled.Tag>
  )
}

export default Tag
