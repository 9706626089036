import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import BannerBody from '@vfuk/core-banner-body'
import Overlay from '@vfuk/core-overlay'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SkinnyBanner.style'

import type { SkinnyBannerProps } from './SkinnyBanner.types'

export class SkinnyBanner extends PureComponent<SkinnyBannerProps> {
  public componentName = 'SkinnyBanner'

  public static defaultProps: Partial<SkinnyBannerProps> = {
    enableOverlay: false,
  }

  public render(): ReactNode {
    return (
      <Styled.SkinnyBanner
        backgroundImage={this.props.backgroundImage}
        id={this.props.id}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
        {...this.props.dataAttributes}
      >
        {this.props.enableOverlay && (
          <Overlay show position='absolute' zIndex={1} dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'overlay')} />
        )}
        <BannerBody
          justify='center'
          inverse={true}
          heading={{
            level: this.props.heading.level || 2,
            text: this.props.heading.text,
            weight: 4,
          }}
          text={this.props.text}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'banner-body')}
        />
      </Styled.SkinnyBanner>
    )
  }
}

export default SkinnyBanner
