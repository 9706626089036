/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { advancedSpacing, iconAppearance, respondTo, spacing, opacity } from '@vfuk/core-mixins'
import { IconStyles } from '@vfuk/core-icon'
import { LoadingSpinnerStyles } from '@vfuk/core-loading-spinner'

import type { StyledButtonProps } from './Button.style.types'

export const Button = styled(Interaction)((props: StyledButtonProps): FlattenSimpleInterpolation => {
  const themeAppearance = props.buttonTheme.appearance[props.appearance!]!

  return css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    transition: 0.4s all ease;
    line-height: 12px;
    width: 100%;
    height: 44px;
    min-width: 152px;
    ${props.icon?.justify === 'right' &&
    css`
      flex-direction: row-reverse;
    `}

    font-weight: ${props.theme.fontWeight[2]};
    color: ${themeAppearance.color};
    background: ${themeAppearance.backgroundColor};
    border-radius: ${props.buttonTheme.borderRadius};
    ${advancedSpacing('padding', [3, 5])};

    ${IconStyles.Icon} {
      transition: 0.4s all ease;
    }

    border: ${props.theme.border.style[1]} ${props.buttonTheme.borderWidth} ${themeAppearance.borderColor};

    ${respondTo.md(css`
      ${props.width === 'auto' &&
      css`
        width: auto;
      `}
    `)}

    ${props.state &&
    css`
      ${props.state !== 'disabled' &&
      css`
        color: ${props.buttonTheme.state![props.state].color};
        background: ${props.buttonTheme.state![props.state].backgroundColor};

        border: ${props.theme.border.style[1]} ${props.buttonTheme.borderWidth} ${props.buttonTheme.state![props.state].borderColor};
      `}
    `}

    ${props.state === 'disabled' &&
    css`
      ${opacity(true)};
      cursor: not-allowed;
    `}

    ${!props.loading &&
    css`
      ${props.icon?.justify !== 'right' &&
      css`
        ${IconStyles.Icon} {
          ${spacing('margin-right', 2)}
        }
      `}

      ${props.icon?.justify === 'right' &&
      css`
        ${IconStyles.Icon} {
          ${spacing('margin-left', 2)}
        }
      `}
    `}

    ${props.state !== 'disabled' &&
    css`
      &:hover {
        color: ${themeAppearance.hover.color};
        background: ${themeAppearance.hover.backgroundColor};
        border-color: ${themeAppearance.hover.borderColor};

        ${IconStyles.Icon} {
          * {
            ${iconAppearance(themeAppearance.hover.color)};
          }
        }

        ${LoadingSpinnerStyles.LoadingSpinner} {
          svg {
            & > * {
              stroke: ${themeAppearance.hover.color};
            }
          }
        }

        ${props.state &&
        css`
          color: ${props.buttonTheme.state![props.state].hover.color};
          background: ${props.buttonTheme.state![props.state].hover.backgroundColor};
          border-color: ${props.buttonTheme.state![props.state].hover.borderColor};

          ${IconStyles.Icon} {
            * {
              ${iconAppearance(props.buttonTheme.state![props.state].hover.color)};
            }
          }

          ${LoadingSpinnerStyles.LoadingSpinner} {
            svg {
              & > * {
                stroke: ${props.buttonTheme.state![props.state].hover.color};
              }
            }
          }
        `}
      }
    `}

    ${props.state !== 'disabled' &&
    themeAppearance.pressed &&
    css`
      &:active {
        background: ${themeAppearance.pressed?.backgroundColor};
        border-color: ${themeAppearance.pressed?.borderColor};
        color: ${themeAppearance.pressed?.color};

        ${IconStyles.Icon} {
          * {
            ${iconAppearance(themeAppearance.pressed!.color)};
          }
        }

        ${LoadingSpinnerStyles.LoadingSpinner} {
          svg {
            & > * {
              stroke: ${themeAppearance.pressed?.color};
            }
          }
        }

        ${props.state &&
        css`
          background: ${props.buttonTheme.state![props.state]?.pressed?.backgroundColor};
          border-color: ${props.buttonTheme.state![props.state]?.pressed?.borderColor};
          color: ${props.buttonTheme.state![props.state]?.pressed?.color};
        `}
      }
    `}

    ${props.inverse &&
    css`
      color: ${themeAppearance.inverse!.color};
      background: ${themeAppearance.inverse!.backgroundColor};
      border-color: ${themeAppearance.inverse!.borderColor};

      ${props.state &&
      css`
        ${props.state !== 'disabled' &&
        css`
          color: ${props.buttonTheme.state![props.state].color};
          background: ${props.buttonTheme.state![props.state].backgroundColor};
          border-color: ${props.buttonTheme.state![props.state].borderColor};
        `}
      `}

      ${props.state === 'disabled' &&
      css`
        cursor: not-allowed;
      `}

      ${props.state !== 'disabled' &&
      css`
        &:hover {
          color: ${themeAppearance.inverse!.hover.color};
          background: ${themeAppearance.inverse!.hover.backgroundColor};
          border-color: ${themeAppearance.inverse!.hover.borderColor};

          ${props.state &&
          css`
            color: ${props.buttonTheme.state![props.state].hover.color};
            background: ${props.buttonTheme.state![props.state].hover.backgroundColor};
            border-color: ${props.buttonTheme.state![props.state].hover.borderColor};
          `}

          ${IconStyles.Icon} {
            * {
              ${iconAppearance(themeAppearance.inverse!.hover.color)};
            }
          }

          ${LoadingSpinnerStyles.LoadingSpinner} {
            svg {
              & > * {
                stroke: ${themeAppearance.inverse!.hover.color};
              }
            }
          }
        }
      `}

      ${props.state !== 'disabled' &&
      themeAppearance.inverse!.pressed &&
      css`
        &:active {
          background: ${themeAppearance.inverse!.pressed?.backgroundColor};
          border-color: ${themeAppearance.inverse!.pressed?.borderColor};
          color: ${themeAppearance.inverse!.pressed?.color};

          ${IconStyles.Icon} {
            * {
              ${iconAppearance(themeAppearance.inverse!.pressed!.color)};
            }
          }

          ${LoadingSpinnerStyles.LoadingSpinner} {
            svg {
              & > * {
                stroke: ${themeAppearance.inverse!.pressed?.color};
              }
            }
          }

          ${props.state &&
          css`
            background: ${props.buttonTheme.state![props.state]?.pressed?.backgroundColor};
            border-color: ${props.buttonTheme.state![props.state]?.pressed?.borderColor};
            color: ${props.buttonTheme.state![props.state]?.pressed?.color};
          `}
        }
      `}
    `}
  `
})

export const HiddenText = styled.span((): FlattenSimpleInterpolation => {
  return css`
    visibility: hidden;
  `
})

export const LoadingIcon = styled.div((): FlattenSimpleInterpolation => {
  return css`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
})
/* eslint-enable complexity, sonarjs/cognitive-complexity */
