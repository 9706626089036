import type { Dictionary } from '../../dictionary.types'

const it: Dictionary = {
  Optional: 'Opzionale',
  Required: 'Necessaria',
  Show: 'Mostra',
  Hide: 'Nascondi',
  Search: 'Cerca',
  Clear: 'Pulisci',
  'No results found': 'Nessun risultato trovato pér {{value}}',
  'Go back': 'Indietro',
  Carousel: 'Carousel',
  Edit: 'Modifica',
  Item: '(0)[Oggetto];(1-inf)[Oggetti];',
  'Go to': 'Vai a',
  Back: 'Indientro',
  'Go to the previous page': 'Vai alla pagina preccedente',
  'Go to the next page': 'Vai alla pagina successiva',
  Play: 'Avvia',
  Pause: 'Ferma',
  'Show more': 'Mostra di piu',
  Next: 'Prossimo',
  'Clear filters': 'Pullisci i flitri',
  'Sort by': 'Ordina per',
  Confirm: 'Conferma',
  Cancel: 'Cancella',
  Retry: 'Riprova',
  Undo: 'Annullamento',
  Apply: 'Applica',
  'Clear all': 'Cancella tutto',
  Error: 'Errore',
  Complete: 'Completa',
  Incomplete: 'Incompleta',
  Loading: 'Caricamento in corso',
  Close: 'Chiudi',
  Reset: 'Reseta',
  'Find out more': 'Scopri di più',
  Total: 'Totale',
  Iteration: '{{iterator}} di {{array}}',
  'Use your current location': 'Usa la tua posizione attuale',
  'Zoom in': 'Ingrandire',
  'Navigates to': 'Naviga verso {{direction}}',
  'Filter options': 'Opzioni di filtro',
  Filter: '(0)[Filtro];(1-inf)[Filtri];',
  Applied: 'Applicato',
  'Language name': 'Italiano',
  'More info': 'Ulteriori informazioni',
  From: 'Da',
  To: 'A',
}

export default it
