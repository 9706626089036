import type { ColorSwatchTheme } from './ColorSwatch.theme.types'

const defaultTheme = (): ColorSwatchTheme => {
  return {
    size: {
      1: {
        swatch: {
          sm: 16,
          md: 16,
          lg: 16,
          xl: 16,
        },
        iconSize: 1,
      },
      2: {
        swatch: {
          sm: 20,
          md: 20,
          lg: 20,
          xl: 20,
        },
        iconSize: 1,
      },
      3: {
        swatch: {
          sm: 20,
          md: 24,
          lg: 24,
          xl: 24,
        },
        iconSize: 2,
      },
      4: {
        swatch: {
          sm: 24,
          md: 32,
          lg: 32,
          xl: 32,
        },
        iconSize: 3,
      },
      5: {
        swatch: {
          sm: 32,
          md: 36,
          lg: 36,
          xl: 36,
        },
        iconSize: 4,
      },
    },
  }
}

export default defaultTheme
