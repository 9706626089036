import type { ReactElement, FC } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import * as Styled from './styles/Paragraph.style'
import defaultTheme from './themes/Paragraph.theme'

import type { ParagraphProps } from './Paragraph.types'

const Paragraph: FC<ParagraphProps> = ({
  appearance = 'primary',
  inverse = false,
  size = 2,
  noMargin = false,
  justify,
  weight,
  children,
  dataAttributes,
  dataSelectorPrefix,
  id,
  localTheme,
}: ParagraphProps): ReactElement => {
  const componentName = 'Paragraph'
  const paragraphTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  return (
    <Styled.Paragraph
      paragraphTheme={paragraphTheme}
      size={size!}
      appearance={appearance}
      inverse={inverse}
      justify={justify}
      weight={weight}
      noMargin={noMargin!}
      id={id}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
    >
      {children}
    </Styled.Paragraph>
  )
}

export default Paragraph
