import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Radio.style'

import type { RadioProps } from './Radio.types'

export class Radio extends PureComponent<RadioProps> {
  public static defaultProps: Partial<RadioProps> = {
    required: false,
  }

  public componentName = 'Radio'

  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.state !== 'disabled' && this.props.onChange) {
      this.props.onChange(event)
    }
  }

  public render(): ReactNode {
    return (
      <Styled.RadioWrapper
        id={`${this.props.id}-radio`}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        {...this.props.dataAttributes}
        data-component-name={this.componentName}
      >
        <Styled.Radio
          type='radio'
          required={this.props.required}
          disabled={this.props.state === 'disabled'}
          id={this.props.id}
          onChange={this.handleOnChange}
          checked={this.props.checked}
          name={this.props.groupName}
          value={this.props.value}
          {...this.props.dataAttributes}
          aria-disabled={this.props.state === 'disabled'}
          aria-describedby={this.props.describedBy}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'radio')}
        />
        <Styled.LabelText
          htmlFor={this.props.id}
          disabled={this.props.state === 'disabled'}
          checked={this.props.checked}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'label')}
        >
          {this.props.srText ? (
            <Styled.HiddenLabel data-selector={getDataSelector(this.props.dataSelectorPrefix, 'hidden-label')}>
              {this.props.srText}
            </Styled.HiddenLabel>
          ) : (
            this.props.children
          )}
        </Styled.LabelText>
        {this.props.srText && <Styled.FullWidthWrapper>{this.props.children}</Styled.FullWidthWrapper>}
      </Styled.RadioWrapper>
    )
  }
}

export default Radio
