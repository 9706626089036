enum errorMessages {
  MIN_DATE_EXCEPTION = 'Please enter a date after',
  MAX_DATE_EXCEPTION = 'Please enter a date before',
  FILTER_DATE_EXCEPTION = 'Please enter a valid date',
  INCLUDE_DATE_EXCEPTION = 'Please enter one of the following dates:',
  EXCLUDE_DATE_EXCEPTION = 'Please enter a date other than one of the following:',
  INVALID_DATE = 'Please enter the date in the format ',
}

export default errorMessages
