const getInitials = (customerName: string): string => {
  return customerName
    ? customerName
        .trim()
        .split(' ')
        .map((charset) => {
          return charset[0].toUpperCase()
        })
        .filter((char) => {
          return char && char.match(/^[a-zA-Z]*$/)
        })
        .filter((char, index, arr) => {
          return index === 0 || index === arr.length - 1
        })
        .join('')
    : ''
}

export default getInitials
