import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { applyFocus, iconAppearance, opacity, typography } from '@vfuk/core-mixins'

import type { StyledToggleProps } from './Toggle.style.types'

export const ToggleWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    display: inline-block;
    padding: 1rem 0;

    label::before {
      ${applyFocus()}
    }
  `,
)

export const LabelText = styled.label<StyledToggleProps>((props: StyledToggleProps): FlattenSimpleInterpolation => {
  return css`
    ${typography.paragraph(2, false)}
    ${`padding-${props.align}`}: ${props.hasChildren ? '3.125rem' : '2.5rem'};
    cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};

    &::before {
      box-sizing: border-box;
      border-radius: 100px;
      content: '';
      position: absolute;
      top: calc(50% - 0.75rem);
      ${props.align}: 0;
      height: 1.5rem;
      width: 2.5rem;
      background: ${props.toggleLocalTheme.appearance[props.appearance].inactive.color};
      transition: background-color 0.1s ease;
      transition-delay: 0.1s;
    }

    ${props.isActive &&
    css`
      &::before {
        background-color: ${props.theme.formFields.selected.backgroundColor};
      }
    `}

    ${props.isDisabled &&
    css`
      &:before {
        ${opacity(true)}
      }
    `}
  `
})

type ToggleProps = Pick<StyledToggleProps, 'isDisabled' | 'align'>
export const Toggle = styled.input(
  (props: ToggleProps): FlattenSimpleInterpolation => css`
    position: absolute;
    top: calc(50% - 0.75rem);
    ${props.align}: 0;
    opacity: 0;
    margin: 0;
    width: 2.5rem;
    height: 1.5rem;
    font-size: 1rem;
    cursor: ${props.isDisabled ? 'not-allowed' : 'pointer'};
  `,
)

type ToggleSliderProps = Omit<StyledToggleProps, 'hasChildren'>
export const ToggleSlider = styled.div<ToggleSliderProps>((props: ToggleSliderProps): FlattenSimpleInterpolation => {
  return css`
    position: absolute;
    top: calc(50% - 0.625rem);
    ${props.align}: ${props.align === 'left' ? '0.05rem' : '1.1875rem'};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    background-color: ${props.theme.formFields.backgroundColor};
    border: 1px solid
      ${props.isDisabled
        ? props.toggleLocalTheme.state.disabled.inactive.color
        : props.toggleLocalTheme.appearance[props.appearance].inactive.color};
    border-radius: 50%;
    transform: translateX(0);
    transition: all 0.2s ease-in-out;

    ${props.isActive &&
    css`
      border-color: ${props.isDisabled
        ? props.toggleLocalTheme.state.disabled.active.color
        : props.toggleLocalTheme.appearance[props.appearance].active.color};
      transform: translateX(1.125rem);
    `}
  `
})

type IconWrapperProps = Pick<StyledToggleProps, 'appearance' | 'isDisabled' | 'isActive' | 'theme' | 'toggleLocalTheme'>
export const IconWrapper = styled.span<IconWrapperProps>((props: IconWrapperProps): FlattenSimpleInterpolation => {
  const { active, inactive } = props.toggleLocalTheme.appearance[props.appearance]

  return css`
    display: flex;
    align-items: center;
    svg {
      stroke-width: 1.5px;

      ${props.isActive &&
      css`
        width: 16px;
        height: 16px;
        * {
          ${iconAppearance(active.color)};
        }
      `}

      ${!props.isActive &&
      css`
        width: 12px;
        height: 12px;
        * {
          ${iconAppearance(inactive.color)};
        }
      `}

      ${props.isDisabled &&
      css`
        * {
          ${opacity(true)}
        }
      `}
    }
  `
})
