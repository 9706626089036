import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { IconStyles } from '@vfuk/core-icon'
import { advancedSpacing, applyFocus, iconAppearance, spacing, typography } from '@vfuk/core-mixins'

import type { StyledSelectInputProps } from './SelectInput.style.types'

export const SelectInputWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
  `,
)

export const SelectInput = styled.select((props: StyledSelectInputProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && props.theme.formFields.state[props.state]

  return css`
    text-overflow: ellipsis;
    border-radius: ${props.theme.formFields.border.radius};
    color: ${props.theme.formFields.color};
    background-color: ${props.theme.formFields.backgroundColor};
    width: 100%;
    ${advancedSpacing('padding', [2, 8, 2, 3])};
    ${typography.heading(1, false)};
    border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
    appearance: none;
    transition: 0.4s box-shadow ease;
    box-sizing: border-box;

    &::-ms-expand {
      display: none;
    }

    ${applyFocus()}

    ${props.state &&
    stateFromTheme &&
    css`
      color: ${stateFromTheme.color};
      background-color: ${stateFromTheme.backgroundColor};
      border-color: ${stateFromTheme.border.color};
      border-width: ${stateFromTheme.border.width};
    `}

    ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;

      &:focus {
        caret-color: transparent;
      }
    `}
  `
})

export const Option = styled.option(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
  `,
)

export const IconContainer = styled.div((props: StyledSelectInputProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && props.theme.formFields.state[props.state]

  return css`
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    right: 0;
    ${spacing('padding-right', 3)}
    pointer-events: none;

    ${props.state === 'disabled' &&
    stateFromTheme &&
    css`
      ${IconStyles.Icon} {
        * {
          ${iconAppearance(stateFromTheme.color)};
        }
      }
    `}
  `
})
