import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { responsivePosition } from '@vfuk/core-mixins'

import type { StyledCloseButtonProps } from './CloseButton.style.types'

export const CloseButton = styled.div<StyledCloseButtonProps>(
  (props: StyledCloseButtonProps): FlattenSimpleInterpolation => css`
    ${!props.isWithinHeader &&
    css`
      position: absolute;
      ${responsivePosition('top', 4)};
      ${responsivePosition('right', 4)};
      z-index: 3;
    `}
  `,
)
