import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/ListGroup.style'

import type { ListGroupProps } from './ListGroup.types'

const ListGroup: FC<ListGroupProps> = ({
  type = 'unordered',
  listStyle = 'none',
  className,
  role,
  children,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: ListGroupProps): ReactElement => {
  const componentName = 'ListGroup'

  return (
    <Styled.ListGroup
      id={id}
      as={type === 'unordered' ? 'ul' : 'ol'}
      className={className}
      role={role}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      {React.Children.map(children, (child) => {
        if (!child) return
        return React.cloneElement(child, {
          listType: type,
          listStyle,
        })
      })}
    </Styled.ListGroup>
  )
}

export default ListGroup
