import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'

import type { StyledInlineLinkProps } from './InlineLink.style.types'

export const InlineLink = styled(Interaction)(
  (props: StyledInlineLinkProps): FlattenSimpleInterpolation => css`
    color: ${props.inlineLinkTheme.appearance[props.appearance].color};
    text-decoration: underline;
    font-weight: inherit;

    ${props.inverse &&
    css`
      color: ${props.inlineLinkTheme.appearance[props.appearance].inverse.color};
    `}

    &:hover {
      color: ${props.inlineLinkTheme.appearance[props.appearance].hover};

      ${props.inverse &&
      css`
        color: ${props.inlineLinkTheme.appearance[props.appearance].inverse.hover};
      `}
    }

    &:visited {
      color: ${props.inlineLinkTheme.appearance[props.appearance].visited};

      ${props.inverse &&
      css`
        color: ${props.inlineLinkTheme.appearance[props.appearance].inverse.visited};
      `}
    }
  `,
)
