import type { AdvertTheme } from './Advert.theme.types'

const defaultTheme = (): AdvertTheme => ({
  appearance: {
    primary: {
      containerAppearance: 'primary',
      iconAppearance: 'secondary',
      inverseIcon: false,
      inverseCountdownTimer: false,
      inverseText: false,
    },
    secondary: {
      containerAppearance: 'secondary',
      iconAppearance: 'secondary',
      inverseIcon: false,
      inverseCountdownTimer: false,
      inverseText: false,
    },
    alt1: {
      containerAppearance: 'alt1',
      iconAppearance: 'primary',
      inverseIcon: true,
      inverseCountdownTimer: true,
      inverseText: true,
    },
    alt2: {
      containerAppearance: 'alt2',
      iconAppearance: 'primary',
      inverseIcon: true,
      inverseCountdownTimer: true,
      inverseText: true,
    },
    alt3: {
      containerAppearance: 'alt3',
      iconAppearance: 'primary',
      inverseIcon: true,
      inverseCountdownTimer: true,
      inverseText: true,
    },
    alt4: {
      containerAppearance: 'alt4',
      iconAppearance: 'primary',
      inverseIcon: true,
      inverseCountdownTimer: true,
      inverseText: true,
    },
  },
})

export default defaultTheme
