import dayjs from 'dayjs'
import IsSameOrAfter from 'dayjs/plugin/isSameOrAfter'

import errorMessages from '../../constants/errorMessages'

dayjs.extend(IsSameOrAfter)

const isMinDate = (date: dayjs.Dayjs, minDate: Date): [boolean, string] => {
  const dateFormat = dayjs(minDate).subtract(1, 'day').format('DD/MM/YYYY')
  return [date.isSameOrAfter(minDate, 'day'), `${errorMessages.MIN_DATE_EXCEPTION} ${dateFormat}`]
}

export default isMinDate
