import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { advancedSpacing, spacing, typography } from '@vfuk/core-mixins'

import type { StyledTagProps } from './Tag.style.types'

export const Tag = styled(Interaction)((props: StyledTagProps): FlattenSimpleInterpolation => {
  return css`
    border-radius: 13px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    ${advancedSpacing('padding', [1, 2, 1, 3])};
    ${typography.paragraph(1, false)};
    background: ${props.tagTheme.appearance[props.appearance!].backgroundColor};
    ${spacing('gap', 2)};

    &:hover {
      background: ${props.tagTheme.appearance[props.appearance!].hover.backgroundColor};
    }

    ${props.inverse &&
    `
      background: ${props.tagTheme.appearance[props.appearance!].inverse.backgroundColor};

      &:hover {
        background: ${props.tagTheme.appearance[props.appearance!].inverse.hover.backgroundColor}
      }
    `}
  `
})
