import type { Theme } from '@vfuk/core-themes'
import type { SideTrayRendererTheme } from './SideTrayRenderer.theme.types'

const defaultTheme = (theme: Theme): SideTrayRendererTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1.default,
        closeIconAppearance: 'secondary',
        inverseCloseIcon: false,
      },
      secondary: {
        backgroundColor: theme.color.monochrome1.default,
        closeIconAppearance: 'secondary',
        inverseCloseIcon: false,
      },
    },
  }
}

export default defaultTheme
