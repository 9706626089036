import type { Theme } from '@vfuk/core-themes'
import { OverlayTheme } from './Overlay.theme.types'

const defaultTheme = (theme: Theme): OverlayTheme => {
  return {
    appearance: {
      primary: {
        background: theme.color.opacity5.default,
      },
    },
  }
}

export default defaultTheme
