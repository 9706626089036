import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, applyFocus, elevation, respondTo, responsiveSizing, spacing, typography } from '@vfuk/core-mixins'

import type { StyledCalendarPickerProps } from './CalendarPicker.style.types'

import ChevronLeft from '../assets/chevron-left.svg'
import ChevronRight from '../assets/chevron-right.svg'

export const CalendarPicker = styled.div((props: StyledCalendarPickerProps): FlattenSimpleInterpolation => {
  const { color, hover, state, selected } = props.calendarPickerTheme.appearance[props.appearance!]

  return css`
    position: relative;
    z-index: 10;

    .react-datepicker-wrapper {
      width: 100%;

      ${respondTo.md(css`
        width: 290px;
      `)}
    }

    .react-datepicker {
      right: 0;

      ${respondTo.md(css`
        position: relative;
        left: 0 !important;
      `)}

      ${elevation(true)};
      font-family: ${props.theme.baseFont.fontFamily};
    }

    .react-datepicker__day {
      border-radius: 50%;
      ${typography.paragraph(2, false)};
      ${spacing('margin', 1)};
      ${responsiveSizing('width', 6)};
      ${responsiveSizing('height', 6)};
      ${responsiveSizing('line-height', 6)};
      color: ${color};

      &:hover {
        background-color: ${hover.backgroundColor};
        color: ${props.theme.formFields.selected.color};
      }

      ${state.disabled &&
      css`
        &--disabled {
          cursor: default;
          color: ${state.disabled.color};
        }
      `}

      &--selected {
        background-color: ${selected.backgroundColor};
        color: ${color};

        &:focus {
          box-shadow: 0 0 0 1px ${selected.focus.boxShadow}, 0 0 0 4px ${selected.focus.color};
          outline: 3px solid transparent;
        }
      }
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected {
      background-color: ${selected.focus.color};

      &:focus {
        box-shadow: 0 0 0 1px ${selected.focus.boxShadow}, 0 0 0 4px ${selected.focus.color};
        outline: 3px solid transparent;
      }
    }

    .react-datepicker__header {
      background-color: ${props.theme.formFields.backgroundColor};
      border-bottom: none;
    }

    .react-datepicker__day-name {
      ${typography.paragraph(2, false)};
      ${advancedSpacing('margin', [1, 1, 0])};
      ${responsiveSizing('width', 6)};
      font-weight: ${props.theme.fontWeight[3]};
      color: ${color};
    }

    .react-datepicker-year-header,
    .react-datepicker__current-month {
      ${typography.heading(1, false)};
      ${spacing('margin', 2)};
    }

    .react-datepicker-year-header {
      padding-top: 0;
    }

    .react-datepicker__triangle {
      left: 90% !important;
      border-bottom-color: ${props.theme.formFields.backgroundColor} !important;

      ${respondTo.md(css`
        left: 84% !important;
      `)}
    }

    .react-datepicker__month-text {
      ${typography.paragraph(2, false)};
      border-radius: ${props.theme.border.radius[3]};
      ${advancedSpacing('margin', [2, 3])};

      &:hover {
        background-color: ${hover.backgroundColor};
        color: ${props.theme.formFields.selected.color};
      }
    }

    .react-datepicker__month--selected {
      background-color: ${selected.backgroundColor};
    }

    .react-datepicker__navigation {
      ${advancedSpacing('margin', [3, 2])};
      border: none;
      ${responsiveSizing('width', 4)};
      ${responsiveSizing('height', 4)};
      background: no-repeat center center transparent;
      background-size: contain;

      &--previous {
        background-image: url(${ChevronLeft});
      }

      &--next {
        background-image: url(${ChevronRight});
      }

      ${applyFocus()}
    }
  `
})
