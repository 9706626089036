import dayjs from 'dayjs'

import errorMessages from '../../constants/errorMessages'

const isIncludedDate = (date: dayjs.Dayjs, includedDates: Date[]): [boolean, string] => {
  const dateArray: string[] = []

  includedDates.forEach((includedDate) => {
    const dateFormat = dayjs(includedDate).format('DD/MM/YYYY')
    dateArray.push(dateFormat)
  })

  const validDate = includedDates.find((includedDate) => {
    const parsedIncludedDate = dayjs(dayjs(includedDate).format('DD/MM/YYYY'), 'DD/MM/YYYY', true)
    return date.isSame(parsedIncludedDate)
  })
  return [!!validDate, `${errorMessages.INCLUDE_DATE_EXCEPTION} ${dateArray.join(', ')}`]
}

export default isIncludedDate
