/* eslint-disable lines-between-class-members */
import BaseTheme from '@vfuk/core-themes'
import styles from '@vfuk/core-styles'

import * as constants from './constants'

export class WS10Theme extends BaseTheme {
  public name = 'WS10'
  public globalStyles = styles
  public fonts = constants.fonts
  public formFields = constants.formFields
  public formLabels = constants.formLabels
  public baseFont = constants.baseFont
  public fontWeight = constants.fontWeight
  public opacity = constants.opacity
  public color = constants.colors
  public inverseColor = constants.inverseColors
  public spacing = constants.spacing
  public typography = constants.typography
  public breakpoints = constants.breakpoints
  public gridConfig = constants.gridConfig
  public iconRules = constants.iconRules
  public elevation = constants.elevation
  public border = constants.border
  public brandLogo = constants.brandLogo
  public inverseBrandLogo = constants.inverseBrandLogo
  public baseAssetLocation = `assets/${this.name.toLowerCase()}/`
  public localThemes = {}
}

export default new WS10Theme()
