import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing, respondTo } from '@vfuk/core-mixins'

import type { StyledCardGridCellProps } from './SameHeightCardGrid.style.types'

export const CardGrid = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-flow: column nowrap;

    ${spacing('gap', 4)}
  `,
)

export const CardGridRow = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-flow: row nowrap;
    height: min-content;

    ${spacing('gap', 4)}
  `,
)

export const CardGridCell = styled.span<StyledCardGridCellProps>((props: StyledCardGridCellProps): FlattenSimpleInterpolation => {
  const spacingLevel = props.theme.spacing.responsive[4]

  return css`
    display: inline-block;
    flex-basis: ${props.isNotFull ? 'auto' : '100%'};

    & > * {
      height: 100% !important;
      width: auto;

      ${props.bottomPadding &&
      css`
        padding-bottom: ${props.bottomPadding}px;
      `}
    }

    ${props.shouldGrow &&
    css`
      flex-grow: 1;
    `}

    width: calc((100% / ${props.columns}) - ${spacingLevel.sm - spacingLevel.sm / props.columns}px);

    ${respondTo.md(css`
      width: calc((100% / ${props.columns}) - ${spacingLevel.md - spacingLevel.md / props.columns}px);
    `)}

    ${respondTo.lg(css`
      width: calc((100% / ${props.columns}) - ${spacingLevel.lg - spacingLevel.lg / props.columns}px);
    `)}

      ${respondTo.xl(css`
      width: calc((100% / ${props.columns}) - ${spacingLevel.xl - spacingLevel.xl / props.columns}px);
    `)}
  `
})
