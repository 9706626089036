import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

function* navWorker(action) {
  yield put(push(action.payload.path ?? '/'));
}

function* navWatcher() {
  yield takeLatest('GOTO', navWorker);
}

const navSaga = [navWatcher()];

export default navSaga;
