import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

export const ListGroup = styled.ul(
  (): FlattenSimpleInterpolation => css`
    list-style-type: none;
    margin: 0;
    padding: 0;
  `,
)
