import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { respondTo } from '@vfuk/core-mixins'

import type { StyledIconWithBackgroundProps } from './IconWithBackground.style.types'

export const IconWithBackground = styled.div<StyledIconWithBackgroundProps>(
  (props: StyledIconWithBackgroundProps): FlattenSimpleInterpolation => {
    return css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      position: relative;
      height: ${props.iconWithBackgroundTheme.size[props.size!].sm.containerSize}px;
      width: ${props.iconWithBackgroundTheme.size[props.size!].sm.containerSize}px;
      border-radius: ${props.theme.border.radius[2]};
      background-color: ${props.iconWithBackgroundTheme.appearance[props.appearance!].backgroundColor};

      ${props.inverse &&
      css`
        background-color: ${props.iconWithBackgroundTheme.appearance[props.appearance!].inverse.backgroundColor};
      `}

      ${respondTo.md(css`
        height: ${props.iconWithBackgroundTheme.size[props.size!].md.containerSize}px;
        width: ${props.iconWithBackgroundTheme.size[props.size!].md.containerSize}px;
      `)}

    ${props.shape === 'circle' &&
      css`
        border-radius: 50%;
      `}
    `
  },
)
