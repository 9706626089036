import type { Theme } from '@vfuk/core-themes'
import type { ModalRendererTheme } from './ModalRenderer.theme.types'

const defaultTheme = (theme: Theme): ModalRendererTheme => {
  return {
    appearance: {
      primary: {
        containerAppearance: 'primary',
        inverseIcon: false,
        header: {
          backgroundColor: theme.color.monochrome1.default,
          linkWithIconAppearance: 'secondary',
          inverseLinkWithIcon: false,
          interactiveIconAppearance: 'primary',
          inverseInteractiveIcon: false,
        },
        footer: {
          borderColor: theme.color.monochrome2.default,
        },
      },
      secondary: {
        containerAppearance: 'primary',
        inverseIcon: false,
        header: {
          backgroundColor: theme.color.monochrome1.default,
          linkWithIconAppearance: 'secondary',
          inverseLinkWithIcon: false,
          interactiveIconAppearance: 'primary',
          inverseInteractiveIcon: false,
        },
        footer: {
          borderColor: theme.color.monochrome2.default,
        },
      },
    },
  }
}

export default defaultTheme
