import type { ReactNode } from 'react'
import React, { PureComponent, Children } from 'react'

import Paragraph from '@vfuk/core-paragraph'
import ContainerWithLabel from '@vfuk/core-container-with-label'
import Divider from '@vfuk/core-divider'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/CardBuilder.style'

import type { CardBuilderProps, lineBreakSpacerShape } from './CardBuilder.types'

export class CardBuilder extends PureComponent<CardBuilderProps> {
  public componentName = 'CardBuilder'

  get children(): ReactNode[] {
    return Children.toArray(this.props.children)
  }

  get validLineBreakSpacer(): lineBreakSpacerShape[] | undefined {
    /**
     * If children is and array and lineBreakSpacer is truthy, if lineBreakSpacer is greater than or equal to zero
     * and if lineBreakSpacer is less than or equal to the amount of children passed, return the lineBreakSpacer
     *  */
    if (
      this.props.lineBreakSpacer &&
      this.props.lineBreakSpacer.length > 0 &&
      Math.max(...this.props.lineBreakSpacer.map((line) => line.slotIndex)) <= this.children.length
    )
      return this.props.lineBreakSpacer
  }

  render(): ReactNode {
    return (
      <Styled.CardBuilder
        id={this.props.id}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
        {...this.props.dataAttributes}
      >
        <ContainerWithLabel
          {...this.props.container}
          paddingLevel={0}
          allowOverflow={false}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'container-with-label')}
        >
          <Styled.SlotsWrapper ref={this.props.domRef}>
            {this.children.map((child, index) => {
              const lineBreakSpacer = this.validLineBreakSpacer?.find((spacer) => spacer.slotIndex === index)
              return (
                <Styled.CardSlots key={index} lineBreakPosition={lineBreakSpacer?.position}>
                  {this.props.dividers?.[index] && (
                    <Styled.DividerWrapper>
                      <Divider
                        appearance={this.props.dividers?.[index]}
                        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `divider-${index}`)}
                      />
                    </Styled.DividerWrapper>
                  )}
                  {child}
                </Styled.CardSlots>
              )
            })}
          </Styled.SlotsWrapper>
        </ContainerWithLabel>
        {this.props.footerText && (
          <Styled.CardBuilderFooterTextWrapper>
            <Paragraph size={1} dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'footer-text')}>
              {this.props.footerText}
            </Paragraph>
          </Styled.CardBuilderFooterTextWrapper>
        )}
      </Styled.CardBuilder>
    )
  }
}

export default CardBuilder
