import type { ReactElement, FC } from 'react'
import React, { memo } from 'react'

import FunctionalPickerItem from '@vfuk/core-functional-picker-item'
import SimpleGrid from '@vfuk/core-simple-grid'
import Icon from '@vfuk/core-icon'
import { getDataSelector } from '@vfuk/core-base-props'

import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/IconPicker.theme'
import * as Styled from './styles/IconPicker.style'

import type { IconPickerProps } from './IconPicker.types'

const IconPicker: FC<IconPickerProps> = ({
  label,
  selected,
  groupName,
  inverse,
  onChange,
  onBlur,
  items,
  iconOrientation = 'vertical',
  columns,
  id,
  appearance = 'primary',
  dataSelectorPrefix,
  dataAttributes,
  localTheme,
}: IconPickerProps): ReactElement => {
  const componentName = 'IconPicker'
  const iconPickerTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const { iconAppearance, iconInverse } = inverse ? iconPickerTheme.appearance[appearance].inverse : iconPickerTheme.appearance[appearance]

  const defaultColumns = {
    sm: items.length <= 2 ? items.length : 3,
    md: items.length <= 4 ? items.length : 5,
  }

  const pickerType = Array.isArray(selected) ? 'checkbox' : 'radio'

  const handleOnBlur = (event: React.FocusEvent<HTMLFieldSetElement>): void => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.Fieldset
      onBlur={handleOnBlur}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.Legend data-seletor={getDataSelector(dataSelectorPrefix, 'legend')}>{label}</Styled.Legend>
      <SimpleGrid columns={{ ...defaultColumns, ...columns }} spacing={2} verticalAlign='stretch'>
        {items?.map((pickerItem, index) => {
          const isSelected = pickerType === 'radio' ? selected === pickerItem.value : selected.includes(pickerItem.value)
          return (
            <Styled.PickerItemWrapper key={`${index + groupName}`}>
              <FunctionalPickerItem
                groupName={groupName}
                inverse={inverse}
                type={pickerType}
                onChange={onChange}
                value={pickerItem.value}
                title={pickerItem.title}
                subTitle={pickerItem.subTitle}
                id={pickerItem.id}
                state={pickerItem.state}
                key={index}
                srText={pickerItem.srText}
                checked={isSelected}
                orientation={iconOrientation}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `item-${index}`)}
              >
                <Styled.IconWrapper orientation={iconOrientation}>
                  <Icon
                    appearance={iconAppearance}
                    inverse={iconInverse}
                    name={pickerItem.icon?.name}
                    size={3}
                    isResponsive={false}
                    dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `icon-${index}`)}
                  />
                </Styled.IconWrapper>
              </FunctionalPickerItem>
            </Styled.PickerItemWrapper>
          )
        })}
      </SimpleGrid>
    </Styled.Fieldset>
  )
}

export default memo(IconPicker)
