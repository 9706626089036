import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/CarouselDotPager.style'
import type { CarouselDotPagerProps } from './CarouselDotPager.types'

import { componentName } from '../../locales'

export class CarouselDotPager extends PureComponent<CarouselDotPagerProps> {
  public render(): ReactNode {
    return (
      <Styled.CarouselDotPagerWrapper
        active={this.props.active}
        appearance={this.props.appearance || 'primary'}
        inverse={this.props.inverse}
        onClick={(): void => this.props.onClick(this.props.index)}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
      >
        <Styled.CarouselDotPager>
          <Styled.SrText data-selector={getDataSelector(this.props.dataSelectorPrefix, 'sr-text')}>
            {this.props.t!('ItemCountOfTotalCount', { index: this.props.index + 1, pagerCount: this.props.pagerCount })}(
            {this.props.active && this.props.t!('Currently Active')})
          </Styled.SrText>
        </Styled.CarouselDotPager>
      </Styled.CarouselDotPagerWrapper>
    )
  }
}

export default withTranslation(componentName)(CarouselDotPager)
