import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import Checkbox from '@vfuk/core-checkbox'

import ListItem from '@vfuk/core-list-item'

import * as Styled from './styles/CheckboxFieldSet.style'

import type { CheckboxFieldSetProps } from './CheckboxFieldSet.types'

export default class CheckboxFieldSet extends PureComponent<CheckboxFieldSetProps> {
  private handleOnBlur = (event: React.FocusEvent<HTMLFieldSetElement>): void => {
    if (this.props.checkboxes.onBlur) {
      this.props.checkboxes.onBlur(event)
    }
  }

  public render(): ReactNode {
    return (
      <fieldset id={this.props.checkboxes.id} onBlur={this.handleOnBlur} data-selector={getDataSelector(this.props.dataSelectorPrefix)}>
        <Styled.Legend data-selector={getDataSelector(this.props.dataSelectorPrefix, 'legend')}>{this.props.label}</Styled.Legend>
        <Styled.CheckboxList
          hasLabel={this.props.showLabel}
          orientation={this.props.orientation}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'list-group')}
        >
          {this.props.checkboxes.items.map((checkbox, index: number) => {
            const allChecked = checkbox.checkboxes?.items.every((element) => element.checked) || false
            const isIndeterminate = checkbox.checkboxes?.items.some((element) => element.checked) && !allChecked
            return (
              <ListItem key={index} dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `list-item-${index}`)}>
                <Checkbox
                  id={`${this.props.checkboxes.id}-${index}`}
                  name={this.props.checkboxes.groupName}
                  onChange={this.props.checkboxes.onChange}
                  required={this.props.checkboxes.required}
                  value={checkbox.value}
                  dataAttributes={checkbox.dataAttributes}
                  checked={checkbox.checkboxes?.items.length ? allChecked : checkbox.checked || false}
                  indeterminate={isIndeterminate}
                  state={checkbox.state}
                  describedBy={this.props.describedBy}
                  inverse={this.props.inverse}
                  dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, this.props.checkboxes.groupName)}
                >
                  {checkbox.label}
                </Checkbox>
                {checkbox.checkboxes && (
                  <Styled.ChildrenCheckboxes
                    orientation={this.props.orientation}
                    dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'list-group-checkboxes')}
                  >
                    {checkbox.checkboxes!.items.map((childCheckbox, childIndex: number) => {
                      return (
                        <Styled.ChildCheckboxWrapper
                          key={childIndex}
                          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `item-${childIndex}`)}
                        >
                          <Checkbox
                            id={`${this.props.checkboxes.id}-${childIndex}-nested`}
                            name={this.props.checkboxes.groupName}
                            onChange={checkbox.checkboxes!.onChange}
                            required={this.props.checkboxes.required}
                            value={childCheckbox.value}
                            dataAttributes={childCheckbox.dataAttributes}
                            checked={childCheckbox.checked || false}
                            state={checkbox.state}
                            describedBy={this.props.describedBy}
                            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `checkbox-${childIndex}`)}
                          >
                            {childCheckbox.label}
                          </Checkbox>
                        </Styled.ChildCheckboxWrapper>
                      )
                    })}
                  </Styled.ChildrenCheckboxes>
                )}
              </ListItem>
            )
          })}
        </Styled.CheckboxList>
      </fieldset>
    )
  }
}
