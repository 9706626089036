import type { Theme } from '@vfuk/core-themes'

import type { InitialsAvatarTheme } from './InitialsAvatar.theme.types'

const initialsAvatarLocal = (theme: Theme): InitialsAvatarTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.secondary1.default,
        textColor: theme.color.monochrome1.default,
      },
    },
  }
}

export default initialsAvatarLocal
