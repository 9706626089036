import type { Theme } from '@vfuk/core-themes'

import type { VerticalStepperTheme } from './VerticalStepper.theme.types'

const defaultTheme = (theme: Theme): VerticalStepperTheme => {
  return {
    appearance: {
      primary: {
        stepColor: theme.color.monochrome3.default,
        activeStep: {
          color: theme.color.primary1.default,
          border: `${theme.border.width[2]} ${theme.border.style[1]} ${theme.color.monochrome1.default}`,
          boxShadow: `0 0 0 1px ${theme.color.primary1.default}`,
        },
        inverseText: false,
        linkAppearance: 'primary',
        headingAppearance: 'primary',
        paragraphAppearance: 'primary',
      },
    },
  }
}

export default defaultTheme
