import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing } from '@vfuk/core-mixins'

import type { StyledBottomTrayRendererProps, StyledBottomTrayContentProps } from './BottomTrayRenderer.style.types'

export const BottomTrayRenderer = styled.div<StyledBottomTrayRendererProps>(
  (props: StyledBottomTrayRendererProps): FlattenSimpleInterpolation => css`
    display: block;
    position: relative;
    inset: 0;
    z-index: ${props.zIndex};
  `,
)

export const BottomTrayRendererContent = styled.div<StyledBottomTrayContentProps>(
  (props: StyledBottomTrayContentProps): FlattenSimpleInterpolation => {
    const { backgroundColor, color } = props.bottomTrayRendererTheme.appearance[props.appearance]

    return css`
      position: fixed;
      inset: auto 0 0;
      z-index: ${props.zIndex + 1};
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      overflow-y: auto;
      background-color: ${backgroundColor};
      color: ${color};
      border-top-left-radius: ${props.theme.border.radius[2]};
      border-top-right-radius: ${props.theme.border.radius[2]};
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 16%);
      ${advancedSpacing('padding', props.isClosable ? [10, 5, 5] : [5])}
    `
  },
)
