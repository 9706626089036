import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import FieldWrapper from '@vfuk/core-field-wrapper'

import RadioButtonListFieldSet from './components/RadioButtonListFieldSet'

import type { RadioButtonListProps } from './RadioButtonList.types'

class RadioButtonList extends PureComponent<RadioButtonListProps> {
  public componentName = 'RadioButtonList'

  public static defaultProps: Partial<RadioButtonListProps> = {
    orientation: 'vertical',
  }

  public render(): ReactNode {
    return (
      <FieldWrapper
        id={this.props.id}
        label={this.props.fieldWrapper.label}
        showLabel={this.props.fieldWrapper.showLabel}
        state={this.props.state}
        helpText={this.props.fieldWrapper.helpText}
        helpLink={this.props.fieldWrapper.helpLink}
        subText={this.props.fieldWrapper.subText}
        stateText={this.props.fieldWrapper.stateText}
        optional={this.props.fieldWrapper.optional}
        required={this.props.fieldWrapper.required}
        width={this.props.fieldWrapper.width}
        renderAsLabel={false}
        formElementId={this.props.radioButtons.id}
        dataAttributes={this.props.dataAttributes}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
        componentName={this.componentName}
      >
        <RadioButtonListFieldSet
          label={this.props.fieldWrapper.label}
          showLabel={this.props.fieldWrapper.showLabel}
          radioButtons={this.props.radioButtons}
          orientation={this.props.orientation}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'fieldset')}
        />
      </FieldWrapper>
    )
  }
}

export default RadioButtonList
