import type { ImageProps, Breakpoint, ImageSrc } from '../../Image.types'
import { breakpoints } from '../../utils/breakpoints'

function getFallbackImage(props: ImageProps, breakpoint: Breakpoint = 'xl'): ImageSrc | undefined {
  const currentBreakpoint = breakpoints.indexOf(breakpoint)
  const imageObj = props[breakpoint]
  const nextBreakpoint = breakpoints[currentBreakpoint + 1]

  if (imageObj) return imageObj

  // If no image object found for current breakpoint try next size up
  return getFallbackImage(props, nextBreakpoint)
}

export default getFallbackImage
