/// <reference types="resize-observer-browser" />

import { useEffect, useRef } from 'react'

type Nullable<T> = T | null

function useResizeObserver(
  element: Nullable<HTMLElement> | Nullable<HTMLElement>[],
  callback: (entries: ResizeObserverEntry[]) => void,
): ResizeObserver | null {
  const resizeObserver = useRef<ResizeObserver | null>(null)
  const cb = useRef(callback)

  useEffect(() => {
    cb.current = callback
  }, [callback])

  useEffect(() => {
    if (!element) return

    if (window.ResizeObserver) {
      resizeObserver.current = new ResizeObserver(cb.current)

      if (Array.isArray(element)) {
        for (const el of element) {
          if (el) resizeObserver.current?.observe(el)
        }
      } else {
        if (element) resizeObserver.current?.observe(element)
      }

      return (): void => {
        if (resizeObserver.current) {
          resizeObserver.current.disconnect()
        }
      }
    }

    const handleResizeEvent = (): void => {
      cb.current([])
    }

    window.addEventListener('resize', handleResizeEvent)

    return (): void => {
      window.removeEventListener('resize', handleResizeEvent)
    }
  }, [element])

  return resizeObserver.current
}

export default useResizeObserver
