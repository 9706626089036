import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing } from '@vfuk/core-mixins'

import type { StyledBlockProps, StyledContentWrapperProps } from './Block.style.types'

export const Block = styled.div(
  (props: StyledBlockProps): FlattenSimpleInterpolation => css`
    background: ${props.blockTheme.appearance[props.appearance!].backgroundColor};
    ${advancedSpacing('padding', props.paddingLevel)}

    ${props.removeBottomPadding &&
    css`
      padding-bottom: 0 !important;
    `}
  `,
)

export const ContentWrapper = styled.div(
  (props: StyledContentWrapperProps): FlattenSimpleInterpolation => css`
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: 0 auto;
  `,
)
