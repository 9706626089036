import type { ReactNode } from 'react'
import React from 'react'
import { createPortal } from 'react-dom'

import type { AnimationTypes } from '@vfuk/core-animate'
import Animate from '@vfuk/core-animate'
import Overlay from '@vfuk/core-overlay'
import { capitalizeFirstCharacter } from '@vfuk/core-helpers'
import { getDataSelector } from '@vfuk/core-base-props'
import { withLocalTheme } from '@vfuk/core-themes'

import * as Styled from './styles/FlyoutRenderer.style'

import type { FlyoutRendererProps } from './FlyoutRenderer.types'

import localTheme from './themes/FlyoutRenderer.theme'

import Renderer from '../Renderer'

import setInitialFocus from '../utils/setInitialFocus'

import type { RendererState } from '../Renderer.types'
import { OverlayCloseSource } from '../constants/constants'

import CloseButton from '../components/CloseButton'
import { FlyoutRendererTheme } from './themes/FlyoutRenderer.theme.types'
import defaultTheme from './themes/FlyoutRenderer.theme'

export class FlyoutRenderer extends Renderer<FlyoutRendererProps, RendererState> {
  public static defaultProps: Partial<FlyoutRendererProps> = {
    appearance: 'primary',
    side: 'right',
    overlayBlur: false,
  }

  public render(): ReactNode {
    return createPortal(
      <Styled.FlyoutRenderer
        zIndex={this.props.zIndex}
        id={this.props.id}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.props.componentName}
        {...this.props.dataAttributes}
      >
        <Animate
          show={this.state.show}
          enter={{
            animations: [`slideFrom${capitalizeFirstCharacter(this.props.side!)}` as AnimationTypes],
            duration: 200,
            delay: 200,
            onDone: (): void => setInitialFocus(this.props.initialFocusId),
          }}
          exit={{
            animations: [`slideTo${capitalizeFirstCharacter(this.props.side!)}` as AnimationTypes],
            duration: 200,
            onDone: this.onDoneCallback,
          }}
        >
          <Styled.FlyoutRendererContent
            zIndex={this.props.zIndex}
            side={this.props.side!}
            data-selector={getDataSelector(this.props.dataSelectorPrefix, 'content')}
            appearance={this.props.appearance!}
            flyoutRendererTheme={this.props.localTheme!}
          >
            <Styled.FocusLock shards={this.props.focusEnabledRefs}>
              <CloseButton
                srName={this.props.srName}
                onClick={this.getOnCloseHandler(OverlayCloseSource.FLYOUT_CROSS_CLICK)}
                inverse={localTheme(this.props.theme!).appearance[this.props.appearance!].inverseIcon}
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'close-button')}
              />
              {this.props.children}
            </Styled.FocusLock>
          </Styled.FlyoutRendererContent>
        </Animate>
        <Animate
          show={this.state.show}
          enter={{
            animations: ['fadeIn'],
            duration: 400,
          }}
          exit={{
            animations: ['fadeOut'],
            duration: 200,
          }}
        >
          <Overlay
            show
            onClick={this.getOnCloseHandler(OverlayCloseSource.FLYOUT_OUTSIDE_CLICK)}
            position='fixed'
            zIndex={this.props.zIndex - 1}
            blur={this.props.overlayBlur}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'overlay')}
          />
        </Animate>
      </Styled.FlyoutRenderer>,
      this.props.rootElement,
    )
  }
}

export default withLocalTheme<FlyoutRendererProps, FlyoutRendererTheme>(FlyoutRenderer, defaultTheme)
