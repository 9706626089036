import type { FC } from 'react'
import React, { Fragment } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/Overlay.theme'
import * as Styled from './styles/Overlay.style'

import type { OverlayProps } from './Overlay.types'

const Overlay: FC<OverlayProps> = ({
  position = 'fixed',
  show = false,
  overflowHidden = false,
  zIndex = 10,
  blur = false,
  onClick,
  appearance = 'primary',
  localTheme,
  id,
  dataSelectorPrefix,
  dataAttributes,
}) => {
  const componentName = 'Overlay'
  const overlayTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  if (!show) return null

  return (
    <Fragment>
      <Styled.HideOverflow show={show} overflowHidden={overflowHidden} />
      <Styled.Overlay
        onClick={onClick}
        position={position}
        zIndex={zIndex!}
        blur={blur}
        appearance={appearance}
        overlayTheme={overlayTheme}
        id={id}
        data-selector={getDataSelector(dataSelectorPrefix)}
        data-component-name={componentName}
        {...dataAttributes}
      />
    </Fragment>
  )
}

export default Overlay
