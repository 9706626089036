import * as serviceWorker from "./serviceWorker";
import { Provider } from 'react-redux';
import { store } from 'store/configureStore';
import { application } from './settings.json';
import "./index.scss";


import React from "react";
import ReactDOM from "react-dom";
import configService from "./services/ConfigService";
import App from "./App";

const getEntryNode = async () => {
  const APP_ROOT_ID = "gs-app";
  const APP_SHADOW_DOM_ROOT_CONTAINER_ID = "gs-app-shadow";
  const APP_SHADOW_DOM_STYLES_SLOT_ID = "gs-app-styles";
  // get and set config
  const localConfig = localStorage.getItem(`config-${application}`);
  const config = localConfig ? JSON.parse(localConfig) : (await configService.fetchConfig());
  localStorage.setItem(`config-${application}`,JSON.stringify(config));
  const widgetConfig = Object.values(config?.widgets?.drools).find(c=> c.enableShadowDOM !== undefined)
  if (widgetConfig && widgetConfig.enableShadowDOM) {
    const shadowAppRoot = document.getElementById(APP_ROOT_ID).attachShadow({ mode: "open" });
    // styles container
    const styleSlot = document.createElement("span");
    styleSlot.id = APP_SHADOW_DOM_STYLES_SLOT_ID;
    shadowAppRoot.appendChild(styleSlot);

    // create the element where we would render our app
    const shadowAppContainer = document.createElement("div");
    shadowAppContainer.id = APP_SHADOW_DOM_ROOT_CONTAINER_ID;
    shadowAppRoot.appendChild(shadowAppContainer);
    return shadowAppContainer;
  }

  return document.getElementById(APP_ROOT_ID);
};

const attemptRender = async (count) => {
  try {
    const entryNode = await getEntryNode();
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      entryNode
    );
  } catch (error) {
    console.log("Unable to locate entry node");
    if (count < 5) setTimeout(attemptRender, 1000, count+1);
  }
};

const renderFunction = attemptRender(0)


serviceWorker.unregister();
