interface DayObject {
  days: number
  hours: number
  minutes: number
  seconds: number
}

function secondsToDhms(remainingSeconds: number): DayObject {
  const oneMinute = 60 // seconds
  const oneHour = oneMinute * 60
  const oneDay = oneHour * 24

  const days = Math.floor(remainingSeconds / oneDay)
  const hours = Math.floor((remainingSeconds % oneDay) / oneHour)
  const minutes = Math.floor((remainingSeconds % oneHour) / oneMinute)
  const seconds = Math.floor(remainingSeconds % oneMinute)

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

export default secondsToDhms
