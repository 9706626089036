import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { IconStyles } from '@vfuk/core-icon'
import { ParagraphStyles } from '@vfuk/core-paragraph'
import { advancedSpacing, applyFocus, opacity, spacing, srOnly } from '@vfuk/core-mixins'

import type { StyledFilterListProps } from './FilterList.style.types'

export const FilterList = styled.fieldset(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-wrap: wrap;
    ${spacing('gap', 4)};
  `,
)

export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

type StyledFilterItemPropsLabel = Pick<StyledFilterListProps, 'imgSet' | 'theme' | 'checked' | 'size'>
export const Span = styled.span<StyledFilterItemPropsLabel>(
  (props: StyledFilterItemPropsLabel): FlattenSimpleInterpolation => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ${props.imgSet &&
    css`
      min-width: 24px;
      width: ${props.imgSet.width};
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(${props.imgSet.src});
      background-size: 100% 100%;
      ${spacing('margin-right', 1)}

      ${props.size === 1 &&
      css`
        min-width: 16px;
        height: 16px;
      `}
    ${props.checked &&
      props.imgSet.selected &&
      css`
        background-image: url(${props.imgSet.selected});
      `}
    `}
  `,
)

type StyledFilterItemProps = Pick<
  StyledFilterListProps,
  'state' | 'appearance' | 'size' | 'theme' | 'checked' | 'inverse' | 'imgSet' | 'filterListTheme'
>
export const FilterItem = styled.label<StyledFilterItemProps>((props: StyledFilterItemProps): FlattenSimpleInterpolation => {
  const { backgroundColor, hover, borderColor } = props.inverse
    ? props.filterListTheme.appearance[props.appearance].inverse
    : props.filterListTheme.appearance[props.appearance]

  return css`
    ${advancedSpacing('padding', [2, 3])}
    min-height: 42px;
    border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
    border-radius: ${props.theme.border.radius[3]};
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background: ${backgroundColor};

    ${props.size === 1 &&
    css`
      min-height: 34px;
    `}

    ${applyFocus()}

  &:hover {
      box-shadow: inset 0px 0px 0px 1px ${hover.backgroundColor};
      ${props.imgSet?.hover &&
      css`
        ${Span} {
          background-image: url(${props.imgSet.hover});
        }
      `}
    }

    ${props.checked &&
    css`
      background: ${props.theme.formFields.selected.backgroundColor};
      border-color: transparent;

      &:hover {
        background: ${hover.selectedBackgroundColor};
        box-shadow: none;
      }

      ${ParagraphStyles.Paragraph} {
        color: ${props.theme.formFields.selected.color};
      }
    `}

    ${props.state === 'disabled' &&
    css`
      ${opacity(true)}
      cursor: not-allowed;
    `}

  ${IconStyles.Icon} {
      ${spacing('margin-right', 1)}
    }

    ${props.inverse &&
    css`
      background: ${props.theme.formFields.inverse.backgroundColor};
      border-color: ${props.theme.formFields.inverse.border.color};
      ${ParagraphStyles.Paragraph} {
        color: ${props.theme.formFields.inverse.color};
      }
      &:hover {
        box-shadow: inset 0px 0px 0px 1px ${borderColor};
      }
      ${props.checked &&
      css`
        background: ${props.theme.formFields.inverse.selected.backgroundColor};
        border-color: transparent;

        ${ParagraphStyles.Paragraph} {
          color: ${props.theme.formFields.inverse.selected.color};
        }
        &:hover {
          box-shadow: none;
          background: ${hover.selectedBackgroundColor};
        }
      `}
    `}
  `
})

type StyledFilterItemInputProps = Pick<StyledFilterListProps, 'state'>
export const FilterInput = styled.input(
  (props: StyledFilterItemInputProps): FlattenSimpleInterpolation => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    overflow: hidden;

    ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;
    `}
  `,
)
