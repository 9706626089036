import { elementPluginType } from "@vf-djr/renderer";
import { createMapStateToProps, mergeProps } from "@vf-djr/utilities";

const mapStateToPropsPlugin: elementPluginType = (
  { __mapStateToProps },
  Element,
  { ReactReduxContext, selectors = {}, connect } = {}
) => {
  if (!connect) {
    console.error(
      "Missing the 'connect' initialisation property for the Map State To Props plugin"
    );
    return Element;
  }
  const mapStateToProps = createMapStateToProps(selectors)(__mapStateToProps);
  return connect(mapStateToProps, {}, mergeProps, {
    context: ReactReduxContext
  })(Element);
};

export default mapStateToPropsPlugin;
