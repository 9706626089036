import type { SimpleNotificationTheme } from './SimpleNotification.theme.types'

const defaultTheme = (): SimpleNotificationTheme => {
  return {
    appearance: {
      primary: {
        containerAppearance: 'primary',
        iconAppearance: 'primary',
        inverseText: false,
        inverseIcon: false,
      },
      secondary: {
        containerAppearance: 'secondary',
        iconAppearance: 'primary',
        inverseText: false,
        inverseIcon: false,
      },
      alt1: {
        containerAppearance: 'alt2',
        iconAppearance: 'primary',
        inverseText: true,
        inverseIcon: true,
      },
      alt2: {
        containerAppearance: 'alt3',
        iconAppearance: 'primary',
        inverseText: true,
        inverseIcon: true,
      },
    },
  }
}

export default defaultTheme
