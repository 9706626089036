import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { respondTo, responsiveCalc, spacing, srOnly } from '@vfuk/core-mixins'
import { RadioStyles } from '@vfuk/core-radio'

import type { StyledRadioButtonListFieldSetProps } from './RadioButtonListFieldSet.style.types'

export const RadioButtonList = styled.div(
  (props: StyledRadioButtonListFieldSetProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${spacing('gap', 3)};

    ${props.hasLabel !== false &&
    css`
      ${spacing('margin-top', 2)};
    `}

    ${props.orientation === 'horizontal' &&
    css`
      ${respondTo.md(css`
        flex-direction: row;
      `)}

      ${RadioStyles.RadioWrapper} {
        padding-left: 0;
      }

      ${RadioStyles.LabelText} {
        display: inline-block;
        position: relative;
        ${responsiveCalc('padding-left', '1.25rem', '+', 2)};
      }
    `}
  `,
)

export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)
