/* eslint-disable complexity, sonarjs/cognitive-complexity */
import type { FC } from 'react'
import React, { useMemo, Fragment } from 'react'
import Icon from '@vfuk/core-icon'
import Link from '@vfuk/core-link'
import Heading from '@vfuk/core-heading'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/CardHeadingAndIconSlot.style'

import type { CardHeadingAndIconSlotProps } from './CardHeadingAndIconSlot.types'

const CardHeadingAndIconSlot: FC<CardHeadingAndIconSlotProps> = ({
  heading,
  icon,
  prefix,
  suffix,
  link,
  subIconGroup = 'system',
  subIcons = [],
  subIconSeparatorText,
  level = 1,
  id,
  dataSelectorPrefix,
  dataAttributes,
}) => {
  const componentName = 'CardHeadingAndIconSlot'

  const subIconsWithGroup = useMemo(() => {
    return subIcons.map((subIcon) => ({ ...subIcon, group: subIconGroup }))
  }, [subIconGroup, subIcons])

  return (
    <Styled.CardHeadingAndIconSlot
      justifyIcon={icon?.justify ?? 'left'}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.IconContainer>
        {icon?.component && !icon.name && icon.component}
        {icon?.name && !icon.component && (
          <Icon size={4} name={icon!.name} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')} />
        )}
      </Styled.IconContainer>
      <Styled.HeadingContainer justifyIcon={icon?.justify ?? 'left'} hasIcon={!!icon}>
        {prefix && (
          <Styled.PreffixSuffixContainer level={level} data-selector={getDataSelector(dataSelectorPrefix, 'prefix')}>
            {prefix}
          </Styled.PreffixSuffixContainer>
        )}
        <Heading
          size={level === 2 ? 1 : 2}
          level={heading.level}
          text={heading.name}
          weight={3}
          noMargin
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'heading')}
        />
        {suffix && (
          <Styled.PreffixSuffixContainer level={level} data-selector={getDataSelector(dataSelectorPrefix, 'suffix')}>
            {suffix}
          </Styled.PreffixSuffixContainer>
        )}
        {link && (
          <Styled.LinkContainer>
            <Link {...link} size={1} showUnderline dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'link')} />
          </Styled.LinkContainer>
        )}
        {subIconsWithGroup.length > 0 &&
          subIconsWithGroup.map((subIconProps, index) => {
            const showSeparatorText = index !== 0 && subIconSeparatorText
            return (
              <Fragment key={index}>
                {showSeparatorText && (
                  <Styled.IconsSeparatorText
                    key={`separator-${index}`}
                    data-selector={getDataSelector(dataSelectorPrefix, `icon-text-${index}`)}
                  >
                    {subIconSeparatorText}
                  </Styled.IconsSeparatorText>
                )}
                <Icon
                  key={`${subIconProps.name}-${index}`}
                  {...subIconProps}
                  dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `icon-${index}`)}
                />
              </Fragment>
            )
          })}
      </Styled.HeadingContainer>
    </Styled.CardHeadingAndIconSlot>
  )
}

export default CardHeadingAndIconSlot
