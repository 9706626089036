import type { FC, ReactElement } from 'react'
import React from 'react'
import isEmpty from 'lodash/isEmpty'

import { useLocalTheme } from '@vfuk/core-themes'

import Heading from '@vfuk/core-heading'
import Paragraph from '@vfuk/core-paragraph'
import Button from '@vfuk/core-button'
import Link from '@vfuk/core-link'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/Snippet.theme'
import * as Styled from './styles/Snippet.style'

import type { SnippetProps } from './Snippet.types'

const Snippet: FC<SnippetProps> = ({
  heading,
  text,
  appearance = 'primary',
  inverse = false,
  justify = 'left',
  button,
  link,
  localTheme,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: SnippetProps): ReactElement => {
  const componentName = 'Snippet'
  const snippetTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  const { buttonAppearance } = snippetTheme.appearance[appearance]

  return (
    <Styled.Snippet
      inverse={inverse}
      justify={justify}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.SnippetTextWrapper hasButton={!isEmpty(button)}>
        {heading && (
          <Heading
            size={1}
            inverse={inverse}
            level={heading?.level}
            weight={3}
            text={heading?.text}
            noMargin
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'heading')}
          />
        )}
        <Paragraph inverse={inverse} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'text')}>
          {text}
        </Paragraph>
      </Styled.SnippetTextWrapper>
      {!isEmpty(button) && (
        <Styled.InteractionWrapper>
          <Button
            appearance={buttonAppearance}
            inverse={inverse}
            text={button!.text}
            {...button}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'button')}
          />
        </Styled.InteractionWrapper>
      )}
      {isEmpty(button) && !isEmpty(link) && (
        <Styled.InteractionWrapper>
          <Link
            showUnderline
            inverse={inverse}
            text={link!.text}
            {...link}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'link')}
          />
        </Styled.InteractionWrapper>
      )}
    </Styled.Snippet>
  )
}

export default Snippet
