import type { InteractiveIconTheme } from './InteractiveIcon.theme.types'

const defaultTheme = (): InteractiveIconTheme => {
  return {
    appearance: {
      primary: {
        iconAppearance: 'primary',
      },
      secondary: {
        iconAppearance: 'secondary',
      },
    },
  }
}

export default defaultTheme
