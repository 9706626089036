import { useCallback } from 'react'
import type { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'

import type { FunctionDebounceType, UseDebounceHookProps } from './useDebounce.types'

/**
 * - Hook that accept a function and returns the debounce memorized version.
 * - if the function dont have delay propierty in the parameters will return the current Function.
 */
function useDebounce<T extends FunctionDebounceType>({ fn, delay = 0, dependencies = [] }: UseDebounceHookProps<T>): DebouncedFunc<T> | T {
  if (delay > 0) {
    // Memorized With Optional Dependencies
    return useCallback(debounce(fn, delay), dependencies)
  }
  return fn
}

export default useDebounce
