import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SelectInputContent.style'

import type { SelectInputContentProps } from './SelectInputContent.types'

const SelectInputContent: FC<SelectInputContentProps> = ({ children, text, dataSelectorPrefix }: SelectInputContentProps): ReactElement => {
  return (
    <Styled.TextContainer data-selector={getDataSelector(dataSelectorPrefix)}>
      {children && <Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>}
      <Styled.Text data-selector={getDataSelector(dataSelectorPrefix, 'text')}>{text}</Styled.Text>
    </Styled.TextContainer>
  )
}

export default SelectInputContent
