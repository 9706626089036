import type { ReactElement, FC } from 'react'
import React from 'react'

import type { BaseProps } from './BaseProps.types'

const BasePropsComponent: FC<BaseProps> = (): ReactElement => {
  return <div>Placeholder Component</div>
}

export default BasePropsComponent
