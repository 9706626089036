import type { FC, ReactElement } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/Link.theme'

import * as Styled from './styles/Link.style'

import type { LinkProps } from './Link.types'

const Link: FC<LinkProps> = ({
  appearance = 'primary',
  showUnderline = false,
  href,
  htmlAttributes = {},
  dataAttributes,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  inverse,
  srText,
  text,
  children,
  size = 2,
  id,
  customRouterProps,
  dataSelectorPrefix,
  localTheme,
}: LinkProps): ReactElement => {
  const componentName = 'Link'
  const linkTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  return (
    <Styled.Link
      appearance={appearance}
      linkTheme={linkTheme}
      href={href}
      target={htmlAttributes!.target}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      showUnderline={showUnderline!}
      inverse={inverse}
      label={srText}
      rel={htmlAttributes!.rel}
      componentName={componentName}
      dataAttributes={dataAttributes}
      size={size}
      id={id}
      customRouterProps={customRouterProps}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
    >
      {text || children}
    </Styled.Link>
  )
}

export default Link
