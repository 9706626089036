import type { FC, ReactElement } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import Heading from '@vfuk/core-heading'
import Paragraph from '@vfuk/core-paragraph'
import LinkWithIcon from '@vfuk/core-link-with-icon'
import { getDataSelector } from '@vfuk/core-base-props'

import { useTranslation } from 'react-i18next'

import * as Styled from './styles/VerticalStepper.style'
import defaultTheme from './themes/VerticalStepper.theme'

import type { VerticalStepperProps } from './VerticalStepper.types'

const VerticalStepper: FC<VerticalStepperProps> = ({
  appearance = 'primary',
  currentStep = 1,
  showAllStepContent = false,
  steps,
  id,
  dataSelectorPrefix,
  dataAttributes,
  localTheme,
}: VerticalStepperProps): ReactElement => {
  const componentName = 'VerticalStepper'

  const verticalStepperTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const { linkAppearance, inverseText, headingAppearance, paragraphAppearance } = verticalStepperTheme.appearance[appearance]

  const currentStepIndex = currentStep! - 1

  const { t } = useTranslation()

  return (
    <Styled.VerticalStepper
      id={id}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      dataAttributes={{ 'data-component-name': componentName, ...dataAttributes }}
    >
      {steps.map((step, index) => {
        return (
          <Styled.Step
            key={index}
            state={step.state}
            isFinal={step.isFinal}
            isActive={index === currentStepIndex}
            verticalStepperTheme={verticalStepperTheme}
            stepAppearance={appearance}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `step-${index}`)}
          >
            <Styled.StepTitle>
              <Heading
                inverse={inverseText}
                appearance={headingAppearance}
                size={1}
                weight={index === currentStepIndex ? 3 : 2}
                noMargin
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `step-heading-${index}`)}
              >
                {step.title}
              </Heading>
              {step.state === 'completed' && index !== currentStepIndex && step.onEdit && (
                <LinkWithIcon
                  appearance={linkAppearance}
                  inverse={inverseText}
                  text={t('Edit')}
                  icon={{
                    name: 'edit',
                    justify: 'right',
                  }}
                  onClick={step.onEdit}
                  size={2}
                  dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `edit-link-${index}`)}
                />
              )}
            </Styled.StepTitle>
            {(showAllStepContent || index === currentStepIndex) && (
              <Styled.StepContent data-selector={getDataSelector(dataSelectorPrefix, `step-content-${index}`)}>
                {step.subTitle && (
                  <Styled.StepSubtitle>
                    <Paragraph
                      noMargin
                      appearance={paragraphAppearance}
                      dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `step-subtitle-${index}`)}
                    >
                      {step.subTitle}
                    </Paragraph>
                  </Styled.StepSubtitle>
                )}
                {step.content}
              </Styled.StepContent>
            )}
          </Styled.Step>
        )
      })}
    </Styled.VerticalStepper>
  )
}

export default VerticalStepper
