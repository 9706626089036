import type { ReactElement, FC } from 'react'
import React, { memo } from 'react'

import FunctionalPickerItem from '@vfuk/core-functional-picker-item'
import SimpleGrid from '@vfuk/core-simple-grid'
import Image from '@vfuk/core-image'
import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/ImagePicker.theme'
import * as Styled from './styles/ImagePicker.style'

import type { ImagePickerProps } from './ImagePicker.types'

const ImagePicker: FC<ImagePickerProps> = ({
  label,
  selected,
  groupName,
  inverse = false,
  onChange,
  onBlur,
  items,
  columns,
  id,
  appearance = 'primary',
  localTheme,
  dataSelectorPrefix,
  dataAttributes,
}: ImagePickerProps): ReactElement => {
  const componentName = 'ImagePicker'
  const imagePickerTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const defaultColumns = {
    sm: 1,
    md: items.length <= 3 ? items.length : 3,
  }

  const pickerType = Array.isArray(selected) ? 'checkbox' : 'radio'

  const handleOnBlur = (event: React.FocusEvent<HTMLFieldSetElement>): void => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.Fieldset
      onBlur={handleOnBlur}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.Legend data-selector={getDataSelector(dataSelectorPrefix, 'legend')}>{label}</Styled.Legend>
      <SimpleGrid columns={{ ...defaultColumns, ...columns }} spacing={2} verticalAlign='stretch'>
        {items?.map((pickerItem, index) => {
          const isSelected = pickerType === 'radio' ? selected === pickerItem.value : selected.includes(pickerItem.value)
          return (
            <Styled.PickerItemWrapper key={`${index + groupName}`}>
              <FunctionalPickerItem
                groupName={groupName}
                inverse={inverse}
                type={pickerType}
                onChange={onChange}
                value={pickerItem.value}
                title={pickerItem.title}
                id={pickerItem.id}
                state={pickerItem.state}
                key={index}
                srText={pickerItem.srText}
                checked={isSelected}
                orientation={'horizontal'}
                justify={'left'}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `item-${index}`)}
              >
                <Styled.ImageWrapper appearance={appearance} imagePickerTheme={imagePickerTheme} inverse={inverse}>
                  <Image
                    key={`${pickerItem.id + groupName}`}
                    alt={pickerItem.image?.alt}
                    sm={{
                      src: pickerItem.image?.src,
                      width: '52px',
                      height: '52px',
                    }}
                    dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `image-${index}`)}
                  />
                </Styled.ImageWrapper>
              </FunctionalPickerItem>
            </Styled.PickerItemWrapper>
          )
        })}
      </SimpleGrid>
    </Styled.Fieldset>
  )
}

export default memo(ImagePicker)
