import type { CardGridProps } from '../../../../CardGrid.types'

type MaxHeightIndexes = number[]

export default function getMaxHeightIndexes(cardRefs: (HTMLSpanElement | null)[], cardElements: CardGridProps['cards']): MaxHeightIndexes {
  if (!cardRefs?.length || cardRefs.every((ref) => ref === null)) return []

  const heights = cardRefs.map((ref, i) => {
    if (ref?.offsetHeight && cardElements[i]?.props?.container?.label) return ref.offsetHeight - 34

    return ref?.offsetHeight ?? 0
  })

  if (!heights.length || heights.every((height) => height === 0)) return []

  const maxHeight = Math.max(...heights)

  return heights.reduce<number[]>((acc, height, index) => {
    if (height === maxHeight) return [...acc, index]

    return acc
  }, [])
}
