import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { respondTo } from '@vfuk/core-mixins'

import type { StyledInitialsAvatarProps } from './InitialsAvatar.style.types'

import calculateTextSize from './helpers/calculateTextSize'

export const Initials = styled.span(
  (props: StyledInitialsAvatarProps): FlattenSimpleInterpolation => css`
    color: ${props.initialsAvatarTheme.appearance[props.appearance].textColor};
    text-transform: uppercase;
    font-weight: ${props.theme.fontWeight[2]};

    font-size: ${calculateTextSize(props.size, props.theme).sm};

    ${respondTo.md(css`
      font-size: ${calculateTextSize(props.size, props.theme).md};
    `)}

    ${respondTo.lg(css`
      font-size: ${calculateTextSize(props.size, props.theme).lg};
    `)}
  `,
)
