import type { ReactNodeArray, ReactElement } from 'react'
import React, { Fragment } from 'react'

import type { Settings } from 'react-slick'

import type { FunctionalCarouselSettings } from '../../FunctionalCarousel.types'

interface BreakpointSettings {
  slidesToShow: number
  slidesToScroll: number
}
interface ResponsiveSettings {
  breakpoint: number
  settings: BreakpointSettings
}

interface SlideCount {
  sm?: number
  md?: number
  lg?: number
  xl: number
}

export default class SliderSettings {
  component: FunctionalCarouselSettings

  constructor(component: FunctionalCarouselSettings) {
    this.component = component
  }

  public get settings(): Settings {
    return {
      accessibility: true,
      arrows: false,
      autoplay: !!this.component.props.autoPlay?.active,
      autoplaySpeed: this.component.props.autoPlay?.waitTime || 3000,
      dots: true,
      draggable: true,
      focusOnSelect: false,
      infinite: this.component.props.infiniteLoop,
      initialSlide: this.component.props.startingSlide,
      speed: this.component.props.transitionSpeed,
      onInit: this.onSliderInit,
      onReInit: (): void => this.reInit(),
      afterChange: this.afterChange,
      beforeChange: this.beforeChange,
      slidesToShow: this.component.props.slidesToShow.xl,
      slidesToScroll: this.component.props.slidesToScroll!.xl || this.component.props.slidesToShow.xl,
      responsive: this.responsiveSettings,
      appendDots: this.onResize,
      customPaging: (): ReactElement => {
        return React.createElement(Fragment)
      },
    }
  }

  public get slides(): ReactNodeArray {
    if (this.component.sliderRef && Array.isArray(this.component.sliderRef.props.children)) {
      return this.component.sliderRef.props.children
    }
    return []
  }

  private setSlidesToShow(slides: number | undefined): void {
    this.component.setState({ slidesToShow: slides })
  }

  private reInit(): void {
    // case when dots are shown and no infiniteLoop
    if (this.component.sliderRef && !this.component.props.infiniteLoop && this.isDotsShown && this.isDotsSameAsSlides) {
      // if all slides shown - remove dots
      if (this.compareSettingsSlidesToShow) {
        this.setSlidesToShow(this.currentResponsiveSetting?.settings.slidesToShow)
      }
      // if there is no breakpoint, all slides are shown we need to remove dots
      if (!this.currentBreakpoint) {
        this.setSlidesToShow(this.component.props.slidesToShow.xl)
      }
    }
    if (this.noBreakpointAndMaxSlides || this.settingsSlidesSameAsCurrent) {
      this.setSlidesToShow(this.component.props.slidesToShow.xl)
    }
    this.setSlideToOverflow(this.component.state.currentSlide - 1)
  }

  private onSliderInit = (): void => {
    this.component.setState({
      currentSlide: this.component.props.startingSlide || 0,
      playing: !!this.component.props.autoPlay?.active,
      slidesToShow: this.component.props.slidesToShow.xl,
      slidesToScroll: this.component.props.slidesToScroll!.xl || this.component.props.slidesToShow.xl,
    })
  }

  private onEachVisibleSlide(callbacks: ((index: number) => void)[]): void {
    for (let i = this.slides.length - this.component.state.slidesToScroll; i < this.slides.length; i++) {
      callbacks.forEach((callback) => {
        callback(i)
      })
    }
  }

  private onEachSlide(callbacks: ((index: number) => void)[]): void {
    for (let i = 0; i < this.slides.length; i++) {
      callbacks.forEach((callback) => {
        callback(i)
      })
    }
  }

  private getSlideByIndex(i: number): Element | null {
    try {
      return document.querySelector(`#${this.component.props.id} [data-index="${i}"]`)
    } catch {
      return null
    }
  }

  private setSlideToActive = (i: number): void => {
    const visibleSlide = this.getSlideByIndex(i)
    if (visibleSlide && !visibleSlide.classList.contains('slick-active')) {
      visibleSlide.classList.add('slick-active')
    }
  }

  private setSlideToOverflow = (i: number): void => {
    const overflowSlideLeft = this.getSlideByIndex(i)
    if (overflowSlideLeft && this.component.props.allowOverflow) {
      overflowSlideLeft.classList.add('overflow-slide')
    }
  }

  private sliderHasRemainderSlides(): boolean {
    return this.component.state.currentSlide + this.component.state.slidesToScroll >= this.slides.length
  }

  private afterChange = (): void => {
    this.setSlideToOverflow(this.component.state.currentSlide - 1)

    if (this.sliderHasRemainderSlides()) {
      this.setSlideToOverflow(this.slides.length - (this.component.state.slidesToShow + 1))
      this.onEachVisibleSlide([this.setSlideToActive])
    }
    this.component.setState({
      transitioning: false,
    })
  }

  private removeAdditionalClasses = (i: number): void => {
    const slide = this.getSlideByIndex(i)
    const classesToRemove = ['overflow-slide']
    if (!slide) return
    classesToRemove.forEach((classToRemove) => {
      if (slide.classList.contains(classToRemove)) {
        slide.classList.remove(classToRemove)
      }
    })
  }

  private beforeChange = (...args: number[]): void => {
    this.onEachSlide([this.removeAdditionalClasses])
    this.component.setState({
      currentSlide: args[1],
      transitioning: true,
    })
  }

  private get currentBreakpoint(): number | undefined {
    if (this.component.sliderRef) {
      // Ignoring TS as slider.state is not protected as a type
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.component.sliderRef.state.breakpoint
    }
  }

  private get isDotsSameAsSlides(): boolean {
    return this.component.state.pagersToShow === this.slides.length
  }

  private get compareSettingsSlidesToShow(): boolean {
    return this.slides.length === this.currentResponsiveSetting?.settings.slidesToShow
  }

  private get compareMaxSlidesToShow(): boolean {
    return this.component.props.slidesToShow.xl === this.slides.length
  }

  private get isDotsShown(): boolean {
    return this.component.state.pagersToShow !== 0
  }

  private get currentResponsiveSetting(): ResponsiveSettings | undefined {
    return this.responsiveSettings.find((setting) => setting.breakpoint === this.currentBreakpoint)
  }

  private get responsiveSettings(): ResponsiveSettings[] {
    const responsiveSettings = []
    const breakpointIdentifiers = ['xl', 'lg', 'md', 'sm']

    for (let i = 1; i < breakpointIdentifiers.length; i++) {
      const breakpointIdentifier = breakpointIdentifiers[i] as keyof SlideCount
      const nextBreakpoint = breakpointIdentifiers[i - 1] as keyof SlideCount

      if (this.component.props.slidesToShow[breakpointIdentifier]) {
        const slidesToScroll = this.component.props.slidesToScroll![breakpointIdentifier]
        const slidesToShow = this.component.props.slidesToShow[breakpointIdentifier]
        responsiveSettings.push({
          breakpoint: this.component.props.theme!.breakpoints[nextBreakpoint],
          settings: {
            slidesToShow,
            slidesToScroll: slidesToScroll || slidesToShow,
          },
        })
      }
    }
    return responsiveSettings as ResponsiveSettings[]
  }

  private onResize = (dots: []): JSX.Element => {
    if (this.component.sliderRef) {
      if (this.currentResponsiveSetting) {
        this.component.setState({
          slidesToShow: this.currentResponsiveSetting.settings.slidesToShow,
          slidesToScroll: this.currentResponsiveSetting.settings.slidesToScroll,
        })
      } else {
        this.component.setState({
          slidesToShow: this.component.props.slidesToShow.xl,
          slidesToScroll: this.component.props.slidesToScroll!.xl || this.component.props.slidesToShow.xl,
        })
      }
    }
    if (dots && dots.length > this.component.state.pagersToShow) {
      this.component.setState({
        pagersToShow: dots.length,
      })
    }
    return React.createElement(Fragment)
  }

  private get settingsSlidesSameAsCurrent(): boolean {
    return this.compareSettingsSlidesToShow && this.isDotsShown
  }

  private get noBreakpointAndMaxSlides(): boolean {
    return !this.currentBreakpoint && this.compareMaxSlidesToShow && this.isDotsShown
  }

  private get isFirstSlide(): boolean {
    return this.component.state.currentSlide === 0
  }

  private get isLastSlide(): boolean {
    return this.component.state.currentSlide + this.component.state.slidesToShow >= this.slides.length
  }

  public get invertedControls(): boolean {
    if (this.component.props.autoPlay?.invertControls === undefined) return true
    return this.component.props.autoPlay.invertControls
  }

  public get showPreviousArrow(): boolean {
    return (
      (this.component.props.infiniteLoop && this.component.state.pagersToShow !== 0) ||
      (!this.component.props.infiniteLoop && !this.isFirstSlide)
    )
  }

  public get showNextArrow(): boolean {
    return (
      (this.component.props.infiniteLoop && this.component.state.pagersToShow !== 0) ||
      (!this.component.props.infiniteLoop && !this.isLastSlide)
    )
  }
}
