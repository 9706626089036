import type { LoaderSizeKeys } from '../../../../Loader.types'

type Size = 'width' | 'height'

function calculateLoaderSVGSize(size: LoaderSizeKeys): { [P in Size]: number } {
  switch (size) {
    case 1:
      return {
        width: 76,
        height: 76,
      }
    case 2:
      return {
        width: 148,
        height: 148,
      }
    case 3:
      return {
        width: 300,
        height: 300,
      }
  }
}

export default calculateLoaderSVGSize
