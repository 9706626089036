import { SUB_TEXT, HELP_TEXT, STATE_TEXT } from '../../constants'

export const getAriaDescribedByIds = (
  formElementId: string,
  helpText?: string | string[],
  stateText?: string | string[],
  subText?: string | string[],
): string => {
  const ariaDescribedByIds: string[] = []

  const getAriaDescribedByForSection = (key: string, value: string | string[]): void => {
    if (Array.isArray(value) && value.length !== 1) {
      value.forEach((text, index) => ariaDescribedByIds.push(`${formElementId}-${key}-${index}`))
    } else ariaDescribedByIds.push(`${formElementId}-${key}`)
  }

  if (subText) getAriaDescribedByForSection(SUB_TEXT, subText)

  if (helpText) getAriaDescribedByForSection(HELP_TEXT, helpText)

  if (stateText) getAriaDescribedByForSection(STATE_TEXT, stateText)

  return ariaDescribedByIds.join(' ')
}
