import type { ReactElement, FC } from 'react'
import React from 'react'

import Container from '@vfuk/core-container'
import Paragraph from '@vfuk/core-paragraph'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/ContainerWithLabel.style'
import getHighlight from './helpers/getHighlight'

import type { ContainerWithLabelProps } from './ContainerWithLabel.types'

const ContainerWithLabel: FC<ContainerWithLabelProps> = ({
  children,
  appearance = 'primary',
  label,
  href,
  id,
  dataAttributes,
  dataSelectorPrefix,
  highlight,
  paddingLevel = 2,
  level = 1,
  elevation = true,
  showOuterTopPadding = false,
  allowOverflow,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  customRouterProps,
  state,
  srText,
}: ContainerWithLabelProps): ReactElement => {
  const componentName = 'ContainerWithLabel'

  return (
    <Styled.ContainerWithLabel
      hasLabel={!!label}
      level={level}
      showOuterTopPadding={showOuterTopPadding}
      id={id}
      data-component-name={componentName}
      data-selector={getDataSelector(dataSelectorPrefix)}
      {...dataAttributes}
    >
      {label && (
        <Styled.Header>
          <Styled.LabelContainer appearance={appearance} level={level} state={state}>
            <Paragraph weight={3} inverse dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'label')}>
              {label}
            </Paragraph>
          </Styled.LabelContainer>
        </Styled.Header>
      )}
      <Container
        elevation={elevation}
        paddingLevel={paddingLevel}
        appearance='primary'
        href={href}
        dataAttributes={dataAttributes}
        allowOverflow={allowOverflow}
        highlight={getHighlight(label, appearance, highlight)}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        customRouterProps={customRouterProps}
        state={state}
        zIndex={0}
        srText={srText}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'container')}
      >
        {children}
      </Container>
    </Styled.ContainerWithLabel>
  )
}

export default ContainerWithLabel
