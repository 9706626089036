import React, { useState, useCallback } from 'react'

import type { WindowSize, WindowSizeOptions } from './useWindowSize.types'

import { useDebounce, useThrottle } from '..'

/**
 * - Hook that returns the client windows a update size property using event resize
 */
function useWindowSize(options?: WindowSizeOptions): WindowSize {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })

  // Handler to call on window resize
  const handleResize = useCallback(() => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  // check if want throttle version of the event
  const trottleIt = useThrottle({ fn: handleResize, delay: options?.delayThrottledResizeTimer ?? 0 }, [windowSize])
  const resizeIt = options?.delayThrottledResizeTimer ? trottleIt : handleResize
  // check if want debounce version of the event
  const debounceIt = useDebounce({ fn: resizeIt, delay: options?.delayDebounceResizeTimer, dependencies: [windowSize] })
  // need to use React Object, to spy in the test doing that way we can get clean up function
  React.useEffect(() => {
    // Add event listener & Check for the debounce version of event
    window.addEventListener('resize', debounceIt)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return (): void => window.removeEventListener('resize', debounceIt)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export default useWindowSize
