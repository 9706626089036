import React, { FC } from 'react'

import { Icon5gProps } from './Icon5g.types'
import * as Styled from './styles/Icon5g.style'

import { nonThemeableRules } from '../../ruleSet'

const Icon5g: FC<Icon5gProps> = ({
  size = nonThemeableRules.defaultSize as number as Icon5gProps['size'],
  isResponsive = false,
}: Icon5gProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 109' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='m119.925.15-1.639 6.036-1.267 4.696c-.597 2.162-1.789 3.13-4.1 3.13h-67.23l-1.64.075c0 .075-.074.224-.074.373.224.149.522.447.746.596l4.844 2.46c1.118.596 1.118.596.746 1.714l-1.566 5.515c-.447 1.491-.372 1.64 1.044 2.31 4.845 2.46 9.168 5.666 12.745 9.69 5.665 6.336 8.87 13.79 9.764 22.212 1.64 13.937-2.46 26.012-12.372 35.925-7.454 7.528-16.696 11.627-27.28 12.522-11.18.894-21.019-2.534-29.814-9.168-1.938-1.49-2.534-3.28-1.938-5.665l3.28-11.776c.074-.224.149-.447.373-.894.447.521.67.894 1.043 1.267 4.249 5.44 9.615 9.391 16.323 11.18 16.621 4.472 33.54-6.336 36.224-23.404 1.267-8.124-.448-15.652-5.814-22.211-4.174-5.143-9.69-8.05-16.025-9.466-.745-.149-1.49-.224-2.236-.447-3.056-.596-4.621-3.056-3.801-5.963.373-1.267.67-2.609 1.043-3.876l5.814-21.018c.745-2.982 2.534-4.92 5.441-5.74C43.229.076 43.975 0 44.721 0h73.639c.522 0 .969.15 1.565.15Zm-5.515 18.484c0 .223 0 .521-.075.745l-1.416 4.845c-.521 1.714-1.714 2.608-3.577 2.608h-4.994c-2.982.075-5.814.522-8.572 1.491-4.248 1.565-7.677 4.174-9.987 8.124-2.31 3.95-3.727 8.199-4.1 12.82-.074.969 0 2.012.075 2.981.373 3.056 2.012 4.845 4.994 5.292 1.118.15 2.236.224 3.354.224h5.366l3.205-11.33h-6.931l1.937-6.782c.373-.969 1.118-1.49 2.162-1.565h8.422c2.46.074 3.876 2.161 3.205 4.621l-6.037 21.242c-.447 1.416-1.118 2.013-2.609 2.013-4.1 0-8.05 0-12.149-.224-1.714-.074-3.428-.596-5.143-1.267-5.143-2.161-7.677-6.335-8.124-11.702-1.192-14.236 8.124-29.515 23.255-33.168 2.31-.596 4.845-.894 7.304-1.043 3.131-.15 6.261-.075 9.242-.075.597.075.895.15 1.193.15Z'
          fill='#E60000'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default Icon5g
