import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { applyFocus, spacing, typography } from '@vfuk/core-mixins'

import type { StyledCheckboxProps } from './Checkbox.style.types'

type CheckboxProps = Pick<StyledCheckboxProps, 'theme' | 'indeterminate' | 'state' | 'inverse' | 'checkboxTheme' | 'appearance'>
export const Checkbox = styled.div((props: CheckboxProps): FlattenSimpleInterpolation => {
  return css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    ${spacing('margin-right', 3)}
    border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
    text-align: center;
    background-color: ${props.theme.formFields.backgroundColor};

    border-radius: ${props.theme.border.radius[1]};

    & svg {
      display: none;
    }

    ${props.inverse &&
    css`
      background-color: ${props.theme.formFields.inverse.backgroundColor};
      border-color: ${props.theme.formFields.inverse.border.color};
    `}

    ${props.indeterminate &&
    css`
      background-color: ${props.state === 'disabled'
        ? props.checkboxTheme.appearance[props.appearance].state.disabled.checkedBackgroundColor
        : props.theme.formFields.selected.backgroundColor};
      border: none;
      & svg {
        display: inline;
        ${props.state === 'disabled' &&
        css`
          stroke: ${props.checkboxTheme.appearance[props.appearance].state.disabled.iconColor};
        `}
      }
    `}
  `
})

type CheckboxLabelProps = Pick<StyledCheckboxProps, 'theme' | 'checked' | 'state' | 'inverse' | 'checkboxTheme' | 'appearance'>
export const CheckboxLabel = styled.label((props: CheckboxLabelProps): FlattenSimpleInterpolation => {
  const localThemeAppearance = props.checkboxTheme.appearance[props.appearance]
  const { formFields } = props.theme

  return css`
    display: flex;
    align-items: center;
    ${typography.paragraph(2, false)}

    ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;
      color: ${formFields.state.disabled.color};

      ${Checkbox} {
        background-color: ${localThemeAppearance.backgroundColor};
        border: ${formFields.border.width} ${formFields.border.style} ${formFields.state.disabled.border.color};
      }
    `}

    ${props.inverse &&
    css`
      color: ${formFields.inverse.color};
    `}

    ${props.state &&
    props.state !== 'disabled' &&
    css`
      color: ${formFields.state[props.state].color};
      ${Checkbox} {
        color: ${formFields.state[props.state].color};
        border-color: ${formFields.state[props.state].border.color};
      }
    `}

    & input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      ~ ${Checkbox} {
        ${applyFocus()}
      }

      &:checked ~ ${Checkbox} {
        background-color: ${props.state === 'disabled'
          ? localThemeAppearance.state.disabled.checkedBackgroundColor
          : formFields.selected.backgroundColor};
        border: none;
        & svg {
          display: inline;
          ${props.state === 'disabled' &&
          css`
            stroke: ${localThemeAppearance.state.disabled.iconColor};
          `}
        }
      }
    }
  `
})
