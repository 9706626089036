const SET_PROPS = 'SET_PROPS';
const SAVE_VAL = 'SAVE_VAL';

const setProps = (comp, props) => ({
  type: SET_PROPS,
  payload: { comp, props }
});

const saveVal = (name, boolean, number, string) => ({
  type: SET_PROPS,
  payload: { name, boolean, number, string }
});

export { SET_PROPS, SAVE_VAL, setProps, saveVal };
