import { all } from 'redux-saga/effects';
  import DROOLS from 'drools';

import configSaga from './configSaga';
import languageSaga from './languageSaga';
import navSaga from "./navSaga";

export default function* rootSaga() {
  yield all([
    ...configSaga,
    ...languageSaga,
    ...navSaga,
  ...(DROOLS.sagas || []),
  ]);
}
