import type { ReactElement } from 'react'
import React from 'react'

import Snippet from '@vfuk/core-snippet'
import Icon from '@vfuk/core-icon'
import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/IconSnippet.theme'
import * as Styled from './styles/IconSnippet.style'

import type { IconSnippetProps } from './IconSnippet.types'

const IconSnippet = ({
  heading,
  text,
  appearance = 'primary',
  inverse = false,
  justify = 'left',
  button,
  icon,
  link,
  id,
  dataSelectorPrefix,
  dataAttributes,
  localTheme,
}: IconSnippetProps): ReactElement => {
  const componentName = 'IconSnippet'
  const iconSnippetTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const { iconAppearance, iconInverse, snippetAppearance, snippetInverse } = inverse
    ? iconSnippetTheme.appearance[appearance].inverse
    : iconSnippetTheme.appearance[appearance]

  return (
    <Styled.IconSnippetContainer
      justify={justify}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      {icon.component
        ? icon.component
        : icon.name && (
            <Icon
              name={icon.name!}
              isResponsive={false}
              size={icon.size || 5}
              appearance={iconAppearance}
              inverse={iconInverse}
              group={icon.group! || 'system'}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')}
            />
          )}
      <Snippet
        heading={heading}
        text={text}
        appearance={snippetAppearance}
        inverse={snippetInverse}
        justify={justify}
        button={button}
        link={link}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'snippet')}
      />
    </Styled.IconSnippetContainer>
  )
}

export default IconSnippet
