import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'

import { getDataSelector } from '@vfuk/core-base-props'
import type { FieldWrapperProps } from '@vfuk/core-field-wrapper'
import FieldWrapper from '@vfuk/core-field-wrapper'
import { withLocalTheme } from '@vfuk/core-themes'

import SearchTextInput from './components/SearchTextInput'

import * as Styled from './styles/SearchInput.style'

import type { SearchInputProps } from './SearchInput.types'

import type { SearchInputTheme } from './themes/SearchInput.theme.types'
import defaultTheme from './themes/SearchInput.theme'

export class SearchInput extends PureComponent<SearchInputProps> {
  public componentName = 'SearchInput'

  public static defaultProps: Partial<SearchInputProps> = {
    appearance: 'primary',
  }

  private handleSubmit = (event: React.FormEvent): void => {
    if (!this.props.searchIconButton?.onClick) return
    event.preventDefault()
    this.props.searchIconButton.onClick(event)
  }

  public render(): ReactNode {
    return (
      <Styled.SearchInput
        hasText={!!this.props.textInput.value}
        width={this.props.fieldWrapper.width!}
        id={this.props.id}
        {...this.props.dataAttributes}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
      >
        <form onSubmit={this.handleSubmit}>
          <FieldWrapper
            label={this.props.fieldWrapper.label}
            width={this.props.fieldWrapper.width!}
            formElementId={this.props.textInput.id}
            helpText={this.props.fieldWrapper.helpText}
            stateText={this.props.fieldWrapper.stateText}
            showLabel={this.props.fieldWrapper.showLabel}
            validateOn={this.props.fieldWrapper.validateOn}
            state={this.props.state as FieldWrapperProps['state']}
            helpLink={this.props.fieldWrapper.helpLink}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'field-wrapper')}
          >
            <SearchTextInput
              label={this.props.fieldWrapper.label}
              textInput={this.props.textInput}
              state={this.props.state}
              searchIconButton={this.props.searchIconButton}
              onClear={this.props.onClear}
              handleSubmit={this.handleSubmit}
              t={this.props.t}
              searchInputTheme={this.props.localTheme!}
              appearance={this.props.appearance}
              dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'search-input')}
            />
          </FieldWrapper>
        </form>
      </Styled.SearchInput>
    )
  }
}

export default withTranslation()(withLocalTheme<SearchInputProps, SearchInputTheme>(SearchInput, defaultTheme))
