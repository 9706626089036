import type { FC } from 'react'
import React, { Fragment } from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import AnimationContainer from '@vfuk/core-animation-container'
import Heading from '@vfuk/core-heading'

import LoaderSVG from './components/LoaderSVG'

import * as Styled from './styles/Loader.style'

import type { LoaderProps } from './Loader.types'

import defaultTheme from './themes/Loader.theme'

import calculateHeadingSize from './helpers/calculateHeadingSize'

const Loader: FC<LoaderProps> = ({
  appearance = 'primary',
  inverse = false,
  size = 3,
  text,
  localTheme,
  srText,
  id,
  dataAttributes,
  dataSelectorPrefix,
}: LoaderProps) => {
  const componentName = 'Loader'
  const loaderTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  return (
    <Fragment>
      <Styled.Loader id={id} {...dataAttributes} data-selector={getDataSelector(dataSelectorPrefix)} data-component-name={componentName}>
        <AnimationContainer>
          <LoaderSVG loaderTheme={loaderTheme} size={size} appearance={appearance} inverse={inverse} />
        </AnimationContainer>
        <Heading
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'heading')}
          size={calculateHeadingSize(size)}
          justify='center'
          text={text}
          inverse={inverse}
        />
      </Styled.Loader>
      {srText && <Styled.HiddenLabel>{srText}</Styled.HiddenLabel>}
    </Fragment>
  )
}

export default Loader
