import {
  initialSetupType,
  preRenderPluginType,
  renderProps
} from "./types/types";
import { ComponentType } from "react";

const elementPluginsExecutor = (
  plugins: preRenderPluginType,
  Element: ComponentType,
  context: Readonly<renderProps>,
  initialSetup: initialSetupType
) =>
  Object.entries(plugins)
    .filter(([key]) => Object.keys(context).includes(key) && key !== "__props")
    .reduce((alteredElement, [, plugin]) => {
      return plugin(context, alteredElement, initialSetup);
    }, Element);

export default elementPluginsExecutor;
