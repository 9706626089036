import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { FunctionalCarouselStyles } from '@vfuk/core-functional-carousel'
import { CardBuilderStyles } from '@vfuk/core-card-builder'
import { advancedSpacing, spacing } from '@vfuk/core-mixins'

import type { StyledCardListProps } from './CardList.style.types'

type CardListProps = Pick<StyledCardListProps, 'theme'>

export const CardList = styled.div(
  (props: CardListProps): FlattenSimpleInterpolation => css`
    width: 100%;
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: 0 auto;

    ${CardBuilderStyles.CardBuilder} {
      height: auto;
    }
  `,
)

export const Section = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    ${spacing('margin-bottom', 4)};
  `,
)

export const CardsSectionContainer = styled.div(
  (props: StyledCardListProps): FlattenSimpleInterpolation => css`
    max-width: ${(props.theme.gridConfig.springWidth / 12) * props.cardsSectionContainerColumns}px;
    margin: 0 auto;
  `,
)

export const Cards = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('margin', [8, 0])};

    ${FunctionalCarouselStyles.CarouselWrapper} .slick-slide > div {
      min-width: 0;
    }
  `,
)
