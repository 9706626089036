import type { ReactElement, FC } from 'react'
import React, { useRef, memo } from 'react'

import { v4 as uuid } from 'uuid'
import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/FunctionalPickerItem.theme'
import * as Styled from './styles/FunctionalPickerItem.style'

import type { FunctionalPickerItemProps } from './FunctionalPickerItem.types'

const FunctionalPickerItem: FC<FunctionalPickerItemProps> = ({
  type = 'checkbox',
  groupName,
  value,
  checked,
  children,
  dataAttributes,
  id,
  inverse,
  level = 1,
  onBlur,
  onChange,
  orientation = 'vertical',
  justify = 'center',
  srText,
  state,
  title,
  subTitle,
  footnote,
  size = 2,
  dataSelectorPrefix,
  localTheme,
  appearance = 'primary',
}: FunctionalPickerItemProps): ReactElement => {
  const componentName = 'FunctionalPickerItem'
  const functionalPickerItemTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const UUID = useRef<string>(uuid())
  const ID = id ?? UUID.current

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (state !== 'disabled') {
      onChange?.(event)
    }
  }

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (state !== 'disabled') {
      onBlur?.(event)
    }
  }

  return (
    <Styled.FunctionalPickerItemWrapper
      size={size}
      aria-label={srText}
      checked={checked}
      inverse={inverse}
      state={state}
      orientation={orientation}
      id={ID}
      functionalPickerItemTheme={functionalPickerItemTheme}
      appearance={appearance}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.PickerInput
        type={type}
        name={groupName}
        value={value}
        checked={checked}
        {...dataAttributes}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        state={state}
        aria-disabled={state === 'disabled'}
        data-selector={getDataSelector(dataSelectorPrefix, 'input')}
      />
      <Styled.FunctionalPickerItemContainer orientation={orientation} justify={justify}>
        {children && <Styled.ChildWrapper>{children}</Styled.ChildWrapper>}

        <Styled.TitleWrapper orientation={orientation} subTitle={subTitle} size={size} footnote={footnote} justify={justify}>
          <Styled.Title
            level={level}
            data-title={title}
            functionalPickerItemTheme={functionalPickerItemTheme}
            data-selector={getDataSelector(dataSelectorPrefix, 'title')}
          >
            {title}
          </Styled.Title>
          {(subTitle || footnote) && (
            <Styled.SubTitleWrapper orientation={orientation}>
              {subTitle && (
                <Styled.SubTitle
                  functionalPickerItemTheme={functionalPickerItemTheme}
                  data-subtitle={subTitle}
                  data-selector={getDataSelector(dataSelectorPrefix, 'sub-title')}
                >
                  {subTitle}
                </Styled.SubTitle>
              )}
              {footnote && (
                <Styled.Footnote
                  functionalPickerItemTheme={functionalPickerItemTheme}
                  data-footNote={footnote}
                  data-selector={getDataSelector(dataSelectorPrefix, 'footnote')}
                >
                  {footnote}
                </Styled.Footnote>
              )}
            </Styled.SubTitleWrapper>
          )}
        </Styled.TitleWrapper>
      </Styled.FunctionalPickerItemContainer>
    </Styled.FunctionalPickerItemWrapper>
  )
}

export default memo(FunctionalPickerItem)
