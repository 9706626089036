import { useEffect } from 'react'
import type { EffectCallback, DependencyList } from 'react'

import { useIsFirstRender } from '..'

/**
 * Effect that runs only on deps change, ignoring first render
 */
function useUpdateEffect(cb: EffectCallback, deps?: DependencyList): void {
  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (!isFirstRender) {
      return cb()
    }
  }, deps)
}

export default useUpdateEffect
