import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledCollapsibleContainerProps } from './CollapsibleContainer.style.types'

export const CollapsibleContainer = styled.div(
  (props: StyledCollapsibleContainerProps): FlattenSimpleInterpolation => css`
    &:last-child {
      margin-bottom: 0;
    }
  `,
)
