import type { FC, ReactElement } from 'react'
import React from 'react'

import IconButton from '@vfuk/core-icon-button'
import { getDataSelector } from '@vfuk/core-base-props'

import type { CarouselArrowProps } from './CarouselArrow.types'

import * as Styled from './styles/CarouselArrow.style'

const CarouselArrow: FC<CarouselArrowProps> = ({
  position = 'overlap',
  pagerPosition = 'outside',
  direction,
  inverse = false,
  shape = 'circle',
  onClick,
  dataSelectorPrefix,
}: CarouselArrowProps): ReactElement => {
  return (
    <Styled.CarouselArrow
      position={position}
      pagerPosition={pagerPosition}
      direction={direction}
      data-selector={getDataSelector(dataSelectorPrefix)}
    >
      <IconButton
        icon={{ name: direction === 'next' ? 'chevron-right' : 'chevron-left' }}
        size={2}
        appearance='alt1'
        inverse={inverse}
        shape={shape}
        onClick={onClick}
        srText={`Move to ${direction} slide`}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon-button')}
      />
    </Styled.CarouselArrow>
  )
}

export default CarouselArrow
