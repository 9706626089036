import type { FC, ReactElement } from 'react'
import React from 'react'
import FieldWrapper from '@vfuk/core-field-wrapper'
import TextAreaInput from '@vfuk/core-text-area-input'

import { getDataSelector } from '@vfuk/core-base-props'

import type { TextAreaInputWithLabelProps } from './TextAreaInputWithLabel.types'

const TextAreaInputWithLabel: FC<TextAreaInputWithLabelProps> = ({
  id,
  dataAttributes,
  state,
  fieldWrapper,
  textAreaInput,
  dataSelectorPrefix,
}: TextAreaInputWithLabelProps): ReactElement => {
  const componentName = 'TextAreaInputWithLabel'

  return (
    <FieldWrapper
      id={id}
      dataAttributes={dataAttributes}
      state={state}
      required={fieldWrapper.required}
      formElementId={textAreaInput.id}
      label={fieldWrapper.label}
      showLabel={fieldWrapper.showLabel}
      helpText={fieldWrapper.helpText}
      subText={fieldWrapper.subText}
      stateText={fieldWrapper.stateText}
      validateOn={fieldWrapper.validateOn}
      width={fieldWrapper.width}
      helpLink={fieldWrapper.helpLink}
      optional={fieldWrapper.optional}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName}
    >
      <TextAreaInput
        state={state}
        required={textAreaInput.required}
        id={textAreaInput.id}
        name={textAreaInput.name}
        value={textAreaInput.value}
        rows={textAreaInput.rows}
        placeholder={textAreaInput.placeholder}
        maxLength={textAreaInput.maxLength}
        onChange={textAreaInput.onChange}
        onBlur={textAreaInput.onBlur}
        onClick={textAreaInput.onClick}
        onKeyDown={textAreaInput.onKeyDown}
        beforeChange={textAreaInput.beforeChange}
        dataAttributes={textAreaInput.dataAttributes}
        describedBy={textAreaInput.describedBy}
        domRef={textAreaInput.domRef}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'text-area-input')}
      />
    </FieldWrapper>
  )
}

export default TextAreaInputWithLabel
