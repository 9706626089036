/* eslint-disable max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import ListGroup from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'
import { InteractionStyles } from '@vfuk/core-interaction'
import { spacing, typography } from '@vfuk/core-mixins'

import type { StyledFieldWrapperProps } from './HelpText.style.types'

export const HelpList = styled(ListGroup)(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-top', 2)};
  `,
)

export const HelpText = styled(ListItem)((props: Pick<StyledFieldWrapperProps, 'theme' | 'state'>): FlattenSimpleInterpolation => {
  return css`
    ${typography.paragraph(1, false)};
    color: ${props.theme.formFields.color};

    ${props.state === 'disabled' &&
    css`
      color: ${props.theme.formFields.state.disabled.color};
    `}

    ${InteractionStyles.Interaction} {
      color: ${props.theme.formLabels.color};
      border-bottom: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
      display: inline-block;
      width: auto;
    }
  `
})
