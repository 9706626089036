import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import calculateIconSize from './helpers/calculateLoaderSVGSize'

import type { LoaderSVGProps } from '../LoaderSVG.types'

import type { AppearanceProps } from '../../../themes/Loader.theme.types'

export const Spinner = styled.div((props: LoaderSVGProps): FlattenSimpleInterpolation => {
  let loaderTheme: AppearanceProps = props.loaderTheme.appearance[props.appearance!]

  if (props.inverse) {
    loaderTheme = loaderTheme.inverse!
  }

  const { color } = loaderTheme

  return css`
    @keyframes dashdraw {
      to {
        stroke-dashoffset: 610;
      }
    }
    svg {
      width: ${calculateIconSize(props.size).width}px;
      height: ${calculateIconSize(props.size).height}px;
    }

    svg g path {
      fill: ${color};
    }

    svg > path:nth-child(1) {
      fill: none;
      stroke: ${color};
      stroke-width: 2;
      stroke-linejoin: round;
      stroke-linecap: round;
      opacity: 0.3;
      stroke-dasharray: 120 32;
      animation: dashdraw 3.5s linear infinite;
      animation-delay: 0.2s;
    }

    svg > path:nth-child(2) {
      opacity: 0.6;
      stroke-dasharray: 120 32;
      animation: dashdraw 3.5s linear infinite;
      animation-delay: 0.1s;
      fill: none;
      stroke: ${color};
      stroke-width: 3;
      stroke-linejoin: round;
      stroke-linecap: round;
    }

    svg > path:nth-child(3) {
      stroke-dasharray: 120 32;
      animation: dashdraw 3.5s linear infinite;
      fill: none;
      stroke: ${color};
      stroke-width: 4;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
  `
})
