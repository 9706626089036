import { FC, Fragment, ReactElement } from 'react'
import React, { useMemo, cloneElement, Children } from 'react'
import { v4 as uuid } from 'uuid'

import { useLocalTheme } from '@vfuk/core-themes'
import Container from '@vfuk/core-container'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Accordion.style'
import defaultTheme from './themes/Accordion.theme'
import useAccordion from './hooks'

import type { AccordionProps, UncontrolledAccordionExtraProps, ControlledAccordionExtraProps } from './Accordion.types'
import type { useAccordionProps } from './hooks/useAccordion.types'

const Accordion: FC<AccordionProps> = ({
  appearance = 'primary',
  id,
  children,
  multi,
  level = 1,
  localTheme,
  dataSelectorPrefix,
  dataAttributes,
  ...props
}) => {
  const componentName = 'Accordion'
  const accordionTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  const { containerAppearance, inverseCollapsibleContainer } = accordionTheme.appearance[appearance!]

  const { onToggle, openIds: controlledOpenIds } = props as ControlledAccordionExtraProps
  const isControlled = useMemo((): boolean => !!controlledOpenIds && !!onToggle, [controlledOpenIds, onToggle])

  const { initiallyOpenIds } = props as UncontrolledAccordionExtraProps
  const { openIds: defaultOpenIds, onToggle: defaultOnToggle } = useAccordion({ initiallyOpenIds, multi } as useAccordionProps)
  const uniqueId = useMemo((): string => uuid(), [])
  const onToggleHandler = useMemo(() => (isControlled ? onToggle : defaultOnToggle), [isControlled, onToggle, defaultOnToggle])
  const openIds = useMemo(
    (): string[] => (isControlled ? controlledOpenIds : defaultOpenIds),
    [isControlled, controlledOpenIds, defaultOpenIds],
  )

  return (
    <Styled.Accordion
      id={id}
      level={level}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      {Children.map(children, (child: ReactElement, index: number) => {
        const collapsibleContainerId = child.props.id ?? `${uniqueId}-${index}`
        return (
          <Fragment key={collapsibleContainerId}>
            {level === 1 ? (
              <Container
                key={collapsibleContainerId}
                elevation
                appearance={containerAppearance}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `container-${index}`)}
              >
                {cloneElement(child, {
                  id: collapsibleContainerId,
                  isActive: openIds.includes(collapsibleContainerId),
                  onToggle: () => onToggleHandler(collapsibleContainerId),
                  inverse: inverseCollapsibleContainer,
                })}
              </Container>
            ) : (
              cloneElement(child, {
                key: index,
                id: collapsibleContainerId,
                isActive: openIds.includes(collapsibleContainerId),
                onToggle: () => onToggleHandler(collapsibleContainerId),
                inverse: inverseCollapsibleContainer,
              })
            )}
          </Fragment>
        )
      })}
    </Styled.Accordion>
  )
}

export default Accordion
