import type { FC } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/Pill.theme'
import * as Styled from './styles/Pill.style'

import type { PillProps } from './Pill.types'

const Pill: FC<PillProps> = ({ appearance = 'primary', state, text, localTheme, id, dataAttributes, dataSelectorPrefix }: PillProps) => {
  const componentName = 'Pill'

  const pillTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  return (
    <Styled.Pill
      appearance={appearance}
      state={state}
      pillTheme={pillTheme}
      id={id}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
    >
      {text}
    </Styled.Pill>
  )
}

export default Pill
