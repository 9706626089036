/**
 * NECESSARY TO REDUCE COMPLEXITY OF getNextTab
 * Check if it should allow inifite scroll with arrow keys. Returns the returnValue if true, -1 if false.
 * @param accessibilityInfiniteScroll - If true, enables infinite scrolling with arrow keys
 * @param returnValue
 * @param isSame - used if we need to skip the check directly (e.g. if pressing home when keyIndex is already 0)
 */
function getReturnValue(accessibilityInfiniteScroll: boolean, returnValue: number, isSame = false): number {
  if (accessibilityInfiniteScroll && !isSame) return returnValue
  return -1
}

/**
 * Returns the index of the next tab to select, according to the key pressed. It returns -1 for unhandled keys.
 * @param keyCode
 * @param length
 * @param keyIndex
 * @param accessibilityInfiniteScroll - If true, enables infinite scroll (moving with arrow keys never stops)
 */
export function getNextTab(keyCode: number, length: number, keyIndex: number, accessibilityInfiniteScroll: boolean = true): number {
  switch (keyCode) {
    // End
    case 35:
      return getReturnValue(accessibilityInfiniteScroll, length - 1, keyIndex === length - 1)
    // Home
    case 36:
      return getReturnValue(accessibilityInfiniteScroll, 0, keyIndex === 0)
    // Left Arrow
    case 37:
      if (keyIndex === 0) return getReturnValue(accessibilityInfiniteScroll, length - 1)
      return keyIndex - 1
    // Right Arrow
    case 39:
      if (keyIndex === length - 1) return getReturnValue(accessibilityInfiniteScroll, 0)
      return keyIndex + 1
    default:
      return -1
  }
}
