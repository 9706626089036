import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { withLocalTheme } from '@vfuk/core-themes'

import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import { dateFormatter } from '@vfuk/core-date-text-input'

import DateTextInputWrapper from './components/DateTextInputWrapper'

import * as Styled from './styles/CalendarPicker.style'

import type { CalendarPickerProps, CalendarPickerState } from './CalendarPicker.types'

import defaultTheme from './themes/CalendarPicker.theme'
import type { CalendarPickerTheme } from './themes/CalendarPicker.theme.types'

export class CalendarPicker extends PureComponent<CalendarPickerProps, CalendarPickerState> {
  public componentName = 'CalendarPicker'

  public static defaultProps: Partial<CalendarPickerProps> = {
    appearance: 'primary',
    showMonthYearPicker: false,
    shouldCloseOnSelect: true,
    autoComplete: true,
  }

  state = { isCalendarOpen: false }

  openCalendar = (): void => {
    if (this.props.state !== 'disabled') {
      this.setState({ isCalendarOpen: true })
    }
  }

  closeCalendar = (): void => {
    this.setState({ isCalendarOpen: false })
  }

  handleOnChange = (value: Date, dateString?: string): void => {
    const formattedDateString = dateString || dateFormatter(value, this.props.showMonthYearPicker).toString()
    this.props.onChange(value, formattedDateString)
  }

  handleCalendarOpen = (): void => {
    if (this.props.onCalendarOpen) {
      this.props.onCalendarOpen()
    }
  }

  handleCalendarClose = (): void => {
    if (this.props.onCalendarClose) {
      this.props.onCalendarClose()
    }
  }

  handleOnDatePickerChange = (date: Date): void => {
    this.handleOnChange(date)
  }

  handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Tab') {
      this.closeCalendar()
    }
  }

  public render(): ReactNode {
    return (
      <Styled.CalendarPicker
        {...this.props.dataAttributes}
        id={`${this.props.id}-calendar-picker`}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
        appearance={this.props.appearance!}
        calendarPickerTheme={this.props.localTheme!}
      >
        <DatePicker
          enableTabLoop={false}
          selected={this.props.value === 'error' ? null : this.props.value}
          dateFormat={this.props.showMonthYearPicker ? 'MM/yyyy' : 'dd/MM/yyyy'}
          id={`${this.props.id}-date-picker`}
          onChange={this.handleOnDatePickerChange}
          disabled={this.props.state === 'disabled'}
          includeDates={this.props.includeDates}
          excludeDates={this.props.excludeDates}
          onCalendarOpen={this.handleCalendarOpen}
          onCalendarClose={this.handleCalendarClose}
          openToDate={this.props.openToDate}
          shouldCloseOnSelect={this.props.shouldCloseOnSelect}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          filterDate={this.props.filterDate}
          onBlur={this.props.onBlur}
          popperPlacement={this.props.popupPosition}
          onKeyDown={this.handleOnKeyDown}
          onInputClick={this.openCalendar}
          onClickOutside={this.closeCalendar}
          open={this.state.isCalendarOpen}
          customInput={
            <DateTextInputWrapper
              id={`${this.props.id}-date-text-input`}
              name={this.props.name}
              required={this.props.required}
              value={this.props.value}
              rawValue={this.props.value}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
              includeDates={this.props.includeDates}
              excludeDates={this.props.excludeDates}
              filterDate={this.props.filterDate}
              onInvalidInputEntry={this.props.onInvalidInputEntry}
              showMonthYearPicker={this.props.showMonthYearPicker}
              state={this.props.state}
              errorMessages={this.props.errorMessages}
              onDateChange={this.handleOnChange}
              inputAutoComplete={this.props.autoComplete}
              describedBy={this.props.describedBy}
              labels={this.props.labels}
              dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'input')}
              calendarPickerTheme={this.props.localTheme!}
            />
          }
          showMonthYearPicker={this.props.showMonthYearPicker}
        />
      </Styled.CalendarPicker>
    )
  }
}

export default withLocalTheme<CalendarPickerProps, CalendarPickerTheme>(CalendarPicker, defaultTheme)
