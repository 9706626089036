import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, respondTo } from '@vfuk/core-mixins'

import type { StyledTimerBlockProps } from './TimerBlock.style.types'

export const TimerBlock = styled.span(
  (): FlattenSimpleInterpolation => css`
    display: inline-block;
    position: relative;
  `,
)

type TimerNumberProps = Pick<StyledTimerBlockProps, 'theme' | 'inverse'>
export const TimerNumber = styled.span(
  (props: TimerNumberProps): FlattenSimpleInterpolation => css`
    display: inline-block;
    ${advancedSpacing('padding', [0, 1])};
    border-radius: ${props.theme.border.radius[1]};
    margin-right: 2px;
    color: ${props.theme.color.monochrome1.default};
    background: ${props.theme.color.monochrome5.default};

    &:nth-child(2) {
      margin-right: 0;
    }

    ${props.inverse &&
    `
    color: ${props.theme.color.monochrome5.default};
    background: ${props.theme.color.monochrome1.default};
  `}
  `,
)

type TimerUnitProps = Pick<StyledTimerBlockProps, 'theme'>
export const TimerUnit = styled.div(
  (props: TimerUnitProps): FlattenSimpleInterpolation => css`
    display: none;

    ${respondTo.md(css`
      display: block;
      position: absolute;
      text-align: center;
      top: 100%;
      left: 0;
      right: 0;
      font-size: ${props.theme.typography.paragraph[2].fontDesktop};
      font-weight: ${props.theme.fontWeight[1]};
    `)}
  `,
)
