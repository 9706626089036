import type { FC } from 'react'
import React from 'react'

import IconWithBackground from '@vfuk/core-icon-with-background'
import IconButton from '@vfuk/core-icon-button'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SliderIcon.style'

import type { SliderIconProps } from './SliderIcon.types'

const SliderIcon: FC<SliderIconProps> = ({
  appearance = 'primary',
  min,
  max,
  value,
  onChange,
  state,
  increment,
  icon,
  position,
  srLabel,
  rangeSliderTheme,
  dataSelectorPrefix,
}) => {
  const handleIconButtonChange = (): void => {
    if (Array.isArray(value)) return

    if (position === 'right' && value < max) {
      onChange(value + increment!, 'rightIcon')
    }

    if (position === 'left' && value > min) {
      onChange(value - increment!, 'leftIcon')
    }
  }

  const isButtonDisabled = (): 'disabled' | undefined => {
    if (state === 'disabled') return 'disabled'

    if (position === 'left' && value <= min) return 'disabled'

    if (position === 'right' && value >= max) return 'disabled'

    return undefined
  }

  const { iconButtonAppearance, iconWithBackgroundAppearance } = rangeSliderTheme.appearance[appearance!]

  return (
    <Styled.IconWrapper position={position} data-selector={getDataSelector(dataSelectorPrefix)}>
      {icon.interactive ? (
        <IconButton
          icon={{ name: icon.name }}
          shape='square'
          size={2}
          appearance={iconButtonAppearance}
          state={isButtonDisabled()}
          onClick={handleIconButtonChange}
          srText={position === 'left' ? srLabel.leftLabel! : srLabel.rightLabel!}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, icon.name)}
        />
      ) : (
        <IconWithBackground
          icon={{ name: icon.name }}
          shape='square'
          size={2}
          appearance={iconWithBackgroundAppearance}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, icon.name)}
        />
      )}
    </Styled.IconWrapper>
  )
}

export default SliderIcon
