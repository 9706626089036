/* eslint-disable complexity, sonarjs/cognitive-complexity */
import type { FC } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import { useLocalTheme } from '@vfuk/core-themes'
import Icon from '@vfuk/core-icon'
import LoadingSpinner from '@vfuk/core-loading-spinner'

import defaultTheme from './themes/Button.theme'

import type { Appearance } from './themes/Button.theme.types'

import * as Styled from './styles/Button.style'

import type { ButtonProps } from './Button.types'

const Button: FC<ButtonProps> = ({
  appearance = 'primary',
  width = 'auto',
  loading = false,
  htmlAttributes = { target: undefined, type: undefined },
  dataAttributes,
  inverse = false,
  state,
  href,
  id,
  text,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  icon,
  customRouterProps,
  srText,
  dataSelectorPrefix,
  localTheme,
}: ButtonProps) => {
  const componentName = 'Button'
  const buttonTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  let themeAppearance = buttonTheme.appearance[appearance]
  if (inverse) themeAppearance = themeAppearance.inverse as Appearance

  const { inverseIcon, loadingSpinnerAppearance, iconAppearance } = themeAppearance

  const isSelected = state === 'selected'
  const isDisabled = state === 'disabled' || loading!

  const { target, type } = htmlAttributes

  return (
    <Styled.Button
      buttonTheme={buttonTheme}
      label={srText ?? text}
      disabled={isDisabled}
      href={!isDisabled ? href : undefined}
      componentName={componentName}
      dataAttributes={dataAttributes}
      {...{ onClick, onMouseDown, onMouseUp, onTouchStart, onTouchEnd }} // interactions
      {...{ type, id, target, onTouchEnd }} // html attributes
      {...{ state, loading, inverse, appearance, width, icon, customRouterProps }} // props
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
    >
      {loading && (
        <>
          <Styled.LoadingIcon>
            <LoadingSpinner
              inverse={inverseIcon}
              size={3}
              appearance={loadingSpinnerAppearance}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'loading')}
            />
          </Styled.LoadingIcon>
          <Styled.HiddenText data-selector={getDataSelector(dataSelectorPrefix, 'hidden-text')}>{text}</Styled.HiddenText>
        </>
      )}
      {(Boolean(icon) || isSelected) && !loading && (
        <>
          <Icon
            inverse={inverseIcon}
            size={3}
            appearance={iconAppearance}
            name={isSelected ? 'tick' : icon!.name}
            group='system'
            isResponsive={false}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `${isSelected ? 'tick' : icon!.name}`)}
          />
          {text}
        </>
      )}
      {!icon && !isSelected && !loading && text}
    </Styled.Button>
  )
}

export default Button
