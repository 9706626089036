import type { FC } from 'react'
import { useRef, useEffect, memo } from 'react'
import { v4 as uuid } from 'uuid'

import { overlayController } from '@vfuk/core-overlay-controller'

import type { SideTrayProps } from './SideTray.types'

const SideTray: FC<SideTrayProps> = ({
  children,
  isOpen = false,
  srName,
  isClosable = true,
  onCloseCb = null,
  animate = true,
  initialFocusId = null,
  focusEnabledRefs,
  id,
  enableOverlay = true,
  overlayBlur = false,
  side = 'left',
  size = 1,
  topOffset,
  rootElement,
  dataSelectorPrefix,
  dataAttributes,
}: SideTrayProps) => {
  const componentName = 'SideTray'

  const sideTrayID = useRef(id || uuid())

  useEffect(() => {
    if (isOpen) {
      overlayController.setConfig(sideTrayID.current, {
        type: 'sideTray',
        isShowing: true,
        isClosable,
        onCloseCb,
        initialFocusId,
        focusEnabledRefs,
        content: children,
        animate,
        srName,
        rootElement,
        renderOptions: {
          enableOverlay,
          overlayBlur,
          side,
          size,
          topOffset,
        },
        id: sideTrayID.current,
        dataSelectorPrefix,
        dataAttributes,
        componentName,
      })
    } else {
      overlayController.hide(sideTrayID.current)
    }
  }, [
    isOpen,
    isClosable,
    onCloseCb,
    initialFocusId,
    focusEnabledRefs,
    children,
    animate,
    enableOverlay,
    id,
    dataSelectorPrefix,
    dataAttributes,
  ])

  // Destructor to be run when the component unmounts
  useEffect(() => {
    return (): void => {
      overlayController.hide(sideTrayID.current)
    }
  }, [])

  return null
}

export default memo(SideTray)
