import { useCallback, useEffect, useRef } from 'react'
import throttle from 'lodash/throttle'

import type { UseThrottle } from './useThrottle.types'

/**
 * - Hook that accept a function and returns the throttle memorized version.
 * - if the function dont have delay propierty in the parameters will return the current Function.
 */
const useThrottle: UseThrottle = ({ fn, delay }, dependencies) => {
  const throttled = useRef(throttle(fn, delay))

  useEffect(() => {
    throttled.current = throttle(fn, delay)
  }, dependencies)

  return useCallback(throttled.current, [])
}

export default useThrottle
