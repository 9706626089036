import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import Animate from '@vfuk/core-animate'
import Icon from '@vfuk/core-icon'
import { withLocalTheme } from '@vfuk/core-themes'

import * as Styled from './styles/Toggle.style'

import type { ToggleProps, ToggleState } from './Toggle.types'

import defaultTheme from './themes/Toggle.theme'
import type { ToggleTheme } from './themes/Toggle.theme.types'

export class Toggle extends PureComponent<ToggleProps> {
  public componentName = 'Toggle'

  public static defaultProps: Partial<ToggleProps> = {
    appearance: 'primary',
    showIcons: true,
    align: 'right',
  }

  public state: ToggleState = {
    hasAnimated: this.props.isActive,
  }

  private handleAnimationDone = (): void => {
    this.setState({
      hasAnimated: !this.state.hasAnimated,
    })
  }

  private handleEvent = (event: React.ChangeEvent<HTMLInputElement>, callback: ToggleProps['onChange'] | ToggleProps['onBlur']): void => {
    if (this.props.state !== 'disabled' && callback) callback(event)
  }

  public render(): ReactNode {
    const localAppearance = this.props.localTheme!.appearance[this.props.appearance!]
    const additionalToggleProps = !this.props.children ? { 'aria-label': this.props.srText } : {}

    return (
      <Styled.ToggleWrapper
        id={this.props.id}
        {...this.props.dataAttributes}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
      >
        <Styled.LabelText
          htmlFor={`${this.props.id}-toggle`}
          isActive={this.props.isActive!}
          appearance={this.props.appearance!}
          isDisabled={this.props.state === 'disabled'}
          hasChildren={!!this.props.children}
          align={this.props.align!}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'label')}
          toggleLocalTheme={this.props.localTheme!}
        >
          {this.props.children && !this.props.srText && this.props.children}
          <Styled.ToggleSlider
            align={this.props.align!}
            appearance={this.props.appearance!}
            isActive={this.props.isActive!}
            isDisabled={this.props.state === 'disabled'}
            toggleLocalTheme={this.props.localTheme!}
          >
            {this.props.showIcons && (
              <>
                <Animate
                  show={this.props.isActive && this.state.hasAnimated}
                  enter={{
                    animations: ['fadeIn'],
                    duration: 100,
                  }}
                  exit={{
                    animations: ['fadeOut'],
                    duration: 100,
                    onDone: this.handleAnimationDone,
                  }}
                >
                  <Styled.IconWrapper
                    isActive={this.props.isActive!}
                    isDisabled={this.props.state === 'disabled'}
                    appearance={this.props.appearance!}
                    toggleLocalTheme={this.props.localTheme!}
                  >
                    <Icon
                      name={localAppearance.active.icon}
                      appearance={this.props.theme?.formFields.icon.appearance}
                      inverse={this.props.state === 'disabled'}
                      size={1}
                      isResponsive={false}
                      dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'icon')}
                    />
                  </Styled.IconWrapper>
                </Animate>
                <Animate
                  show={!this.props.isActive && !this.state.hasAnimated}
                  enter={{
                    animations: ['fadeIn'],
                    duration: 100,
                  }}
                  exit={{
                    animations: ['fadeOut'],
                    duration: 100,
                    onDone: this.handleAnimationDone,
                  }}
                >
                  <Styled.IconWrapper
                    isActive={this.props.isActive!}
                    isDisabled={this.props.state === 'disabled'}
                    appearance={this.props.appearance!}
                    toggleLocalTheme={this.props.localTheme!}
                  >
                    <Icon
                      name={localAppearance.inactive.icon}
                      appearance={this.props.theme?.formFields.icon.appearance}
                      inverse={this.props.state === 'disabled'}
                      size={1}
                      isResponsive={false}
                      dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'icon')}
                    />
                  </Styled.IconWrapper>
                </Animate>
              </>
            )}
          </Styled.ToggleSlider>
        </Styled.LabelText>
        {this.props.srText && this.props.children}
        <Styled.Toggle
          id={`${this.props.id}-toggle`}
          checked={this.props.isActive}
          isDisabled={this.props.state === 'disabled'}
          align={this.props.align!}
          type='checkbox'
          name={this.props.name}
          required={this.props.required}
          onChange={(event): void => {
            this.handleEvent(event, this.props.onChange)
          }}
          onBlur={(event): void => {
            this.handleEvent(event, this.props.onBlur)
          }}
          value={this.props.value}
          {...this.props.dataAttributes}
          {...additionalToggleProps}
          aria-disabled={this.props.state === 'disabled'}
          aria-describedby={this.props.describedBy}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'input')}
        />
      </Styled.ToggleWrapper>
    )
  }
}

export default withLocalTheme<ToggleProps, ToggleTheme>(Toggle, defaultTheme)
