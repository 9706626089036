import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css, createGlobalStyle } from 'styled-components'

import type { StyledOverlayProps } from './Overlay.style.types'

type OverflowProps = Pick<StyledOverlayProps, 'show' | 'overflowHidden'>
export const HideOverflow = createGlobalStyle(
  (props: OverflowProps): FlattenSimpleInterpolation => css`
    ${props.show &&
    props.overflowHidden &&
    css`
      html,
      body {
        overflow: hidden;
      }
    `}
  `,
)

type OverlayProps = Pick<StyledOverlayProps, 'theme' | 'position' | 'zIndex' | 'blur' | 'overlayTheme' | 'appearance'>
export const Overlay = styled.div<OverlayProps>((props: OverlayProps): FlattenSimpleInterpolation => {
  const { background } = props.overlayTheme.appearance[props.appearance]

  return css`
    background: ${background};
    position: ${props.position};
    z-index: ${props.zIndex};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${props.blur &&
    `
    backdrop-filter: blur(5px);
  `}
  `
})
