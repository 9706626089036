import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import type { SelectInputWithMenuProps } from '@vfuk/core-select-input-with-menu'
import SelectInputWithMenu from '@vfuk/core-select-input-with-menu'
import ColorSwatch from '@vfuk/core-color-swatch'

import * as Styled from './styles/ColorSelect.style'

import type { ColorSelectProps } from './ColorSelect.types'

class ColorSelect extends PureComponent<ColorSelectProps> {
  public componentName = 'ColorSelect'

  public static defaultProps: Partial<ColorSelectProps> = {}

  get mappedOptions(): SelectInputWithMenuProps['options'] {
    return this.props.options.map((option) => {
      const children = option.color ? <ColorSwatch color={option.color} available={!option.disabled} /> : <Styled.ColorSwatchPlaceholder />
      const mappedOption = {
        ...option,
        children,
      }
      delete mappedOption.color

      return mappedOption
    })
  }

  public render(): ReactNode {
    return (
      <SelectInputWithMenu
        id={this.props.id}
        value={this.props.value}
        state={this.props.state}
        options={this.mappedOptions}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
        placeholder={this.props.placeholder}
        dataAttributes={{ ...this.props.dataAttributes, 'data-component-name': this.componentName }}
        describedBy={this.props.describedBy}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
      >
        <Styled.ColorSwatchPlaceholder data-selector={getDataSelector(this.props.dataSelectorPrefix, 'color-swatch')} />
      </SelectInputWithMenu>
    )
  }
}

export default ColorSelect
