export const layoutMap = {
  '2': {
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2,
  },
  '3': {
    sm: 1,
    md: 3,
    lg: 3,
    xl: 3,
  },
  '4': {
    sm: 1,
    md: 2,
    lg: 2,
    xl: 2,
  },
  '6': {
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3,
  },
}
