import { useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import { overlayController } from '@vfuk/core-overlay-controller'

import type { ModalProps } from './Modal.types'

const Modal: React.FC<ModalProps> = ({
  isClosable = true,
  isOpen = false,
  onCloseCb = null,
  initialFocusId = null,
  focusEnabledRefs,
  children,
  animate = true,
  size = 1,
  srName,
  appearance = 'primary',
  type = 'modal',
  side = 'right',
  overlayBlur = false,
  id,
  fullScreenOnSM = false,
  header,
  footer,
  rootElement,
  dataSelectorPrefix,
  dataAttributes,
}: ModalProps) => {
  const componentName = 'Modal'

  const modalId = useRef(id || uuid())

  useEffect(() => {
    if (isOpen) {
      overlayController.setConfig(modalId.current, {
        isShowing: true,
        isClosable,
        onCloseCb,
        initialFocusId,
        focusEnabledRefs,
        content: children,
        animate,
        srName,
        type,
        header,
        footer,
        rootElement,
        renderOptions: {
          size,
          appearance,
          side,
          overlayBlur,
          fullScreenOnSM,
        },
        id: modalId.current,
        dataSelectorPrefix,
        dataAttributes,
        componentName,
      })
    } else {
      overlayController.hide(modalId.current)
    }
  }, [
    isOpen,
    isClosable,
    onCloseCb,
    initialFocusId,
    focusEnabledRefs,
    children,
    animate,
    size,
    srName,
    appearance,
    side,
    overlayBlur,
    type,
    id,
    dataSelectorPrefix,
    dataAttributes,
  ])

  // Destructor to be run when the component unmounts
  useEffect(() => {
    return (): void => {
      if (isOpen) {
        overlayController.hide(modalId.current)
      }
    }
  }, [])

  return null
}

export default Modal
