import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme } from '@vfuk/core-themes'

import type { MixinOpacity } from './opacity.types'

import createMixin from '../utils/createMixin'

export default createMixin(
  (theme: Theme, disabled: boolean = false): FlattenSimpleInterpolation => css`
    opacity: ${disabled ? theme.opacity : '1'};
  `,
) as MixinOpacity
