import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { ContainerStyles } from '@vfuk/core-container'
import { respondTo, spacing } from '@vfuk/core-mixins'

import type { StyledModalRendererProps } from './ModalRenderer.style.types'

type ModalRendererTheme = Pick<StyledModalRendererProps, 'theme'>

type ModalRendererProps = Pick<StyledModalRendererProps, 'theme' | 'zIndex'>
export const ModalRenderer = styled.div<ModalRendererProps>((props: ModalRendererProps): FlattenSimpleInterpolation => {
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    ${spacing('padding', 3)}
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${props.zIndex};
  `
})

export const ModalWrapper = styled.div(
  (props: ModalRendererProps) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${props.zIndex};
    width: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    ${respondTo.md(css`
      top: ${props.theme.spacing.responsive[5].md}px;
      left: ${props.theme.spacing.responsive[5].md}px;
      right: ${props.theme.spacing.responsive[5].md}px;
      bottom: ${props.theme.spacing.responsive[5].md}px;
      width: auto;
    `)}
  `,
)

type ContainerWrapperProps = Pick<StyledModalRendererProps, 'theme' | 'size' | 'fullScreenOnSM'>
export const ContainerWrapper = styled.div<ContainerWrapperProps>((props) => {
  return css`
    pointer-events: auto;
    width: ${props.size.sm};
    max-height: 100%;

    ${props.fullScreenOnSM &&
    css`
      height: 100%;
    `}

    ${respondTo.md(css`
      max-width: ${props.size.md};
      height: auto;
    `)}

    ${respondTo.lg(css`
      max-width: ${props.size.lg};
      height: auto;
    `)}

    ${respondTo.xl(css`
      max-width: ${props.size.xl};
      height: auto;
    `)}

    & > ${ContainerStyles.Container} {
      width: 100%;
      max-height: 100%;

      ${props.fullScreenOnSM &&
      css`
        border-radius: 0;
      `}

      ${respondTo.md(css`
        border-radius: ${props.theme.border.radius[2]};
      `)}

      & > div {
        max-height: 100%;
        overflow: auto;
      }
    }
  `
})

export const ModalContent = styled.div(
  () => css`
    max-height: 100%;
    overflow: auto;
    ${spacing('padding', 7)}
  `,
)

export const Footer = styled.footer(
  (props: Pick<StyledModalRendererProps, 'theme' | 'modalTheme' | 'appearance'>) => css`
    position: sticky;
    bottom: 0;
    border-top: ${props.theme.border.width[1]} ${props.theme.border.style[1]}
      ${props.modalTheme.appearance[props.appearance].footer.borderColor};
  `,
)

export const ContentWrapper = styled.div(
  () => css`
    position: relative;
  `,
)
