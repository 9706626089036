import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme, SpacingKeys } from '@vfuk/core-themes'

import type { MixinResponsiveCalc, Properties, Operators } from './responsiveCalc.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (
    theme: Theme,
    property: Properties,
    initialvalue: string,
    operator: Operators,
    spacingValue: SpacingKeys,
  ): FlattenSimpleInterpolation => {
    return css`
      ${property}: calc(${initialvalue} ${operator} ${theme.spacing.responsive[spacingValue!].sm}px);

      ${respondTo.md(css`
        ${property}: calc(${initialvalue} ${operator} ${theme.spacing.responsive[spacingValue!].md}px);
      `)}

      ${respondTo.lg(css`
        ${property}: calc(${initialvalue} ${operator} ${theme.spacing.responsive[spacingValue!].lg}px);
      `)}

      ${respondTo.xl(css`
        ${property}: calc(${initialvalue} ${operator} ${theme.spacing.responsive[spacingValue!].xl}px);
      `)}
    `
  },
) as MixinResponsiveCalc
