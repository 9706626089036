/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Paragraph, StateNotification } from 'r10-source-library';

const AuthMessage = props => {
  const { message } = props;
  useEffect(() => {
    if (props.liveness) {
      props.liveness.update();
    }
  });

  return message ? (
    <div style={{ maxWidth: '483px', marginTop: '16px' }}>
      <StateNotification state="error">
        <Paragraph>{message}</Paragraph>
      </StateNotification>
    </div>
  ) : null;
};

const mapStateToProps = state => ({
  message: state.authentication ? state.authentication.error : null
});

export default connect(mapStateToProps)(AuthMessage);
