import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/CollapsibleContainerBody.style'

import type { CollapsibleContainerBodyProps, CollapsibleContainerBodyState } from './CollapsibleContainerBody.types'

import { CollapsibleContainerContext } from '../../contexts/CollapsibleContainerContext'
import type { CollapsibleContainerContextType } from '../../contexts/CollapsibleContainerContext.types'

export class CollapsibleContainerBody extends PureComponent<CollapsibleContainerBodyProps, CollapsibleContainerBodyState> {
  public static contextType = CollapsibleContainerContext

  public context: CollapsibleContainerContextType

  private collapsibleBodyElement = React.createRef<HTMLDivElement>()

  public render(): ReactNode {
    return (
      <Styled.CollapsibleContainerBody
        appearance={this.context.appearance}
        isInverse={this.context.isInverse}
        isOpen={this.context.isActive}
        id={this.context.id}
        ref={this.collapsibleBodyElement}
        isTransitioning={this.context.isTransitioning}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
      >
        {this.props.children}
      </Styled.CollapsibleContainerBody>
    )
  }
}
