import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import { createMixin } from '@vfuk/core-mixins'

import type { Animation } from './animations.types'

const none: Animation = {
  animation: createMixin(
    (): FlattenSimpleInterpolation => css`
      @keyframes none {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 1;
        }
      }
    `,
  ) as Animation['animation'],
  config: {
    duration: 0,
  },
}

export default none
