import type { Theme } from '@vfuk/core-themes'

import type { FunctionalPickerItemTheme } from './FunctionalPickerItem.theme.types'

const functionalPickerItemTheme = (theme: Theme): FunctionalPickerItemTheme => {
  return {
    appearance: {
      primary: {
        background: theme.color.monochrome1.default,
        color: theme.color.monochrome6.default,
        border: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.color.monochrome4.default}`,
        boxShadow: '',
        hoverBorderColor: theme.color.monochrome5.default,
        disabled: {
          background: theme.color.monochrome1.default,
          color: theme.color.monochrome6.default,
          border: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.color.monochrome4.default}`,
          boxShadow: 'none',
          hoverBorderColor: theme.color.monochrome5.default,
        },
        checked: {
          background: theme.color.monochrome1.default,
          color: theme.color.secondary1.default,
          border: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.color.secondary1.default}`,
          hoverBorderColor: theme.color.secondary1.default,
          boxShadow: `inset 0px 0px 0px 1px ${theme.color.secondary1.default}`,
        },
        inverse: {
          background: theme.inverseColor.monochrome1.default,
          color: theme.inverseColor.monochrome6.default,
          border: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.inverseColor.monochrome4.default}`,
          boxShadow: 'none',
          hoverBorderColor: theme.inverseColor.monochrome5.default,
          disabled: {
            background: theme.inverseColor.monochrome1.default,
            color: theme.inverseColor.monochrome6.default,
            border: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.inverseColor.monochrome4.default}`,
            boxShadow: 'none',
            hoverBorderColor: theme.inverseColor.monochrome5.default,
          },
          checked: {
            background: theme.inverseColor.monochrome1.default,
            color: theme.inverseColor.secondary1.default,
            border: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.inverseColor.secondary1.default}`,
            boxShadow: `inset 0px 0px 0px 1px ${theme.inverseColor.secondary1.default}`,
            hoverBorderColor: theme.inverseColor.secondary1.default,
          },
        },
      },
    },
    size: {
      1: {
        borderRadius: theme.border.radius[1],
      },
      2: {
        borderRadius: theme.border.radius[2],
      },
    },
    level: {
      1: {
        titleFontWeight: '',
      },
      2: {
        titleFontWeight: `${theme.fontWeight[3]}`,
      },
    },
    afterFontWeight: `${theme.fontWeight[3]}}`,
    titleFontWeight: `${theme.fontWeight[3]}`,
    subTitleFontWeight: `${theme.fontWeight[3]}`,
    footnoteFontWeight: `${theme.fontWeight[3]}`,
  }
}

export default functionalPickerItemTheme
