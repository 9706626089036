import type { ImageProps, Breakpoint, SrcSetSizes } from '../../Image.types'
import { breakpoints } from '../../utils/breakpoints'

import getSourceSetValue from '../getSourceSetValue'

function getSource(breakpoint: Breakpoint, props: ImageProps): string | null {
  const currentBreakpoint = breakpoints.indexOf(breakpoint)

  const sourceSet = props[breakpoint]?.srcSet
  const src = props[breakpoint]?.src
  const nextBreakpoint = breakpoints[currentBreakpoint - 1]

  // If neither then go the next breakpoint
  if (!sourceSet && !src && nextBreakpoint) {
    return getSource(nextBreakpoint, props)
  }

  // If the breakpoint has no sourceSet but has src
  if (!sourceSet && src) return src

  if (sourceSet) {
    return Object.keys(sourceSet)
      .map((size: SrcSetSizes) => getSourceSetValue(sourceSet[size], size))
      .filter(Boolean)
      .join(', ')
  }

  return null
}

export default getSource
