export enum OverlayCloseSource {
  MODAL_CROSS_CLICK = 'MODAL_CROSS_CLICK',
  MODAL_OUTSIDE_CLICK = 'MODAL_OUTSIDE_CLICK',
  FLYOUT_CROSS_CLICK = 'FLYOUT_CROSS_CLICK',
  FLYOUT_OUTSIDE_CLICK = 'FLYOUT_OUTSIDE_CLICK',
  BOTTOMTRAY_CROSS_CLICK = 'BOTTOMTRAY_CROSS_CLICK',
  BOTTOMTRAY_OUTSIDE_CLICK = 'BOTTOMTRAY_OUTSIDE_CLICK',
  SIDETRAY_CROSS_CLICK = 'SIDETRAY_CROSS_CLICK',
  SIDETRAY_OUTSIDE_CLICK = 'SIDETRAY_OUTSIDE_CLICK',
}
