import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { capitalizeFirstCharacter } from '@vfuk/core-helpers'
import { getDataSelector } from '@vfuk/core-base-props'

import type { TimerBlockProps } from './TimerBlock.types'

import * as Styled from './styles/TimerBlock.style'

export default class TimerBlock extends PureComponent<TimerBlockProps> {
  public render(): ReactNode {
    const remainingTime = this.props.remainingTime.toString()
    return (
      <Styled.TimerBlock data-selector={getDataSelector(this.props.dataSelectorPrefix)}>
        {this.props.remainingTime < 10 ? (
          <>
            <Styled.TimerNumber inverse={this.props.inverse} data-selector={getDataSelector(this.props.dataSelectorPrefix, 'zero')}>
              0
            </Styled.TimerNumber>
            <Styled.TimerNumber
              inverse={this.props.inverse}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, 'remaining-time')}
            >
              {this.props.remainingTime}
            </Styled.TimerNumber>
          </>
        ) : (
          <>
            <Styled.TimerNumber
              inverse={this.props.inverse}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, 'remaining-time-1')}
            >
              {remainingTime.charAt(0)}
            </Styled.TimerNumber>
            <Styled.TimerNumber
              inverse={this.props.inverse}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, 'remaining-time-2')}
            >
              {remainingTime.charAt(1)}
            </Styled.TimerNumber>
          </>
        )}
        {this.props.showUnit && (
          <Styled.TimerUnit data-selector={getDataSelector(this.props.dataSelectorPrefix, 'unit')}>
            {capitalizeFirstCharacter(this.props.unit)}
          </Styled.TimerUnit>
        )}
      </Styled.TimerBlock>
    )
  }
}
