import { useBreakpoints } from '@vfuk/core-match-media'

import type { BreakpointKeys } from '@vfuk/core-themes'

const sortedBreakpoints: BreakpointKeys[] = ['xl', 'lg', 'md', 'sm']

function useBreakpointSwitch<T extends { [Breakpoint in BreakpointKeys]?: unknown }>(data: T): T[keyof T] {
  const breakpoints = useBreakpoints()

  if (typeof data[breakpoints.current] !== 'undefined') return data[breakpoints.current] as T[keyof T]

  const validBreakpoints = sortedBreakpoints.filter(
    (breakpoint) =>
      Object.prototype.hasOwnProperty.call(data, breakpoint) ||
      sortedBreakpoints.indexOf(breakpoint) < sortedBreakpoints.indexOf(breakpoints.current),
  )

  return validBreakpoints.reduce((acc, breakpoint, i) => {
    if (breakpoints[breakpoint] && (i === 0 || !breakpoints[sortedBreakpoints[i - 1]])) {
      return data[breakpoint]
    }
    return acc
  }, undefined) as T[keyof T]
}

export default useBreakpointSwitch
