import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import * as Styled from './styles/Heading.style'
import defaultTheme from './themes/Heading.theme'

import type { HeadingProps, HeadingElements } from './Heading.types'

const Heading: FC<HeadingProps> = ({
  level = 2,
  noMargin = false,
  weight = 1,
  inverse = false,
  appearance = 'primary',
  size,
  text,
  justify,
  children,
  dataAttributes,
  dataSelectorPrefix,
  id,
  localTheme,
}: HeadingProps): ReactElement => {
  const componentName = 'Heading'

  const headingTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  return (
    <Styled.Heading
      as={`h${level}` as HeadingElements}
      level={level!}
      size={size}
      justify={justify}
      appearance={appearance!}
      inverse={inverse!}
      weight={weight}
      noMargin={noMargin!}
      id={id}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      headingTheme={headingTheme}
      data-component-name={componentName}
    >
      {text || children}
    </Styled.Heading>
  )
}

export default Heading
