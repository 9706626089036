import type { FC, ReactElement } from 'react'
import React from 'react'

import * as Styled from './styles/Spacing.style'

import type { SpacingProps } from './Spacing.types'

const Spacing: FC<SpacingProps> = ({ spacingLevel, children }: SpacingProps): ReactElement => {
  return (
    <Styled.Spacing
      spacingLevel={{
        top: spacingLevel.top,
        right: spacingLevel.right,
        bottom: spacingLevel.bottom,
        left: spacingLevel.left,
      }}
    >
      {children}
    </Styled.Spacing>
  )
}

export default Spacing
