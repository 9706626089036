import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledTabProps } from './Tab.style.types'

export const TabContainer = styled.div(
  (props: StyledTabProps): FlattenSimpleInterpolation => css`
    > div:first-child {
      &:focus {
        outline: 0 none !important;
      }
    }
  `,
)
