import type { Theme } from '@vfuk/core-themes'

import type { LinkWithIconTheme } from './LinkWithIcon.theme.types'

const defaultTheme = (theme: Theme): LinkWithIconTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.secondary7.default,
        hoverColor: theme.color.secondary7.hover,
        iconAppearance: 'secondary',
        inverse: {
          color: theme.inverseColor.secondary7.default,
          hoverColor: theme.inverseColor.secondary7.hover,
          iconAppearance: 'primary',
        },
      },
      secondary: {
        color: theme.color.monochrome6.default,
        hoverColor: theme.color.secondary7.hover,
        iconAppearance: 'primary',
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          hoverColor: theme.inverseColor.secondary7.hover,
          iconAppearance: 'primary',
        },
      },
      alt1: {
        color: theme.color.monochrome6.default,
        hoverColor: theme.color.secondary7.hover,
        iconAppearance: 'primary',
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          hoverColor: theme.inverseColor.monochrome6.hover,
          iconAppearance: 'primary',
        },
      },
    },
  }
}

export default defaultTheme
