import type { FormFields } from '@vfuk/core-themes'

import colors from './colors'
import inverseColors from './inverseColors'
import border from './border'

const formFields: FormFields = {
  backgroundColor: colors.monochrome1.default,
  color: colors.monochrome5.default,
  border: {
    radius: border.radius[2],
    width: border.width[1],
    color: colors.monochrome4.default,
    style: border.style[1],
  },
  icon: {
    appearance: 'primary',
    inverse: false,
  },
  state: {
    success: {
      color: colors.monochrome5.default,
      backgroundColor: colors.monochrome1.default,
      border: {
        color: colors.success.default,
        width: border.width[2],
        radius: border.radius[2],
        style: border.style[1],
      },
    },
    info: {
      color: colors.monochrome5.default,
      backgroundColor: colors.monochrome1.default,
      border: {
        color: colors.info.default,
        width: border.width[2],
        radius: border.radius[2],
        style: border.style[1],
      },
    },
    warn: {
      color: colors.monochrome5.default,
      backgroundColor: colors.monochrome1.default,
      border: {
        color: colors.warn.default,
        width: border.width[2],
        radius: border.radius[2],
        style: border.style[1],
      },
    },
    error: {
      color: colors.monochrome5.default,
      backgroundColor: colors.monochrome1.default,
      border: {
        color: colors.error.default,
        width: border.width[2],
        radius: border.radius[2],
        style: border.style[1],
      },
    },
    disabled: {
      color: colors.monochrome5.disabled,
      backgroundColor: colors.monochrome2.default,
      border: {
        color: colors.monochrome5.disabled,
        width: border.width[1],
        radius: border.radius[2],
        style: border.style[1],
      },
    },
  },
  selected: {
    backgroundColor: colors.secondary1.default,
    color: colors.monochrome1.default,
    border: {
      color: colors.secondary1.default,
      width: border.width[1],
      radius: border.radius[2],
      style: border.style[1],
    },
  },
  inverse: {
    backgroundColor: inverseColors.monochrome1.default,
    color: inverseColors.monochrome5.default,
    border: {
      radius: border.radius[2],
      width: border.width[1],
      color: inverseColors.monochrome3.default,
      style: border.style[1],
    },
    icon: {
      appearance: 'primary',
      inverse: false,
    },
    state: {
      success: {
        color: inverseColors.monochrome5.default,
        backgroundColor: inverseColors.monochrome1.default,
        border: {
          color: inverseColors.success.default,
          width: border.width[2],
          radius: border.radius[2],
          style: border.style[1],
        },
      },
      info: {
        color: inverseColors.monochrome5.default,
        backgroundColor: inverseColors.monochrome1.default,
        border: {
          color: inverseColors.info.default,
          width: border.width[2],
          radius: border.radius[2],
          style: border.style[1],
        },
      },
      warn: {
        color: inverseColors.monochrome5.default,
        backgroundColor: inverseColors.monochrome1.default,
        border: {
          color: inverseColors.warn.default,
          width: border.width[2],
          radius: border.radius[2],
          style: border.style[1],
        },
      },
      error: {
        color: inverseColors.monochrome5.default,
        backgroundColor: inverseColors.monochrome1.default,
        border: {
          color: inverseColors.error.default,
          width: border.width[2],
          radius: border.radius[2],
          style: border.style[1],
        },
      },
      disabled: {
        color: inverseColors.monochrome5.disabled,
        backgroundColor: inverseColors.monochrome2.default,
        border: {
          color: inverseColors.monochrome5.disabled,
          width: border.width[1],
          radius: border.radius[2],
          style: border.style[1],
        },
      },
    },
    selected: {
      backgroundColor: inverseColors.secondary1.default,
      color: inverseColors.monochrome6.default,
      border: {
        color: inverseColors.secondary1.default,
        width: border.width[1],
        radius: border.radius[2],
        style: border.style[1],
      },
    },
  },
}

export default formFields
