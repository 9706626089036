import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'
import { advancedSpacing, spacing } from '@vfuk/core-mixins'
import { FunctionalCarouselStyles } from '@vfuk/core-functional-carousel'

import type { StyledCardsSectionProps } from './CardsSection.style.types'

export const Section = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    ${spacing('margin-bottom', 4)};
  `,
)

export const CardsSection = styled.div<StyledCardsSectionProps>(
  (props: StyledCardsSectionProps): FlattenSimpleInterpolation => css`
    max-width: ${(props.theme.gridConfig.springWidth / 12) * props.cardsSectionColumns}px;
    margin: 0 auto;
  `,
)

export const Cards = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('margin', [8, 0])};

    ${FunctionalCarouselStyles.CarouselWrapper} {
      .slick-slide {
        align-self: end;
        & > div {
          min-width: 0;
        }
      }
    }
  `,
)
