import { useTheme } from 'styled-components'

import type { Theme } from '../../themes.types'
import camelCase from '../../helpers/camelCase'

function useLocalTheme<T>(componentName: string, defaultTheme: (theme?: Theme) => T, localTheme?: T): T {
  const theme = useTheme() as Theme
  const camelCaseComponentName = camelCase(componentName)

  if (localTheme) return localTheme

  if (theme.localThemes && theme.localThemes[camelCaseComponentName]) return theme.localThemes[camelCaseComponentName]

  return defaultTheme(theme)
}

export default useLocalTheme
