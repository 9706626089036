import type { FC } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/TopLabels.style'
import type { TopLabelsProps } from './TopLabels.types'

const TopLabels: FC<TopLabelsProps> = ({ labels, dataSelectorPrefix }) => (
  <Styled.Labels data-selector={getDataSelector(dataSelectorPrefix)}>
    {labels?.left && (
      <Styled.LeftLabel isRight={!!labels?.right} isLeft={!!labels?.left} data-selector={getDataSelector(dataSelectorPrefix, 'left-label')}>
        {labels?.left}
      </Styled.LeftLabel>
    )}
    {labels?.center && (
      <Styled.CenterLabel
        isRight={!!labels?.right}
        isLeft={!!labels?.left}
        data-selector={getDataSelector(dataSelectorPrefix, 'center-label')}
      >
        {labels?.center}
      </Styled.CenterLabel>
    )}
    {labels?.right && (
      <Styled.RightLabel data-selector={getDataSelector(dataSelectorPrefix, 'right-label')}>{labels?.right}</Styled.RightLabel>
    )}
  </Styled.Labels>
)

export default TopLabels
