import type { ComponentType } from 'react'
import React from 'react'

import { useBreakpoints } from '../useBreakpoints'

// HOC
export const withBreakpoints = <T,>(TargetComponent: ComponentType<T>): ComponentType<T> => {
  return (props: T): React.ReactElement => {
    const breakpoints = useBreakpoints()

    // TypeScript error with React.ComponentType means it won't allow
    // it to be returned in a rendered format. Need to recast it to
    // a FunctionalComponent to allow the generic T to be passed in
    const RecastComponent = TargetComponent as unknown as React.FC<T>
    return <RecastComponent {...props} breakpoints={breakpoints} />
  }
}
