import type { ScriptProperties } from './loadScript.types'

function loadScript(src: string, params: ScriptProperties = { async: false, defer: false }): Promise<boolean> {
  const scriptElement = document.createElement('script')
  scriptElement.type = 'text/javascript'
  scriptElement.async = params.async!
  scriptElement.defer = params.defer!
  scriptElement.src = src

  return new Promise((resolve, reject) => {
    scriptElement.onload = (): void => {
      resolve(true)
    }
    scriptElement.onerror = reject
    document.head.appendChild(scriptElement)
  })
}

export default loadScript
