import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { getIconSize } from '@vfuk/core-icon/dist/styles/mixins/iconSizes'

import { StyledIconYoutubePremiumProps } from './IconYoutubePremium.style.types'

import ruleSet from '../../../ruleSet'

export const IconWrapper = styled.span<StyledIconYoutubePremiumProps>(
  (props: StyledIconYoutubePremiumProps): FlattenSimpleInterpolation => {
    const localTheme = ruleSet()

    return css`
      position: relative;
      display: inline-flex;
      vertical-align: middle;

      span {
        display: inline-flex;
      }

      svg {
        ${getIconSize(props.isResponsive, localTheme.size[props.size!])}
      }
    `
  },
)
