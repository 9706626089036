import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme, SpacingKeys } from '@vfuk/core-themes'

import type { MixinSpacing, Properties } from './spacing.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (theme: Theme, property: Properties, value: SpacingKeys): FlattenSimpleInterpolation => css`
    ${value < 0 &&
    css`
      ${property}: -${theme.spacing.responsive[Math.abs(value) as SpacingKeys].sm}px;

      ${respondTo.md(css`
        ${property}: -${theme.spacing.responsive[Math.abs(value) as SpacingKeys].md}px;
      `)}

      ${respondTo.lg(css`
        ${property}: -${theme.spacing.responsive[Math.abs(value) as SpacingKeys].lg}px;
      `)}

    ${respondTo.xl(css`
        ${property}: -${theme.spacing.responsive[Math.abs(value) as SpacingKeys].xl}px;
      `)}
    `}

    ${value > -1 &&
    css`
      ${property}: ${theme.spacing.responsive[value].sm}px;

      ${respondTo.md(css`
        ${property}: ${theme.spacing.responsive[value].md}px;
      `)}

      ${respondTo.lg(css`
        ${property}: ${theme.spacing.responsive[value].lg}px;
      `)}

    ${respondTo.xl(css`
        ${property}: ${theme.spacing.responsive[value].xl}px;
      `)}
    `}
  `,
) as MixinSpacing
