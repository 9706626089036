import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { elevation, respondTo } from '@vfuk/core-mixins'

import type { StyledOverflowMenuProps } from './OverflowMenu.style.types'

export const OverflowMenu = styled.ul<StyledOverflowMenuProps>(
  (props): FlattenSimpleInterpolation => css`
    position: absolute;
    padding: 0;
    margin: 0;
    max-height: 0;
    width: 100%;
    max-width: 300px;
    min-width: 200px;
    overflow-x: auto;
    background-color: ${props.theme.color.monochrome1.default};
    border-radius: ${props.theme.border.radius[2]};
    ${elevation(true)};
    list-style: none;
    z-index: 1000;
    transition: all 0.4s ease-in-out;

    ${props.isOpen &&
    css`
      max-height: 192px;

      ${respondTo.lg(css`
        max-height: 200px;
      `)}
    `}

    ${respondTo.md(css`
      max-width: 700px;
    `)}
  `,
)
