/* eslint-disable sonarjs/cognitive-complexity */
import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'
import Span from '@vfuk/core-span'

import defaultTheme from './themes/TextStack.theme'
import * as Styled from './styles/TextStack.style'

import type { CurrencySymbol, Justify, TextStackProps } from './TextStack.types'

const TextStack: FC<TextStackProps> = ({
  appearance = 'primary',
  negative = false,
  level = 1,
  inverse = false,
  justify = 'left',
  size = 1,
  text,
  currency,
  id,
  localTheme,
  dataAttributes,
  dataSelectorPrefix,
}: TextStackProps): ReactElement => {
  const componentName = 'TextStack'
  const textStackTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const { prefixWeight, currencyWeight, mainTextWeight, mainSuffixWeight, suffixWeight, suffix2Weight } = textStackTheme.level[level]

  const appearances = inverse ? textStackTheme.appearance[appearance].inverse : textStackTheme.appearance[appearance]

  const { prefixAppearance, mainSuffixAppearance, suffixAppearance, suffix2Appearance, inverseText } = appearances
  const { currencyAppearance, mainTextAppearance } = negative ? appearances.negative : appearances

  const getCurrencyJustify = (): Justify => {
    if (!currency) return 'left'
    if (currency?.justify) return currency.justify

    const rightJustifiedCurrencies: CurrencySymbol[] = ['€', 'Kč']

    return rightJustifiedCurrencies.includes(currency.symbol) ? 'right' : 'left'
  }

  return (
    <Styled.TextStack
      id={id}
      justify={justify!}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      noPrefix={!text.prefix && !text.suffix}
      {...dataAttributes}
    >
      {text.prefix && (
        <Styled.TextWrapper
          textStackTheme={textStackTheme}
          size={size}
          appearance={prefixAppearance}
          weight={prefixWeight}
          inverse={inverseText}
          display='block'
          textType='supportingText'
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'prefix')}
        >
          {text.prefix}
        </Styled.TextWrapper>
      )}
      <Styled.MainContent size={size} justify={justify} display='inline'>
        {negative && (
          <Styled.TextWrapper
            textStackTheme={textStackTheme}
            appearance={mainTextAppearance}
            display='inline'
            inverse={inverseText}
            weight={mainTextWeight}
            size={size}
            textType='sign'
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'negative-sign')}
          >
            -
          </Styled.TextWrapper>
        )}
        <Styled.PriceContainer currencyJustify={getCurrencyJustify()}>
          {currency && (
            <Styled.TextWrapper
              textStackTheme={textStackTheme}
              appearance={currencyAppearance}
              display='inline'
              inverse={inverseText}
              weight={currencyWeight}
              size={size}
              textType='currency'
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'currency-symbol')}
            >
              {currency.symbol}
            </Styled.TextWrapper>
          )}
          <Span
            appearance={mainTextAppearance}
            weight={mainTextWeight}
            display='inline'
            inverse={inverseText}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'main-text')}
          >
            {text.main}
          </Span>
        </Styled.PriceContainer>
        {text.mainSuffix && (
          <Styled.MainSuffixWrapper>
            <Styled.TextWrapper
              textStackTheme={textStackTheme}
              appearance={mainSuffixAppearance}
              display='inline'
              inverse={inverseText}
              weight={mainSuffixWeight}
              size={size}
              textType='supportingText'
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'main-suffix')}
            >
              {text.mainSuffix}
            </Styled.TextWrapper>
          </Styled.MainSuffixWrapper>
        )}
      </Styled.MainContent>
      {text.suffix && (
        <Styled.TextWrapper
          textStackTheme={textStackTheme}
          size={size}
          appearance={suffixAppearance}
          weight={suffixWeight}
          display='block'
          inverse={inverseText}
          textType='supportingText'
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'suffix')}
        >
          {text.suffix}
        </Styled.TextWrapper>
      )}
      {text.suffix2 && (
        <Styled.TextWrapper
          textStackTheme={textStackTheme}
          size={size}
          appearance={suffix2Appearance}
          weight={suffix2Weight}
          display='block'
          inverse={inverseText}
          textType='supportingText'
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'suffix2')}
        >
          {text.suffix2}
        </Styled.TextWrapper>
      )}
    </Styled.TextStack>
  )
}

export default TextStack
