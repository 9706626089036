import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, respondTo, responsiveSizing, spacing, srOnly, typography } from '@vfuk/core-mixins'

import type { StyledHorizontalStepperProps } from './Step.style.types'

import type { Appearance } from '../../../themes/HorizontalStepper.theme.types'

type StepWrapperProps = Pick<
  StyledHorizontalStepperProps,
  'theme' | 'inverse' | 'isCompleted' | 'isActive' | 'appearance' | 'horizontalStepperTheme'
>
export const StepWrapper = styled.div((props: StepWrapperProps): FlattenSimpleInterpolation => {
  let stepWrapperAppearance = props.horizontalStepperTheme.appearance[props.appearance]
  if (props.inverse) {
    stepWrapperAppearance = stepWrapperAppearance.inverse as Appearance
  }

  const { stepColor, activeStepColor } = stepWrapperAppearance

  return css`
    position: relative;
    display: flex;
    flex: 1;
    flex-shrink: 0;
    justify-content: center;
    text-align: center;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 50%;
      background: ${stepColor};
      transform: translateY(-50%);
      position: absolute;
      top: ${props.theme.spacing.responsive[4].sm / 2}px;

      ${respondTo.md(css`
        top: ${props.theme.spacing.responsive[4].md / 2}px;
      `)}

      ${respondTo.lg(css`
        top: ${props.theme.spacing.responsive[4].lg / 2}px;
      `)}

      ${respondTo.xl(css`
        top: ${props.theme.spacing.responsive[4].xl / 2}px;
      `)}
    }

    &::before {
      right: 50%;
    }

    &::after {
      left: 50%;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    ${props.isCompleted &&
    css`
      &::before,
      &::after {
        background: ${activeStepColor};
      }
    `}

    ${props.isActive &&
    css`
      &::before {
        background: ${activeStepColor};

        right: calc(50% + ${props.theme.spacing.responsive[4].sm / 2}px + 3px);

        ${respondTo.md(css`
          right: calc(50% + ${props.theme.spacing.responsive[4].md / 2}px + 3px);
        `)}

        ${respondTo.lg(css`
          right: calc(50% + ${props.theme.spacing.responsive[4].lg / 2}px + 3px);
        `)}

      ${respondTo.xl(css`
          right: calc(50% + ${props.theme.spacing.responsive[4].xl / 2}px + 3px);
        `)}
      }

      &::after {
        left: calc(50% + ${props.theme.spacing.responsive[4].sm / 2}px + 3px);

        ${respondTo.md(css`
          left: calc(50% + ${props.theme.spacing.responsive[4].md / 2}px + 3px);
        `)}

        ${respondTo.lg(css`
          left: calc(50% + ${props.theme.spacing.responsive[4].lg / 2}px + 3px);
        `)}

      ${respondTo.xl(css`
          left: calc(50% + ${props.theme.spacing.responsive[4].xl / 2}px + 3px);
        `)}
      }
    `}
  `
})

type CircleProps = Pick<
  StyledHorizontalStepperProps,
  'theme' | 'isActive' | 'inverse' | 'isCompleted' | 'appearance' | 'horizontalStepperTheme'
>
export const Circle = styled.div<CircleProps>((props: CircleProps): FlattenSimpleInterpolation => {
  let stepWrapperAppearance = props.horizontalStepperTheme.appearance[props.appearance]
  if (props.inverse) {
    stepWrapperAppearance = stepWrapperAppearance.inverse as Appearance
  }

  const { stepColor, activeStepColor } = stepWrapperAppearance
  return css`
    position: relative;
    background: ${stepColor};
    ${responsiveSizing('width', 4)};
    ${responsiveSizing('height', 4)};
    border-radius: 50%;

    ${props.isCompleted &&
    css`
      background: ${activeStepColor};
    `}

    ${props.isActive &&
    css`
      background: ${activeStepColor};

      &::before {
        content: '';
        position: absolute;
        box-sizing: border-box;
        border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${activeStepColor};
        border-radius: 50%;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
      }
    `}
  `
})

type StepTitleProps = Pick<StyledHorizontalStepperProps, 'theme' | 'isActive' | 'inverse' | 'appearance' | 'horizontalStepperTheme'>
export const StepTitle = styled.div<StepTitleProps>((props: StepTitleProps): FlattenSimpleInterpolation => {
  let stepWrapperAppearance = props.horizontalStepperTheme.appearance[props.appearance]
  if (props.inverse) {
    stepWrapperAppearance = stepWrapperAppearance.inverse as Appearance
  }

  const { textColor } = stepWrapperAppearance

  return css`
    display: inline-block;
    transition: all 50ms linear;
    ${typography.paragraph(2, false)};
    font-family: ${props.theme.baseFont.fontFamily};
    font-weight: ${props.theme.fontWeight[1]};
    min-width: 70px;
    ${spacing('padding-top', 2)}
    color: ${textColor};

    ${respondTo.md(css`
      width: max-content;
    `)}

    &::after {
      display: block;
      content: attr(data-title);
      font-weight: ${props.theme.fontWeight[4]};
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    ${props.isActive &&
    css`
      font-weight: ${props.theme.fontWeight[3]};
    `}
  `
})

export const HiddenLabel = styled.span(
  () => css`
    ${srOnly()}
  `,
)

type StepProps = Pick<StyledHorizontalStepperProps, 'theme' | 'hasHover'>
export const Step = styled.div<StepProps>(
  (props: StepProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex: 1;
    flex-shrink: 0;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    border: none;
    background: none;
    ${advancedSpacing('padding', [0, 2])};
    margin: 0;
    position: relative;
    z-index: 1;

    &:focus {
      outline: none;

      ${Circle} {
        box-shadow: 0 0 0 3px ${props.theme.color.focus.default};
      }
    }

    ${props.hasHover &&
    `
    cursor: pointer;

    &:hover {
      ${StepTitle} {
        font-weight: ${props.theme.fontWeight[3]};
      }
    }
    `}
  `,
)
