import type { FC } from 'react'
import React, { memo } from 'react'

import IconWithBackground from '@vfuk/core-icon-with-background'
import Span from '@vfuk/core-span'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/ProgressBar.style'

import type { ProgressBarProps } from './ProgressBar.types'

const ProgressBar: FC<ProgressBarProps> = ({
  value,
  topText,
  icon,
  appearance = 'primary',
  inverse = false,
  srLabel,
  id,
  dataSelectorPrefix,
  dataAttributes,
}) => {
  const componentName = 'ProgressBar'

  return (
    <Styled.ProgressBarContainer
      inverse={inverse}
      topText={topText}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      {icon?.name && (
        <Styled.IconWithBackgroundWrapper>
          <IconWithBackground
            icon={icon!}
            shape='square'
            size={3}
            appearance='alt3'
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')}
          />
        </Styled.IconWithBackgroundWrapper>
      )}

      <Styled.ProgressBarSection>
        <Styled.TopText>
          <Span inverse={inverse} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'left-top-text')}>
            {topText?.left}
          </Span>
          <Span inverse={inverse} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'right-top-text')}>
            {topText?.right}
          </Span>
        </Styled.TopText>

        <Styled.ProgressBarTrack>
          <Styled.ProgressBar
            role='progressbar'
            inverse={inverse}
            width={value}
            appearance={appearance}
            aria-valuenow={value}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-label={srLabel}
            data-selector={getDataSelector(dataSelectorPrefix, 'bar-track')}
          />
        </Styled.ProgressBarTrack>
      </Styled.ProgressBarSection>
    </Styled.ProgressBarContainer>
  )
}

export default memo(ProgressBar)
