import type { Theme } from '@vfuk/core-themes'

import type { FlyoutRendererTheme } from './FlyoutRenderer.theme.types'

const defaultTheme = (theme: Theme): FlyoutRendererTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1.default,
        textColor: theme.color.monochrome6.default,
        inverseIcon: false,
      },
      secondary: {
        backgroundColor: theme.color.monochrome6.default,
        textColor: theme.color.monochrome1.default,
        inverseIcon: true,
      },
    },
  }
}

export default defaultTheme
