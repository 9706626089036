import type { Theme } from '@vfuk/core-themes'

import type { HorizontalStepperTheme } from './HorizontalStepper.theme.types'

const defaultTheme = (theme: Theme): HorizontalStepperTheme => {
  return {
    appearance: {
      primary: {
        stepColor: theme.color.monochrome3.default,
        activeStepColor: theme.color.primary1.default,
        textColor: theme.color.monochrome6.default,
        inverse: {
          stepColor: theme.inverseColor.monochrome4.default,
          activeStepColor: theme.inverseColor.primary1.default,
          textColor: theme.inverseColor.monochrome6.default,
        },
      },
    },
  }
}

export default defaultTheme
