import { SET_PAGE_CONFIG } from 'actions/configActions';

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PAGE_CONFIG:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
