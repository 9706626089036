import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { srOnly } from '@vfuk/core-mixins'

export const Loader = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
)

export const HiddenLabel = styled.span(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)
