import type { BreakpointKeys } from '@vfuk/core-themes'

import type { SizeKeys } from '../../FunctionalAvatar.types'

function calculateSize(size: SizeKeys): { [P in BreakpointKeys]: number } {
  switch (size) {
    case 1:
      return {
        sm: 24,
        md: 24,
        lg: 24,
        xl: 24,
      }
    case 2:
      return {
        sm: 32,
        md: 32,
        lg: 32,
        xl: 32,
      }
    case 3:
      return {
        sm: 48,
        md: 48,
        lg: 48,
        xl: 48,
      }
    case 4:
      return {
        sm: 64,
        md: 64,
        lg: 64,
        xl: 64,
      }
    case 5:
      return {
        sm: 80,
        md: 80,
        lg: 88,
        xl: 88,
      }
    case 6:
      return {
        sm: 88,
        md: 96,
        lg: 104,
        xl: 104,
      }
    default:
      return {
        sm: 0,
        md: 0,
        lg: 0,
        xl: 0,
      }
  }
}

export default calculateSize
