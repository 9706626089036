/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { IconStyles } from '@vfuk/core-icon'
import { LoadingSpinnerStyles } from '@vfuk/core-loading-spinner'
import { iconAppearance, opacity, respondTo } from '@vfuk/core-mixins'

import type { StyledIconButtonProps } from './IconButton.style.types'

export const IconButton = styled(Interaction)<StyledIconButtonProps>(
  (props: StyledIconButtonProps): FlattenSimpleInterpolation => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    height: ${props.iconButtonTheme.size[props.size!].sm.buttonSize}px;
    width: ${props.iconButtonTheme.size[props.size!].sm.buttonSize}px;
    border-radius: ${props.theme.border.radius[2]};
    font-weight: ${props.theme.fontWeight[2]};
    background: ${props.iconButtonTheme.appearance[props.appearance!].backgroundColor};
    border: ${props.theme.border.width[2]} ${props.theme.border.style[1]} ${props.iconButtonTheme.appearance[props.appearance!].border};

    ${IconStyles.Icon} {
      * {
        ${iconAppearance(props.iconButtonTheme.appearance[props.appearance!].iconColor)};
      }
    }

    &:active {
      background: ${props.iconButtonTheme.appearance[props.appearance!].pressed.backgroundColor};
      border-color: ${props.iconButtonTheme.appearance[props.appearance!].pressed.border};

      ${IconStyles.Icon} {
        * {
          ${iconAppearance(props.iconButtonTheme.appearance[props.appearance!].pressed.iconColor)};
        }
      }
    }

    &:hover {
      background: ${props.iconButtonTheme.appearance[props.appearance!].hover.backgroundColor};
      border: ${props.theme.border.width[2]} ${props.theme.border.style[1]}
        ${props.iconButtonTheme.appearance[props.appearance!].hover.border};

      ${IconStyles.Icon} {
        * {
          ${iconAppearance(props.iconButtonTheme.appearance[props.appearance!].hover.iconColor)};
        }
      }

      ${LoadingSpinnerStyles.LoadingSpinner} {
        svg {
          & > * {
            stroke: ${props.iconButtonTheme.appearance[props.appearance!].hover.iconColor};
          }
        }
      }
    }

    ${respondTo.md(css`
      height: ${props.iconButtonTheme.size[props.size!].md.buttonSize}px;
      width: ${props.iconButtonTheme.size[props.size!].md.buttonSize}px;
    `)}

    ${props.inverse &&
    css`
      background: ${props.iconButtonTheme.appearance[props.appearance!].inverse.backgroundColor};
      border: ${props.theme.border.width[2]} ${props.theme.border.style[1]}
        ${props.iconButtonTheme.appearance[props.appearance!].inverse.border};

      &:hover {
        background: ${props.iconButtonTheme.appearance[props.appearance!].inverse.hover.backgroundColor};
        border: ${props.theme.border.width[2]} ${props.theme.border.style[1]}
          ${props.iconButtonTheme.appearance[props.appearance!].inverse.hover.border};

        ${IconStyles.Icon} {
          * {
            ${iconAppearance(props.iconButtonTheme.appearance[props.appearance!].inverse.hover.iconColor)};
          }
        }

        ${LoadingSpinnerStyles.LoadingSpinner} {
          svg {
            & > * {
              stroke: ${props.iconButtonTheme.appearance[props.appearance!].inverse.hover.iconColor};
            }
          }
        }
      }

      ${IconStyles.Icon} {
        * {
          ${iconAppearance(props.iconButtonTheme.appearance[props.appearance!].inverse.iconColor)};
        }
      }

      &:active {
        background: ${props.iconButtonTheme.appearance[props.appearance!].inverse.pressed.backgroundColor};
        border-color: ${props.iconButtonTheme.appearance[props.appearance!].inverse.pressed.border};

        ${IconStyles.Icon} {
          * {
            ${iconAppearance(props.iconButtonTheme.appearance[props.appearance!].inverse.pressed.iconColor)};
          }
        }
      }
    `}

  ${props.shape === 'circle' &&
    css`
      border-radius: 50%;
    `}

  ${props.state &&
    css`
      ${props.state === 'disabled' &&
      css`
        ${opacity(true)};
        cursor: not-allowed;
      `}

      ${props.state !== 'disabled' &&
      css`
        background: ${props.iconButtonTheme.state[props.state!].backgroundColor};

        &:hover {
          background: ${props.iconButtonTheme.state[props.state!].hover.backgroundColor};

          ${IconStyles.Icon} {
            * {
              ${iconAppearance(props.iconButtonTheme.state[props.state!]!.iconColor)};
            }
          }
        }
      `}
    `}
  `,
)

export const LoadingIcon = styled.span((): FlattenSimpleInterpolation => {
  return css`
    position: absolute;
    top: auto;
    bottom: auto;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
  `
})
