import type { FC } from 'react'
import React, { Fragment, memo, useEffect, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Tooltip.style'

import type { TooltipProps } from './Tooltip.types'

const Tooltip: FC<TooltipProps> = ({
  appearance = 'primary',
  size = 1,
  text,
  children,
  id,
  srText,
  zIndex = 10,
  dataSelectorPrefix,
  dataAttributes,
}) => {
  const componentName = 'Tooltip'

  const [isOpen, setIsOpen] = useState(false)

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)

  const customOffsetModifier = useMemo(
    () => ({
      name: 'offset',
      options: {
        offset: ({ placement }: { placement: string }): [number, number] => {
          if (placement === 'top') return [0, 8]

          return [0, 12]
        },
      },
    }),
    [],
  )

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      customOffsetModifier,
      {
        name: 'preventOverflow',
        options: {
          padding: { left: 16, right: 16, bottom: 8 },
        },
      },
    ],
    placement: 'top',
  })

  const openTooltip = (): void => {
    setIsOpen(true)
  }

  const closeTooltip = (): void => {
    setIsOpen(false)
  }

  /** Option to close Tooltip with Escape key to improve accessibility */
  const closeOnEscapeKey = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeTooltip()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', closeOnEscapeKey)

    return (): void => {
      window.removeEventListener('keydown', closeOnEscapeKey)
    }
  }, [])

  return (
    <Fragment>
      <Styled.TooltipTrigger
        ref={setReferenceElement}
        role='button'
        onMouseOver={openTooltip}
        onFocus={openTooltip}
        onMouseLeave={closeTooltip}
        onBlur={closeTooltip}
        tabIndex={0}
        aria-describedby={id}
        aria-label={srText}
        data-selector={getDataSelector(dataSelectorPrefix, 'trigger')}
      >
        {children}
      </Styled.TooltipTrigger>
      <Styled.TooltipWrapper>
        {isOpen && (
          <Styled.TooltipBody
            role='tooltip'
            appearance={appearance}
            size={size}
            zIndex={zIndex}
            id={id}
            ref={setPopperElement}
            style={styles.popper}
            data-selector={getDataSelector(dataSelectorPrefix)}
            data-component-name={componentName}
            {...dataAttributes}
            {...attributes.popper}
          >
            {text}
            <Styled.TooltipArrow
              appearance={appearance}
              ref={setArrowElement}
              style={styles.arrow}
              zIndex={zIndex}
              data-selector={getDataSelector(dataSelectorPrefix, 'arrow')}
            />
          </Styled.TooltipBody>
        )}
      </Styled.TooltipWrapper>
    </Fragment>
  )
}

export default memo(Tooltip)
