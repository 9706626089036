import type { FC, ReactElement } from 'react'
import React from 'react'
import Heading from '@vfuk/core-heading'
import Divider from '@vfuk/core-divider'
import StateNotification from '@vfuk/core-state-notification'
import SimpleNotification from '@vfuk/core-simple-notification'
import Advert from '@vfuk/core-advert'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/CardList.theme'
import * as Styled from './styles/CardList.style'

import CardsSection from './components/CardsSection'

import type { CardListProps } from './CardList.types'

const CardList: FC<CardListProps> = ({
  dividerTop,
  stateNotification,
  heading,
  subHeading,
  topContent,
  simpleNotification,
  advert,
  cardsSections,
  footerContent,
  dividerBottom,
  renderSameHeight = false,
  id,
  dataAttributes,
  dataSelectorPrefix,
  localTheme,
}: CardListProps): ReactElement => {
  const componentName = 'CardList'
  const cardListTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  return (
    <Styled.CardList id={id} data-selector={getDataSelector(dataSelectorPrefix)} data-component-name={componentName} {...dataAttributes}>
      {dividerTop && (
        <Styled.Section>
          <Divider appearance='secondary' noMargin />
        </Styled.Section>
      )}
      {stateNotification?.state && (
        <Styled.Section>
          <StateNotification dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'state-notification')} {...stateNotification!} />
        </Styled.Section>
      )}
      <Styled.Section>
        <Heading {...heading} weight={3} size={3} noMargin dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'heading')} />
      </Styled.Section>
      {subHeading?.text && (
        <Styled.Section>
          <Heading
            text={subHeading!.text}
            noMargin
            size={2}
            level={subHeading!.level}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'sub-heading')}
          />
        </Styled.Section>
      )}
      {topContent && <Styled.Section data-selector={getDataSelector(dataSelectorPrefix, 'top-content')}>{topContent}</Styled.Section>}
      {simpleNotification?.text && (
        <Styled.Section>
          <SimpleNotification {...simpleNotification!} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'simple-notification')} />
        </Styled.Section>
      )}
      {advert?.headingText && !simpleNotification?.text && (
        <Styled.Section>
          <Advert
            headingText={advert!.headingText}
            image={advert!.image}
            text={advert!.text}
            countdownTimer={advert!.countdownTimer}
            href={advert!.href}
            customRouterProps={advert!.customRouterProps}
            onClick={advert!.onClick}
            onMouseDown={advert!.onMouseDown}
            onMouseUp={advert!.onMouseUp}
            onTouchStart={advert!.onTouchStart}
            onTouchEnd={advert!.onTouchEnd}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'advert')}
          />
        </Styled.Section>
      )}
      {cardsSections.map((cardsSection, sectionIndex) => {
        return (
          <CardsSection
            key={`grid-section-${sectionIndex}-${cardsSection.heading?.text.toLowerCase().replace(' ', '-')}`}
            renderSameHeight={renderSameHeight}
            cardsSection={cardsSection}
            cardListTheme={cardListTheme}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `cards-section-${sectionIndex}`)}
          />
        )
      })}
      {footerContent && (
        <Styled.Section data-selector={getDataSelector(dataSelectorPrefix, 'footer-content')}>{footerContent}</Styled.Section>
      )}
      {dividerBottom && (
        <Styled.Section>
          <Divider appearance='secondary' noMargin dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'divider')} />
        </Styled.Section>
      )}
    </Styled.CardList>
  )
}

export default CardList
