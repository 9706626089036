import type { FlattenSimpleInterpolation } from 'styled-components'
import s, { css } from 'styled-components'
// @ts-expect-error styled is not typed with default, resolves ESM issue found in Nextjs
const styled: typeof s = typeof s === 'object' && 'default' in s ? s.default : s

import ListGroup from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'
import { respondTo, responsiveCalc, responsivePosition, responsiveSizing, spacing } from '@vfuk/core-mixins'

import type { StyledVerticalStepperProps } from './VerticalStepper.style.types'

// Constant for step circle size in the stepper
const stepCircleSize = 4
// Constant for step circle's right padding
const stepCircleRightPadding = 3

export const VerticalStepper = styled(ListGroup)((): FlattenSimpleInterpolation => [])

export const Step = styled(ListItem)<StyledVerticalStepperProps>((props: StyledVerticalStepperProps): FlattenSimpleInterpolation => {
  const { stepColor, activeStep } = props.verticalStepperTheme.appearance[props.stepAppearance]

  return css`
    position: relative;
    padding-left: ${props.theme.spacing.responsive[stepCircleSize].sm + props.theme.spacing.responsive[stepCircleRightPadding].sm}px;
    ${spacing('margin-bottom', 6)};

    ${respondTo.md(css`
      padding-left: ${props.theme.spacing.responsive[stepCircleSize].md + props.theme.spacing.responsive[stepCircleRightPadding].md}px;
    `)}

    ${respondTo.lg(css`
      padding-left: ${props.theme.spacing.responsive[stepCircleSize].lg + props.theme.spacing.responsive[stepCircleRightPadding].lg}px;
    `)}

    ${respondTo.xl(css`
      padding-left: ${props.theme.spacing.responsive[stepCircleSize].xl + props.theme.spacing.responsive[stepCircleRightPadding].xl}px;
    `)}

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 13px;
      left: 0;
      z-index: 1;
      background: ${stepColor};
      border-radius: 50%;
      ${responsiveSizing('height', stepCircleSize)};
      ${responsiveSizing('width', stepCircleSize)};
      transform: translateY(-50%);

      ${props.state === 'completed' &&
      css`
        background: ${activeStep.color};
      `}

      ${props.isActive &&
      css`
        background: ${activeStep.color};
        border: ${activeStep.border};
        box-shadow: ${activeStep.boxShadow};
      `}
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      ${responsivePosition('top', stepCircleSize)};
      width: 2px;
      background: ${stepColor};
      ${responsiveCalc('height', '100%', '+', 6)};

      ${(props.isActive || props.state === 'completed') &&
      css`
        background: ${activeStep.color};
      `}

      left: calc((${props.theme.spacing.responsive[stepCircleSize].sm}px / 2) - 1px);

      ${respondTo.md(css`
        left: calc((${props.theme.spacing.responsive[stepCircleSize].md}px / 2) - 1px);
      `)}

      ${respondTo.lg(css`
        left: calc((${props.theme.spacing.responsive[stepCircleSize].lg}px / 2) - 1px);
      `)}

        ${respondTo.xl(css`
        left: calc((${props.theme.spacing.responsive[stepCircleSize].xl}px / 2) - 1px);
      `)}
    }

    &:last-child {
      ${spacing('margin-bottom', 3)};

      &::after {
        height: 100%;
      }
    }

    ${props.isFinal &&
    css`
      margin-bottom: 0;

      &::after {
        display: none;
      }
    `}
  `
})

export const StepTitle = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: space-between;
  `,
)

export const StepSubtitle = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-bottom', 2)};
  `,
)

export const StepContent = styled.div(
  () => css`
    ${spacing('padding-top', 2)};
  `,
)
