import type { FC, ReactElement } from 'react'
import React, { useEffect, useRef } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import Step from './components/Step'

import * as Styled from './styles/HorizontalStepper.style'
import defaultTheme from './themes/HorizontalStepper.theme'

import type { HorizontalStepperProps } from './HorizontalStepper.types'

import scrollToStep from './helpers/scrollToStep'

const HorizontalStepper: FC<HorizontalStepperProps> = ({
  appearance = 'primary',
  currentStep = 1,
  inverse = false,
  steps,
  id,
  dataSelectorPrefix,
  dataAttributes,
  localTheme,
}: HorizontalStepperProps): ReactElement => {
  const componentName = 'HorizontalStepper'
  const horizontalStepperTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const stepperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    scrollToStep({ stepperRef, currentStep })
  }, [currentStep])

  return (
    <Styled.HorizontalStepper
      ref={stepperRef}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      {steps.map((step, index: number) => {
        const stepIndex = index + 1
        const isCompleted = stepIndex < currentStep
        const isActive = stepIndex === currentStep
        const isClickable = stepIndex < currentStep && step.onClick

        return (
          <Step
            key={stepIndex}
            inverse={inverse}
            isCompleted={isCompleted}
            isActive={isActive}
            onClick={isClickable ? (): void => step.onClick!(stepIndex) : undefined}
            title={step.title}
            srText={step.srText}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `step-${index}`)}
            horizontalStepperTheme={horizontalStepperTheme}
            appearance={appearance}
          />
        )
      })}
    </Styled.HorizontalStepper>
  )
}

export default HorizontalStepper
