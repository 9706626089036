import settings from "settings";
import axios from "axios";
let Liveness = {
  lastActionTimestamp: null,
  store: null,
  screenLockTimeout: 60 * 60000,
  screenLockTimeoutWarning: 5 * 60000,
  isAuthenticated: false,
  updateTimeout: () => {
    let state = Liveness.store.getState();
    if (state.config.data && state.config.data.global && state.config.data.global.screenLockTimeout) {
      let screenLockTimeout = parseInt(state.config.data.global.screenLockTimeout);
      let screenLockTimeoutWarning = parseInt(state.config.data.global.screenLockTimeoutWarning);

      if (screenLockTimeoutWarning > 0) {
        Liveness.screenLockTimeoutWarning = screenLockTimeoutWarning * 60000;
      }

      if (screenLockTimeout > 0) {
        Liveness.screenLockTimeout = screenLockTimeout * 60000;
      }
    }
    Liveness.isAuthenticated = (state.authentication && state.authentication.name) || state.authentication.authentication?.name;
  },
  setStore: (store) => {
    Liveness.store = store;
    Liveness.updateTimeout();
    store.subscribe(Liveness.updateTimeout);
  },
  getInactivityDuration: () => {
    return Date.now() - Liveness.lastActionTimestamp;
  },
  checkTimeout: () => {
    if (Liveness.lastActionTimestamp) {
      let inactivityDuration = Liveness.getInactivityDuration();
      if (Liveness.store && Liveness.isAuthenticated) {
        if (inactivityDuration > Liveness.screenLockTimeoutWarning) {
          showInactivityWarning();
        }

        if (inactivityDuration > Liveness.screenLockTimeout) {
          logout();
        }
      }
    }
  },
  update: (shouldHideInactivityWarning) => {
    let { authentication } = Liveness.store.getState();
    if (shouldHideInactivityWarning) {
      if (authentication && authentication.inactivityWarningShown) {
        Liveness.store.dispatch({
          type: "HIDE_INACTIVITY_WARNING",
        });
      }
      Liveness.lastActionTimestamp = Date.now();
    } else {
      if (authentication && !authentication.inactivityWarningShown) {
        Liveness.store.dispatch({
          type: "HIDE_INACTIVITY_WARNING",
        });
        Liveness.lastActionTimestamp = Date.now();
      }
    }
  },
};

setInterval(Liveness.checkTimeout, 5000);

function showInactivityWarning() {
  let { authentication } = Liveness.store.getState();
  if (authentication && authentication.inactivityWarningShown) {
    // do nothing
  } else {
    Liveness.store.dispatch({
      type: "SHOW_INACTIVITY_WARNING",
    });
  }
}

export async function logout() {
  console.log("GS PWA: Logout");
}

export default Liveness;
