import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

import type { StyledSpacingProps } from './Spacing.style.types'

export const Spacing = styled.div<StyledSpacingProps>(
  (props: StyledSpacingProps): FlattenSimpleInterpolation => css`
    ${props.spacingLevel.top ? spacing('margin-top', props.spacingLevel.top) : ''}
    ${props.spacingLevel.right ? spacing('margin-right', props.spacingLevel.right) : ''}
    ${props.spacingLevel.bottom ? spacing('margin-bottom', props.spacingLevel.bottom) : ''}
    ${props.spacingLevel.left ? spacing('margin-left', props.spacingLevel.left) : ''}
  `,
)
