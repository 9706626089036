import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import MatchMedia from '@vfuk/core-match-media'
import { getDataSelector } from '@vfuk/core-base-props'

import { withTranslation } from 'react-i18next'

import * as Styled from './styles/CarouselLoaderPager.style'

import type { CarouselLoaderPagerProps } from './CarouselLoaderPager.types'

import { componentName } from '../../locales'

export class CarouselLoaderPager extends PureComponent<CarouselLoaderPagerProps> {
  /**
   * determines how many times the loading bar will be updated (re-rendered) per second
   * the smooth bar movement is ensured by css transition
   * recommended values are between 1 - 10 (1 = best performance, 10+ = best accuracy)
   */
  private loadingBarFrameRate = 5

  public state = {
    progress: 0,
  }

  private loadingInterval: number

  componentDidMount(): void {
    this.startLoading()
  }

  componentDidUpdate(prevProps: CarouselLoaderPagerProps): void {
    if (!prevProps.active) {
      this.reset()
    }
    if (prevProps.active !== this.props.active && this.props.autoplay) {
      this.startLoading()
    }
    if (this.props.active && !this.props.autoplay) {
      this.setState({ progress: 1 })
    }
  }

  componentWillUnmount(): void {
    this.clearLoadingInterval()
  }

  private clearLoadingInterval(): void {
    if (this.loadingInterval) {
      clearTimeout(this.loadingInterval)
    }
  }

  private reset(): void {
    this.clearLoadingInterval()
    this.setState({ progress: 0 })
  }

  private nextLoadStep(progress: number, frameInterval: number): void {
    if (progress >= 1) {
      this.reset()
      this.nextSlide()
      return
    }
    progress += 1 / (this.props.timeout / frameInterval)
    this.setState({ progress: progress < 1 ? progress : 1 })
  }

  private startLoading(): void {
    if (this.props.active) {
      const frameInterval = 1000 / this.loadingBarFrameRate
      this.loadingInterval = setInterval(() => this.nextLoadStep(this.state.progress, frameInterval), frameInterval)
    }
  }

  private nextSlide(): void {
    const nextIndex = this.props.index + 1
    const nextActive = nextIndex >= this.props.pagerCount ? 0 : nextIndex
    this.props.onChange(nextActive)
  }

  private handleClick(): void {
    this.setState({ progress: 1 })
    this.props.onSlideControlChange(false)
    if (this.props.active) {
      this.clearLoadingInterval()
    } else {
      this.props.onChange(this.props.index)
    }
  }

  public render(): ReactNode {
    return (
      <Styled.CarouselLoaderPager
        active={this.props.active}
        inverse={this.props.inverse}
        onClick={(): void => this.handleClick()}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
      >
        <Styled.Bar
          active={this.props.active}
          progress={this.state.progress}
          transitionTime={1000 / this.loadingBarFrameRate}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'bar')}
        ></Styled.Bar>
        <MatchMedia breakpoint='lg' andAbove>
          <Styled.Title data-selector={getDataSelector(this.props.dataSelectorPrefix, 'title')}>{this.props.title}</Styled.Title>
        </MatchMedia>
        <Styled.SrText data-selector={getDataSelector(this.props.dataSelectorPrefix, 'sr-text')}>
          {this.props.t!('ItemCountOfTotalCount', { index: this.props.index + 1, pagerCount: this.props.pagerCount })}
          {this.props.active && this.props.t!('Currently Active')}
        </Styled.SrText>
      </Styled.CarouselLoaderPager>
    )
  }
}

export default withTranslation(componentName)(CarouselLoaderPager)
