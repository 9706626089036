import type { IconSnippetTheme } from './IconSnippet.theme.types'

const iconSnippetTheme = (): IconSnippetTheme => {
  return {
    appearance: {
      primary: {
        iconAppearance: 'primary',
        iconInverse: false,
        snippetAppearance: 'primary',
        snippetInverse: false,
        inverse: {
          iconAppearance: 'primary',
          iconInverse: true,
          snippetAppearance: 'primary',
          snippetInverse: true,
        },
      },
      secondary: {
        iconAppearance: 'primary',
        iconInverse: false,
        snippetAppearance: 'secondary',
        snippetInverse: false,
        inverse: {
          iconAppearance: 'primary',
          iconInverse: true,
          snippetAppearance: 'secondary',
          snippetInverse: true,
        },
      },
      alt1: {
        iconAppearance: 'primary',
        iconInverse: false,
        snippetAppearance: 'alt1',
        snippetInverse: false,
        inverse: {
          iconAppearance: 'primary',
          iconInverse: true,
          snippetAppearance: 'alt1',
          snippetInverse: true,
        },
      },
    },
  }
}

export default iconSnippetTheme
