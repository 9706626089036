import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import FunctionalCarousel from '@vfuk/core-functional-carousel'
import { getDataSelector } from '@vfuk/core-base-props'

import type { ImageCarouselProps } from './ImageCarousel.types'

export class ImageCarousel extends PureComponent<ImageCarouselProps> {
  private componentName = 'ImageCarousel'

  public static defaultProps: Partial<ImageCarouselProps> = {
    arrows: {
      position: 'overlap',
    },
    pager: {
      position: 'outside',
      inverse: false,
    },
    autoPlay: {
      active: false,
      waitTime: 3000,
      invertControls: true,
    },
  }

  public render(): ReactNode {
    return (
      <FunctionalCarousel
        slidesToShow={{
          xl: 1,
        }}
        id={this.props.id}
        srName={this.props.srName}
        arrows={this.props.arrows}
        pager={this.props.pager}
        infiniteLoop={this.props.infiniteLoop}
        autoPlay={this.props.autoPlay}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
        dataAttributes={{ 'data-component-name': this.componentName, ...this.props.dataAttributes }}
      >
        {this.props.children}
      </FunctionalCarousel>
    )
  }
}

export default ImageCarousel
