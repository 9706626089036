import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

import type { StyledHeaderProps } from './Header.style.types'

export const Header = styled.header<StyledHeaderProps>((props: StyledHeaderProps): FlattenSimpleInterpolation => {
  return css`
    ${spacing('padding', 3)};
    background-color: ${props.modalTheme.appearance[props.appearance].header.backgroundColor};

    ${props.isSticky &&
    css`
      position: sticky;
      top: 0;
    `}
  `
})

type IconContainerProps = Pick<StyledHeaderProps, 'hasLeftIcon'>
export const IconContainer = styled.div<IconContainerProps>(
  (props: IconContainerProps): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: ${props.hasLeftIcon ? 'space-between' : 'right'};
  `,
)
