import type { SrcSetSizes } from '../../Image.types'

function getSourceSetValue(src: string | undefined, size: SrcSetSizes): string {
  if (!src) return ''
  const resolutionMap = {
    x1: '',
    x2: '2x',
    x3: '3x',
  }
  return `${src} ${resolutionMap[size]}`.trim()
}

export default getSourceSetValue
