import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, spacing } from '@vfuk/core-mixins'

import type { StyledStateNotificationProps } from './StateNotification.style.types'

export const StateNotification = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
  `,
)

export const IconWrapper = styled.div<Pick<StyledStateNotificationProps, 'state' | 'iconBackground'>>(
  (props: StyledStateNotificationProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    ${spacing('padding', 4)};
    background: ${props.iconBackground[props.state].background};
  `,
)

export const ContentWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    ${spacing('gap', 2)}
    ${spacing('padding', 4)}
  `,
)

export const HeadingWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-bottom', 2)}
  `,
)

export const CloseButton = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    ${advancedSpacing('padding', [4, 4, 0, 0])};
    align-self: flex-start;
  `,
)

export const ButtonWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-top', 4)}
  `,
)
