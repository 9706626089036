import type { FC } from 'react'
import { useRef, useEffect, memo } from 'react'
import { v4 as uuid } from 'uuid'

import { overlayController } from '@vfuk/core-overlay-controller'

import type { BottomTrayProps } from './BottomTray.types'

const BottomTray: FC<BottomTrayProps> = ({
  children,
  rootElement,
  isOpen = false,
  srName,
  isClosable = true,
  onCloseCb = null,
  animate = true,
  initialFocusId = null,
  focusEnabledRefs,
  id,
  enableOverlay = false,
  overlayBlur = false,
  dataSelectorPrefix,
  dataAttributes,
}: BottomTrayProps) => {
  const componentName = 'BottomTray'

  const bottomTrayID = useRef(id || uuid())

  useEffect(() => {
    if (isOpen) {
      overlayController.setConfig(bottomTrayID.current, {
        type: 'bottomTray',
        isShowing: true,
        isClosable,
        onCloseCb,
        initialFocusId,
        focusEnabledRefs,
        content: children,
        animate,
        srName,
        rootElement,
        renderOptions: {
          enableOverlay,
          overlayBlur,
        },
        id: bottomTrayID.current,
        dataSelectorPrefix,
        dataAttributes,
        componentName,
      })
    } else {
      overlayController.hide(bottomTrayID.current)
    }
  }, [
    isOpen,
    isClosable,
    onCloseCb,
    initialFocusId,
    focusEnabledRefs,
    children,
    animate,
    enableOverlay,
    id,
    dataSelectorPrefix,
    dataAttributes,
  ])

  // Destructor to be run when the component unmounts
  useEffect(() => {
    return (): void => {
      if (isOpen) {
        overlayController.hide(bottomTrayID.current)
      }
    }
  }, [])

  return null
}

export default memo(BottomTray)
