/* eslint-disable max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, applyFocus, spacing, typography } from '@vfuk/core-mixins'

import type { StyledTabTitleProps } from './TabTitle.style.types'

type TabContainerProps = Pick<StyledTabTitleProps, 'tabWidth' | 'overflowActive'>
export const TabContainer = styled.div<TabContainerProps>((props: TabContainerProps): FlattenSimpleInterpolation => {
  return css`
    display: flex;
    position: relative;
    align-items: stretch;
    justify-content: center;
    flex-shrink: 0;

    > button:first-child {
      ${applyFocus()}
    }

    ${applyFocus()}

    ${!props.overflowActive &&
    `
    ${
      props.tabWidth === 'stretch' &&
      `
      flex-grow: 1;
    `
    }
  `}
  `
})

type TabTitleProps = Pick<
  StyledTabTitleProps,
  'tabsTheme' | 'theme' | 'appearance' | 'inverse' | 'isActiveTab' | 'tabWidth' | 'overflowActive'
>
export const TabTitle = styled.div<TabTitleProps>((props: TabTitleProps): FlattenSimpleInterpolation => {
  const {
    textColor,
    titleBottomBorder,
    titleBottomBorderRadius,
    hoverFontWeight,
    activeFontWeight,
    activeUnderlineColor,
    activeTextColor,
    textFontWeight,
  } = props.inverse ? props.tabsTheme.appearance[props.appearance].inverse : props.tabsTheme.appearance[props.appearance]

  return css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    color: ${textColor};
    ${advancedSpacing('padding', [3, 3, 2])};
    border-bottom: ${titleBottomBorder};
    white-space: nowrap;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -5px;
      transform: translateY(-5px);
      left: 0;
      right: 0;
      height: 4px;
      border-top-left-radius: ${titleBottomBorderRadius};
      border-top-right-radius: ${titleBottomBorderRadius};
    }

    ${typography.paragraph(2, false)}

    ${props.tabWidth &&
    `
      ${advancedSpacing('padding', [3, 3, 2])};
    `}

    &:hover {
      font-weight: ${hoverFontWeight};
    }

    ${!props.overflowActive &&
    `
      justify-content: center;
      flex-flow: column;
      &::after {
        content: attr(data-text) / "";
        margin-top: -9px;
        height: 0%;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: ${textFontWeight};

        @media speech {
          display: none;
        }
      }
    `}

    ${props.isActiveTab &&
    `
      color: ${activeTextColor};
      font-weight: ${activeFontWeight};

      &::before {
        background: ${activeUnderlineColor};
      }
    `}
  `
})

type IconContainerProps = Pick<StyledTabTitleProps, 'isOverflowMenuOpen' | 'theme'>
export const IconContainer = styled.div<IconContainerProps>(
  (props: IconContainerProps): FlattenSimpleInterpolation => css`
    ${spacing('margin-left', 2)};
    transition: 0.2s ease-out;

    ${props.isOverflowMenuOpen &&
    `
      transform: rotate(180deg);
    `}
  `,
)
