import type { ReactElement, FC } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import FunctionalAvatar from '@vfuk/core-functional-avatar'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/InitialsAvatar.theme'
import * as Styled from './styles/InitialsAvatar.style'

import type { InitialsAvatarProps } from './InitialsAvatar.types'

const InitialsAvatar: FC<InitialsAvatarProps> = ({
  appearance = 'primary',
  initials,
  href,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  size = 2,
  srText,
  id,
  dataSelectorPrefix,
  dataAttributes,
  customRouterProps,
  localTheme,
}: InitialsAvatarProps): ReactElement => {
  const componentName = 'InitialsAvatar'

  const initialsAvatarTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  const { backgroundColor } = initialsAvatarTheme.appearance[appearance]

  return (
    <FunctionalAvatar
      backgroundColor={backgroundColor}
      size={size}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      customRouterProps={customRouterProps}
      href={href}
      id={id}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      dataAttributes={dataAttributes}
      componentName={componentName}
      srText={srText}
    >
      <Styled.Initials
        initialsAvatarTheme={initialsAvatarTheme}
        appearance={appearance}
        size={size}
        data-selector={getDataSelector(dataSelectorPrefix, 'initials')}
      >
        {initials}
      </Styled.Initials>
    </FunctionalAvatar>
  )
}

export default InitialsAvatar
