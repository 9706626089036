import type { FC, ReactElement } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import Container from '@vfuk/core-container'
import DateCountdown from '@vfuk/core-date-countdown'
import Image from '@vfuk/core-image'
import Icon from '@vfuk/core-icon'
import Heading from '@vfuk/core-heading'
import Paragraph from '@vfuk/core-paragraph'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Advert.style'
import defaultTheme from './themes/Advert.theme'

import type { AdvertProps } from './Advert.types'

const Advert: FC<AdvertProps> = ({
  appearance = 'primary',
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  customRouterProps,
  href,
  headingText,
  text,
  countdownTimer,
  image,
  localTheme,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: AdvertProps): ReactElement => {
  const componentName = 'Advert'
  const advertTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  const { containerAppearance, inverseText, iconAppearance, inverseIcon, inverseCountdownTimer } = advertTheme.appearance[appearance]

  return (
    <Styled.Advert
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      customRouterProps={customRouterProps}
      href={href}
      label={headingText}
      fullSize
      id={id}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName}
      dataAttributes={dataAttributes}
    >
      <Container
        appearance={containerAppearance}
        paddingLevel={0}
        elevation={true}
        allowOverflow={false}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'container')}
      >
        <Styled.AdvertContent>
          <Styled.AdvertBody>
            <Heading weight={3} size={2} inverse={inverseText} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'heading')}>
              {headingText}
              {(href || customRouterProps || onClick) && (
                <Icon
                  name='chevron-right'
                  group='system'
                  size={3}
                  appearance={iconAppearance}
                  inverse={inverseIcon}
                  dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'chevron-icon')}
                />
              )}
            </Heading>
            {text && (
              <Paragraph inverse={inverseText} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'text')}>
                {text}
              </Paragraph>
            )}
            {countdownTimer && (
              <DateCountdown
                {...countdownTimer}
                size={1}
                inverse={inverseCountdownTimer}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'date-countdown')}
              />
            )}
          </Styled.AdvertBody>
          <Styled.AdvertImage>
            <Image {...image} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'image')} />
          </Styled.AdvertImage>
        </Styled.AdvertContent>
      </Container>
    </Styled.Advert>
  )
}

export default Advert
