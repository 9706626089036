import React, { useMemo, isValidElement, cloneElement, useState, useCallback } from 'react'

import FunctionalCarousel from '@vfuk/core-functional-carousel'
import SimpleGrid from '@vfuk/core-simple-grid'
import Button from '@vfuk/core-button'
import { getDataSelector } from '@vfuk/core-base-props'
import { useBreakpointSwitch } from '@vfuk/core-helpers'
import { useTranslation } from 'react-i18next'

import { SameHeightGrid } from './components'
import { carouselSlides, carouselSlidesToScroll, columnsLayout } from './constants'
import * as Styled from './styles/CardGrid.style'

import type { FC } from 'react'
import type { CardGridProps } from './CardGrid.types'
import type { InteractionEvent } from '@vfuk/core-interaction'

const CardGrid: FC<CardGridProps> = ({
  cards,
  columns: propColumns,
  cardsOnLoad,
  showMoreCards,
  onPaginatedButtonClick,
  customRouterProps,
  showPaginationButton,
  inverse,
  carousel,
  renderSameHeight,
  dataSelectorPrefix,
}) => {
  const [displayedCards, setDisplayedCards] = useState(cardsOnLoad ?? 0)

  const layout = typeof propColumns === 'object' ? propColumns : columnsLayout

  const { t } = useTranslation()
  const responsiveColumns = useBreakpointSwitch(layout)

  const columns = typeof propColumns === 'number' ? propColumns : responsiveColumns ?? 3
  const rows = Math.ceil(cards.length / columns)

  const isPaginated = !!cardsOnLoad
  const shouldShowPaginationButton = isPaginated && (showPaginationButton || displayedCards < cards.length)

  const parsedCards: CardGridProps['cards'] = useMemo(() => {
    const anyLabelPerRow = Array.from({ length: rows }, (_, i) =>
      cards.slice(i * columns, i * columns + columns).some((card) => !!card?.props?.container?.label),
    )

    const slicedCards = isPaginated ? cards.slice(0, displayedCards) : cards

    return slicedCards.map((card, i) => {
      const rowHaslabel = anyLabelPerRow[Math.floor(i / columns)]
      const showOuterTopPadding = !carousel && rowHaslabel && !card?.props?.container?.label

      return isValidElement(card)
        ? cloneElement(card, {
            dataSelectorPrefix: getDataSelector(dataSelectorPrefix, `card-${i}`),
            container: { ...card?.props?.container, showOuterTopPadding },
            showOuterTopPadding,
          })
        : card
    })
  }, [cards, carousel, dataSelectorPrefix, isPaginated, columns, rows, displayedCards])

  const handleShowMore = useCallback(
    async (event: InteractionEvent) => {
      if (onPaginatedButtonClick) {
        await onPaginatedButtonClick(event)
      }

      setDisplayedCards((prev) => {
        const newDisplayedCards = prev + (showMoreCards ?? columns)

        return newDisplayedCards > cards.length ? cards.length : newDisplayedCards
      })
    },
    [onPaginatedButtonClick, showMoreCards, columns],
  )

  return (
    <Styled.CardGrid data-selector={getDataSelector(dataSelectorPrefix)}>
      {carousel || renderSameHeight ? (
        <>
          {carousel ? (
            <FunctionalCarousel
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'functional-carousel')}
              slidesToScroll={carouselSlidesToScroll}
              slidesToShow={carouselSlides}
              infiniteLoop
              {...carousel}
            >
              {parsedCards}
            </FunctionalCarousel>
          ) : (
            <SameHeightGrid
              cards={parsedCards}
              columns={columns}
              rows={rows}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'same-height-grid')}
            />
          )}
        </>
      ) : (
        <SimpleGrid columns={layout} spacing={4} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'simple-grid')}>
          {parsedCards}
        </SimpleGrid>
      )}
      {shouldShowPaginationButton && (
        <Styled.ButtonContainer>
          <Button
            text={t('Show More')}
            inverse={inverse}
            onClick={handleShowMore}
            customRouterProps={customRouterProps}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'button')}
          />
        </Styled.ButtonContainer>
      )}
    </Styled.CardGrid>
  )
}

export default CardGrid
