import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, spacing, srOnly, typography } from '@vfuk/core-mixins'

import type { StyledDateTextInputProps } from './DateTextInput.style.types'

export const DateTextInputWrapper = styled.fieldset(
  (): FlattenSimpleInterpolation => css`
    position: relative;
  `,
)

export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

export const DateTextInput = styled.div(
  (props: StyledDateTextInputProps): FlattenSimpleInterpolation => css`
    color: ${props.theme.formFields.color};
    transition: 0.4s box-shadow ease;
    border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
    border-radius: ${props.theme.formFields.border.radius};
    background-color: ${props.theme.formFields.backgroundColor};
    ${advancedSpacing('padding', [1, 3])};
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    ${typography.paragraph(2, false)}

    ${props.state &&
    props.state !== 'disabled' &&
    css`
      border: ${props.theme.formFields.state[props.state].border.width} ${props.theme.formFields.border.style}
        ${props.theme.formFields.state[props.state].border.color};
      ${spacing('padding-right', 7)};
    `}

  ${props.state === 'disabled' &&
    css`
      background-color: ${props.theme.formFields.state.disabled.backgroundColor};
      color: ${props.theme.formFields.state.disabled.color};
      cursor: not-allowed;
    `}
  `,
)
