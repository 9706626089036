import type { FC, ReactElement } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/LoadingSpinner.theme'
import * as Styled from './styles/LoadingSpinner.style'

import type { LoadingSpinnerProps } from './LoadingSpinner.types'

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  appearance = 'primary',
  size = 1,
  inverse = false,
  localTheme,
  id,
  dataAttributes,
  dataSelectorPrefix,
}): ReactElement => {
  const componentName = 'LoadingSpinner'
  const loadingSpinnerTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  const { svg } = loadingSpinnerTheme
  return (
    <Styled.LoadingSpinner
      loadingSpinnerTheme={loadingSpinnerTheme}
      size={size!}
      appearance={appearance!}
      inverse={inverse!}
      id={id}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
    >
      {svg}
    </Styled.LoadingSpinner>
  )
}

export default LoadingSpinner
