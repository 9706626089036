/* eslint-disable complexity */
/* eslint-disable sonarjs/cognitive-complexity */
import type { ReactNode } from 'react'
import React, { PureComponent, cloneElement } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import InteractiveIcon from '@vfuk/core-interactive-icon'

import { withTranslation } from 'react-i18next'

import * as Styled from './styles/FieldWrapper.style'

import type { FieldWrapperProps, ChildrenAdditionalProps, FieldWrapperState } from './FieldWrapper.types'

import HelpText from './components/HelpText'
import StateText from './components/StateText'

import { SUB_TEXT } from './constants'

import { getAriaDescribedByIds } from './utils/getDescribedByIds'

export class FieldWrapper extends PureComponent<FieldWrapperProps> {
  public componentName = 'FieldWrapper'

  public static defaultProps: Partial<FieldWrapperProps> = {
    showLabel: true,
    width: 'default',
    renderAsLabel: true,
  }

  public state: FieldWrapperState = {
    isMasked: true,
  }

  private showStateText(): boolean {
    if (this.props.stateText && this.props.state !== 'disabled') {
      return !!this.props.state
    }
    return false
  }

  private handleInputVisibilityClick = (): void => {
    this.setState({
      isMasked: !this.state.isMasked,
    })
  }

  private get childrenAdditionalProps(): ChildrenAdditionalProps {
    const additionalProps: ChildrenAdditionalProps = {}

    if (this.props.required) {
      additionalProps.required = true
    }

    if (this.props.children?.props.type === 'password' && !this.state.isMasked) {
      additionalProps.type = 'text'
    }

    const ariaDescribedByIds = getAriaDescribedByIds(
      this.props.formElementId,
      this.props.helpText,
      this.props.stateText,
      this.props.subText,
    )

    if (ariaDescribedByIds.length) additionalProps.describedBy = ariaDescribedByIds

    return additionalProps
  }

  public render(): ReactNode {
    /**
     * If the children has a root type of 'password'
     */
    const isTypePassword = this.props.children?.props.type === 'password'

    return (
      <Styled.FieldWrapper
        width={this.props.width}
        id={this.props.id}
        {...this.props.dataAttributes}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.props.componentName || this.componentName}
      >
        <Styled.TopLabelWrapper showLabel={this.props.showLabel}>
          <Styled.FlexIndicatorContainer showLabel={this.props.showLabel}>
            <Styled.Label
              as={this.props.renderAsLabel ? ('label' as React.ElementType) : ('div' as React.ElementType)}
              htmlFor={this.props.renderAsLabel ? this.props.formElementId : undefined}
              isTypePassword={isTypePassword}
              state={this.props.state!}
              showLabel={this.props.showLabel}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, 'label')}
            >
              {this.props.label}
            </Styled.Label>
            {this.props.showLabel && (
              <>
                {(this.props.optional || this.props.required) && (
                  <Styled.OptionalOrRequiredLabel
                    aria-hidden
                    data-selector={getDataSelector(this.props.dataSelectorPrefix, 'optional-or-required-label')}
                  >
                    {this.props.optional ? this.props.t!('Optional') : this.props.t!('Required')}
                  </Styled.OptionalOrRequiredLabel>
                )}
                {isTypePassword && (
                  <InteractiveIcon
                    srText={`${this.state.isMasked ? this.props.t!('Show') : this.props.t!('Hide')} ${this.props.label.toLowerCase()}`}
                    name={this.state.isMasked ? 'show-password' : 'hide-password'}
                    size={2}
                    isResponsive={false}
                    onClick={this.handleInputVisibilityClick}
                    dataSelectorPrefix={getDataSelector(
                      this.props.dataSelectorPrefix,
                      `${this.state.isMasked ? 'show-password' : 'hide-password'}`,
                    )}
                  />
                )}
              </>
            )}
          </Styled.FlexIndicatorContainer>
          {this.props.subText && (
            <Styled.SubText
              id={`${this.props.formElementId}-${SUB_TEXT}`}
              state={this.props.state}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, 'sub-text')}
            >
              {this.props.subText}
            </Styled.SubText>
          )}
        </Styled.TopLabelWrapper>
        <Styled.InputWrapper>{cloneElement(this.props.children, this.childrenAdditionalProps)}</Styled.InputWrapper>
        {this.showStateText() && (
          <StateText
            state={this.props.state!}
            stateText={this.props.stateText!}
            formElementId={this.props.formElementId}
            validateOn={this.props.validateOn}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'state-text')}
          />
        )}
        {(this.props.helpText || this.props.helpLink) && (
          <HelpText
            state={this.props.state}
            formElementId={this.props.formElementId}
            helpText={this.props.helpText}
            helpLink={this.props.helpLink}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'help-text')}
          />
        )}
      </Styled.FieldWrapper>
    )
  }
}

export default withTranslation()(FieldWrapper)
