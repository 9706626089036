import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import { withTheme } from 'styled-components'

import { getDataSelector } from '@vfuk/core-base-props'

import Icon from '@vfuk/core-icon'

import * as Styled from './styles/SelectInput.style'

import type { SelectInputProps, OnChangeEvent, OnBlurEvent } from './SelectInput.types'

export class SelectInput extends PureComponent<SelectInputProps> {
  public static defaultProps: Partial<SelectInputProps> = {
    required: false,
  }

  private handleOnChange = (event: OnChangeEvent): void => {
    if (this.props.onChange && this.props.state !== 'disabled') this.props.onChange(event)
  }

  private handleOnBlur = (event: OnBlurEvent): void => {
    if (this.props.onBlur && this.props.state !== 'disabled') this.props.onBlur(event)
  }

  public componentName = 'SelectInput'

  public render(): ReactNode {
    return (
      <Styled.SelectInputWrapper
        id={`${this.props.id}-wrapper`}
        {...this.props.dataAttributes}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
      >
        <Styled.SelectInput
          state={this.props.state}
          aria-disabled={this.props.state === 'disabled'}
          id={this.props.id}
          name={this.props.name ? this.props.name : this.props.id}
          required={this.props.required}
          value={this.props.value}
          ref={this.props.domRef}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          aria-describedby={this.props.describedBy}
          {...this.props.dataAttributes}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'select-input')}
        >
          {this.props.placeholder && (
            <Styled.Option selected={!this.props.value} value={this.props.placeholder} disabled>
              {this.props.placeholder}
            </Styled.Option>
          )}
          {this.props.options.map((option) => {
            const optionText = option.disabled && option.helpText ? `${option.text} ${option.helpText}` : option.text

            return (
              <Styled.Option
                key={option.value || option.text}
                value={option.value || option.text}
                disabled={this.props.state === 'disabled' || option.disabled}
              >
                {optionText}
              </Styled.Option>
            )
          })}
        </Styled.SelectInput>
        <Styled.IconContainer state={this.props.state}>
          <Icon
            name='chevron-down'
            appearance={this.props.theme!.formFields.icon.appearance}
            inverse={this.props.theme!.formFields.icon.inverse}
            size={1}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'icon')}
          />
        </Styled.IconContainer>
      </Styled.SelectInputWrapper>
    )
  }
}

export default withTheme(SelectInput)
