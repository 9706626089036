import type { FC } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import FieldWrapper from '@vfuk/core-field-wrapper'
import { useLocalTheme } from '@vfuk/core-themes'

import RangeSliderFieldSet from './components/RangeSliderFieldSet'

import type { RangeSliderProps } from './RangeSlider.types'
import defaultTheme from './themes/RangeSlider.theme'

const RangeSlider: FC<RangeSliderProps> = (props) => {
  const componentName = 'RangeSlider'

  const localTheme = useLocalTheme(componentName, defaultTheme, props.localTheme)

  return (
    <FieldWrapper
      label={props.srLabel.mainLabel}
      formElementId={`${props.id}-fieldset`}
      state={props.state}
      stateText={props.stateText}
      showLabel={false}
      id={props.id}
      dataAttributes={props.dataAttributes}
      dataSelectorPrefix={getDataSelector(props.dataSelectorPrefix)}
      componentName={componentName}
    >
      <RangeSliderFieldSet {...props} localTheme={localTheme} dataSelectorPrefix={getDataSelector(props.dataSelectorPrefix, 'field-set')} />
    </FieldWrapper>
  )
}

export default RangeSlider
