import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { applyFocus, responsiveCalc, srOnly, typography } from '@vfuk/core-mixins'

import type { StyledRadioProps } from './Radio.style.types'

export const RadioWrapper = styled.div(() => {
  return css`
    min-height: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    ${responsiveCalc('padding-left', '1.25rem', '+', 2)};
  `
})

export const Radio = styled.input(
  () => css`
    font-size: 1rem;
    height: 2rem;
    left: 0;
    opacity: 0;
    margin: 0;
    position: relative;
    top: 0;
    width: 0;

    & + label:before {
      ${applyFocus()}
    }
  `,
)

export const HiddenLabel = styled.span(
  () => css`
    ${srOnly()}
  `,
)

type LabelTextProps = Pick<StyledRadioProps, 'theme' | 'checked' | 'disabled'>
const labelLineHeight = 2
export const LabelText = styled.label<LabelTextProps>((props) => {
  return css`
    ${typography.paragraph(2, false)}
    line-height: ${labelLineHeight}rem;

    &:before {
      content: '';
      position: absolute;
      top: ${labelLineHeight / 2}rem;
      left: 0;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      background: ${props.theme.formFields.backgroundColor};
      border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &:after {
      content: '';
      position: absolute;
      top: ${labelLineHeight / 2}rem;
      left: 4px;
      height: 12px;
      width: 12px;
      opacity: 0;
      background-color: ${props.disabled
        ? props.theme.formFields.state.disabled.backgroundColor
        : props.theme.formFields.selected.backgroundColor};
      border-radius: 50%;
      transform: translateY(-50%);
      transition: opacity 0.1s ease-in-out;
    }

    ${props.checked &&
    css`
      &:before {
        border-color: ${props.theme.formFields.selected.border.color};
      }

      &:after {
        opacity: 1;
      }
    `}

    ${props.disabled &&
    css`
      color: ${props.theme.formFields.state.disabled.color};
      cursor: not-allowed;

      &:before {
        background-color: ${props.theme.formFields.state.disabled.backgroundColor};
        border-color: ${props.theme.formFields.state.disabled.border.color};
      }
    `}
  `
})

export const FullWidthWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
  `,
)
