import setHeight from '../setHeight'

function animateToggle(willOpen: boolean, id: string, duration: number, onEndAnimate: () => void): void {
  const target = document.getElementById(id)
  if (target && window.requestAnimationFrame) {
    const initHeight = willOpen ? 0 : target.offsetHeight
    const finalHeight = willOpen ? target.offsetHeight : 0
    setHeight(initHeight, finalHeight, target, duration, onEndAnimate)
  }
}

export default animateToggle
