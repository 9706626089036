import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, applyFocus, opacity, typography } from '@vfuk/core-mixins'

import type { StyledRangeSliderProps } from './RangeSliderFieldSet.style.types'

export const RangeSlider = styled.div<StyledRangeSliderProps>((props: StyledRangeSliderProps): FlattenSimpleInterpolation => {
  const { backgroundColor, sliderTooltipColor, sliderLabelsColor, sliderRailColor } = props.rangeSliderTheme.appearance[props.appearance!]

  return css`
    display: flex;
    align-items: center;
    justify-content: center;

    .rc-slider {
      display: flex;
      align-items: center;
      justify-content: center;

      ${props.state === 'disabled' &&
      css`
        ${opacity(true)};
      `}
    }

    .rc-slider-handle {
      background-color: ${backgroundColor.default};
      border: none;
      width: 20px;
      height: 20px;
      top: 0;
      z-index: 1;
      box-shadow: none;

      &:active {
        background-color: ${backgroundColor.pressed};
      }

      &:hover {
        background-color: ${backgroundColor.hover};
      }

      ${applyFocus()}
    }

    .rc-slider-rail {
      top: 3px;
      border: 2px solid ${sliderRailColor};
    }

    .rc-slider-track {
      height: 6px;
      top: 1px;
      background-color: ${backgroundColor.default};
    }

    .rc-slider-dot {
      bottom: 3px;
      height: 2px;
      width: 2px;
      border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};

      &:first-child {
        margin-left: 2px;
      }
    }

    .rc-slider-dot-active {
      bottom: 4px;
      border-color: ${props.theme.formFields.backgroundColor};
      z-index: 0;
    }

    .rc-slider-tooltip {
      z-index: 2;
      top: -50px !important;
    }

    .rc-slider-tooltip-placement-top {
      padding: 0;
    }

    .rc-slider-tooltip-inner {
      height: inherit;
      background-color: ${sliderTooltipColor};
      border-radius: ${props.theme.formFields.border.radius};
      ${advancedSpacing('padding', [2, 3])}
      ${typography.paragraph(1, false)}
      white-space: nowrap;
    }

    .rc-slider-tooltip-arrow {
      border-top-color: ${sliderTooltipColor};
      margin-left: -8px;
      border-width: 8px 8px 0;
      top: 34px;
    }

    .rc-slider-mark-text {
      ${typography.paragraph(1, false)}
      color: ${sliderLabelsColor};
      margin-left: -3px;
    }

    ${!!props.rangeLabels &&
    css`
      margin-bottom: 2rem;
    `}
  `
})

export const LeftIconWrapper = styled.span(
  (): FlattenSimpleInterpolation => css`
    margin-right: 26px;
  `,
)

export const RightIconWrapper = styled.span(
  (): FlattenSimpleInterpolation => css`
    margin-left: 20px;
  `,
)
