import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import { createMixin } from '@vfuk/core-mixins'

import type { Animation } from './animations.types'

const slideToLeft: Animation = {
  animation: createMixin(
    (): FlattenSimpleInterpolation => css`
      @keyframes slideToLeft {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    `,
  ) as Animation['animation'],
  config: {
    duration: 150,
  },
}

export default slideToLeft
