import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { IconStyles } from '@vfuk/core-icon'
import { respondTo } from '@vfuk/core-mixins'

import type { StyledColorSwatchProps } from './ColorSwatch.style.types'

export const ColorSwatch = styled.span((props: StyledColorSwatchProps): FlattenSimpleInterpolation => {
  return css`
    display: inline-block;
    position: relative;
    background: ${props.color};
    border-radius: 50%;
    width: ${props.colorSwatchTheme.size[props.size].swatch.sm}px;
    height: ${props.colorSwatchTheme.size[props.size].swatch.sm}px;

    ${props.lowContrast &&
    css`
      border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.color.monochrome4.default};

      ${props.inverse &&
      css`
        border: ${props.theme.border.width[1]} ${props.theme.border.style[1]} ${props.theme.inverseColor.monochrome4.default};
      `}
    `}

    ${respondTo.md(css`
      width: ${props.colorSwatchTheme.size[props.size].swatch.md}px;
      height: ${props.colorSwatchTheme.size[props.size].swatch.md}px;
    `)}

    ${respondTo.lg(css`
      width: ${props.colorSwatchTheme.size[props.size].swatch.lg}px;
      height: ${props.colorSwatchTheme.size[props.size].swatch.lg}px;
    `)}

    ${respondTo.xl(css`
      width: ${props.colorSwatchTheme.size[props.size].swatch.xl}px;
      height: ${props.colorSwatchTheme.size[props.size].swatch.xl}px;
    `)}

    ${IconStyles.Icon} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `
})
