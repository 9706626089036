import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import GroupedTextInput from '@vfuk/core-grouped-text-input'
import FieldWrapper from '@vfuk/core-field-wrapper'

import type { GroupedTextInputWithLabelProps } from './GroupedTextInputWithLabel.types'

class GroupedTextInputWithLabel extends PureComponent<GroupedTextInputWithLabelProps> {
  public componentName = 'GroupedTextInputWithLabel'

  public render(): ReactNode {
    return (
      <FieldWrapper
        state={this.props.state}
        label={this.props.fieldWrapper.label}
        helpText={this.props.fieldWrapper.helpText}
        subText={this.props.fieldWrapper.subText}
        stateText={this.props.fieldWrapper.stateText}
        width={this.props.fieldWrapper.width}
        helpLink={this.props.fieldWrapper.helpLink}
        optional={this.props.fieldWrapper.optional}
        required={this.props.fieldWrapper.required}
        renderAsLabel={false}
        formElementId={this.props.groupedTextInput.id}
        id={this.props.id}
        dataAttributes={this.props.dataAttributes}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
        componentName={this.componentName}
      >
        <GroupedTextInput
          type={this.props.groupedTextInput.type}
          state={this.props.state}
          values={this.props.groupedTextInput.values}
          id={this.props.groupedTextInput.id}
          showStateOn={this.props.groupedTextInput.showStateOn}
          placeholder={this.props.groupedTextInput.placeholder}
          onBlur={this.props.groupedTextInput.onBlur}
          onChange={this.props.groupedTextInput.onChange}
          srLabels={this.props.groupedTextInput.srLabels}
          labels={this.props.groupedTextInput.labels}
          name={this.props.fieldWrapper.label}
          required={this.props.groupedTextInput.required}
          textInputs={{
            maxLength: this.props.groupedTextInput.textInputs?.maxLength,
            dataAttributes: this.props.groupedTextInput.textInputs?.dataAttributes,
            autoComplete: this.props.groupedTextInput.textInputs?.autoComplete,
            onKeyDown: this.props.groupedTextInput.textInputs?.onKeyDown,
            beforeChange: this.props.groupedTextInput.textInputs?.beforeChange,
            domRefs: this.props.groupedTextInput.textInputs?.domRefs,
            autoCapitalize: this.props.groupedTextInput.textInputs?.autoCapitalize,
          }}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'grouped-text-input')}
        />
      </FieldWrapper>
    )
  }
}

export default GroupedTextInputWithLabel
