import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import SimpleGrid from '@vfuk/core-simple-grid'
import Button from '@vfuk/core-button'

import { getDataSelector } from '@vfuk/core-base-props'

import { withTranslation } from 'react-i18next'

import { layoutMap } from './helpers/layoutMap'

import * as Styled from './styles/CardGrid.style'

import type { CardGridProps, CardGridState } from './CardGrid.types'

export class CardGrid extends PureComponent<CardGridProps, CardGridState> {
  private componentName = 'CardGrid'

  public static defaultProps: Partial<CardGridProps> = {
    inverse: false,
    isLoading: false,
  }

  constructor(props: CardGridProps) {
    super(props)
    this.state = {
      cardsToDisplay: this.props.cardsOnLoad,
      displayShowMore: this.showPaginationButton(this.props.cardsOnLoad),
    }
  }

  showPaginationButton = (cardsToDisplay: number): boolean => {
    return (
      this.props.showPaginatedButton || (typeof this.props.showPaginatedButton === 'undefined' && cardsToDisplay < this.props.items.length)
    )
  }

  getCards(): ReactNode[] {
    return this.props.items.slice(0, this.state.cardsToDisplay)
  }

  showMore = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    if (this.props.onPaginatedButtonClick) {
      await this.props.onPaginatedButtonClick(event)
    }

    this.setState((state, props) => {
      const displayShowMore = this.showPaginationButton(state.cardsToDisplay + this.props.showMoreCards!)
      return {
        cardsToDisplay: state.cardsToDisplay + props.showMoreCards,
        displayShowMore,
      }
    })
  }

  public render(): ReactNode {
    return (
      <Styled.CardGridWrapper
        id={this.props.id}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
        {...this.props.dataAttributes}
      >
        <SimpleGrid columns={layoutMap[this.props.gridLayoutSize]} spacing={4} verticalAlign='stretch'>
          {this.getCards()}
        </SimpleGrid>
        {this.state.displayShowMore && (
          <Styled.ShowMoreButton>
            <Button
              loading={this.props.isLoading}
              text={this.props.t!('Show more')}
              onClick={this.showMore}
              appearance='alt1'
              inverse={this.props.inverse}
              customRouterProps={this.props.customRouterProps}
              dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'button')}
            />
          </Styled.ShowMoreButton>
        )}
      </Styled.CardGridWrapper>
    )
  }
}

export default withTranslation()(CardGrid)
