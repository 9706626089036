import React, { FC } from 'react'

import { IconNetflixNProps } from './IconNetflixN.types'
import * as Styled from './styles/IconNetflixN.style'

import { nonThemeableRules } from '../../ruleSet'

const IconNetflixN: FC<IconNetflixNProps> = ({
  size = nonThemeableRules.defaultSize as number as IconNetflixNProps['size'],
  isResponsive = false,
}: IconNetflixNProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0 38.4c0-13.441 0-20.162 2.616-25.296A24 24 0 0 1 13.104 2.616C18.238 0 24.96 0 38.4 0h43.2c13.441 0 20.162 0 25.296 2.616a23.999 23.999 0 0 1 10.488 10.488C120 18.238 120 24.96 120 38.4v43.2c0 13.441 0 20.162-2.616 25.296a23.998 23.998 0 0 1-10.488 10.488C101.762 120 95.041 120 81.6 120H38.4c-13.441 0-20.162 0-25.296-2.616a23.999 23.999 0 0 1-10.488-10.488C0 101.762 0 95.041 0 81.6V38.4Z'
          fill='#000'
        />
        <path
          d='M67.134 18.056 67.1 36.84l-.034 18.784-1.545-4.36-.003-.009-1.997 41.82c1.964 5.545 3.015 8.505 3.027 8.517.016.015 1.135.083 2.487.15 4.097.203 9.174.639 13.025 1.117.892.111 1.659.168 1.705.126.046-.041.072-19.167.056-42.503l-.028-42.427h-16.66ZM37 18v42.47c0 23.36.023 42.494.05 42.522.029.028 1.475-.108 3.216-.303 1.74-.195 4.145-.437 5.344-.539 1.839-.155 7.33-.508 7.966-.512.185-.001.197-.955.224-17.989l.028-17.987 1.329 3.759.47 1.328 1.996-41.81-.686-1.938-3.076-8.694-.11-.307H37Z'
          fill='#B1060F'
          fillRule='evenodd'
          stroke='#000'
          strokeWidth='.082'
        />
        <path
          d='M37 18v23.84l16.794 44.549.006-2.74.028-17.987 1.329 3.759c7.396 20.928 11.37 32.148 11.392 32.172.016.015 1.135.083 2.487.15 4.097.203 9.174.639 13.025 1.117.892.111 1.66.168 1.705.126.032-.028.053-9.406.058-22.636L67.11 32.293l-.008 4.548-.033 18.784-1.546-4.36c-1.51-4.261-2.518-7.11-8.585-24.264l-3.076-8.694-.11-.307H37Z'
          fill='url(#985cddd1-ad6c-4469-b627-188ae5804186__a)'
          fillRule='evenodd'
        />
        <path
          d='m37 18 16.828 47.683v-.021l1.329 3.759c7.396 20.928 11.369 32.148 11.392 32.172.016.015 1.135.083 2.487.15 4.097.203 9.174.639 13.025 1.117.887.111 1.65.167 1.704.127l-16.698-47.37v.008l-1.545-4.36c-1.51-4.261-2.518-7.11-8.585-24.264a16778.04 16778.04 0 0 0-3.076-8.694L53.75 18H37Z'
          fill='#E50914'
          fillRule='evenodd'
        />
        <path
          d='M67.134 18.056 67.1 36.84l-.034 18.784-1.545-4.36-.003-.009-1.997 41.82c1.964 5.545 3.015 8.505 3.027 8.517.016.015 1.135.083 2.487.15 4.097.203 9.174.639 13.025 1.117.892.111 1.659.168 1.705.126.046-.041.072-19.167.056-42.503l-.028-42.427h-16.66ZM37 18v42.47c0 23.36.023 42.494.05 42.522.029.028 1.475-.108 3.216-.303 1.74-.195 4.145-.437 5.344-.539 1.839-.155 7.33-.508 7.966-.512.185-.001.197-.955.224-17.989l.028-17.987 1.329 3.759.47 1.328 1.996-41.81-.686-1.938-3.076-8.694-.11-.307H37Z'
          fill='#B1060F'
          fillRule='evenodd'
          stroke='#000'
          strokeWidth='.082'
        />
        <path
          d='M37 18v23.84l16.794 44.549.006-2.74.028-17.987 1.329 3.759c7.396 20.928 11.37 32.148 11.392 32.172.016.015 1.135.083 2.487.15 4.097.203 9.174.639 13.025 1.117.892.111 1.66.168 1.705.126.032-.028.053-9.406.058-22.636L67.11 32.293l-.008 4.548-.033 18.784-1.546-4.36c-1.51-4.261-2.518-7.11-8.585-24.264l-3.076-8.694-.11-.307H37Z'
          fill='url(#985cddd1-ad6c-4469-b627-188ae5804186__b)'
          fillRule='evenodd'
        />
        <path
          d='m37 18 16.828 47.683v-.021l1.329 3.759c7.396 20.928 11.369 32.148 11.392 32.172.016.015 1.135.083 2.487.15 4.097.203 9.174.639 13.025 1.117.887.111 1.65.167 1.704.127l-16.698-47.37v.008l-1.545-4.36c-1.51-4.261-2.518-7.11-8.585-24.264a16778.04 16778.04 0 0 0-3.076-8.694L53.75 18H37Z'
          fill='#E50914'
          fillRule='evenodd'
        />
        <defs>
          <radialGradient
            id='985cddd1-ad6c-4469-b627-188ae5804186__a'
            cx='0'
            cy='0'
            gradientTransform='rotate(-19.098 208.169 -147.246) scale(10.613 388.745)'
            gradientUnits='userSpaceOnUse'
            r='1'
          >
            <stop />
            <stop offset='1' stopOpacity='0' />
          </radialGradient>
          <radialGradient
            id='985cddd1-ad6c-4469-b627-188ae5804186__b'
            cx='0'
            cy='0'
            gradientTransform='rotate(-19.098 208.169 -147.246) scale(10.613 388.745)'
            gradientUnits='userSpaceOnUse'
            r='1'
          >
            <stop />
            <stop offset='1' stopOpacity='0' />
          </radialGradient>
        </defs>
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconNetflixN
