import includes from 'lodash/includes'

const specialFields = ['context', 'type']

import type { Schema } from './parseSchemaObject.types'

const parseSchemaObject = (dirtySchema: Schema): Schema => {
  // Create the initial cleanSchema object for the recursive parser to add properties to
  const cleanSchema: Schema = {}

  // For each of the keys of dirtySchema
  Object.keys(dirtySchema).forEach((schemaItem) => {
    // Get the currentSchemaItem based on the looped key in the dirtySchema object
    const currentSchemaItem = dirtySchema[schemaItem]

    // If the currentSchemaItem schemaItem is type array
    if (Array.isArray(currentSchemaItem)) {
      // Add a new property to the cleanSchema with the looped schemaItem as the key and map over the arrays content
      cleanSchema[schemaItem] = currentSchemaItem.map((innerSchemaItem) => {
        // If the currentSchemaItem arrays innerItem is an object
        if (innerSchemaItem instanceof Object) {
          // Run it back through the parser for its inner items to be parsed
          return parseSchemaObject(innerSchemaItem as Schema)
        }

        // If it is not an object, we know it must be a string so just return the string (schemas don't have arrays nested inside arrays)
        return innerSchemaItem
      })
      return
    }

    // If the currentSchemaItem schemaItem is type object
    if (currentSchemaItem instanceof Object) {
      // Add a new property to the cleanSchema with the looped schemaItem as the key pass its items back though the parser
      cleanSchema[schemaItem] = parseSchemaObject(currentSchemaItem as Schema)
      return
    }

    // If the looped schemaItem matches on of the specialFields defined at the top of the file
    if (includes(specialFields, schemaItem)) {
      // Add a new property to the cleanSchema with the currentSchemaItem as its value
      cleanSchema[`@${schemaItem}`] = currentSchemaItem
      return
    }

    // If the looped schemaItem isn't an array, an object or a specialField, then assume it is a primitive data type, such as string
    cleanSchema[schemaItem] = currentSchemaItem
  })

  // Return the parse 'clean' schema
  return cleanSchema
}

export default parseSchemaObject
