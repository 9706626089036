import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledFunctionalTabPanelProps } from './FunctionalTabPanel.style.types'

export const TabPanel = styled.div(
  (props: StyledFunctionalTabPanelProps): FlattenSimpleInterpolation => css`
    ${!props.isActiveTab &&
    `
      position: absolute;
      opacity: 0;
      height: 0;
      overflow: hidden;
    `}
  `,
)
