import { useEffect, useState } from 'react'
import { useBreakpoints } from '@vfuk/core-match-media'

/**
 * - Hook that takes an element and returns the position of that element within the visible screen boundaries
 */

const useVisibleScreenRect = (element: Nullable<Element>): DOMRect | undefined => {
  const [rect, setRect] = useState<DOMRect>()

  const breakpoints = useBreakpoints()

  const handleEvent = () => {
    setRect(element?.getBoundingClientRect())
  }

  useEffect(() => {
    if (!element) return
    setRect(element.getBoundingClientRect())
    window.addEventListener('scroll', handleEvent)
    window.addEventListener('resize', handleEvent)

    return (): void => {
      window.removeEventListener('scroll', handleEvent)
      window.removeEventListener('resize', handleEvent)
    }
  }, [element, breakpoints])

  return rect
}

export default useVisibleScreenRect
