import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import TextInput from '@vfuk/core-text-input'
import FieldWrapper from '@vfuk/core-field-wrapper'

import type { TextInputWithLabelProps } from './TextInputWithLabel.types'

const TextInputWithLabel: FC<TextInputWithLabelProps> = ({
  state,
  fieldWrapper,
  textInput,
  id,
  dataAttributes,
  dataSelectorPrefix,
}: TextInputWithLabelProps): ReactElement => {
  const componentName = 'TextInputWithLabel'

  return (
    <FieldWrapper
      id={id}
      dataAttributes={dataAttributes}
      state={state}
      label={fieldWrapper.label}
      formElementId={textInput.id}
      showLabel={fieldWrapper.showLabel}
      helpText={fieldWrapper.helpText}
      stateText={fieldWrapper.stateText}
      subText={fieldWrapper.subText}
      validateOn={fieldWrapper.validateOn}
      width={fieldWrapper.width}
      helpLink={fieldWrapper.helpLink}
      optional={fieldWrapper.optional}
      required={fieldWrapper.required}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName}
    >
      <TextInput
        state={state}
        autoComplete={textInput.autoComplete}
        autoCapitalize={textInput.autoCapitalize}
        dataAttributes={textInput.dataAttributes}
        id={textInput.id}
        name={textInput.name}
        onBlur={textInput.onBlur}
        onChange={textInput.onChange}
        onClick={textInput.onClick}
        placeholder={textInput.placeholder}
        required={textInput.required}
        type={textInput.type}
        value={textInput.value}
        pattern={textInput.pattern}
        spellcheck={textInput.spellcheck}
        minLength={textInput.minLength}
        maxLength={textInput.maxLength}
        onKeyDown={textInput.onKeyDown}
        beforeChange={textInput.beforeChange}
        infieldIcon={textInput.infieldIcon}
        domRef={textInput.domRef}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'text-input')}
      />
    </FieldWrapper>
  )
}

export default TextInputWithLabel
