import type { Theme } from '@vfuk/core-themes'

import type { TabsTheme } from './Tabs.theme.types'

const tabsLocal = (theme: Theme): TabsTheme => {
  return {
    appearance: {
      primary: {
        textColor: theme.color.monochrome5.default,
        activeUnderlineColor: theme.color.primary1.default,
        tabListBottomBorder: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.color.monochrome3.default}`,
        containerFocusShadow: `0 0 0 3px ${theme.color.focus.default}`,
        titleBottomBorder: `4px ${theme.border.style[1]} transparent`,
        titleBottomBorderRadius: theme.border.radius[1],
        hoverFontWeight: `${theme.fontWeight[3]}`,
        activeTextColor: theme.color.monochrome5.default,
        activeFontWeight: `${theme.fontWeight[3]}`,
        textFontWeight: `${theme.fontWeight[3]}}`,
        overflowIcon: {
          appearance: 'primary',
          inverse: false,
        },
        inverse: {
          textColor: theme.inverseColor.monochrome5.default,
          activeUnderlineColor: theme.inverseColor.primary1.default,
          tabListBottomBorder: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.color.monochrome3.default}`,
          containerFocusShadow: `0 0 0 3px ${theme.color.focus.default}`,
          titleBottomBorder: `4px ${theme.border.style[1]} transparent`,
          titleBottomBorderRadius: theme.border.radius[1],
          hoverFontWeight: `${theme.fontWeight[3]}`,
          activeTextColor: theme.inverseColor.monochrome5.default,
          activeFontWeight: `${theme.fontWeight[3]}`,
          textFontWeight: `${theme.fontWeight[3]}}`,
          overflowIcon: {
            appearance: 'primary',
            inverse: true,
          },
        },
      },
      secondary: {
        textColor: theme.color.monochrome5.default,
        activeUnderlineColor: theme.color.monochrome5.default,
        tabListBottomBorder: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.color.monochrome3.default}`,
        containerFocusShadow: `0 0 0 3px ${theme.color.focus.default}`,
        titleBottomBorder: `4px ${theme.border.style[1]} transparent`,
        titleBottomBorderRadius: theme.border.radius[1],
        hoverFontWeight: `${theme.fontWeight[3]}`,
        activeTextColor: theme.color.monochrome5.default,
        activeFontWeight: `${theme.fontWeight[3]}`,
        textFontWeight: `${theme.fontWeight[3]}}`,
        overflowIcon: {
          appearance: 'primary',
          inverse: false,
        },
        inverse: {
          textColor: theme.inverseColor.monochrome5.default,
          activeUnderlineColor: theme.inverseColor.monochrome5.default,
          tabListBottomBorder: `${theme.border.width[1]} ${theme.border.style[1]} ${theme.color.monochrome3.default}`,
          containerFocusShadow: `0 0 0 3px ${theme.color.focus.default}`,
          titleBottomBorder: `4px ${theme.border.style[1]} transparent`,
          titleBottomBorderRadius: theme.border.radius[1],
          hoverFontWeight: `${theme.fontWeight[3]}`,
          activeTextColor: theme.inverseColor.monochrome5.default,
          activeFontWeight: `${theme.fontWeight[3]}`,
          textFontWeight: `${theme.fontWeight[3]}}`,
          overflowIcon: {
            appearance: 'primary',
            inverse: true,
          },
        },
      },
    },
  }
}

export default tabsLocal
