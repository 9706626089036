import React, { FC } from 'react'

import { IconNetflixWordmarkProps } from './IconNetflixWordmark.types'
import * as Styled from './styles/IconNetflixWordmark.style'

import { nonThemeableRules } from '../../ruleSet'

const IconNetflixWordmark: FC<IconNetflixWordmarkProps> = ({
  size = nonThemeableRules.defaultSize as number as IconNetflixWordmarkProps['size'],
  isResponsive = false,
}: IconNetflixWordmarkProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0 38.4c0-13.441 0-20.162 2.616-25.296A24 24 0 0 1 13.104 2.616C18.238 0 24.96 0 38.4 0h43.2c13.441 0 20.162 0 25.296 2.616a23.999 23.999 0 0 1 10.488 10.488C120 18.238 120 24.96 120 38.4v43.2c0 13.441 0 20.162-2.616 25.296a23.998 23.998 0 0 1-10.488 10.488C101.762 120 95.041 120 81.6 120H38.4c-13.441 0-20.162 0-25.296-2.616a23.999 23.999 0 0 1-10.488-10.488C0 101.762 0 95.041 0 81.6V38.4Z'
          fill='#000'
        />
        <path
          d='M86.327 70.147c1.334.102 2.667.211 3.998.33V47.002h-3.998v23.145Zm-64.32-8.71L16.8 47.002H13V72.51c1.33-.19 2.663-.37 3.998-.544V58.094l4.63 13.301a350.52 350.52 0 0 1 4.377-.479V47.002h-3.998v14.435Zm7.843 9.107c3.685-.334 7.388-.61 11.106-.825v-3.94c-2.376.137-4.745.3-7.108.486V60.3c1.55-.015 3.591-.062 5.4-.04v-3.94c-1.445-.003-3.767.02-5.4.042v-5.385h7.108v-3.974H29.85v23.541Zm13.67-19.567h4.184v18.422c1.33-.05 2.663-.091 3.998-.126V50.977h4.184v-3.975H43.52v3.975Zm14.93 18.188h3.998v-9.096h5.42v-3.94h-5.42v-5.151h7.163v-3.975H58.45v22.162Zm49.497-22.163h-4.392l-2.895 6.71-2.601-6.71h-4.326l4.648 11.99-5.074 11.763c1.404.137 2.804.283 4.202.437l2.952-6.839 2.923 7.54c1.522.195 3.041.4 4.557.615l.006-.002-5.209-13.433 5.209-12.071ZM76.172 47h-3.998v22.36c3.634.126 7.252.309 10.856.55v-3.94c-2.28-.152-4.566-.28-6.858-.388V47Z'
          fill='#E50914'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconNetflixWordmark
