import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme } from '@vfuk/core-themes'

import type { MixinApplyFocus } from './applyFocus.types'

import createMixin from '../utils/createMixin'

export default createMixin(
  (theme: Theme): FlattenSimpleInterpolation =>
    css`
      &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 3px ${theme.color.focus.default};
      }

      &:focus:not(:focus-visible) {
        outline: none;
        box-shadow: none;
      }

      &:focus-visible:not(:focus) {
        outline: none;
        box-shadow: none;
      }
    `,
) as MixinApplyFocus
