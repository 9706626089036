import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme } from '@vfuk/core-themes'

import type { MixinBackgroundImage, BackgroundImage, BackgroundImageValue } from './backgroundImage.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * bgStyling - function to map over and return the necessary css
 * @param bgImage -object containing size, position, repeat and src
 * @returns - the styling for the background image
 */
function bgStyling(bgImage: BackgroundImageValue): FlattenSimpleInterpolation {
  const bgSizeDefault = 'cover'
  const bgPositionDefault = 'center'
  const bgRepeatDefault = 'no-repeat'
  const bgAttachmentDefault = 'scroll'

  return css`
    background-size: ${bgImage.size || bgSizeDefault};
    background-position: ${bgImage.position || bgPositionDefault};
    background-repeat: ${bgImage.repeat || bgRepeatDefault};
    background-attachment: ${bgImage.attachment || bgAttachmentDefault};
    ${bgImage.src &&
    `
      background-image: url(${bgImage.src});
    `}
  `
}

export default createMixin((theme: Theme, bgImages: BackgroundImage): FlattenSimpleInterpolation => {
  return css`
    ${bgImages.sm && bgStyling(bgImages.sm)}

    ${respondTo.md(css`
      ${bgImages.md && bgStyling(bgImages.md)}
    `)}

    ${respondTo.lg(css`
      ${bgImages.lg && bgStyling(bgImages.lg)}
    `)}

    ${respondTo.xl(css`
      ${bgImages.xl && bgStyling(bgImages.xl)}
    `)}
  `
}) as MixinBackgroundImage
