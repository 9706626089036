import type { AccordionTheme } from './Accordion.theme.types'

const defaultTheme = (): AccordionTheme => {
  return {
    appearance: {
      primary: {
        containerAppearance: 'primary',
        inverseCollapsibleContainer: false,
      },
      secondary: {
        containerAppearance: 'secondary',
        inverseCollapsibleContainer: false,
      },
    },
  }
}

export default defaultTheme
