import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import { createMixin } from '@vfuk/core-mixins'

import type { Animation } from './animations.types'

const scaleDown: Animation = {
  animation: createMixin(
    (): FlattenSimpleInterpolation => css`
      @keyframes scaleDown {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.25);
        }
      }
    `,
  ) as Animation['animation'],
  config: {
    duration: 150,
  },
}

export default scaleDown
