import React, { ComponentType } from "react";
import { createMapStateToProps, mergeProps } from "@vf-djr/utilities";
import { elementPluginType } from "@vf-djr/renderer";

const conditionalRenderingPlugin: elementPluginType = (
  { __render },
  Element,
  { selectors = {}, ReactReduxContext, connect } = {}
) => {
  if (!connect) {
    console.error(
      "Missing the 'connect' initialisation property for the Conditional Render plugin"
    );
    return Element;
  }
  const Component = Element;
  const Suspend = ({ dj$render, ...props }: { dj$render: string }) => {
    return dj$render ? <Component {...props} /> : <></>;
  };

  const mapStateToProps = createMapStateToProps(selectors)({
    dj$render: __render as string
  });

  return connect(mapStateToProps, {}, mergeProps, {
    context: ReactReduxContext
  })(Suspend as ComponentType);
};

export default conditionalRenderingPlugin;
