import type { SandwichTheme } from './Sandwich.theme.types'

const defaultTheme = (): SandwichTheme => {
  return {
    level: {
      1: {
        mainTextWeight: 1,
        currencyWeight: 1,
        signWeight: 1,
        subTextWeight: 3,
        appearance: {
          primary: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'alt2',
            prefixAppearance: 'alt2',
            suffixAppearance: 'alt2',
            suffix2Appearance: 'alt2',
            inverse: {
              mainAppearance: 'primary',
              mainSuffixAppearance: 'primary',
              prefixAppearance: 'primary',
              suffixAppearance: 'primary',
              suffix2Appearance: 'primary',
            },
          },
          secondary: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'alt2',
            prefixAppearance: 'alt2',
            suffixAppearance: 'secondary',
            suffix2Appearance: 'alt2',
            inverse: {
              mainAppearance: 'primary',
              mainSuffixAppearance: 'primary',
              prefixAppearance: 'primary',
              suffixAppearance: 'primary',
              suffix2Appearance: 'primary',
            },
          },
          alt1: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'alt2',
            prefixAppearance: 'alt2',
            suffixAppearance: 'alt1',
            suffix2Appearance: 'alt2',
            inverse: {
              mainAppearance: 'primary',
              mainSuffixAppearance: 'primary',
              prefixAppearance: 'primary',
              suffixAppearance: 'primary',
              suffix2Appearance: 'primary',
            },
          },
          alt2: {
            mainAppearance: 'secondary',
            mainSuffixAppearance: 'alt2',
            prefixAppearance: 'alt2',
            suffixAppearance: 'alt2',
            suffix2Appearance: 'alt2',
            inverse: {
              mainAppearance: 'primary',
              mainSuffixAppearance: 'primary',
              prefixAppearance: 'primary',
              suffixAppearance: 'primary',
              suffix2Appearance: 'primary',
            },
          },
        },
      },
      2: {
        mainTextWeight: 3,
        currencyWeight: 3,
        signWeight: 3,
        subTextWeight: 2,
        appearance: {
          primary: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'alt2',
            prefixAppearance: 'alt2',
            suffixAppearance: 'alt2',
            suffix2Appearance: 'alt2',
            inverse: {
              mainAppearance: 'primary',
              mainSuffixAppearance: 'primary',
              prefixAppearance: 'primary',
              suffixAppearance: 'primary',
              suffix2Appearance: 'primary',
            },
          },
          secondary: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'alt2',
            prefixAppearance: 'alt2',
            suffixAppearance: 'secondary',
            suffix2Appearance: 'alt2',
            inverse: {
              mainAppearance: 'primary',
              mainSuffixAppearance: 'primary',
              prefixAppearance: 'primary',
              suffixAppearance: 'primary',
              suffix2Appearance: 'primary',
            },
          },
          alt1: {
            mainAppearance: 'primary',
            mainSuffixAppearance: 'alt2',
            prefixAppearance: 'alt2',
            suffixAppearance: 'alt1',
            suffix2Appearance: 'alt2',
            inverse: {
              mainAppearance: 'primary',
              mainSuffixAppearance: 'primary',
              prefixAppearance: 'primary',
              suffixAppearance: 'primary',
              suffix2Appearance: 'primary',
            },
          },
          alt2: {
            mainAppearance: 'secondary',
            mainSuffixAppearance: 'alt2',
            prefixAppearance: 'alt2',
            suffixAppearance: 'alt2',
            suffix2Appearance: 'alt2',
            inverse: {
              mainAppearance: 'primary',
              mainSuffixAppearance: 'primary',
              prefixAppearance: 'primary',
              suffixAppearance: 'primary',
              suffix2Appearance: 'primary',
            },
          },
        },
      },
    },
    size: {
      1: {
        supportingText: {
          element: 'paragraph',
          size: 1,
        },
        currency: {
          element: 'paragraph',
          size: 1,
        },
        sign: {
          element: 'paragraph',
          size: 2,
        },
      },
      2: {
        supportingText: {
          element: 'paragraph',
          size: 2,
        },
        currency: {
          element: 'heading',
          size: 1,
        },
        sign: {
          element: 'heading',
          size: 1,
        },
      },
      3: {
        supportingText: {
          element: 'paragraph',
          size: 2,
        },
        currency: {
          element: 'heading',
          size: 2,
        },
        sign: {
          element: 'heading',
          size: 2,
        },
      },
      4: {
        supportingText: {
          element: 'heading',
          size: 1,
        },
        currency: {
          element: 'heading',
          size: 2,
        },
        sign: {
          element: 'heading',
          size: 2,
        },
      },
      5: {
        supportingText: {
          element: 'heading',
          size: 2,
        },
        currency: {
          element: 'heading',
          size: 3,
        },
        sign: {
          element: 'heading',
          size: 3,
        },
      },
    },
  }
}

export default defaultTheme
