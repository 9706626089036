import { useState, useCallback } from 'react'

import type { useAccordionProps, useAccordionReturn } from './useAccordion.types'

const useAccordion = ({ initiallyOpenIds = [], multi }: useAccordionProps = {}): useAccordionReturn => {
  const [openIds, setOpenIds] = useState<string[]>(initiallyOpenIds)

  const onToggle = useCallback(
    (id: string): void => {
      if (multi || initiallyOpenIds.length > 1) {
        // Add the ID to the existing open IDs
        if (!openIds.includes(id)) return setOpenIds([...openIds, id])

        // Remove the ID from the existing open IDs
        const newOpenIds = [...openIds]
        newOpenIds.splice(newOpenIds.indexOf(id), 1)
        return setOpenIds(newOpenIds)
      }
      // Add the single ID into the existing open list
      if (!openIds.includes(id)) return setOpenIds([id])
      // Reset to none in the list
      return setOpenIds([])
    },
    [openIds, setOpenIds, multi, initiallyOpenIds.length],
  )

  return {
    openIds,
    onToggle,
  }
}

export default useAccordion
