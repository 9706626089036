import type { ReactNode, ReactElement } from 'react'
import React, { PureComponent } from 'react'

import { FunctionalTabs } from '@vfuk/core-functional-tabs'
import { OverflowMenu, OverflowMenuItem } from '@vfuk/core-overflow-menu'
import { getDataSelector } from '@vfuk/core-base-props'
import { withLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/Tabs.theme'
import * as Styled from './styles/Tabs.style'

import type { TabsProps, TabsState } from './Tabs.types'
import type { TabsTheme } from './themes/Tabs.theme.types'

import TabTitle from './components/TabTitle'

class Tabs extends PureComponent<TabsProps> {
  private componentName = 'Tabs'

  public static defaultProps: Partial<TabsProps> = {
    appearance: 'primary',
    inverse: false,
    tabs: {
      width: 'fit',
    },
  }

  public state: TabsState = {
    overflowActive: false,
    activeTabId: this.props.externallySetTabId || '',
    maxWidthOfTabList: 0,
    isOverflowMenuOpen: false,
  }

  private tabsListRef = React.createRef<HTMLDivElement>()

  private overflowMenuRef = React.createRef<HTMLDivElement>()

  componentDidMount(): void {
    this.setOverflowActive()
    window.addEventListener('resize', this.setOverflowActive)
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.setOverflowActive)
  }

  componentDidUpdate(prevProps: TabsProps): void {
    if (this.props.externallySetTabId !== prevProps.externallySetTabId && this.props.externallySetTabId !== this.state.activeTabId) {
      this.setState({
        activeTabId: this.props.externallySetTabId,
      })
    }
  }

  private isOverflowActive(element: HTMLDivElement): boolean {
    if (this.state.maxWidthOfTabList) {
      return this.state.maxWidthOfTabList > element.offsetWidth
    }
    return element.offsetWidth < element.scrollWidth
  }

  private setOverflowActive = (): void => {
    // Checks the tab size against the maximum allowed size (viewable area) of the tabs container.
    // If it would overflow then we need to show the dropdown version
    const tableList = this.tabsListRef?.current
    if (tableList) {
      // Here we set the width equal to the sum of all elements, if we have not already set it
      if (tableList.scrollWidth > tableList.offsetWidth && !this.state.overflowActive && !this.state.maxWidthOfTabList) {
        const maxWidthOfTabList = Array.from(tableList.children).reduce((previousValue: number, currentValue: HTMLDivElement) => {
          return previousValue + currentValue.offsetWidth
        }, 0)
        this.setState({
          maxWidthOfTabList,
        })
      }
      // If the maxWidthOfTabList is not set, then scrollWidth is compared with offsetWidth
      this.setState({
        overflowActive: this.isOverflowActive(tableList),
      })
    }
  }

  private getActiveTab = (): ReactElement => {
    const activeTab = this.props.children.find((tab) => tab.props.id === this.state.activeTabId)
    return activeTab || this.props.children[0]
  }

  private setisOverflowMenuOpen = (): void => {
    this.setState({ isOverflowMenuOpen: !this.state.isOverflowMenuOpen })
  }

  private handleChangeOverflowMenu = (value: string): void => {
    this.setState({ activeTabId: value })
  }

  private handleCloseOverflowMenu = (): void => {
    this.setState({ isOverflowMenuOpen: false })
  }

  private handleTabTitleOnClick = (tabId: string) => (): void => {
    this.setState({ activeTabId: tabId })
  }

  private handleTabTitleOnKeyPress =
    (tabId: string) =>
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === 'Enter') {
        this.setState({ activeTabId: tabId })
      }
    }

  public render(): ReactNode {
    const overflowActiveTabTitle = this.state.overflowActive && this.getActiveTab()

    return (
      <FunctionalTabs
        onTabClick={this.props.onTabClick}
        externallySetTabId={this.state.activeTabId}
        accessibilityInfiniteScroll={this.props.accessibilityInfiniteScroll}
        id={this.props.id}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
        componentName={this.componentName}
        dataAttributes={this.props.dataAttributes}
      >
        <Styled.TabListContainer tabsTheme={this.props.localTheme!} appearance={this.props.appearance!} inverse={this.props.inverse}>
          <Styled.TabList
            ref={this.tabsListRef}
            overflowActive={this.state.overflowActive}
            role='tablist'
            data-selector={getDataSelector(this.props.dataSelectorPrefix, 'tab-list')}
          >
            {/* Maps through <Tab> children to pull out props and pass to <FunctionalTab> */}
            {this.state.overflowActive ? (
              <Styled.TabTitleContainer>
                <TabTitle
                  tabWidth={this.props.tabs!.width!}
                  tabId={(overflowActiveTabTitle as ReactElement).props.id}
                  isActiveTab={(overflowActiveTabTitle as ReactElement).props.isActiveTab}
                  appearance={this.props.appearance!}
                  inverse={this.props.inverse!}
                  text={(overflowActiveTabTitle as ReactElement).props.text}
                  onClick={this.setisOverflowMenuOpen}
                  overflowActive={this.state.overflowActive}
                  isOverflowMenuOpen={this.state.isOverflowMenuOpen}
                  localTheme={this.props.localTheme!}
                  dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'tab-title')}
                />
                <Styled.OverflowMenuContainer>
                  <OverflowMenu
                    isOpen={this.state.isOverflowMenuOpen}
                    onChange={this.handleChangeOverflowMenu}
                    activeItem={this.state.activeTabId || this.props.children[0].props.id}
                    onClose={this.handleCloseOverflowMenu}
                    triggerRef={this.overflowMenuRef}
                    onOutsideClick={this.handleCloseOverflowMenu}
                    srText={(overflowActiveTabTitle as ReactElement).props.text}
                    dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'overflow-menu')}
                  >
                    {React.Children.map(this.props.children, (child: ReactElement, key: number): ReactNode => {
                      return (
                        <OverflowMenuItem
                          key={key}
                          value={child.props.id}
                          text={child.props.text}
                          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `menu-item-${key}`)}
                        />
                      )
                    })}
                  </OverflowMenu>
                </Styled.OverflowMenuContainer>
              </Styled.TabTitleContainer>
            ) : (
              React.Children.map(this.props.children, (child: ReactElement, key: number): ReactNode => {
                return (
                  <TabTitle
                    key={key}
                    tabWidth={this.props.tabs!.width!}
                    tabId={child.props.id}
                    isActiveTab={child.props.isActiveTab}
                    appearance={this.props.appearance!}
                    inverse={this.props.inverse!}
                    text={child.props.text}
                    onClick={this.handleTabTitleOnClick(child.props.id)}
                    onKeyPress={this.handleTabTitleOnKeyPress(child.props.id)}
                    localTheme={this.props.localTheme!}
                    dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `tab-title-${key}`)}
                  />
                )
              })
            )}
          </Styled.TabList>
        </Styled.TabListContainer>
        {/* <Tab> children render <FunctionalTabPanel> components which contain the tab content */}
        {this.props.children}
      </FunctionalTabs>
    )
  }
}

export default withLocalTheme<TabsProps, TabsTheme>(Tabs, defaultTheme)
