import type { ReactElement, FC } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/HelpText.style'

import type { HelpTextProps } from './HelpText.types'

const HelpText: FC<HelpTextProps> = ({ helpText, formElementId, helpLink, state, dataSelectorPrefix }: HelpTextProps): ReactElement => {
  const helpTextId = `${formElementId}-help-text`

  return (
    <Styled.HelpList dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}>
      <>
        {Array.isArray(helpText) ? (
          helpText.map((text, index) => {
            return (
              <Styled.HelpText
                id={`${helpTextId}-${index}`}
                key={index}
                state={state}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `help-text-${index}`)}
              >
                {text}
              </Styled.HelpText>
            )
          })
        ) : (
          <Styled.HelpText state={state} id={helpTextId} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'help-text')}>
            {helpText}
          </Styled.HelpText>
        )}

        {helpLink && (
          <Styled.HelpText state={state} {...helpLink} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'help-link')}>
            {helpLink!.text}
          </Styled.HelpText>
        )}
      </>
    </Styled.HelpList>
  )
}

export default HelpText
