import type { HorizontalScrollProps } from './scrollToStep.type'

function scrollToStep({ stepperRef, currentStep }: HorizontalScrollProps): void {
  if (!stepperRef || !stepperRef.current) return

  const currentRef = stepperRef.current
  const maxWidth = currentRef.scrollWidth

  if (currentStep === 1) {
    currentRef.scrollBy({ left: -maxWidth, behavior: 'smooth' })
    return
  }

  if (currentStep === currentRef.children.length) {
    currentRef.scrollBy({ left: maxWidth, behavior: 'smooth' })
    return
  }

  const previousStepWidth = currentRef.children[currentStep - 2].scrollWidth
  const currentStepWidth = currentRef.children[currentStep - 1].scrollWidth
  const scrollBy = previousStepWidth - currentStepWidth / 2
  currentRef.scrollBy({ left: scrollBy, behavior: 'smooth' })
}
export default scrollToStep
