import type { Theme } from '@vfuk/core-themes'

import type { ButtonTheme, ButtonInteractionProps } from './Button.theme.types'

const defaultTheme = (theme: Theme): ButtonTheme => {
  const stateSuccess: ButtonInteractionProps = {
    color: theme.color.monochrome1.default,
    backgroundColor: theme.color.success.default,
    iconAppearance: 'primary',
    inverseIcon: true,
    borderColor: 'transparent',
    hover: {
      color: theme.color.monochrome1.default,
      backgroundColor: theme.color.success.hover,
      borderColor: 'transparent',
      iconAppearance: 'primary',
    },
    pressed: {
      color: theme.color.monochrome1.default,
      backgroundColor: theme.color.success.pressed,
      borderColor: 'transparent',
    },
  }

  const state = {
    success: stateSuccess,
    selected: stateSuccess,
  }

  return {
    appearance: {
      primary: {
        color: theme.color.monochrome1.default,
        backgroundColor: theme.color.primary1.default,
        iconAppearance: 'primary',
        inverseIcon: true,
        borderColor: theme.color.primary1.default,
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.pressed,
          borderColor: theme.color.primary1.pressed,
        },
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.hover,
          borderColor: theme.color.primary1.hover,
          iconAppearance: 'secondary',
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: theme.inverseColor.primary1.default,
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: theme.inverseColor.primary1.default,
          pressed: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.pressed,
            borderColor: theme.inverseColor.primary1.pressed,
          },
          hover: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.hover,
            borderColor: theme.inverseColor.primary1.hover,
            iconAppearance: 'secondary',
          },
        },
      },
      secondary: {
        color: theme.color.monochrome1.default,
        backgroundColor: theme.color.monochrome6.default,
        iconAppearance: 'primary',
        inverseIcon: true,
        borderColor: theme.color.monochrome6.default,
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.pressed,
          borderColor: theme.color.monochrome6.pressed,
        },
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.hover,
          borderColor: theme.color.monochrome6.hover,
          iconAppearance: 'secondary',
        },
        inverse: {
          color: theme.inverseColor.monochrome1.default,
          backgroundColor: theme.inverseColor.monochrome6.default,
          iconAppearance: 'primary',
          inverseIcon: false,
          borderColor: theme.inverseColor.monochrome6.default,
          pressed: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.pressed,
            borderColor: theme.inverseColor.monochrome6.pressed,
          },
          hover: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.hover,
            borderColor: theme.inverseColor.monochrome6.hover,
            iconAppearance: 'primary',
          },
        },
      },
      alt1: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.pressed,
          borderColor: 'transparent',
        },
        iconAppearance: 'primary',
        inverseIcon: false,
        borderColor: theme.color.monochrome6.default,
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.monochrome6.hover,
          borderColor: 'transparent',
          iconAppearance: 'primary',
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          pressed: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.pressed,
            borderColor: 'transparent',
          },
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: theme.color.monochrome1.default,
          hover: {
            color: theme.inverseColor.monochrome1.default,
            backgroundColor: theme.inverseColor.monochrome6.hover,
            borderColor: 'transparent',
            iconAppearance: 'alt1',
          },
        },
      },
      alt2: {
        color: theme.color.monochrome6.default,
        backgroundColor: 'transparent',
        pressed: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.pressed,
          borderColor: 'transparent',
        },
        iconAppearance: 'primary',
        inverseIcon: false,
        borderColor: theme.color.primary1.default,
        hover: {
          color: theme.color.monochrome1.default,
          backgroundColor: theme.color.primary1.hover,
          borderColor: 'transparent',
          iconAppearance: 'alt1',
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          backgroundColor: 'transparent',
          pressed: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.pressed,
            borderColor: 'transparent',
          },
          iconAppearance: 'primary',
          inverseIcon: true,
          borderColor: theme.inverseColor.primary1.default,
          hover: {
            color: theme.inverseColor.monochrome6.default,
            backgroundColor: theme.inverseColor.primary1.hover,
            borderColor: 'transparent',
            iconAppearance: 'alt1',
          },
        },
      },
    },
    state: { ...state },
    borderRadius: theme.border.radius[2],
    borderWidth: theme.border.width[2],
  }
}

export default defaultTheme
