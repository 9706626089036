import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, spacing } from '@vfuk/core-mixins'

import type { StyledCardBuilderProps, StyledCardSlotProps } from './CardBuilder.style.types'

export const CardBuilder = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  `,
)

export const DividerWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [0, 3])};
  `,
)

export const SlotsWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    /*
     * 'sc-sel' tells the processor that 'DividerWrapper' is a selector
     * which suppresses the 'selector-type-no-unknown' warning
     * https://styled-components.com/docs/tooling#interpolation-tagging
     */

    & > *:first-child > div:not(${/* sc-sel */ DividerWrapper}) {
      ${advancedSpacing('padding', [4, 4, 2])}
    }

    & > *:last-child > div:not(${/* sc-sel */ DividerWrapper}) {
      ${spacing('padding-bottom', 4)}
    }

    [class*='CardIconSnippetSlot']:nth-last-child(-n + 1) {
      ${spacing('margin-top', 2)};
    }
  `,
)

export const CardBuilderFooterTextWrapper = styled.div(
  (props: StyledCardBuilderProps): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [2, 2, 0, 2])};
    color: ${props.theme.color.monochrome4.default};
  `,
)

export const CardSlots = styled.div(
  (props: StyledCardSlotProps): FlattenSimpleInterpolation => css`
    ${props.lineBreakPosition && `margin-${props.lineBreakPosition}: auto;`}
  `,
)
