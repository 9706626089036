import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme, ParagraphKeys, HeadingKeys } from '@vfuk/core-themes'

import type { ParagraphTypographyMixin, HeadingTypographyMixin } from './typography.types'

import respondTo from '../respondTo'
import spacing from '../spacing'
import createMixin from '../utils/createMixin'

const paragraphMixin = createMixin(
  (theme: Theme, size: ParagraphKeys, withMargin: boolean = true): FlattenSimpleInterpolation =>
    theme.typography.paragraph[size] &&
    css`
      margin: 0;
      font-size: ${theme.typography.paragraph[size].fontMobile};
      line-height: ${theme.typography.paragraph[size].lineHeightMobile};
      letter-spacing: ${theme.typography.paragraph[size].letterSpacingMobile};

      ${withMargin && spacing('margin-bottom', theme.typography.paragraph[size].marginBottom)}

      ${respondTo.md(css`
        font-size: ${theme.typography.paragraph[size].fontTablet};
        line-height: ${theme.typography.paragraph[size].lineHeightTablet};
        letter-spacing: ${theme.typography.paragraph[size].letterSpacingTablet};
      `)}

      ${respondTo.lg(css`
        font-size: ${theme.typography.paragraph[size].fontDesktop};
        line-height: ${theme.typography.paragraph[size].lineHeightDesktop};
        letter-spacing: ${theme.typography.paragraph[size].letterSpacingDesktop};
      `)}
    `,
) as ParagraphTypographyMixin

const headingMixin = createMixin(
  (theme: Theme, size: HeadingKeys, withMargin: boolean = true): FlattenSimpleInterpolation =>
    theme.typography.heading[size] &&
    css`
      margin: 0;
      font-size: ${theme.typography.heading[size].fontMobile};
      line-height: ${theme.typography.heading[size].lineHeightMobile};
      letter-spacing: ${theme.typography.heading[size].letterSpacingMobile};

      ${withMargin &&
      css`
        ${spacing('margin-bottom', theme.typography.heading[size].marginBottom)}
      `}

      ${respondTo.md(css`
        font-size: ${theme.typography.heading[size].fontTablet};
        line-height: ${theme.typography.heading[size].lineHeightTablet};
        letter-spacing: ${theme.typography.heading[size].letterSpacingTablet};
      `)}

      ${respondTo.lg(css`
        font-size: ${theme.typography.heading[size].fontDesktop};
        line-height: ${theme.typography.heading[size].lineHeightDesktop};
        letter-spacing: ${theme.typography.heading[size].letterSpacingDesktop};
      `)}
    `,
) as HeadingTypographyMixin

export default {
  heading: headingMixin,
  paragraph: paragraphMixin,
}
