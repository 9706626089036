import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { ButtonStyles } from '@vfuk/core-button'
import { IconStyles } from '@vfuk/core-icon'
import { respondTo, spacing } from '@vfuk/core-mixins'

import type { StyledBannerBodyProps } from './BannerBody.style.types'

export const BannerBody = styled.div(
  (props: StyledBannerBodyProps): FlattenSimpleInterpolation => css`
    text-align: ${props.justify};
    position: relative;
    max-width: 960px;
    z-index: 2;

    ${props.inverse &&
    `
    color: ${props.theme.color.monochrome1.default};
  `}

    ${props.justify === 'center' &&
    `
    width: 90%;
  `}
  `,
)

type BannerButtonsProps = Pick<StyledBannerBodyProps, 'theme' | 'justify'>
export const BannerButtons = styled.div(
  (props: BannerButtonsProps): FlattenSimpleInterpolation => css`
    text-align: ${props.justify};
    ${spacing('margin-bottom', 5)}

    &:last-child {
      margin-bottom: 0;
    }

    ${ButtonStyles.Button} {
      margin-bottom: ${props.theme.spacing.responsive[3].sm}px;

      ${respondTo.md(css`
        margin-bottom: 0;
        margin-right: ${props.theme.spacing.responsive[3].md}px;
      `)}

      ${respondTo.lg(css`
        margin-right: ${props.theme.spacing.responsive[3].lg}px;
      `)}

    ${respondTo.xl(css`
        margin-right: ${props.theme.spacing.responsive[3].xl}px;
      `)}

    &:last-child {
        margin: 0;
      }
    }
  `,
)

export const BannerFootnote = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    text-align: left;

    ${IconStyles.Icon} {
      ${spacing('margin-right', 3)}

      &:last-child {
        margin-right: 0;
      }
    }
  `,
)

export const BannerContent = styled.div(
  (): FlattenSimpleInterpolation => css`
    ${spacing('margin-bottom', 5)}

    &:last-child {
      margin-bottom: 0;
    }
  `,
)
