import type { ReactNode } from 'react'
import React from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'
import { withTranslation } from 'react-i18next'

import type { AnimationTypes } from '@vfuk/core-animate'
import Animate from '@vfuk/core-animate'
import Overlay from '@vfuk/core-overlay'
import IconButton from '@vfuk/core-icon-button'
import type { Layout } from '@vfuk/core-simple-grid'
import SimpleGrid from '@vfuk/core-simple-grid'
import { capitalizeFirstCharacter } from '@vfuk/core-helpers'
import { getDataSelector } from '@vfuk/core-base-props'
import { withLocalTheme } from '@vfuk/core-themes'

import * as Styled from './styles/SideTrayRenderer.style'
import defaultTheme from './themes/SideTrayRenderer.theme'

import type { SideTrayRendererProps } from './SideTrayRenderer.types'

import Renderer from '../Renderer'

import setInitialFocus from '../utils/setInitialFocus'

import type { RendererState } from '../Renderer.types'
import { OverlayCloseSource } from '../constants/constants'
import { SideTrayRendererTheme } from './themes/SideTrayRenderer.theme.types'

export class SideTrayRenderer extends Renderer<SideTrayRendererProps, RendererState> {
  public static defaultProps: Partial<SideTrayRendererProps> = {
    side: 'left',
    size: 1,
    overlayBlur: false,
    enableOverlay: false,
    isClosable: true,
    topOffset: '0',
    appearance: 'primary',
  }

  private columnWidths(size: SideTrayRendererProps['size']): Layout {
    if (size === 1) {
      return {
        sm: [12],
        md: [5],
        lg: [3],
      }
    }
    if (size === 2) {
      return {
        sm: [12],
        md: [6],
        lg: [5],
      }
    }
    return {
      sm: [12],
      md: [9],
      lg: [6],
    }
  }

  public render(): ReactNode {
    const animationDuration = this.props.animate ? 200 : 0

    return createPortal(
      <Styled.SideTrayRenderer
        zIndex={this.props.zIndex}
        topOffset={this.props.topOffset}
        id={this.props.id}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.props.componentName}
        {...this.props.dataAttributes}
      >
        <Animate
          show={this.state.show}
          enter={{
            animations: this.props.animate ? [`slideFrom${capitalizeFirstCharacter(this.props.side!)}` as AnimationTypes] : ['none'],
            duration: animationDuration,
            delay: animationDuration,
            onDone: (): void => setInitialFocus(this.props.initialFocusId),
          }}
          exit={{
            animations: this.props.animate ? [`slideTo${capitalizeFirstCharacter(this.props.side!)}` as AnimationTypes] : ['none'],
            duration: animationDuration,
            onDone: this.onDoneCallback,
          }}
        >
          <Styled.Container zIndex={this.props.zIndex + 1} side={this.props.side}>
            <SimpleGrid layout={this.columnWidths(this.props.size)} verticalAlign='stretch' justify={this.props.side} spacing={0}>
              <Styled.SideTray
                side={this.props.side}
                data-selector={getDataSelector(this.props.dataSelectorPrefix, 'content')}
                appearance={this.props.appearance!}
                sideTrayTheme={this.props.localTheme!}
              >
                <FocusLock shards={this.props.focusEnabledRefs}>
                  {this.props.isClosable && (
                    <Styled.CloseButton aria-label={this.props.srName} animationDuration={animationDuration} side={this.props.side}>
                      <IconButton
                        srText={this.props.t!('Close')}
                        icon={{ name: 'close' }}
                        appearance={this.props.localTheme?.appearance[this.props.appearance!].closeIconAppearance}
                        inverse={this.props.localTheme?.appearance[this.props.appearance!].inverseCloseIcon}
                        size={4}
                        onClick={this.getOnCloseHandler(OverlayCloseSource.SIDETRAY_CROSS_CLICK)}
                        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'close-button')}
                      />
                    </Styled.CloseButton>
                  )}
                  {this.props.children}
                </FocusLock>
              </Styled.SideTray>
            </SimpleGrid>
          </Styled.Container>
        </Animate>

        {this.props.enableOverlay && (
          <Animate
            show={this.state.show}
            enter={{
              animations: this.props.animate ? ['fadeIn'] : ['none'],
              duration: this.props.animate ? 400 : 0,
            }}
            exit={{
              animations: this.props.animate ? ['fadeOut'] : ['none'],
              duration: animationDuration,
            }}
          >
            <Overlay
              show
              onClick={this.props.isClosable ? this.getOnCloseHandler(OverlayCloseSource.SIDETRAY_OUTSIDE_CLICK) : undefined}
              position='fixed'
              zIndex={0}
              blur={this.props.overlayBlur}
              dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'overlay')}
            />
          </Animate>
        )}
      </Styled.SideTrayRenderer>,
      this.props.rootElement,
    )
  }
}

export default withTranslation()(withLocalTheme<SideTrayRendererProps, SideTrayRendererTheme>(SideTrayRenderer, defaultTheme))
