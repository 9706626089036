/* eslint-disable max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import ListItem from '@vfuk/core-list-item'
import { advancedSpacing, respondTo, spacing, srOnly } from '@vfuk/core-mixins'

import type { StyledCarouselLoaderPagerProps, StyledCarouselLoaderPagerBarProps } from './CarouselLoaderPager.style.types'

import localThemes from '../../../themes/FunctionalCarousel.theme'

export const CarouselLoaderPager = styled(ListItem)((props: StyledCarouselLoaderPagerProps): FlattenSimpleInterpolation => {
  return css`
    display: block;
    ${advancedSpacing('margin', [0, 2])}
    width: 42px;

    ${respondTo.lg(css`
      width: 190px;
    `)}

    [class*=Button] {
      font-weight: ${props.active ? 'bold' : 'normal'};
    }
  `
})

export const Bar = styled.div((props: StyledCarouselLoaderPagerBarProps): FlattenSimpleInterpolation => {
  const localTheme = localThemes(props.theme)
  return css`
    width: 100%;
    height: 6px;
    ${spacing('margin-bottom', 3)}
    background-color: ${localTheme.pagerLoader.bar.backgroundColor};

    &,
    &:before {
      border-radius: 100px;
    }

    ${props.active &&
    css`
      &:before {
        ${props.progress &&
        css`
          transition: width ${props.transitionTime || 100}ms linear;
        `};
        content: '';
        display: block;
        background-color: ${localTheme.pagerLoader.bar.color};
        height: 6px;
        width: ${Math.round((props.progress || 0) * 100)}%;
      }
    `}
  `
})

export const Title = styled.div((): FlattenSimpleInterpolation => {
  return css`
    line-height: 24px;
    min-height: 48px;
    vertical-align: top;
    ${spacing('padding-bottom', 3)}
  `
})

export const SrText = styled.span(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)
