import type { i18n as i18ntype } from 'i18next'

import type { LocalDictionary } from './loadResources.types'

/** Function for components with local dictionaries
 * Allows to extend the global dictionary with a local dictionary of given component
 * @param i18n
 * @param componentName
 * @param dictionary
 */
const loadResources = (i18n: i18ntype | undefined, componentName: string, dictionary: LocalDictionary): void => {
  const languageKeys = Object.keys(dictionary) as Array<string>

  languageKeys.forEach((languageKey) => {
    // If the resource for this language has not been loaded
    // then load it
    if (i18n && !i18n.hasResourceBundle(languageKey, componentName)) {
      const resourceBundle = dictionary[languageKey]
      i18n.addResourceBundle(languageKey, componentName, resourceBundle, true, true)
    }
  })
}

export default loadResources
