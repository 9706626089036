/* eslint-disable sonarjs/no-duplicated-branches */
import type { Theme, BreakpointKeys } from '@vfuk/core-themes'
import type { SizeKeys } from '@vfuk/core-functional-avatar'

function calculateTextSize(size: SizeKeys, theme: Theme): { [P in BreakpointKeys]: string } {
  switch (size) {
    case 1:
      return {
        sm: theme.typography.paragraph[1].fontMobile,
        md: theme.typography.paragraph[1].fontTablet,
        lg: theme.typography.paragraph[1].fontDesktop,
        xl: theme.typography.paragraph[1].fontDesktop,
      }
    case 2:
      return {
        sm: theme.typography.paragraph[1].fontMobile,
        md: theme.typography.paragraph[1].fontTablet,
        lg: theme.typography.paragraph[1].fontDesktop,
        xl: theme.typography.paragraph[1].fontDesktop,
      }
    case 3:
      return {
        sm: theme.typography.heading[2].fontMobile,
        md: theme.typography.heading[1].fontTablet,
        lg: theme.typography.heading[1].fontDesktop,
        xl: theme.typography.heading[1].fontDesktop,
      }
    case 4:
      return {
        sm: theme.typography.heading[4].fontMobile,
        md: theme.typography.heading[2].fontTablet,
        lg: theme.typography.heading[2].fontDesktop,
        xl: theme.typography.heading[2].fontDesktop,
      }
    case 5:
      return {
        sm: theme.typography.heading[5].fontMobile,
        md: theme.typography.heading[4].fontTablet,
        lg: theme.typography.heading[3].fontDesktop,
        xl: theme.typography.heading[3].fontDesktop,
      }
    case 6:
      return {
        sm: theme.typography.heading[5].fontMobile,
        md: theme.typography.heading[4].fontTablet,
        lg: theme.typography.heading[3].fontDesktop,
        xl: theme.typography.heading[3].fontDesktop,
      }
    default:
      return {
        sm: '',
        md: '',
        lg: '',
        xl: '',
      }
  }
}

export default calculateTextSize
