import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledLoadingSpinnerProps } from './LoadingSpinner.style.types'

export const LoadingSpinner = styled.span<StyledLoadingSpinnerProps>((props: StyledLoadingSpinnerProps): FlattenSimpleInterpolation => {
  const strokeColor = props.inverse
    ? props.loadingSpinnerTheme.appearance[props.appearance!]?.inverse.color
    : props.loadingSpinnerTheme.appearance[props.appearance!]?.color

  return css`
    ${props.loadingSpinnerTheme.animationStyle(
      props.loadingSpinnerTheme.size[props.size!].width,
      props.loadingSpinnerTheme.size[props.size!].height,
      strokeColor,
    )}
  `
})
