import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { HeadingKeys } from '@vfuk/core-themes'
import { SpanStyles } from '@vfuk/core-span'
import { spacing, typography } from '@vfuk/core-mixins'

import type { StyledDateCountdownProps } from './DateCountdown.style.types'

type CountdownContainerProps = Pick<StyledDateCountdownProps, 'theme' | 'size' | 'inverse' | 'justify'>
export const CountdownContainer = styled.div(
  (props: CountdownContainerProps): FlattenSimpleInterpolation => css`
    color: ${props.inverse ? props.theme.color.monochrome1.default : props.theme.color.monochrome5.default};
    ${typography.heading(props.size as HeadingKeys, false)}
    font-weight: ${props.theme.fontWeight[3]};
    text-align: ${props.justify};
  `,
)

type CountdownTimerProps = Pick<StyledDateCountdownProps, 'theme' | 'orientation' | 'showUnits' | 'size' | 'text'>
export const CountdownTimer = styled.span(
  (props: CountdownTimerProps): FlattenSimpleInterpolation => css`
    display: inline-block;

    ${props.text?.prefix &&
    css`
      ${spacing('margin-left', 2)}
    `}

    ${props.text?.suffix &&
    css`
      ${spacing('margin-right', 2)}
    `}

  ${props.orientation === 'vertical' &&
    css`
      display: block;
      margin: 0;
    `}

  ${props.showUnits &&
    props.size === 3 &&
    css`
      margin-bottom: ${props.theme.typography.paragraph[2].fontDesktop};
    `}
  `,
)

export const CountdownSection = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: inline-block;

    ${SpanStyles.Span} {
      display: inline-block;
      margin: 0 1px;
    }

    &:last-child {
      ${SpanStyles.Span} {
        display: none;
      }
    }
  `,
)
