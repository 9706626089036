import type { Theme } from '@vfuk/core-themes'

import type { BottomTrayRendererTheme } from './BottomTrayRenderer.theme.types'

const defaultTheme = (theme: Theme): BottomTrayRendererTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1.default,
        color: theme.color.monochrome6.default,
      },
      secondary: {
        backgroundColor: theme.color.monochrome1.default,
        color: theme.color.monochrome6.default,
      },
    },
  }
}

export default defaultTheme
