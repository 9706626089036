import type { FormLabels } from '@vfuk/core-themes'

import colors from './colors'
import fontWeight from './fontWeight'

const formLabels: FormLabels = {
  color: colors.monochrome6.default,
  weight: fontWeight[2],
  state: {
    success: {
      color: colors.monochrome6.default,
      weight: fontWeight[2],
    },
    info: {
      color: colors.monochrome6.default,
      weight: fontWeight[2],
    },
    warn: {
      color: colors.monochrome6.default,
      weight: fontWeight[2],
    },
    error: {
      color: colors.error.default,
      weight: fontWeight[2],
    },
    disabled: {
      color: colors.monochrome6.disabled,
      weight: fontWeight[2],
    },
  },
  inverse: {
    color: colors.monochrome6.default,
    weight: fontWeight[2],
    state: {
      success: {
        color: colors.monochrome6.default,
        weight: fontWeight[2],
      },
      info: {
        color: colors.monochrome6.default,
        weight: fontWeight[2],
      },
      warn: {
        color: colors.monochrome6.default,
        weight: fontWeight[2],
      },
      error: {
        color: colors.error.default,
        weight: fontWeight[2],
      },
      disabled: {
        color: colors.monochrome6.disabled,
        weight: fontWeight[2],
      },
    },
  },
}

export default formLabels
