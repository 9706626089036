import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const Box = props => {
  const [clickNumber, setClickNumber] = useState(0);

  useEffect(() => {
    if (props.liveness) {
      props.liveness.update();
    }
  });

  return (
    <div
      style={{ border: 'solid', width: '200px' }}
      onClick={() => {
        setClickNumber(clickNumber + 1);
        if (props.liveness) {
          props.liveness.update();
        }
      }}
    >
      {props.name ? <h5>Παροιμίες για: {props.name}</h5> : null}
      {props.i18n ? props.i18n('title') : 'No i18n'}
      <h6>Clicked {clickNumber} times</h6>
      <span>{props.text}</span>
    </div>
  );
};

const mapStateToProps = state => ({
  name: state.authentication ? state.authentication.name : null,
  language: state.language.language
});

export default connect(mapStateToProps)(Box);
