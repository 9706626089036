import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { IconButtonStyles } from '@vfuk/core-icon-button'
import { applyFocus, elevation, spacing } from '@vfuk/core-mixins'

import type { StyledCarouselArrowProps } from './CarouselArrow.style.types'

export const CarouselArrow = styled.div<StyledCarouselArrowProps>(
  (props: StyledCarouselArrowProps): FlattenSimpleInterpolation => css`
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    ${elevation(true)};

    ${IconButtonStyles.IconButton} {
      height: 36px;
      width: 36px;
    }

    ${props.position === 'outside' &&
    css`
      ${props.direction === 'next' &&
      css`
        left: 100%;
        ${spacing('margin-left', 2)}
      `}

      ${props.direction === 'previous' &&
      css`
        right: 100%;
        ${spacing('margin-right', 2)}
      `}
    `}

    ${props.position === 'overlap' &&
    css`
      ${props.direction === 'next' &&
      css`
        left: 100%;
        transform: translate(-100%, -50%);
      `}

      ${props.direction === 'previous' &&
      css`
        right: 100%;
        transform: translate(100%, -50%);
      `}
    `}

  ${props.pagerPosition === 'outside' &&
    css`
      margin-top: -14px;
    `}

  ${applyFocus()}
  `,
)
