import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import { withTheme } from 'styled-components'

import { getDataSelector } from '@vfuk/core-base-props'

import Icon from '@vfuk/core-icon'

import { OverflowMenu, OverflowMenuItem } from '@vfuk/core-overflow-menu'

import SelectInputContent from './components/SelectInputContent'
import * as Styled from './styles/SelectInputWithMenu.style'

import type { SelectInputWithMenuProps, SelectInputWithMenuState, Option } from './SelectInputWithMenu.types'

export class SelectInputWithMenu extends PureComponent<SelectInputWithMenuProps, SelectInputWithMenuState> {
  public componentName = 'SelectInputWithMenu'

  public state: SelectInputWithMenuState = {
    isOpen: false,
  }

  componentDidMount(): void {
    if (this.buttonRef?.current && this.hasMultipleOptions) {
      this.buttonRef.current.addEventListener('blur', this.handleOnBlur)
      this.buttonRef.current.addEventListener('keydown', this.handleKeyPress)
    }
  }

  componentWillUnmount(): void {
    if (this.buttonRef?.current && this.hasMultipleOptions) {
      this.buttonRef.current.removeEventListener('keydown', this.handleKeyPress)
      this.buttonRef.current.removeEventListener('blur', this.handleOnBlur)
    }
  }

  private selfButtonRef = React.createRef<HTMLButtonElement>()

  private get buttonRef(): SelectInputWithMenuProps['domRef'] {
    return this.props.domRef ? this.props.domRef : this.selfButtonRef
  }

  private get hasMultipleOptions(): boolean {
    return this.props.options.length > 1
  }

  private get fieldName(): string {
    return this.props.name ? this.props.name : this.props.id
  }

  private get selectedOption(): Option | undefined {
    return this.props.options.find((option) => option.value === this.props.value)
  }

  private handleKeyPress = (event: KeyboardEvent): void => {
    const openMenu = event.code === 'ArrowUp' || event.code === 'ArrowDown'

    if (openMenu) {
      this.setState({ isOpen: true })
    }
  }

  private handleToggleMenu = (): void => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
  }

  private handleCloseMenu = (): void => {
    this.setState({ isOpen: false })
  }

  private handleOnChange = (newValue: string): void => {
    if (this.props.onChange) {
      this.props.onChange(newValue, this.fieldName)
    }
  }

  private handleOnBlur = (): void => {
    if (!this.state.isOpen && this.props.onBlur) this.props.onBlur(this.props.value, this.fieldName)
  }

  private handleOutsideClick = (): void => {
    this.handleCloseMenu()
    this.handleOnBlur()
  }

  public render(): ReactNode {
    if (this.hasMultipleOptions) {
      return (
        <Styled.SelectInputWithMenuWrapper
          id={`${this.props.id}-wrapper`}
          data-component-name={this.componentName}
          {...this.props.dataAttributes}
          data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        >
          <Styled.SelectInputWithMenu
            state={this.props.state}
            disabled={this.props.state === 'disabled'}
            id={this.props.id}
            onClick={this.handleToggleMenu}
            domRef={this.buttonRef}
            dataAttributes={
              this.selectedOption?.srText
                ? { ...this.props.dataAttributes, 'aria-label': this.selectedOption!.srText }
                : this.props.dataAttributes
            }
            aria-disabled={this.props.state === 'disabled'}
            aria-invalid={this.props.state === 'error'}
            aria-describedby={this.props.describedBy}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'select-input-with-menu')}
          >
            {this.selectedOption && (
              <SelectInputContent
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'selected-option')}
                text={this.selectedOption!.text}
              >
                {this.selectedOption?.children}
              </SelectInputContent>
            )}
            {!this.selectedOption && this.props.placeholder && (
              <SelectInputContent
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'selected-option-with-place-holder')}
                text={this.props.placeholder!}
              >
                {this.props?.children}
              </SelectInputContent>
            )}
          </Styled.SelectInputWithMenu>
          <Styled.IconsWrapper>
            <Styled.IconContainer
              state={this.props.state}
              open={this.state.isOpen}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, 'toggle')}
            >
              <Icon
                name='chevron-down'
                group='system'
                appearance={this.props.theme!.formFields.icon.appearance}
                inverse={this.props.theme!.formFields.icon.inverse}
                size={1}
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'icon')}
              />
            </Styled.IconContainer>
          </Styled.IconsWrapper>
          <OverflowMenu
            activeItem={this.props.value}
            isOpen={this.state.isOpen}
            triggerRef={this.buttonRef}
            onChange={this.handleOnChange}
            onClose={this.handleCloseMenu}
            onOutsideClick={this.handleOutsideClick}
            srText={this.props.placeholder || this.props.value}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'overflow-menu')}
          >
            {this.props.options.map((option) => (
              <OverflowMenuItem key={option.value} {...option} />
            ))}
          </OverflowMenu>
        </Styled.SelectInputWithMenuWrapper>
      )
    }

    return (
      <Styled.SingleOptionContainer
        id={`${this.props.id}-wrapper`}
        data-component-name={this.componentName}
        {...this.props.dataAttributes}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
      >
        {this.selectedOption && (
          <SelectInputContent
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'selected-option')}
            text={this.selectedOption!.text}
          >
            {this.selectedOption?.children}
          </SelectInputContent>
        )}
        {!this.selectedOption && this.props.placeholder && (
          <SelectInputContent
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'selected-option-with-place-holder')}
            text={this.props.placeholder!}
          >
            {this.props?.children}
          </SelectInputContent>
        )}
      </Styled.SingleOptionContainer>
    )
  }
}

export default withTheme(SelectInputWithMenu)
