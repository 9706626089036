/* eslint-disable complexity, sonarjs/cognitive-complexity, max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, applyFocus, opacity, spacing, typography } from '@vfuk/core-mixins'

import type { StyledFunctionalPickerItemProps } from './FunctionalPickerItem.style.types'

export const ChildWrapper = styled.span((): FlattenSimpleInterpolation => [])

type StyledFootnote = Pick<StyledFunctionalPickerItemProps, 'functionalPickerItemTheme'>
export const Footnote = styled.span<StyledFootnote>(
  (props: StyledFootnote): FlattenSimpleInterpolation => css`
    ${typography.paragraph(1, false)};

    &::after {
      content: attr(data-footNote);
      height: 0;
      display: grid;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: ${props.functionalPickerItemTheme.afterFontWeight};

      @media speech {
        display: none;
      }
    }
  `,
)

type TitleWrapperProps = Pick<StyledFunctionalPickerItemProps, 'orientation' | 'size' | 'justify' | 'footnote' | 'subTitle'>
export const TitleWrapper = styled.span<TitleWrapperProps>(
  (props: TitleWrapperProps): FlattenSimpleInterpolation => css`
    ${advancedSpacing('padding', [2, 4, 4])};
    text-align: center;

    &:only-child {
      ${spacing('padding', 4)};
    }

    ${props.size === 1 &&
    css`
      &:only-child {
        ${advancedSpacing('padding', [1, 2])};
      }
    `}

    ${props.footnote &&
    css`
      width: 100%;

      ${!props.subTitle &&
      props.orientation === 'horizontal' &&
      css`
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      `}
    `}

  ${props.orientation === 'horizontal' &&
    css`
      text-align: left;
      ${advancedSpacing('padding', [2, 4, 2, 2])};
    `}

  ${props.justify === 'left' &&
    props.orientation === 'vertical' &&
    css`
      text-align: left;
    `}
  `,
)

type StyledTitle = Pick<StyledFunctionalPickerItemProps, 'functionalPickerItemTheme' | 'level'>
export const Title = styled.span<StyledTitle>((props: StyledTitle): FlattenSimpleInterpolation => {
  const { titleFontWeight } = props.functionalPickerItemTheme.level[props.level!]

  return css`
    ${typography.heading(1, false)}
    font-weight: ${titleFontWeight};

    &::after {
      content: attr(data-title);
      height: 0;
      display: grid;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: ${props.functionalPickerItemTheme.afterFontWeight};

      @media speech {
        display: none;
      }
    }
  `
})

type StyledSubTitle = Pick<StyledFunctionalPickerItemProps, 'functionalPickerItemTheme'>
export const SubTitle = styled.span<StyledSubTitle>(
  (props: StyledSubTitle): FlattenSimpleInterpolation => css`
    ${typography.paragraph(1, false)};

    &::after {
      content: attr(data-subtitle);
      height: 0;
      display: grid;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: ${props.functionalPickerItemTheme.afterFontWeight};

      @media speech {
        display: none;
      }
    }
  `,
)

type StyledSubTitleWrapper = Pick<StyledFunctionalPickerItemProps, 'orientation'>
export const SubTitleWrapper = styled.span<StyledSubTitleWrapper>(
  (props: StyledSubTitleWrapper): FlattenSimpleInterpolation => css`
    ${props.orientation === 'horizontal' &&
    css`
      display: flex;
      justify-content: space-between;
    `}
  `,
)

type StyledFunctionalPickerItemWrapper = Pick<
  StyledFunctionalPickerItemProps,
  'functionalPickerItemTheme' | 'theme' | 'checked' | 'inverse' | 'state' | 'orientation' | 'size' | 'appearance'
>
export const FunctionalPickerItemWrapper = styled.label<StyledFunctionalPickerItemWrapper>(
  (props: StyledFunctionalPickerItemWrapper): FlattenSimpleInterpolation => {
    const { background, border, color, boxShadow, checked, hoverBorderColor, disabled } = props.inverse
      ? props.functionalPickerItemTheme.appearance[props.appearance].inverse
      : props.functionalPickerItemTheme.appearance[props.appearance]

    const { borderRadius } = props.functionalPickerItemTheme.size[props.size!]

    const { titleFontWeight, subTitleFontWeight, footnoteFontWeight } = props.functionalPickerItemTheme

    return css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: ${props.checked ? checked.background : background};
      color: ${props.checked ? checked.color : color};
      border: ${props.checked ? checked.border : border};
      box-shadow: ${(() => {
        if (props.state === 'unavailable') return 'none'
        return props.checked ? checked.boxShadow : boxShadow
      })()};
      text-align: center;
      height: 100%;
      border-radius: ${borderRadius};
      border-style: ${props.state === 'unavailable' ? 'dashed' : 'solid'};

      ${applyFocus()}

      ${props.orientation === 'horizontal' &&
      css`
        justify-content: flex-start;
      `}

      ${props.state !== 'disabled' &&
      css`
        &:hover {
          ${Title} {
            font-weight: ${titleFontWeight};
          }

          ${SubTitle} {
            font-weight: ${subTitleFontWeight};
          }

          ${Footnote} {
            font-weight: ${footnoteFontWeight};
          }
        }
      `}

      &:hover {
        border-color: ${props.checked ? checked.hoverBorderColor : hoverBorderColor};
      }

      ${props.checked &&
      css`
        ${Title} {
          font-weight: ${titleFontWeight};
        }

        ${SubTitle} {
          font-weight: ${subTitleFontWeight};
        }

        ${Footnote} {
          font-weight: ${footnoteFontWeight};
        }
      `}

      ${props.state === 'disabled' &&
      css`
        background: ${disabled.background};
        color: ${disabled.color};
        border: ${disabled.border};
        box-shadow: ${disabled.boxShadow};
        cursor: not-allowed;
        ${opacity(true)};

        &:hover {
          border-color: ${disabled.hoverBorderColor};
        }
      `}
    `
  },
)

type StyledPickerInput = Pick<StyledFunctionalPickerItemProps, 'state'>
export const PickerInput = styled.input<StyledPickerInput>(
  (props: StyledPickerInput): FlattenSimpleInterpolation => css`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;

    ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;
    `}
  `,
)

type StyledFunctionalPickerItemContainer = Pick<StyledFunctionalPickerItemProps, 'orientation' | 'justify' | 'theme'>
export const FunctionalPickerItemContainer = styled.span<StyledFunctionalPickerItemContainer>(
  (props: StyledFunctionalPickerItemContainer): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${applyFocus()}

    ${props.orientation === 'horizontal' &&
    props.justify === 'left' &&
    css`
      justify-content: flex-start;
    `}

  ${props.orientation === 'vertical' &&
    css`
      flex-direction: column;
      justify-content: center;
    `}

  ${props.orientation === 'vertical' &&
    props.justify === 'left' &&
    css`
      align-items: flex-start;
    `}
  `,
)
