import type { ReactElement, FC } from 'react'
import React from 'react'
import isEmpty from 'lodash/isEmpty'

import Button from '@vfuk/core-button'
import LinkWithIcon from '@vfuk/core-link-with-icon'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/CardInteractionSlot.style'

import type { CardInteractionSlotProps } from './CardInteractionSlot.types'

const CardInteractionSlot: FC<CardInteractionSlotProps> = ({
  primaryButton,
  secondaryButton,
  linkWithIcon,
  level = 1,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: CardInteractionSlotProps): ReactElement | null => {
  const componentName = 'CardInteractionSlot'

  if (isEmpty(primaryButton) && isEmpty(linkWithIcon)) {
    return null
  }

  const isFull = isEmpty(secondaryButton) && isEmpty(linkWithIcon)

  return (
    <Styled.CardInteractionSlot
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      {!isEmpty(primaryButton) && (
        <Styled.ButtonsContainer fullWidth={isFull}>
          {!isEmpty(secondaryButton) && (
            <Styled.ButtonWrapper>
              <Button
                {...secondaryButton!}
                appearance='alt1'
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'secondary-button')}
              />
            </Styled.ButtonWrapper>
          )}
          <Styled.ButtonWrapper>
            <Button
              {...primaryButton!}
              appearance={level === 2 ? 'secondary' : 'primary'}
              width={isFull ? 'full' : 'auto'}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'primary-button')}
            />
          </Styled.ButtonWrapper>
        </Styled.ButtonsContainer>
      )}
      {!isEmpty(linkWithIcon) && (
        <Styled.LinkWithIconContainer>
          <LinkWithIcon {...linkWithIcon!} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'link-with-icon')} />
        </Styled.LinkWithIconContainer>
      )}
    </Styled.CardInteractionSlot>
  )
}

export default CardInteractionSlot
