import es from './es/es'
import en from './en/en'
import it from './it/it'

const loadedNameSpaces = {
  en,
  es,
  it,
}

export default loadedNameSpaces

export const componentName = 'FunctionalCarousel'
