import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import type { FunctionalTabPanelProps } from './FunctionalTabPanel.types'
import * as Styled from './styles/FunctionalTabPanel.style'

import { TabsContext } from '../../contexts/TabsContext'
import type { TabsContextType } from '../../contexts/TabsContext.types'

class FunctionalTabPanel extends PureComponent<FunctionalTabPanelProps> {
  public static contextType = TabsContext

  public context: TabsContextType

  private hasAlreadyLoaded = false

  public static defaultProps: Partial<FunctionalTabPanelProps> = {
    isLazy: true,
  }

  private isActiveTab(): boolean {
    return this.context.selectedTabId === this.props.tabId
  }

  private hideContent(): boolean {
    return this.props.isLazy! && !this.isActiveTab() && !this.hasAlreadyLoaded
  }

  public render(): React.ReactNode {
    const isActive = this.isActiveTab()
    // If lazy then don't render if not active
    if (this.hideContent()) return null
    this.hasAlreadyLoaded = true
    return (
      <Styled.TabPanel
        role='tabpanel'
        id={`${this.props.tabId}-tab`}
        aria-labelledby={this.props.tabId}
        tabIndex={-1}
        aria-hidden={!isActive}
        isActiveTab={isActive}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
      >
        {this.props.children}
      </Styled.TabPanel>
    )
  }
}

export default FunctionalTabPanel
