import type { FC } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/NumberBadge.theme'
import * as Styled from './styles/NumberBadge.style'

import type { NumberBadgeProps } from './NumberBadge.types'

const NumberBadge: FC<NumberBadgeProps> = ({
  appearance = 'primary',
  number,
  localTheme,
  id,
  dataAttributes,
  dataSelectorPrefix,
}: NumberBadgeProps) => {
  const componentName = 'NumberBadge'
  const numberBadgeTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  const isLargeNumber = number > 999
  const isSmallNumber = number < 10
  return (
    <Styled.NumberBadge
      numberBadgeTheme={numberBadgeTheme}
      appearance={appearance}
      isSmallNumber={isSmallNumber}
      {...dataAttributes}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
    >
      <Styled.Number
        numberBadgeTheme={numberBadgeTheme}
        appearance={appearance}
        data-selector={getDataSelector(dataSelectorPrefix, 'number')}
      >
        {isLargeNumber ? '999+' : Number(number)}
      </Styled.Number>
    </Styled.NumberBadge>
  )
}

export default NumberBadge
