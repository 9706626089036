import { SET_PROPS, SAVE_VAL } from 'actions/propActions';

const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_PROPS: {
      const { comp, props } = payload;
      return {
        ...state,
        [comp]: props
      };
    }
    case SAVE_VAL: {
      const { name, boolean, number, string } = payload;
      return {
        ...state,
        [name]: string || number || boolean
      };
    }
    default:
      return state;
  }
};
