import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import ListGroup from '@vfuk/core-list-group'
import ListItem from '@vfuk/core-list-item'
import { respondTo, spacing, srOnly } from '@vfuk/core-mixins'

import type { StyledCheckboxFieldSetProps } from './CheckboxFieldSet.style.types'

export const CheckboxList = styled(ListGroup)(
  (props: StyledCheckboxFieldSetProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${spacing('gap', 3)};

    ${props.hasLabel !== false &&
    css`
      ${spacing('margin-top', 2)};
    `}

    ${props.orientation === 'horizontal' &&
    css`
      ${respondTo.md(css`
        flex-direction: row;
      `)}
    `}
  `,
)

export const Legend = styled.legend(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)

type CheckboxWrapperProps = Pick<StyledCheckboxFieldSetProps, 'orientation'>
export const ChildrenCheckboxes = styled(ListGroup)(
  (props: CheckboxWrapperProps): FlattenSimpleInterpolation => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${spacing('gap', 3)};
    ${spacing('margin-top', 3)};

    ${props.orientation === 'vertical' &&
    css`
      ${spacing('padding-left', 6)};
    `}

    ${props.orientation === 'horizontal' &&
    css`
      ${respondTo.md(css`
        flex-direction: row;
      `)}
    `}
  `,
)

export const ChildCheckboxWrapper = styled(ListItem)((): FlattenSimpleInterpolation => css``)
