import type { Theme } from '@vfuk/core-themes'

import type { ImagePickerTheme } from './ImagePicker.theme.types'

const imagePickerTheme = (theme: Theme): ImagePickerTheme => {
  return {
    appearance: {
      primary: {
        imgBorderRadius: theme.border.radius[2],
        inverse: {
          imgBorderRadius: theme.border.radius[2],
        },
      },
    },
  }
}

export default imagePickerTheme
