import type { FC } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'
import Icon from '@vfuk/core-icon'
import LoadingSpinner from '@vfuk/core-loading-spinner'
import type { Appearance } from '@vfuk/core-button'
import { buttonTheme } from '@vfuk/core-button'

import type { SmallButtonProps } from './SmallButton.types'

import * as Styled from './styles/SmallButton.style'

const SmallButton: FC<SmallButtonProps> = ({
  appearance = 'primary',
  loading = false,
  htmlAttributes = { target: undefined, type: undefined },
  inverse = false,
  state,
  href,
  id,
  text,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  dataAttributes,
  customRouterProps,
  dataSelectorPrefix,
  srText,
  localTheme,
}) => {
  const componentName = 'SmallButton'
  const smallButtonTheme = useLocalTheme(componentName, buttonTheme, localTheme)

  let themeAppearance = smallButtonTheme.appearance[appearance]
  if (inverse) themeAppearance = themeAppearance.inverse as Appearance

  const { loadingSpinnerAppearance, inverseIcon } = themeAppearance

  const isDisabled = state === 'disabled' || loading!

  const { target, type } = htmlAttributes

  return (
    <Styled.SmallButton
      appearance={appearance!}
      smallButtonTheme={smallButtonTheme}
      label={srText ?? text}
      disabled={isDisabled}
      href={!isDisabled ? href : undefined}
      componentName={componentName}
      dataAttributes={dataAttributes}
      {...{ onClick, onMouseDown, onMouseUp, onTouchStart, onTouchEnd }} // interactions
      {...{ type, id, target, onTouchEnd }} // html attributes
      {...{ state, loading, inverse, appearance, customRouterProps }} // props
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
    >
      {loading || state === 'selected' ? (
        <>
          <Styled.IconContainer>
            {loading && (
              <LoadingSpinner
                appearance={loadingSpinnerAppearance}
                inverse={inverseIcon}
                size={1}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'loading-spinner')}
              />
            )}
            {state === 'selected' && !loading && (
              <Icon
                inverse
                name='tick'
                group='system'
                size={2}
                isResponsive={false}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'tick')}
              />
            )}
          </Styled.IconContainer>
          <Styled.HiddenText data-selector={getDataSelector(dataSelectorPrefix, 'hidden-text')}>{text}</Styled.HiddenText>
        </>
      ) : (
        text
      )}
    </Styled.SmallButton>
  )
}

export default SmallButton
