import type { Theme } from '@vfuk/core-themes'

import type { FilterListTheme } from './FilterList.theme.types'

const filterListLocal = (theme: Theme): FilterListTheme => ({
  appearance: {
    primary: {
      backgroundColor: theme.color.monochrome1.default,
      hover: {
        backgroundColor: theme.color.monochrome5.hover,
        selectedBackgroundColor: theme.color.secondary1.hover,
      },
      inverse: {
        backgroundColor: theme.inverseColor.monochrome1.default,
        hover: {
          backgroundColor: theme.inverseColor.monochrome5.hover,
          selectedBackgroundColor: theme.inverseColor.secondary1.hover,
        },
        borderColor: theme.inverseColor.monochrome6.default,
      },
    },
    secondary: {
      backgroundColor: theme.color.monochrome2.default,
      hover: {
        backgroundColor: theme.color.monochrome5.hover,
        selectedBackgroundColor: theme.color.secondary1.hover,
      },
      inverse: {
        backgroundColor: theme.inverseColor.monochrome2.default,
        hover: {
          backgroundColor: theme.inverseColor.monochrome5.hover,
          selectedBackgroundColor: theme.inverseColor.secondary1.hover,
        },
        borderColor: theme.inverseColor.monochrome6.default,
      },
    },
  },
})

export default filterListLocal
