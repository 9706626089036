import type { CardListTheme } from './CardList.theme.types'

const defaultTheme = (): CardListTheme => {
  return {
    cardsSection: {
      size: {
        1: {
          columns: 4,
        },
        2: {
          columns: 6,
        },
        3: {
          columns: 8,
        },
        4: {
          columns: 10,
        },
        5: {
          columns: 12,
        },
      },
      gridSpacing: 4,
    },
  }
}

export default defaultTheme
