import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/ListItem.style'

import type { ListItemProps } from './ListItem.types'

const ListItem: FC<ListItemProps> = ({
  className,
  role,
  children,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  href,
  customRouterProps,
  id,
  onMouseEnter,
  onMouseLeave,
  srText,
  dataAttributes,
  htmlAttributes,
  interactionRef,
  disabled = false,
  listType = 'unordered',
  listStyle = 'none',
  dataSelectorPrefix,
}: ListItemProps): ReactElement => {
  const componentName = 'ListItem'

  return (
    <Styled.ListItem
      id={id}
      className={className}
      role={role}
      aria-disabled={disabled && !!onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      listType={listType}
      listStyle={listStyle}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      {onClick || href || customRouterProps ? (
        <Styled.InteractionListItem
          onClick={onClick}
          href={href}
          customRouterProps={customRouterProps}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          label={srText}
          domRef={interactionRef}
          disabled={disabled}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'interaction')}
          {...dataAttributes}
          {...htmlAttributes}
        >
          {children}
        </Styled.InteractionListItem>
      ) : (
        children
      )}
    </Styled.ListItem>
  )
}

export default ListItem
