import type { ReactElement, FC } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/Block.theme'

import * as Styled from './styles/Block.style'

import calculatePaddingLevel from './helpers/calculatePaddingLevel'

import type { BlockProps } from './Block.types'

const Block: FC<BlockProps> = ({
  className,
  children,
  appearance = 'primary',
  paddingLevel = 1,
  removeBottomPadding = false,
  localTheme,
  id,
  dataSelectorPrefix,
  dataAttributes,
  componentName = 'Block',
}: BlockProps): ReactElement => {
  const blockTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  return (
    <Styled.Block
      className={className}
      appearance={appearance}
      paddingLevel={calculatePaddingLevel(paddingLevel)}
      removeBottomPadding={removeBottomPadding}
      blockTheme={blockTheme}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
    </Styled.Block>
  )
}

export default Block
