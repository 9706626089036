import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import Icon from '@vfuk/core-icon'
import { withLocalTheme } from '@vfuk/core-themes'

import * as Styled from './styles/Checkbox.style'
import defaultTheme from './themes/CheckBox.theme'

import type { CheckboxProps } from './Checkbox.types'
import type { CheckboxTheme } from './themes/CheckBox.theme.types'

export class Checkbox extends PureComponent<CheckboxProps> {
  public static defaultProps: Partial<CheckboxProps> = {
    appearance: 'primary',
  }

  public componentName = 'Checkbox'

  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.state !== 'disabled' && this.props.onChange) {
      this.props.onChange(event)
    }
  }

  public render(): ReactNode {
    const { iconGroup, iconName, indeterminateIconName } = this.props.localTheme!

    return (
      <Styled.CheckboxLabel
        appearance={this.props.appearance!}
        checkboxTheme={this.props.localTheme!}
        checked={this.props.checked}
        state={this.props.state}
        id={this.props.id}
        inverse={this.props.inverse}
        {...this.props.dataAttributes}
        data-component-name={this.componentName}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
      >
        <input
          type='checkbox'
          aria-disabled={this.props.state === 'disabled'}
          aria-describedby={this.props.describedBy}
          aria-invalid={this.props.state === 'error'}
          required={this.props.required}
          name={this.props.name}
          onChange={this.handleOnChange}
          onBlur={this.props.onBlur}
          checked={this.props.state === 'disabled' ? this.props.checked : this.props.indeterminate || this.props.checked}
          value={this.props.value}
          {...this.props.dataAttributes}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'input')}
        />
        <Styled.Checkbox
          appearance={this.props.appearance!}
          checkboxTheme={this.props.localTheme!}
          indeterminate={!!this.props.indeterminate}
          state={this.props.state}
          inverse={this.props.inverse}
        >
          <Icon
            group={iconGroup}
            inverse
            name={this.props.indeterminate ? indeterminateIconName : iconName}
            size={1}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'icon')}
          />
        </Styled.Checkbox>
        {this.props.children}
      </Styled.CheckboxLabel>
    )
  }
}

export default withLocalTheme<CheckboxProps, CheckboxTheme>(Checkbox, defaultTheme)
