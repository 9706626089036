import type { FC, ReactElement } from 'react'
import React from 'react'

import Icon from '@vfuk/core-icon'
import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/InteractiveIcon.theme'
import * as Styled from './styles/InteractiveIcon.style'

import type { InteractiveIconProps } from './InteractiveIcon.types'

const InteractiveIcon: FC<InteractiveIconProps> = ({
  appearance = 'primary',
  size = 4,
  htmlAttributes = {},
  isResponsive = true,
  inverse = false,
  group = 'system',
  srText,
  dataAttributes,
  state,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchEnd,
  onTouchStart,
  href,
  id,
  customRouterProps,
  numberBadge,
  localTheme,
  name,
  dataSelectorPrefix,
}: InteractiveIconProps): ReactElement => {
  const componentName = 'InteractiveIcon'
  const interactiveIconTheme = useLocalTheme(componentName, defaultTheme, localTheme)
  const { iconAppearance } = interactiveIconTheme.appearance[appearance]

  return (
    <Styled.InteractiveIcon
      label={srText}
      target={htmlAttributes!.target}
      type={htmlAttributes!.type}
      disabled={state === 'disabled'}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      href={href}
      id={id}
      customRouterProps={customRouterProps}
      componentName={componentName}
      dataAttributes={dataAttributes}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
    >
      <Icon
        name={name}
        appearance={iconAppearance}
        inverse={inverse}
        size={size}
        isResponsive={isResponsive!}
        group={group!}
        numberBadge={numberBadge}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, name)}
      />
    </Styled.InteractiveIcon>
  )
}

export default InteractiveIcon
