import type { Theme } from '@vfuk/core-themes'

import type { CheckboxTheme } from './CheckBox.theme.types'

const checkBoxLocal = (theme: Theme): CheckboxTheme => {
  return {
    iconGroup: 'system',
    iconName: 'tick',
    indeterminateIconName: 'minus',
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome2.default,
        borderColor: theme.color.monochrome4.default,
        state: {
          disabled: {
            checkedBackgroundColor: theme.color.monochrome3.default,
            iconColor: theme.color.monochrome4.default,
          },
        },
      },
    },
  }
}

export default checkBoxLocal
