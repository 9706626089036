/* eslint-disable complexity, no-nested-ternary, @vfuk/rules/js-no-complex-ternary */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { IconStyles } from '@vfuk/core-icon'
import { OverlayStyles } from '@vfuk/core-overlay'
import { backgroundImage, elevation, iconAppearance, spacing } from '@vfuk/core-mixins'

import type { StyledContainerProps } from './Container.style.types'

export const Container = styled(Interaction)((props: StyledContainerProps): FlattenSimpleInterpolation => {
  const appearanceFromProp = props.containerTheme.appearance[props.appearance!]

  return css`
    height: 100%;
    color: ${appearanceFromProp.color};
    background: ${appearanceFromProp.backgroundColor};
    box-sizing: border-box;
    background-clip: padding-box;
    border-radius: ${props.theme.border.radius[2]};
    position: relative;

    ${elevation(props.dropShadow)}

    ${props.state &&
    css`
      &::after {
        content: '';
        z-index: ${props.zIndex};
        border-style: ${props.theme.border.style[1]};
        border-width: ${props.theme.border.width[2]};
        border-color: ${appearanceFromProp.state[props.state].borderColor};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: ${props.theme.border.radius[2]};
        pointer-events: none;
      }
    `}

    ${props.highlight &&
    css`
      &::after {
        content: '';
        z-index: ${props.zIndex};
        border-style: ${props.theme.border.style[1]};
        border-width: ${props.theme.border.width[
          appearanceFromProp.highlight[props.highlight.appearance].level[props.highlight.level].borderWidth
        ]};
        border-color: ${appearanceFromProp.highlight[props.highlight.appearance].borderColor};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: ${props.theme.border.radius[2]};
        pointer-events: none;
      }
    `}

    ${props.backgroundImage &&
    css`
      color: ${props.theme.color.monochrome1.default};

      ${IconStyles.Icon} {
        * {
          ${iconAppearance(props.theme.color.monochrome1.default)};
        }
      }

      ${backgroundImage(props.backgroundImage)}
    `}

    ${!props.allowOverflow &&
    css`
      overflow: hidden;
    `}

    ${OverlayStyles.Overlay} {
      border-radius: ${props.theme.border.radius[2]};
    }

    ${props.paddingLevel && spacing('padding', props.paddingLevel)}
  `
})

type ContainerContentProps = Pick<StyledContainerProps, 'zIndex'>
export const ContainerContent = styled.div((props: ContainerContentProps): FlattenSimpleInterpolation => {
  return css`
    height: 100%;
    position: relative;
    z-index: ${props.zIndex! + 1};
  `
})
