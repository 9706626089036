import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/TextAreaInput.style'
import type { TextAreaInputProps } from './TextAreaInput.types'

class TextAreaInput extends PureComponent<TextAreaInputProps> {
  private handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (this.props.state !== 'disabled') {
      if (this.props.beforeChange) {
        event.target.value = this.props.beforeChange(event.target.value)
      }
      this.props.onChange(event)
    }
  }

  private handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>): void => {
    if (this.props.state !== 'disabled' && this.props.onBlur) {
      this.props.onBlur(event)
    }
  }

  private handleOnKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (this.props.state !== 'disabled' && this.props.onKeyDown) {
      this.props.onKeyDown(event)
    }
  }

  public componentName = 'TextAreaInput'

  public render(): ReactNode {
    return (
      <Styled.TextAreaInputWrapper
        id={`${this.props.id}-text-area-input`}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
        {...this.props.dataAttributes}
      >
        <Styled.TextAreaInput
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          rows={this.props.rows}
          placeholder={this.props.placeholder}
          required={this.props.required}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onClick={this.props.onClick}
          maxLength={this.props.maxLength}
          onKeyDown={this.handleOnKeyDown}
          state={this.props.state}
          ref={this.props.domRef}
          aria-disabled={this.props.state === 'disabled'}
          aria-invalid={this.props.state === 'error'}
          aria-describedby={this.props.describedBy}
          {...this.props.dataAttributes}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'text-input')}
        />
      </Styled.TextAreaInputWrapper>
    )
  }
}

export default TextAreaInput
