import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import Radio from '@vfuk/core-radio'

import * as Styled from './styles/RadioButtonListFieldSet.style'

import type { RadioButtonListFieldSetProps } from './RadioButtonListFieldSet.types'

export default class RadioButtonListFieldSet extends PureComponent<RadioButtonListFieldSetProps> {
  private handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (this.props.radioButtons.onBlur) {
      this.props.radioButtons.onBlur(event)
    }
  }

  public render(): ReactNode {
    return (
      <fieldset id={this.props.radioButtons.id} data-selector={getDataSelector(this.props.dataSelectorPrefix)}>
        <Styled.Legend data-selector={getDataSelector(this.props.dataSelectorPrefix, 'legend')}>{this.props.label}</Styled.Legend>
        <Styled.RadioButtonList
          hasLabel={this.props.showLabel}
          orientation={this.props.orientation}
          onBlur={this.handleOnBlur}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, 'radio-button-list')}
        >
          {this.props.radioButtons.items.map((radio, index: number) => {
            return (
              <Radio
                key={index}
                id={`${this.props.radioButtons.id}-${index}`}
                groupName={this.props.radioButtons.groupName}
                onChange={this.props.radioButtons.onChange}
                required={this.props.radioButtons.required}
                value={radio.value}
                state={radio.state}
                dataAttributes={radio.dataAttributes}
                checked={radio.value === this.props.radioButtons.checked}
                describedBy={this.props.describedBy}
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, this.props.radioButtons.groupName)}
              >
                {radio.label}
              </Radio>
            )
          })}
        </Styled.RadioButtonList>
      </fieldset>
    )
  }
}
