import type { FC } from 'react'
import React, { memo } from 'react'

import InteractiveIcon from '@vfuk/core-interactive-icon'
import { getDataSelector } from '@vfuk/core-base-props'

import { useTranslation } from 'react-i18next'

import * as Styled from './styles/CloseButton.style'

import type { CloseButtonProps } from './CloseButton.types'

const CloseButton: FC<CloseButtonProps> = ({ srName, onClick, inverse, isWithinHeader = false, dataSelectorPrefix }) => {
  const { t } = useTranslation()

  return (
    <Styled.CloseButton isWithinHeader={isWithinHeader}>
      <InteractiveIcon
        name='close'
        srText={`${t('Close')} ${srName}`}
        inverse={inverse}
        onClick={onClick}
        size={5}
        isResponsive={false}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      />
    </Styled.CloseButton>
  )
}

export default memo(CloseButton)
