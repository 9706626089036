import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import defaultTheme from './themes/Span.theme'
import * as Styled from './styles/Span.style'

import type { SpanProps } from './Span.types'

const Span: FC<SpanProps> = ({
  appearance = 'primary',
  inverse = false,
  display = 'inline',
  weight,
  className,
  children,
  dataAttributes,
  dataSelectorPrefix,
  id,
  localTheme,
}: SpanProps): ReactElement => {
  const componentName = 'Span'
  const spanTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  return (
    <Styled.Span
      spanTheme={spanTheme}
      id={id}
      as={display === 'block' ? 'div' : 'span'}
      appearance={appearance!}
      inverse={inverse!}
      weight={weight}
      className={className}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
    >
      {children}
    </Styled.Span>
  )
}

export default Span
