import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme, SpacingKeys } from '@vfuk/core-themes'

import type { MixinAdvancedSpacing, AdvancedSpacingValues, Properties } from './advancedSpacing.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin(
  (theme: Theme, property: Properties, values: AdvancedSpacingValues): FlattenSimpleInterpolation => css`
    ${property}: ${values.map((value) => {
      if (value === 'auto') {
        return `${value} `
      }
      if (value && value < 0) {
        return `-${theme.spacing.responsive[Math.abs(value) as SpacingKeys].sm}px `
      }
      return `${theme.spacing.responsive[value as SpacingKeys].sm}px `
    })};

    ${respondTo.md(css`
      ${property}: ${values.map((value) => {
        if (value === 'auto') {
          return `${value} `
        }
        if (value && value < 0) {
          return `-${theme.spacing.responsive[Math.abs(value) as SpacingKeys].md}px `
        }
        return `${theme.spacing.responsive[value as SpacingKeys].md}px `
      })};
    `)}

    ${respondTo.lg(css`
      ${property}: ${values.map((value) => {
        if (value === 'auto') {
          return `${value} `
        }
        if (value && value < 0) {
          return `-${theme.spacing.responsive[Math.abs(value) as SpacingKeys].lg}px `
        }
        return `${theme.spacing.responsive[value as SpacingKeys].lg}px `
      })};
    `)}

    ${respondTo.xl(css`
      ${property}: ${values.map((value) => {
        if (value === 'auto') {
          return `${value} `
        }
        if (value && value < 0) {
          return `-${theme.spacing.responsive[Math.abs(value) as SpacingKeys].xl}px `
        }
        return `${theme.spacing.responsive[value as SpacingKeys].xl}px `
      })};
    `)}
  `,
) as MixinAdvancedSpacing
