import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import Animate from '@vfuk/core-animate'
import IconButton from '@vfuk/core-icon-button'
import type { InteractiveIconProps } from '@vfuk/core-interactive-icon'
import InteractiveIcon from '@vfuk/core-interactive-icon'
import type { TextInputProps } from '@vfuk/core-text-input'
import TextInput from '@vfuk/core-text-input'

import * as Styled from './styles/SearchTextInput.style'

import type { SearchTextInputProps } from './SearchTextInput.types'

export default class SearchTextInput extends PureComponent<SearchTextInputProps> {
  private get showClearButton(): boolean {
    return !!(this.props.textInput.value && this.props.textInput.value.length)
  }

  private get clearButtonState(): InteractiveIconProps['state'] {
    if (this.props.state === 'disabled' || this.props.state === 'loading') return 'disabled'
    return undefined
  }

  public render(): ReactNode {
    const { closeIconAppearance, searchIconAppearance, inverseCloseIcon, inverseSearchIcon } =
      this.props.searchInputTheme.appearance[this.props.appearance!]

    return (
      <Styled.SearchWrapper data-selector={getDataSelector(this.props.dataSelectorPrefix)}>
        <Styled.SearchInput>
          <Styled.Input>
            <TextInput
              id={this.props.textInput.id}
              name={this.props.textInput.name}
              placeholder={this.props.textInput.placeholder}
              required={this.props.textInput.required}
              dataAttributes={this.props.textInput.dataAttributes}
              beforeChange={this.props.textInput.beforeChange}
              autoComplete={this.props.textInput.autoComplete}
              autoCapitalize={this.props.textInput.autoCapitalize}
              state={this.props.state as TextInputProps['state']}
              type='search'
              onChange={this.props.textInput.onChange}
              value={this.props.textInput.value}
              describedBy={this.props.describedBy}
              domRef={this.props.textInput.domRef}
              dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, this.props.textInput.name)}
            />
          </Styled.Input>
          <Styled.InteractionIcons>
            <Animate
              show={this.showClearButton}
              enter={{
                animations: ['fadeIn'],
                duration: 500,
              }}
              exit={{
                animations: ['fadeOut'],
              }}
            >
              <InteractiveIcon
                name='close'
                appearance={closeIconAppearance}
                inverse={inverseCloseIcon}
                srText={`${this.props.t!('Clear')} ${this.props.label.toLowerCase()}`}
                size={2}
                onClick={this.props.onClear}
                state={this.clearButtonState}
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'close')}
              />
            </Animate>
          </Styled.InteractionIcons>
        </Styled.SearchInput>
        <Styled.SearchIcon
          size={4}
          hasState={this.props.state === 'error' || this.props.state === 'success'}
          data-selector={getDataSelector(this.props.dataSelectorPrefix, this.props.state)}
        >
          <IconButton
            id={`${this.props.textInput.id}-trigger`}
            appearance={searchIconAppearance}
            inverse={inverseSearchIcon}
            loading={this.props.state === 'loading'}
            srText={this.props.searchIconButton?.srText ? this.props.searchIconButton.srText : this.props.t!('Search')}
            onClick={this.props.handleSubmit}
            size={4}
            icon={{ name: 'search' }}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'search')}
          />
        </Styled.SearchIcon>
      </Styled.SearchWrapper>
    )
  }
}
