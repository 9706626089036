import type { FC } from 'react'
import React from 'react'

import Container from '@vfuk/core-container'
import Heading from '@vfuk/core-heading'
import Button from '@vfuk/core-button'
import Icon from '@vfuk/core-icon'
import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'

import InteractiveIcon from '@vfuk/core-interactive-icon'

import type { StateNotificationProps } from './StateNotification.types'

import defaultTheme from './themes/StateNotification.theme'

import * as Styled from './styles/StateNotification.style'

const StateNotification: FC<StateNotificationProps> = ({
  state,
  appearance = 'primary',
  button,
  children,
  heading,
  closeButton,
  srAlert = true,
  id,
  dataSelectorPrefix,
  dataAttributes,
  localTheme,
}) => {
  const componentName = 'StateNotification'

  const stateNotificationTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const { containerAppearance, inverseHeading, buttonAppearance, iconBackgroundState } = stateNotificationTheme.appearance[appearance]
  const getIconName = (iconState: StateNotificationProps['state']): string => {
    return `${iconState}-inverse`
  }

  return (
    <Container
      appearance={containerAppearance}
      state={state}
      allowOverflow={false}
      paddingLevel={0}
      id={id}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName}
      dataAttributes={dataAttributes}
    >
      <Styled.StateNotification aria-live={srAlert ? 'assertive' : 'off'} aria-atomic={srAlert}>
        <Styled.IconWrapper state={state} iconBackground={iconBackgroundState}>
          <Icon name={getIconName(state)} group='state' size={3} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'icon')} />
        </Styled.IconWrapper>
        <Styled.ContentWrapper>
          {heading?.text && (
            <Styled.HeadingWrapper>
              <Heading
                level={heading?.level || 2}
                text={heading!.text}
                size={1}
                weight={3}
                noMargin
                inverse={inverseHeading}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'heading')}
              />
            </Styled.HeadingWrapper>
          )}
          {children}
          {button && (
            <Styled.ButtonWrapper>
              <Button appearance={buttonAppearance} {...button!} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'button')} />
            </Styled.ButtonWrapper>
          )}
        </Styled.ContentWrapper>
        {closeButton && (
          <Styled.CloseButton>
            <InteractiveIcon
              srText={closeButton!.srText}
              name='close'
              size={2}
              onClick={closeButton!.onClick}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'close-button')}
            />
          </Styled.CloseButton>
        )}
      </Styled.StateNotification>
    </Container>
  )
}

export default StateNotification
