import type { ReactNode } from 'react'
import React, { PureComponent, Fragment } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import includes from 'lodash/includes'

import TextInput from '@vfuk/core-text-input'

import { withTranslation } from 'react-i18next'

import * as Styled from './styles/GroupedTextInput.style'

import type { GroupedTextInputProps, DirectionKeys, DomRefProps } from './GroupedTextInput.types'

export class GroupedTextInput extends PureComponent<GroupedTextInputProps> {
  public componentName = 'GroupedTextInput'

  public static defaultProps: Partial<GroupedTextInputProps> = {
    type: 'text',
  }

  // Handles where the focus is between the inputs
  private handleFocus = (index: number, direction: DirectionKeys): void => {
    const nextIndex = direction === 'left' ? index - 1 : index + 1

    if (this.props.values.length !== nextIndex) {
      this.goToInputAtIndex(nextIndex)
    }
  }

  private getTextInputId(inputIndex: number): string {
    return `${this.props.id}-${inputIndex}`
  }

  private goToInputAtIndex = (index: number): void => {
    const input = document.getElementById(this.getTextInputId(index)) as HTMLInputElement
    if (input) {
      input.select()
    }
  }

  // Handles when there has been a change to the input
  private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, inputIndex: number): void => {
    const newValues = [...this.props.values]

    if (event.target.value.length === this.props.textInputs.maxLength) {
      this.handleFocus(inputIndex, 'right')
    }

    if (this.props.onChange) {
      newValues[inputIndex] = event.target.value
      this.props.onChange(newValues)
    }
  }

  private handleOnBlur = (): void => {
    // Has to be in a setTimeout to ensure the focus is in the correct place
    setTimeout(() => {
      // Check if any of the inputs are currently focused
      const activeIndex = this.props.values.findIndex((value, index) => {
        const input = document.getElementById(this.getTextInputId(index)) as HTMLInputElement
        return document.activeElement === input
      })

      // If none are focused and there is a blur function then call it
      if (this.props.onBlur && activeIndex === -1) {
        this.props.onBlur(this.props.values)
      }
    })
  }

  private handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, inputIndex: number): void => {
    if (this.props.textInputs.onKeyDown) this.props.textInputs.onKeyDown(event)

    if (event.key !== 'Backspace') return
    // If it's the first input, do nothing
    if (inputIndex === 0) return
    // If the input isn't empty, backspace as normal
    if (this.props.values[inputIndex].length > 0) return

    event.preventDefault()
    this.handleFocus(inputIndex, 'left')
  }

  private handleDomRefOnIndex = (index: number): DomRefProps | undefined => {
    return this.props.textInputs.domRefs?.find((refObject) => refObject.index === index)
  }

  private getScreenReaderLabel(inputIndex: number): string {
    if (this.props.srLabels && this.props.srLabels[inputIndex]) {
      return this.props.srLabels[inputIndex]
    }

    return this.props.t!('Iteration', { iterator: inputIndex + 1, array: this.props.values.length })
  }

  private getLabel(inputIndex: number): string | null {
    return (this.props.labels && this.props.labels[inputIndex]) || null
  }

  private get name(): string {
    return this.props.name || this.props.id
  }

  public render(): ReactNode {
    return (
      <fieldset
        id={`${this.props.id}-fieldset`}
        onBlur={this.handleOnBlur}
        {...this.props.dataAttributes}
        data-component-name={this.componentName}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
      >
        <Styled.Legend data-selector={getDataSelector(this.props.dataSelectorPrefix, 'legend')}>{this.name}</Styled.Legend>
        <Styled.TextInputContainer>
          {this.props.values.map((value, inputIndex) => {
            const showState = this.props.showStateOn && includes(this.props.showStateOn, inputIndex)
            const textInputId = this.getTextInputId(inputIndex)
            return (
              <Fragment key={inputIndex}>
                <Styled.TextInputWrapper>
                  <Styled.TextInputLabelAccessibility
                    htmlFor={textInputId}
                    showLabel={!!this.getLabel(inputIndex)}
                    data-selector={getDataSelector(this.props.dataSelectorPrefix, `label-${inputIndex}`)}
                  >
                    {this.getLabel(inputIndex) || this.getScreenReaderLabel(inputIndex)}
                  </Styled.TextInputLabelAccessibility>
                  <TextInput
                    id={textInputId}
                    value={value}
                    state={showState ? this.props.state : undefined}
                    describedBy={this.props.describedBy}
                    required={this.props.required}
                    pattern={this.props.textInputs.pattern}
                    placeholder={this.props.placeholder}
                    name={`${this.name}-${inputIndex}`}
                    maxLength={this.props.textInputs.maxLength}
                    type={this.props.type}
                    domRef={this.handleDomRefOnIndex(inputIndex)?.domRef}
                    onKeyDown={(event): void => {
                      this.handleOnKeyDown(event, inputIndex)
                    }}
                    onChange={(event): void => {
                      this.handleOnChange(event, inputIndex)
                    }}
                    dataAttributes={this.props.textInputs.dataAttributes}
                    autoComplete={this.props.textInputs.autoComplete}
                    autoCapitalize={this.props.textInputs.autoCapitalize}
                    beforeChange={this.props.textInputs.beforeChange}
                    dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, `text-input-${inputIndex}`)}
                  />
                </Styled.TextInputWrapper>
              </Fragment>
            )
          })}
        </Styled.TextInputContainer>
      </fieldset>
    )
  }
}

export default withTranslation()(GroupedTextInput)
