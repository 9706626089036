import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

import type { StyledIconSnippetProps } from './IconSnippet.style.types'

export const IconSnippetContainer = styled.div<StyledIconSnippetProps>(
  (props): FlattenSimpleInterpolation => css`
    display: flex;

    > span {
      ${spacing('margin-right', 3)}
    }

    ${props.justify === 'center' &&
    css`
      flex-direction: column;
      margin: 0 auto;

      > span {
        margin: auto;
        ${spacing('margin-bottom', 3)}
      }
    `}
  `,
)
