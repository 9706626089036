import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import FieldWrapper from '@vfuk/core-field-wrapper'
import ColorSelect from '@vfuk/core-color-select'

import { getDataSelector } from '@vfuk/core-base-props'

import type { ColorSelectWithLabelProps, GetStateReturn } from './ColorSelectWithLabel.types'

class ColorSelectWithLabel extends PureComponent<ColorSelectWithLabelProps> {
  public componentName = 'ColorSelectWithLabel'

  getState = <T extends 'fieldWrapper' | 'colorSelect'>(type: T): GetStateReturn<T> | undefined => {
    if (type === 'fieldWrapper') return this.props.state as GetStateReturn<T>
    if (type === 'colorSelect') return this.props.state !== 'info' ? (this.props.state as GetStateReturn<T>) : undefined
  }

  public render(): ReactNode {
    return (
      <FieldWrapper
        {...this.props.fieldWrapper}
        state={this.getState('fieldWrapper')}
        formElementId={this.props.colorSelect.id}
        renderAsLabel={false}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
        componentName={this.componentName}
      >
        <ColorSelect
          {...this.props.colorSelect}
          state={this.getState('colorSelect')}
          dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'color-select')}
        />
      </FieldWrapper>
    )
  }
}

export default ColorSelectWithLabel
