import type { ModalRendererProps, Size } from '../../ModalRenderer.types'

function calculateSize(size: ModalRendererProps['size']): Size {
  switch (size) {
    case 2:
      return {
        sm: '100%',
        md: '100%',
        lg: '640px',
        xl: '640px',
      }
    case 3:
      return {
        sm: '100%',
        md: '100%',
        lg: '832px',
        xl: '832px',
      }
    case 4:
      return {
        sm: '100%',
        md: '100%',
        lg: '100%',
        xl: '1024px',
      }
    case 5:
      return {
        sm: '100%',
        md: '100%',
        lg: '100%',
        xl: '1232px',
      }
    default:
      return {
        sm: '100%',
        md: '400px',
        lg: '400px',
        xl: '400px',
      }
  }
}

export default calculateSize
