import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import { withTheme } from 'styled-components'

import Icon from '@vfuk/core-icon'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/CollapsibleContainerHeader.style'

import type { CollapsibleContainerHeaderProps } from './CollapsibleContainerHeader.types'

import { CollapsibleContainerContext } from '../../contexts/CollapsibleContainerContext'
import type { CollapsibleContainerContextType } from '../../contexts/CollapsibleContainerContext.types'

import localThemes from '../../themes/CollapsibleContainer.theme'

export class CollapsibleContainerHeader extends PureComponent<CollapsibleContainerHeaderProps> {
  public static defaultProps: Partial<CollapsibleContainerHeaderProps> = {
    size: 2,
  }

  public static contextType = CollapsibleContainerContext

  public context: CollapsibleContainerContextType

  private get iconName(): string {
    if (this.context.icons) {
      return this.context.icons[this.context.isActive ? 'activeIcon' : 'inactiveIcon']
    }

    return 'chevron-down'
  }

  public render(): ReactNode {
    const appearance = localThemes(this.props.theme!).appearance[this.context.appearance!]

    return (
      <Styled.CollapsibleContainerHeader
        onClick={this.context.toggle}
        appearance={this.context.appearance}
        isInverse={this.context.isInverse}
        size={this.props.size}
        width={this.context.width}
        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix)}
      >
        <Styled.Children>{this.props.children}</Styled.Children>
        <Styled.IconContainer isActive={this.context.isActive && !this.context.icons}>
          <Icon
            appearance={this.context.isInverse ? appearance.inverse.iconAppearance : appearance.iconAppearance}
            inverse={this.context.isInverse ? appearance.inverse.inverseIcon : appearance.inverseIcon}
            name={this.iconName}
            size={3}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, this.context.isActive ? 'active-icon' : 'inactive-icon')}
          />
        </Styled.IconContainer>
      </Styled.CollapsibleContainerHeader>
    )
  }
}

export default withTheme(CollapsibleContainerHeader)
