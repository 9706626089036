import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, applyFocus, responsiveSizing, spacing, typography } from '@vfuk/core-mixins'

import type { StyledTextAreaInputProps } from './TextAreaInput.style.types'

export const TextAreaInputWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
  `,
)

export const TextAreaInput = styled.textarea<StyledTextAreaInputProps>(
  (props: StyledTextAreaInputProps): FlattenSimpleInterpolation => css`
    color: ${props.theme.formFields.color};
    transition: 0.4s box-shadow ease;
    border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
    border-radius: ${props.theme.formFields.border.radius};
    background-color: ${props.theme.formFields.backgroundColor};
    width: 100%;
    vertical-align: top;

    ${responsiveSizing('min-height', 8)};
    ${advancedSpacing('padding', [2, 3])}
    ${typography.heading(1, false)}

  ${props.state &&
    css`
      border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style}
        ${props.theme.formFields.state[props.state].border.color};
      ${spacing('padding-right', 7)}
    `}

  ${props.state === 'disabled' &&
    css`
      cursor: not-allowed;
      background-color: ${props.theme.formFields.state.disabled.backgroundColor};
      color: ${props.theme.formFields.state.disabled.color};
      &:focus {
        caret-color: transparent;
      }
    `}

  ${applyFocus()}
  `,
)
