import {
  LOGGED_IN,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_HANDLER,
  LOGOUT_MESSAGE,
  CLEAR_LOGOUT_MESSAGE,
  SHOW_INACTIVITY_WARNING,
  HIDE_INACTIVITY_WARNING,
  SET_IS_AUTH_APP,
  SET_AGENT_DETAILS,
  SET_SAML_AUTH,
  AUTHORIZED_SAML
} from 'actions/authenticationActions';

import { logout } from '../services/Liveness';
const initialState = {};

const reducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case CLEAR_LOGOUT_MESSAGE:
    case LOGOUT: {
      localStorage.removeItem('auth');
      return {};
    }
    case LOGOUT_HANDLER: {
      logout();
    }
    case LOGOUT_MESSAGE: {
      localStorage.removeItem('auth');
      return { error: payload };
    }
    case SHOW_INACTIVITY_WARNING: {
      return {
        ...state,
        inactivityWarningShown: true
      };
    }
    case HIDE_INACTIVITY_WARNING: {
      return {
        ...state,
        inactivityWarningShown: false
      };
    }
    case LOGIN_ERROR: {
      return error;
    }
    case AUTHORIZED_SAML: {
      console.log(payload);
      if (payload.status != 200) {
        window.localStorage.clear();
        window.location.replace('/');
      }
      return state;
    }
    case LOGGED_IN: {
      localStorage.setItem('auth', JSON.stringify(action.payload));
      return payload;
    }
    case SET_AGENT_DETAILS: {
      const agentDetails = { ...state, agentTokenDetails: payload };
      localStorage.setItem('auth', JSON.stringify(agentDetails));
      return agentDetails;
    }
    case SET_IS_AUTH_APP:
      return { ...state, isAuthApp: payload };
    case SET_SAML_AUTH: {
      console.log("saml reducer", payload);
      return {
        ...state,
        agentTokenDetails: payload?.agentTokenDetails,
        name: payload?.name,
      };
    }
    default:
      return state;
  }
};

export default reducer;
