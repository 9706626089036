import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

export const NotificationBody = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    ${spacing('gap', 3)};
  `,
)

export const NotificationIcon = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
  `,
)

export const CloseButton = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: auto;
  `,
)
