import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { ContainerStyles } from '@vfuk/core-container'
import { spacing } from '@vfuk/core-mixins'

import type { StyledAccordionProps } from './Accordion.style.types'

export const Accordion = styled.div<StyledAccordionProps>(
  (props: StyledAccordionProps): FlattenSimpleInterpolation => css`
    & > ${ContainerStyles.Container} {
      ${props.level === 1 &&
      css`
        ${spacing('margin-bottom', 2)};

        &:last-child {
          margin-bottom: 0;
        }
      `}
    }
  `,
)
