import type { GridConfig } from '@vfuk/core-themes'

import breakpoints from './breakpoints'

/**
 * Grid config
 * consumed by Grid Provider
 */
const gridConfig: GridConfig = {
  breakpoints: {
    md: breakpoints.md,
    lg: breakpoints.lg,
    xl: breakpoints.xl,
  },
  container: {
    padding: 0,
  },
  row: {
    padding: 14,
  },
  col: {
    padding: 14,
  },
  springWidth: 1440,
}

export default gridConfig
