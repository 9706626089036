/* stylelint-disable property-no-vendor-prefix */
import { css } from 'styled-components'

const normalize = css`
  /* Document
    ========================================================================== */

  /**
  * 1. Correct the line height in all browsers.
  * 2. Prevent adjustments of font size after orientation changes in iOS.
  */

  html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /* Sections
    ========================================================================== */

  /**
  * Remove the margin in all browsers.
  */

  body {
    margin: 0;
  }

  /**
  * Render the main element consistently in IE.
  */

  main {
    display: block;
  }

  /**
  * Correct the font size and margin on h1 elements within section and article contexts in Chrome, Firefox, and Safari.
  */

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  /* Grouping content
    ========================================================================== */

  /**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */

  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }

  /**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd em font sizing in all browsers.
  * 3. Remove browser default top margin.
  * 4. Reset browser default of 1em to use rems
  * 5. Don't allow content to break outside
  * 6. We have @viewport set which causes scrollbars to overlap content in IE11 and Edge, so we force a non-overlapping,
  *    non-auto-hiding scrollbar to counteract.
  */

  pre {
    font-family: monospace; /* 1 */
    font-size: 1em; /* 2 */
    margin-top: 0; /* 3 */
    margin-bottom: 1rem; /* 4 */
    overflow: auto; /* 5 */
    -ms-overflow-style: scrollbar; /* 6 */
  }

  /* Text-level semantics
   ========================================================================== */

  /**
  * 1. Remove the gray background on active links in IE 10.
  * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
  */

  a {
    color: inherit;
    text-decoration: underline;
    background-color: transparent; /* 1 */
    -webkit-text-decoration-skip: objects; /* 2 */
  }

  /**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to inherit in Safari.
  */

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  a:not([href]):not([tabindex]) {
    text-decoration: none;

    :hover,
    :focus {
      color: inherit;
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }

  /**
  * 1. Remove the bottom border in Chrome 57-
  * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  * 3. Add explicit cursor to indicate changed behavior.
  */

  abbr[title],
  abbr[data-original-title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    text-decoration: underline dotted; /* 2 */
    cursor: help; /* 3 */
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  /**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */

  b,
  strong {
    font-weight: bolder;
  }

  /**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd em font sizing in all browsers.
  */

  code,
  kbd,
  samp {
    font-family: monospace; /* 1 */
    font-size: 1em; /* 2 */
  }

  /**
  * Add the correct font size in all browsers.
  */

  small {
    font-size: 80%;
  }

  /**
  * Prevent sub and sup elements from affecting the line height in
  * all browsers.
  */

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  /* Forms
    ========================================================================== */

  /**
  * 1. Change the font styles in all browsers.
  * 2. Remove the margin in Firefox and Safari.
  */

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }

  /**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */

  button,
  input {
    /* 1 */
    overflow: visible;
  }

  /**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

  button,
  select {
    /* 1 */
    text-transform: none;
  }

  /**
  * Correct the inability to style clickable types in iOS and Safari.
  */

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  /**
  * Remove the inner border and padding in Firefox.
  */

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /**
  * Restore the focus styles unset by the previous rule.
  */

  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  /**
  * This overrides the extra rounded corners on search inputs in iOS
  * 1. Correct the outline style in Safari.
  */

  [type='search'] {
    outline-offset: -2px; /* 1 */
    -webkit-appearance: none;
  }

  /**
  * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
  */

  [type='search']::-webkit-search-cancel-button,
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  /**
  * Browsers set a default min-width: min-content; on fieldsets,
  * unlike e.g. <div>s, which have min-width: 0; by default.
  * So we reset that to ensure fieldsets behave more like a standard block element.
  * See https://github.com/twbs/bootstrap/issues/12359
  * and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
  */

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  /**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from fieldset elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    fieldset elements in all browsers.
  */

  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }

  /**
  * Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */

  progress {
    vertical-align: baseline;
  }

  /**
  * 1. Remove the default vertical scrollbar in IE 10+.
  * 2. Textareas should really only resize vertically so they don't break their (horizontal) containers.
  */

  textarea {
    overflow: auto;
    resize: vertical;
  }

  /**
  * 1. Add the correct box sizing in IE 10.
  * 2. Remove the padding in IE 10.
  */

  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }

  /**
  * Remove the default appearance of temporal inputs to avoid a Mobile Safari
  * bug where setting a custom line-height prevents text from being vertically
  * centered within the input.
  * See https://bugs.webkit.org/show_bug.cgi?id=139848
  * and https://github.com/twbs/bootstrap/issues/11266
  */

  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    -webkit-appearance: listbox;
  }

  /**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  /**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */
  /* stylelint-disable */
  [type='search'] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }
  /* stylelint-enable */
  /**
  * Remove the inner padding in Chrome and Safari on macOS.
  */

  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  label {
    display: inline-block;
  }

  /* Interactive
    ========================================================================== */

  /*
  * Add the correct display in Edge, IE 10+, and Firefox.
  */

  details {
    display: block;
  }

  /*
  * Add the correct display in all browsers.
  */

  /**
  * Add the correct display in all browsers
  */

  summary {
    display: list-item;
    cursor: pointer;
  }

  /* Misc
    ========================================================================== */

  /**
  * Apply a consistent margin strategy (matches our type styles).
  */
  figure {
    margin: 0 0 1rem;
  }

  /**
  * Add the correct display in IE 10.
  */

  [hidden] {
    display: none;
  }

  /**
  * Copy from lib-web-core
  */

  [tabindex='-1']:focus {
    outline: 0 !important;
  }

  img,
  audio,
  video {
    border: none;
    max-width: 100%;
  }

  /**
  * Remove the border on images inside links in IE 10.
  */

  img {
    border-style: none;
    vertical-align: middle;
  }

  /**
  * Hide the overflow in IE
  */

  svg:not(:root) {
    overflow: hidden;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  /**
  * Prevent double borders
  */

  table {
    width: 100%;
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    text-align: left;
    caption-side: bottom;
  }

  th,
  td {
    text-align: left;
    vertical-align: top;
  }

  output {
    display: inline-block;
  }

  /**
  * Add the correct display in IE
  */

  template {
    display: none;
  }
`

export default normalize
