import dayjs from 'dayjs'

import errorMessages from '../../constants/errorMessages'

const isExcludedDate = (date: dayjs.Dayjs, excludedDates: Date[]): [boolean, string] => {
  const dateArray: string[] = []

  excludedDates.forEach((excludedDate) => {
    const dateFormat = dayjs(excludedDate).format('DD/MM/YYYY')
    dateArray.push(dateFormat)
  })

  const invalidDate = excludedDates.find((excludedDate) => {
    const parsedExcludedDate = dayjs(dayjs(excludedDate).format('DD/MM/YYYY'), 'DD/MM/YYYY', true)
    return date.isSame(parsedExcludedDate)
  })

  // invalidDate is flipped to meet the condition in InputValidator.ts
  return [!invalidDate, `${errorMessages.EXCLUDE_DATE_EXCEPTION} ${dateArray.join(', ')}`]
}

export default isExcludedDate
