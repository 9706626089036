import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'
import FocusLockBase from 'react-focus-lock'

import { advancedSpacing, respondTo } from '@vfuk/core-mixins'

import type { StyledFlyoutRendererProps } from './FlyoutRenderer.style.types'

import localThemes from '../themes/FlyoutRenderer.theme'

type FlyoutRendererProps = Pick<StyledFlyoutRendererProps, 'zIndex'>
export const FlyoutRenderer = styled.div(
  (props: FlyoutRendererProps): FlattenSimpleInterpolation => css`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${props.zIndex};
  `,
)

export const FlyoutRendererContent = styled.div((props: StyledFlyoutRendererProps): FlattenSimpleInterpolation => {
  const { backgroundColor, textColor } = props.flyoutRendererTheme.appearance[props.appearance]

  return css`
    position: fixed;
    ${props.side}: 0;
    top: 0;
    bottom: 0;
    z-index: ${props.zIndex + 1};
    width: calc(100% - ${props.theme.spacing.responsive[3].sm}px);
    background: ${backgroundColor};
    color: ${textColor};
    ${advancedSpacing('padding', [10, 5, 5])}

    ${respondTo.md(css`
      width: 60%;
      max-width: 560px;
    `)}
  `
})

export const FocusLock = styled(FocusLockBase)(
  (): FlattenSimpleInterpolation => css`
    height: 100%;
  `,
)
