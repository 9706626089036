import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { typography } from '@vfuk/core-mixins'

import type { StyledParagraphProps } from './Paragraph.style.types'

export const Paragraph = styled.p(
  (props: StyledParagraphProps): FlattenSimpleInterpolation => css`
    ${typography.paragraph(props.size!, !props.noMargin)};

    ${props.appearance &&
    `
    color: ${props.paragraphTheme.appearance[props.appearance].color};
  `}

    ${props.inverse &&
    `
    color: ${props.paragraphTheme.appearance[props.appearance!].inverse.color};
  `}

  ${props.weight &&
    `
    font-weight: ${props.theme.fontWeight[props.weight]};
  `}

  ${props.justify &&
    `
    text-align: ${props.justify};
  `}

  &:last-child {
      margin-bottom: 0;
    }
  `,
)
