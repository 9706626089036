/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Liveness from 'services/Liveness';
import { render } from "pages/renderer";
import { store } from "../store/configureStore";
import { pageMounted, pageUnmounted } from "../actions/applicationActions";

const TemplatedPage = ({ pageJson, pageTitle }) => {
  const rendered = pageJson ? render(pageJson) : null;
  useEffect(() => Liveness.update(), []);
  useEffect(() => {
    store.dispatch(pageMounted({ pageTitle,taggingId:"page_id"}));
    return () => {
      store.dispatch(pageUnmounted({ pageTitle }));
    };
  }, []);
  return (
    <div
      onKeyDown={() => {}}
      onTouchStart={() => {}}
      onScrollCapture={() => {}}
      onClick={() => {}}
    >
      {rendered}
    </div>
  );
};

export default TemplatedPage;
