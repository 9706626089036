import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledModalProps } from './Modal.style.types'

export const Modal = styled.div(
  (props: StyledModalProps): FlattenSimpleInterpolation => css`
    color: ${props.theme.color.primary1.default};
  `,
)
