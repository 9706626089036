import type { Theme } from '@vfuk/core-themes'

import type { FunctionalCarouselTheme } from './FunctionalCarousel.theme.types'

const defaultTheme = (theme: Theme): FunctionalCarouselTheme => {
  return {
    pagerDot: {
      appearance: {
        primary: {
          backgroundColor: theme.color.monochrome3.default,
          active: {
            backgroundColor: theme.color.primary1.default,
          },
          hover: {
            backgroundColor: theme.color.monochrome4.default,
          },
          inverse: {
            backgroundColor: theme.inverseColor.monochrome3.default,
            active: {
              backgroundColor: theme.inverseColor.primary1.default,
            },
            hover: {
              backgroundColor: theme.inverseColor.monochrome4.default,
            },
          },
        },
        secondary: {
          backgroundColor: theme.color.monochrome3.default,
          active: {
            backgroundColor: theme.color.secondary1.default,
          },
          hover: {
            backgroundColor: theme.color.monochrome4.default,
          },
          inverse: {
            backgroundColor: theme.inverseColor.monochrome3.default,
            active: {
              backgroundColor: theme.inverseColor.secondary1.default,
            },
            hover: {
              backgroundColor: theme.inverseColor.monochrome4.default,
            },
          },
        },
      },
    },
    pagerLoader: {
      bar: {
        color: theme.color.primary1.default,
        backgroundColor: theme.color.monochrome3.default,
      },
    },
    cardBoxShadowOffset: theme.elevation.offset,
  }
}

export default defaultTheme
