import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import isNull from 'lodash/isNull'

import { getDataSelector } from '@vfuk/core-base-props'

import UnitInput from './components/UnitInput'

import * as Styled from './styles/DateTextInput.style'

import toStringParts from './utils/toStringParts'
import inputErrorHandler from './utils/inputErrorHandler'
import dateFormatter from './utils/dateFormatter'
import combineStringParts from './utils/combineStringParts'
import focusOnInputOfType from './utils/focusOnInputOfType'

import type { DateTextInputProps, DateTextInputState, DatePart, DirectionKeys } from './DateTextInput.types'

export class DateTextInput extends PureComponent<DateTextInputProps, DateTextInputState> {
  public static dateParts: DatePart[] = ['day', 'month', 'year']

  public componentName = 'DateTextInput'

  public static dateLengths = {
    day: 2,
    month: 2,
    year: 4,
  }

  public static defaultProps: Partial<DateTextInputProps> = {
    required: false,
    showMonthYearPicker: false,
    autoComplete: true,
  }

  public constructor(props: DateTextInputProps) {
    super(props)
    const stringValues = toStringParts(props.value)
    this.state = {
      value: stringValues,
    }
  }

  public static getDerivedStateFromProps(props: DateTextInputProps): Partial<DateTextInputState> | null {
    const stringValues = toStringParts(props.value)

    // if value null, then we clear the input
    if (isNull(props.rawValue) || (!props.rawValue && isNull(props.value))) {
      return {
        value: toStringParts(null),
      }
    }

    // if prop.value is error return null and let render use existing state
    if (props.value === 'error') {
      return null
    }

    // If date is changed, set the value to the new date
    return {
      value: stringValues,
    }
  }

  public handleOnBlur = (): void => {
    if (this.props.onBlur) {
      const dateString = combineStringParts(this.state.value, this.props.showMonthYearPicker)
      this.props.onBlur(dateFormatter(dateString, this.props.showMonthYearPicker).toDate(), dateString)
    }
  }

  public handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, type: DatePart): void => {
    const value = event.target.value
    const values = { ...this.state.value }
    values[type] = value

    const dateString = combineStringParts(values, this.props.showMonthYearPicker)
    const errors = inputErrorHandler(dateString, this.props)

    // If there are no errors, then trigger the onChange
    if (!errors.length) {
      this.props.onChange(dateFormatter(dateString, this.props.showMonthYearPicker).toDate(), dateString)
    }

    // If there are errors then cascade up the errors
    if (errors.length) {
      this.props.onChange('error', dateString)
      this.props.onInvalidInputEntry(errors)
    }

    // Check if need to focus on the next input
    if (values[type].length >= DateTextInput.dateLengths[type]) {
      this.changeInputFocus(type, 'right')
    }

    this.setState({
      value: values,
    })
  }

  // Handles where the focus is between the inputs
  public changeInputFocus = (type: DatePart, direction: DirectionKeys): void => {
    const currentIndex = this.dateParts.indexOf(type)
    const nextIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1

    if (this.dateParts[nextIndex]) {
      focusOnInputOfType(this.props.id, this.dateParts[nextIndex])
    }
  }

  public handleContainerClick = (): void => {
    if (this.props.onClick) {
      this.props.onClick()
    }

    // Work out which is the best potential option to highlight
    for (let index = 0; index < this.dateParts.length; index++) {
      const datePart = this.dateParts[index]
      if (this.state.value[datePart].length < DateTextInput.dateLengths[datePart]) return focusOnInputOfType(this.props.id, datePart)
    }

    // Everything is filled in fine, go to the last input
    focusOnInputOfType(this.props.id, this.dateParts[this.dateParts.length - 1])
  }

  public handleInputBlur = (event: React.ChangeEvent<HTMLInputElement>, inputType: DatePart): void => {
    const value = event.target.value
    // If no length then do nothing
    if (!value.length) return
    // If year do nothing
    if (inputType === DateTextInput.dateParts[2]) return
    // If the length is already fine do nothing
    if (value.length >= DateTextInput.dateLengths[inputType]) return
    // Add leading zero to the input and trigger the change event
    event.target.value = `0${value}`
    this.handleOnChange(event, inputType)
  }

  public handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, inputType: DatePart): void => {
    if (event.key !== 'Backspace' && event.key !== 'Tab') return
    if (event.key === 'Backspace') {
      this.handleBackspaceBehavior(event, inputType)
    } else {
      this.handleTabBehavior(event, inputType)
    }
  }

  handleBackspaceBehavior(event: React.KeyboardEvent<HTMLInputElement>, inputType: DatePart): void {
    // If Backspace an the first input, do nothing
    if (event.key === 'Backspace' && inputType === this.dateParts[0]) return
    // If the input isn't empty, backspace as normal
    if (event.key === 'Backspace' && this.state.value[inputType].length) return
    event.preventDefault()
    this.changeInputFocus(inputType, 'left')
  }

  handleTabBehavior(event: React.KeyboardEvent<HTMLInputElement>, inputType: DatePart): void {
    // if shift is pressed with tab on the first input, do nothing
    if (event.shiftKey && inputType === this.dateParts[0]) return
    // if last input, focus the calendar
    if (inputType === 'year') return
    event.preventDefault()
    this.changeInputFocus(inputType, event.shiftKey ? 'left' : 'right')
  }

  public get dateParts(): DatePart[] {
    return this.props.showMonthYearPicker ? [DateTextInput.dateParts[1], DateTextInput.dateParts[2]] : DateTextInput.dateParts
  }

  public render(): ReactNode {
    return (
      <Styled.DateTextInputWrapper
        id={`${this.props.id}-date-text-input`}
        name={this.props.name}
        onBlur={this.handleOnBlur}
        onClick={this.handleContainerClick}
        {...this.props.dataAttributes}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.componentName}
      >
        <Styled.Legend data-selector={getDataSelector(this.props.dataSelectorPrefix, 'legend')}>{this.props.name}</Styled.Legend>
        <Styled.DateTextInput state={this.props.state} data-selector={getDataSelector(this.props.dataSelectorPrefix, 'date-text-input')}>
          {this.dateParts.map((datePart, index) => {
            return (
              <UnitInput
                key={`${datePart}-${index}`}
                value={this.state.value[datePart]}
                id={this.props.id}
                name={this.props.name}
                describedBy={this.props.describedBy}
                onChange={(event): void => this.handleOnChange(event, datePart)}
                onBlur={(event): void => this.handleInputBlur(event, datePart)}
                onKeyDown={(event): void => this.handleOnKeyDown(event, datePart)}
                type={datePart}
                required={this.props.required}
                disabled={this.props.state === 'disabled'}
                onClick={this.props.onClick}
                inputLength={DateTextInput.dateLengths[datePart]}
                autoComplete={this.props.autoComplete}
                labels={this.props.labels}
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'unit-input')}
              />
            )
          })}
        </Styled.DateTextInput>
      </Styled.DateTextInputWrapper>
    )
  }
}

export default DateTextInput
