import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { spacing } from '@vfuk/core-mixins'

export const CardGrid = styled.div(
  (): FlattenSimpleInterpolation =>
    css`
      display: flex;
      flex-direction: column;

      ${spacing('gap', 4)}
    `,
)

export const ButtonContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: center;
    height: min-content;
  `,
)
