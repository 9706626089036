/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ComponentType, ReactElement, FC } from 'react'
import React from 'react'
import { useTheme } from 'styled-components'

import type { Theme } from '../../themes.types'
import useLocalTheme from '../../hooks/useLocalTheme'
import camelCase from '../../helpers/camelCase'

function withLocalTheme<T extends any, S>(TargetComponent: ComponentType, defaultTheme: (theme?: Theme) => S): ComponentType<T> {
  return (props: T): ReactElement => {
    const theme = useTheme() as Theme
    const localTheme = useLocalTheme<S>(camelCase(TargetComponent.displayName!), defaultTheme, props.localTheme)
    const RecastComponent = TargetComponent as unknown as FC<T>
    return <RecastComponent {...props} theme={theme} localTheme={localTheme} />
  }
}

export default withLocalTheme
