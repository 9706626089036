import type { Theme } from '@vfuk/core-themes'

import type { BlockTheme } from './Block.theme.types'

const blockLocal = (theme: Theme): BlockTheme => {
  return {
    appearance: {
      primary: {
        backgroundColor: theme.color.monochrome1.default,
      },
      secondary: {
        backgroundColor: theme.color.monochrome5.default,
      },
      alt1: {
        backgroundColor: `linear-gradient(${theme.color.gradient1.default})`,
      },
      alt2: {
        backgroundColor: theme.color.monochrome2.default,
      },
    },
  }
}

export default blockLocal
