import type { Theme } from '@vfuk/core-themes'

import type { InlineLinkTheme } from './InlineLink.theme.types'

const defaultTheme = (theme: Theme): InlineLinkTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome6.default,
        hover: theme.color.monochrome6.hover,
        visited: theme.color.secondary6.default,
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          hover: theme.inverseColor.secondary7.default,
          visited: theme.inverseColor.secondary6.default,
        },
      },
    },
  }
}

export default defaultTheme
