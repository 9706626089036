import type { FC, ReactElement } from 'react'
import React from 'react'
import { useTheme } from 'styled-components'

import NumberBadge from '@vfuk/core-number-badge'
import type { Theme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import type { IconProps } from './Icon.types'

import * as Styled from './styles/Icon.style'

const Icon: FC<IconProps> = ({
  isResponsive = true,
  appearance = 'primary',
  inverse = false,
  group = 'system',
  state,
  size,
  numberBadge,
  name,
  dataAttributes,
  id,
  dataSelectorPrefix,
}: IconProps): ReactElement => {
  const componentName = 'Icon'
  const theme = useTheme() as Theme

  const checkRuleFields = (): boolean => {
    // If the icon group does not exist then return true
    if (!theme.doesIconGroupExist(group!)) return true

    const iconProperties = theme.getIconProperties(group!)

    const errors: Set<boolean> = new Set()

    // Checks if it has an appearance, and if it does check whether the appearance is valid
    if (iconProperties.appearance) {
      errors.add(!iconProperties.appearance[appearance!])
    }

    // Checks if it has a state, and if it does check whether the state is valid
    if (iconProperties.state && state) {
      errors.add(!iconProperties.state[state])
    }

    // Checks if there are any errors, if so then return true
    return errors.has(true)
  }

  if (checkRuleFields()) return <Styled.ErrorPinkBox />

  return (
    <Styled.Icon
      id={id}
      state={state!}
      aria-hidden='true'
      appearance={appearance}
      inverse={inverse}
      size={size}
      isResponsive={isResponsive}
      group={group!}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
    >
      {theme!.getIcon(name, group!, <Styled.ErrorPinkBox />)}
      {numberBadge && group === 'system' && (
        <Styled.NumberBadgeContainer>
          <NumberBadge
            number={numberBadge.number}
            appearance={inverse ? numberBadge.appearance : 'primary'}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'number-badge')}
          />
        </Styled.NumberBadgeContainer>
      )}
    </Styled.Icon>
  )
}

export default Icon
