import type { Theme } from '@vfuk/core-themes'

import type { RangeSliderTheme } from './RangeSlider.theme.types'

const rangeSliderFieldSetLocal = (theme: Theme): RangeSliderTheme => ({
  appearance: {
    primary: {
      iconButtonAppearance: 'alt1',
      iconWithBackgroundAppearance: 'alt3',
      backgroundColor: theme.color.primary1,
      sliderTooltipColor: theme.color.monochrome6.default,
      sliderLabelsColor: theme.color.monochrome6.default,
      sliderRailColor: theme.color.monochrome3.default,
    },
    secondary: {
      iconButtonAppearance: 'alt1',
      iconWithBackgroundAppearance: 'alt3',
      backgroundColor: theme.color.secondary1,
      sliderTooltipColor: theme.color.monochrome6.default,
      sliderLabelsColor: theme.color.monochrome6.default,
      sliderRailColor: theme.color.monochrome3.default,
    },
  },
})

export default rangeSliderFieldSetLocal
