import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import FieldWrapper from '@vfuk/core-field-wrapper'
import SearchSuggestion from '@vfuk/core-search-suggestion'

import type { SearchSuggestionWithLabelProps } from './SearchSuggestionWithLabel.types'

const SearchSuggestionWithLabel: FC<SearchSuggestionWithLabelProps> = ({
  dataAttributes,
  fieldWrapper,
  searchSuggestion,
  state,
  dataSelectorPrefix,
  id,
}: SearchSuggestionWithLabelProps): ReactElement => {
  const componentName = 'SearchSuggestionWithLabel'

  return (
    <FieldWrapper
      state={state!}
      helpText={fieldWrapper.helpText}
      label={fieldWrapper.label}
      formElementId={searchSuggestion.id}
      showLabel={fieldWrapper.showLabel}
      subText={fieldWrapper.subText}
      stateText={fieldWrapper.stateText}
      validateOn={fieldWrapper.validateOn}
      width={fieldWrapper.width}
      helpLink={fieldWrapper.helpLink}
      optional={fieldWrapper.optional}
      required={fieldWrapper.required}
      id={id}
      dataAttributes={dataAttributes}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName}
    >
      <SearchSuggestion
        suggestions={searchSuggestion.suggestions}
        handleFiltering={searchSuggestion.handleFiltering}
        minCharacters={searchSuggestion.minCharacters}
        state={state!}
        dataAttributes={searchSuggestion.dataAttributes}
        id={searchSuggestion.id}
        name={searchSuggestion.name}
        onChange={searchSuggestion.onChange}
        placeholder={searchSuggestion.placeholder}
        required={searchSuggestion.required}
        value={searchSuggestion.value}
        onSelect={searchSuggestion.onSelect}
        domRef={searchSuggestion.domRef}
        dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'search-suggestion')}
      />
    </FieldWrapper>
  )
}

export default SearchSuggestionWithLabel
