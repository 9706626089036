import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { advancedSpacing, applyFocus, srOnly, typography } from '@vfuk/core-mixins'

import type { StyledUnitInputProps, StyledUnitInputWrapperProps } from './UnitInput.style.types'

export const UnitInput = styled.input((props: StyledUnitInputProps): FlattenSimpleInterpolation => {
  return css`
    position: relative;
    border: 0;
    border-radius: ${props.theme.border.radius[2]};
    background-color: ${props.theme.color.monochrome1.default};
    color: ${props.theme.color.monochrome5.default};
    ${advancedSpacing('padding', [1, 2])};
    ${typography.heading(1, false)}
    text-align: center;

    ${props.unitType !== 'year' &&
    css`
      width: 5ch;
    `}

    ${props.unitType === 'year' &&
    css`
      width: 6ch;
    `}

    ${applyFocus()}

    ${props.disabled &&
    css`
      background-color: ${props.theme.color.monochrome2.default};
      color: ${props.theme.color.monochrome5.disabled};
      cursor: not-allowed;
    `}
  `
})

export const UnitInputContainer = styled.div(
  (props: StyledUnitInputWrapperProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;

    ${props.showDelimeter &&
    css`
      &::after {
        ${advancedSpacing('padding', [0, 2])};
        content: '|';
      }
    `}
  `,
)

export const Label = styled.label(
  (): FlattenSimpleInterpolation => css`
    ${srOnly()}
  `,
)
