/* eslint-disable
  sonarjs/cognitive-complexity,
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-empty-function,
*/
import type { ReactElement } from 'react'
import React from 'react'
import { ReactSVG } from 'react-svg'

import type { IconRuleSet, AllSizes, RequiredSizes } from '@vfuk/core-icon-generator'

import type { BaseThemeProps, Theme } from './themes.types'
import type { AssetType } from './constants/assetLocation.types'
import type { LocalThemes } from './constants/localThemes.types'

import type { IconRules, IconRule } from './constants/icons.types'

export class BaseTheme implements BaseThemeProps {
  public name = 'BaseTheme'

  public baseAssetLocation = `assets/${this.name.toLowerCase()}/`

  public localThemes: LocalThemes = {}

  public localThemer = <T extends any>(componentName: string, defaultTheme: T): T => {
    if (this.localThemes && this.localThemes[componentName]) return this.localThemes[componentName]

    return defaultTheme
  }

  public setLocalTheme = (componentName: string, localTheme: any): void => {
    this.localThemes[componentName] = localTheme
  }

  public setBaseAssetLocation = (path: string): void => {
    this.baseAssetLocation = path
  }

  public getAssetLocation = (assetType: AssetType): string => {
    return `${this.baseAssetLocation}${this.assetLocations[assetType]}`
  }

  public setAssetLocations = (assetType: AssetType, path: string): void => {
    this.assetLocations[assetType] = path.endsWith('/') ? path : `${path}/`
  }

  public assetLocations = {
    fonts: 'fonts/',
    icons: 'icons/',
    logos: 'logos/',
  }

  public customRouter: ReactElement | undefined = undefined

  public setCustomRouter = (router: ReactElement): void => {
    this.customRouter = router
  }

  public iconRules: IconRules = {
    system: {
      assetLocation: '',
      properties: (() => {}) as unknown as IconRule['properties'],
    },
    state: {
      assetLocation: '',
      properties: (() => {}) as unknown as IconRule['properties'],
    },
  }

  public setIconGroup = (
    groupName: string,
    assetLocation: string,
    ruleSet?: (theme: Theme) => IconRuleSet<AllSizes | RequiredSizes>,
  ): void => {
    const iconSet = this.iconRules[groupName]

    if (iconSet) {
      iconSet.assetLocation = assetLocation
      iconSet.properties = ruleSet || iconSet.properties
      return
    }

    for (const group in this.iconRules) {
      if (this.iconRules[group].assetLocation === assetLocation) console.warn('The asset location you are trying to use already exists')
    }

    this.iconRules[groupName] = {
      assetLocation,
      properties: ruleSet || this.iconRules.default.properties,
    }
  }

  public getIcon = (iconName: string, groupName: string, ErrorComponent?: ReactElement): ReactElement => {
    return (
      <ReactSVG
        fallback={(): ReactElement => <>{ErrorComponent || null}</>}
        src={`${this.baseAssetLocation}${this.assetLocations.icons}${this.iconRules[groupName]?.assetLocation}/${iconName}.svg`}
        wrapper='span'
      />
    )
  }

  public doesIconGroupExist = (groupName: string): boolean => {
    return !!this.iconRules[groupName]
  }

  public getIconProperties = (groupName: string | 'default'): IconRuleSet<AllSizes | RequiredSizes> => {
    return this.iconRules[groupName].properties(this)
  }

  public getLogo = (logoName: string): ReactElement => {
    return <ReactSVG src={`${this.getAssetLocation('logos')}${logoName}.svg`} wrapper='span' />
  }
}

export default BaseTheme
