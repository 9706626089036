import type { Theme } from '@vfuk/core-themes'

import type { LoaderTheme } from './Loader.theme.types'

const loaderLocal = (theme: Theme): LoaderTheme => ({
  appearance: {
    primary: {
      color: theme.color.primary1.default,
      inverse: {
        color: theme.inverseColor.monochrome6.default,
      },
    },
    secondary: {
      color: theme.color.monochrome3.default,
      inverse: {
        color: theme.inverseColor.monochrome3.default,
      },
    },
  },
})

export default loaderLocal
