import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { ReactNode } from "react";
import elementPluginsExecutor from "./elementPluginsExecuter";
import { createDynamicJourneyRendererProps, renderProps } from "./types/types";

export default function createDynamicJourneyRenderer({
  registry,
  plugins = {}
}: Readonly<createDynamicJourneyRendererProps>) {
  const { initialSetup = {}, preRender = {}, propsPlugin } = plugins;
  let indexKey = 0;
  return function render(
    context: Readonly<renderProps> | string
  ): ReactNode | string {
    if (typeof context === "string") {
      return context;
    }
    const { __component, __props = {}, __returnFunction = false } = context;

    let Element = registry[__component] || __component;
    initialSetup.render = render;
    Element = elementPluginsExecutor(preRender, Element, context, initialSetup);

    let { children = [], ...props } = __props;

    if (propsPlugin) {
      props = propsPlugin(context, Element, initialSetup);
    }

    if (!__component) {
      return props;
    }

    const componentKey = `${__component}-${indexKey++}`;

    const returnedElement = (() => {
      switch (true) {
        case Array.isArray(children) && !!children.length:
          return (
            <Element key={componentKey} {...props}>
              {children.map(render)}
            </Element>
          );
        case typeof children === "string":
          return (
            <Element key={componentKey} {...props}>
              {children}
            </Element>
          );
        case children && typeof children === "object" && !!children.__component:
          return (
            <Element key={componentKey} {...props}>
              {render(children)}
            </Element>
          );
        default:
          return <Element key={componentKey} {...props} />;
      }
    })();

    if (__returnFunction) {
      const ElementAsFunction = () => returnedElement;
      return ElementAsFunction;
    }

    return returnedElement;
  };
}
