import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { spacing, typography } from '@vfuk/core-mixins'

import type { StyledLinkWithIconProps } from './LinkWithIcon.style.types'

type LinkWithIconProps = Pick<StyledLinkWithIconProps, 'appearance' | 'theme' | 'inverse' | 'linkWithIconTheme'>
export const LinkWithIcon = styled(Interaction)<LinkWithIconProps>((props: LinkWithIconProps): FlattenSimpleInterpolation => {
  return css`
    display: flex;
    width: fit-content;
    align-items: center;
    color: ${props.linkWithIconTheme.appearance[props.appearance!].color};

    &:hover {
      color: ${props.linkWithIconTheme.appearance[props.appearance!].hoverColor};
      text-decoration: underline;

      svg {
        fill: ${props.linkWithIconTheme.appearance[props.appearance!].hoverColor};
        stroke: ${props.linkWithIconTheme.appearance[props.appearance!].hoverColor};
      }
    }

    ${props.inverse &&
    css`
      color: ${props.linkWithIconTheme.appearance[props.appearance!].inverse.color};

      &:hover {
        color: ${props.linkWithIconTheme.appearance[props.appearance!].inverse.hoverColor};
        text-decoration: underline;

        svg {
          fill: ${props.linkWithIconTheme.appearance[props.appearance!].inverse.hoverColor};
          stroke: ${props.linkWithIconTheme.appearance[props.appearance!].inverse.hoverColor};
        }
      }
    `}
  `
})

type IconContainerProps = Pick<StyledLinkWithIconProps, 'theme' | 'inverse' | 'justify' | 'appearance' | 'size' | 'linkWithIconTheme'>
export const IconContainer = styled.div<IconContainerProps>((props: IconContainerProps): FlattenSimpleInterpolation => {
  return css`
    display: inline-flex;

    ${props.justify === 'left' &&
    css`
      order: 0;
      ${spacing('margin-right', 2)};

      ${props.size! > 2 &&
      css`
        ${spacing('margin-right', 3)};
      `}
    `}

    ${(props.justify === 'right' || !props.justify) &&
    css`
      order: 1;
      ${spacing('margin-left', 2)};

      ${props.size! > 2 &&
      css`
        ${spacing('margin-left', 3)};
      `}
    `}

    ${props.appearance === 'primary' &&
    props.inverse &&
    css`
      svg {
        fill: ${props.linkWithIconTheme.appearance.primary.inverse.color};
        stroke: ${props.linkWithIconTheme.appearance.primary.inverse.color};
      }
    `}
  `
})

type LinkWithIconTextProps = Pick<StyledLinkWithIconProps, 'theme' | 'size'>
export const LinkWithIconText = styled.div<LinkWithIconTextProps>((props: LinkWithIconTextProps): FlattenSimpleInterpolation => {
  return css`
    display: flex;

    ${props.size === 1 &&
    css`
      ${typography.paragraph(1, false)}
    `}

    ${props.size === 2 &&
    css`
      ${typography.paragraph(2, false)}
    `}

    ${props.size === 3 &&
    css`
      ${typography.heading(1, false)}
    `}

    ${props.size === 4 &&
    css`
      ${typography.heading(2, false)}
    `}
  `
})
