import type { FC } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'

import { getDataSelector } from '@vfuk/core-base-props'

import Icon from '@vfuk/core-icon'

import defaultTheme from './themes/LinkWithIcon.theme'
import * as Styled from './styles/LinkWithIcon.style'

import type { LinkWithIconProps } from './LinkWithIcon.types'
import type { Appearance } from './themes/LinkWithIcon.theme.types'

const LinkWithIcon: FC<LinkWithIconProps> = ({
  appearance = 'primary',
  htmlAttributes = {},
  dataAttributes,
  size = 2,
  inverse = false,
  href,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  id,
  srText,
  icon,
  text,
  customRouterProps,
  dataSelectorPrefix,
  localTheme,
}) => {
  const componentName = 'LinkWithIcon'
  const linkWithIconTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  let themeAppearance = linkWithIconTheme.appearance[appearance]
  if (inverse) {
    themeAppearance = themeAppearance.inverse as Appearance
  }

  const { iconAppearance } = themeAppearance

  return (
    <Styled.LinkWithIcon
      appearance={appearance!}
      inverse={inverse!}
      href={href}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      target={htmlAttributes!.target}
      componentName={componentName}
      dataAttributes={dataAttributes}
      id={id}
      label={srText}
      customRouterProps={customRouterProps}
      linkWithIconTheme={linkWithIconTheme}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
    >
      <Styled.IconContainer
        linkWithIconTheme={linkWithIconTheme}
        inverse={inverse!}
        justify={icon.justify!}
        appearance={appearance}
        size={size}
      >
        <Icon
          name={icon.name}
          group={icon.group || 'system'}
          inverse={inverse}
          size={size}
          appearance={iconAppearance}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, icon.name)}
        />
      </Styled.IconContainer>
      <Styled.LinkWithIconText size={size} data-selector={getDataSelector(dataSelectorPrefix, 'text')}>
        {text}
      </Styled.LinkWithIconText>
    </Styled.LinkWithIcon>
  )
}

export default LinkWithIcon
