import { useRef } from 'react'

/**
 * Hook that returns true on the first render and false on subsequent renders
 */
function useIsFirstRender(): boolean {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}
export default useIsFirstRender
