import type { ReactElement, FC } from 'react'
import React from 'react'
import Paragraph from '@vfuk/core-paragraph'
import Icon from '@vfuk/core-icon'
import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'
import { withTheme } from 'styled-components'

import * as Styled from './styles/FilterList.style'

import type { FilterListProps } from './FilterList.types'
import defaultTheme from './themes/FilterList.theme'

export const FilterList: FC<FilterListProps> = ({
  id,
  label,
  groupName,
  filterItems,
  selected,
  size = 2,
  appearance = 'primary',
  inverse = false,
  onChange,
  onBlur,
  dataAttributes,
  dataSelectorPrefix,
  theme,
}: FilterListProps): ReactElement => {
  const inputType = Array.isArray(selected) ? 'checkbox' : 'radio'

  const componentName = 'FilterList'

  const localTheme = useLocalTheme(componentName, defaultTheme)

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, state?: string): void => {
    if (state !== 'disabled' && onChange) {
      onChange(event)
    }
  }

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>, state?: string): void => {
    if (state !== 'disabled' && onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.FilterList id={id} data-component-name={componentName} {...dataAttributes} data-selector={getDataSelector(dataSelectorPrefix)}>
      <Styled.Legend data-selector={getDataSelector(dataSelectorPrefix, 'legend')}>{label}</Styled.Legend>
      {filterItems?.map((filterItem) => {
        const isSelected = inputType === 'radio' ? selected === filterItem.value : selected.includes(filterItem.value)
        return (
          <Styled.FilterItem
            key={filterItem.id}
            checked={isSelected}
            appearance={appearance}
            state={filterItem.state}
            size={size}
            inverse={inverse}
            aria-label={filterItem.srText || filterItem.title}
            imgSet={filterItem.imageSet}
            data-selector={getDataSelector(dataSelectorPrefix, 'label')}
            filterListTheme={localTheme}
          >
            <Styled.FilterInput
              name={groupName}
              value={filterItem.value}
              checked={isSelected}
              type={inputType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => onChangeHandler(e, filterItem.state)}
              onBlur={(e: React.FocusEvent<HTMLInputElement>): void => onBlurHandler(e, filterItem.state)}
              {...filterItem.dataAttributes}
              state={filterItem.state}
              aria-disabled={filterItem.state === 'disabled'}
              data-selector={getDataSelector(dataSelectorPrefix, 'input')}
            />
            {filterItem.imageSet?.src && (
              <Styled.Span
                size={size}
                imgSet={filterItem.imageSet}
                checked={isSelected}
                aria-label={filterItem.imageSet?.alt}
                role='img'
                data-selector={getDataSelector(dataSelectorPrefix, 'image-set')}
              />
            )}
            {filterItem.icon?.name && (
              <Icon
                name={filterItem.icon!.name}
                group={filterItem.icon?.group}
                inverse={isSelected ? !inverse : inverse}
                appearance={theme!.formFields.icon.appearance}
                size={size}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, filterItem.icon?.name)}
              />
            )}
            <Paragraph size={size} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'title')}>
              {filterItem.title}
            </Paragraph>
          </Styled.FilterItem>
        )
      })}
    </Styled.FilterList>
  )
}

export default withTheme(FilterList)
