import type { ReactElement, FC } from 'react'
import React from 'react'
import { TinyColor } from '@ctrl/tinycolor'

import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'
import Icon from '@vfuk/core-icon'

import * as Styled from './styles/ColorSwatch.style'
import defaultTheme from './themes/ColorSwatch.theme'

import type { ColorSwatchProps } from './ColorSwatch.types'

const ColorSwatch: FC<ColorSwatchProps> = ({
  color,
  size = 1,
  available = true,
  inverse = false,
  localTheme,
  dataAttributes,
  dataSelectorPrefix,
  id,
}: ColorSwatchProps): ReactElement => {
  const componentName = 'ColorSwatch'
  const colorSwatchTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const { iconSize } = colorSwatchTheme.size[size]
  const tinyColor = new TinyColor(color)
  const lowContrast = inverse ? tinyColor.isDark() : tinyColor.isLight()

  return (
    <Styled.ColorSwatch
      colorSwatchTheme={colorSwatchTheme}
      id={id}
      color={color}
      size={size!}
      lowContrast={lowContrast}
      inverse={inverse}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
    >
      {!available && (
        <Icon
          name='close'
          size={iconSize}
          inverse={inverse ? lowContrast : !lowContrast}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'close')}
        />
      )}
    </Styled.ColorSwatch>
  )
}

export default ColorSwatch
