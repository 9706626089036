import type { FC, ReactElement } from 'react'
import React from 'react'

import Heading from '@vfuk/core-heading'

import { getDataSelector } from '@vfuk/core-base-props'

import type { CardsSectionProps } from './CardsSection.types'
import * as Styled from './styles/CardsSection.style'

import CardGrid from '../CardGrid/CardGrid'

const CardsSection: FC<CardsSectionProps> = ({
  cardsSection,
  renderSameHeight = false,
  cardListTheme,
  dataSelectorPrefix,
}: CardsSectionProps): ReactElement => {
  const { columns } = cardListTheme.cardsSection.size[cardsSection.size ?? 5]

  return (
    <Styled.CardsSection data-selector={getDataSelector(dataSelectorPrefix)} cardsSectionColumns={columns}>
      {cardsSection.heading?.text && (
        <Styled.Section>
          <Heading
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'heading')}
            text={cardsSection.heading!.text}
            size={3}
            weight={3}
            level={cardsSection.heading!.level}
          />
        </Styled.Section>
      )}
      {cardsSection.subHeading?.text && cardsSection.heading?.text && (
        <Styled.Section>
          <Heading
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'sub-heading')}
            text={cardsSection.subHeading!.text}
            size={1}
            level={cardsSection.subHeading!.level}
          />
        </Styled.Section>
      )}
      <Styled.Cards>
        <CardGrid
          carousel={cardsSection.carousel}
          cards={cardsSection.cards}
          columns={cardsSection.grid.columns}
          renderSameHeight={renderSameHeight}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'card-grid')}
        />
      </Styled.Cards>
    </Styled.CardsSection>
  )
}

export default CardsSection
