/* eslint-disable camelcase */
import axios from "axios";
import i18next from "i18next";
import { application, apiUrl, apigeeTranslationApiUrl, apigeeTranslationApiKey, envId, ingressURL } from "settings";
import { getMiddlewareBaseUrl } from "utils/helpers";

const REQUEST_TIME_OUT = 15000; // only wait for 15s

class TranslationService {
  moduleT = (module) => (key, options) => i18next.t(`${module}.${key}`, options);
  fetchTranslationsFromAppBuilder = async (language) => {
    const headers = apigeeTranslationApiUrl
      ? {
          "content-type": "application/json",
          Authorization: `Basic ${apigeeTranslationApiKey}`,
        }
      : {
          "content-type": "application/json",
        };
    const url = apigeeTranslationApiUrl
      ? `${apigeeTranslationApiUrl}/${window.REACT_APP_ENV_ID || envId}/${application}/${language}`
      : `${apiUrl}/public/v1/languages/${window.REACT_APP_ENV_ID || envId}/${application}/${language}`;
    const data = await axios.create()({
      method: "get",
      url,
      headers,
    });
    return data;
  };

  fetchLanguagesFromAppBuilder = async () => {
    const headers = apigeeTranslationApiUrl
      ? {
          "content-type": "application/json",
          Authorization: `Basic ${apigeeTranslationApiKey}`,
        }
      : {
          "content-type": "application/json",
        };
    const url = apigeeTranslationApiUrl
      ? `${apigeeTranslationApiUrl}/${window.REACT_APP_ENV_ID || envId}/${application}`
      : `${apiUrl}/public/v1/languages/${window.REACT_APP_ENV_ID || envId}/${application}`;
    try {
      const data = await axios.create()({
        method: "get",
        url,
        headers,
      });
      return data;
    } catch (err) {
      return console.log("Error in translation service:", err);
    }
  };

  fetchTranslations = async (language) => {
    const middlewareBaseUrl = getMiddlewareBaseUrl({ apiUrl, ingressURL });

    try {
      const data = await axios.create()({
        method: "get",
        url: `${middlewareBaseUrl}/api/public/v1/languages/${
          window.REACT_APP_ENV_ID || envId
        }/${application}/${language}`,
        timeout: REQUEST_TIME_OUT,
        headers: {
          "content-type": "application/json",
          Authorization: `Basic ${apigeeTranslationApiKey}`,
        },
      });
      console.log("Getting translations form the middleware", { data });
      return data;
    } catch (error) {
      console.error("Error getting translations from The Middleware, fallback to the app-builder's", { error });
      return this.fetchTranslationsFromAppBuilder(language);
    }
  };

  fetchLanguages = async () => {
    const middlewareBaseUrl = getMiddlewareBaseUrl({ apiUrl, ingressURL });

    try {
      const data = await axios.create()({
        method: "get",
        url: `${middlewareBaseUrl}/api/public/v1/languages/${window.REACT_APP_ENV_ID || envId}/${application}`,
        timeout: REQUEST_TIME_OUT,
        headers: {
          "content-type": "application/json",
          Authorization: `Basic ${apigeeTranslationApiKey}`,
        },
      });
      console.log("Getting languages form the middleware", { data });
      return data;
    } catch (error) {
      console.error("Error getting languages from The Middleware, fallback to the app-builder's", { error });
      return this.fetchLanguagesFromAppBuilder();
    }
  };
}

const inst = new TranslationService();
export default inst;
