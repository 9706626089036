/* eslint-disable max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import type { StyledTabsProps } from './Tabs.style.types'

type TabListContainerProps = Pick<StyledTabsProps, 'theme' | 'tabsTheme' | 'appearance' | 'inverse'>
export const TabListContainer = styled.div<TabListContainerProps>((props: TabListContainerProps): FlattenSimpleInterpolation => {
  const { tabListBottomBorder } = props.inverse
    ? props.tabsTheme.appearance[props.appearance].inverse
    : props.tabsTheme.appearance[props.appearance]

  return css`
    position: relative;
    border-bottom: ${tabListBottomBorder};

    button {
      width: 100%;
    }
  `
})

export const TabList = styled.div<Pick<StyledTabsProps, 'overflowActive'>>(
  (): FlattenSimpleInterpolation => css`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
)

export const TabTitleContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;
    width: 100%;
  `,
)

export const OverflowMenuContainer = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    bottom: 0;
  `,
)
