import type { SpacingKeys } from '@vfuk/core-themes'

import type { BlockProps } from '../../Block.types'

function calculatePaddingLevel(paddingLevel: BlockProps['paddingLevel']): [SpacingKeys, SpacingKeys] {
  switch (paddingLevel) {
    case 1:
      return [6, 4]
    case 2:
      return [10, 4]
    default:
      return [0, 0]
  }
}

export default calculatePaddingLevel
