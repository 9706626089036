import type { FC, ReactElement } from 'react'
import React from 'react'

import Overlay from '@vfuk/core-overlay'
import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/Container.theme'
import * as Styled from './styles/Container.style'

import type { ContainerProps } from './Container.types'

import calculatePadddingLevel from './helpers/calculatePaddingLevel'

const Container: FC<ContainerProps> = ({
  children,
  appearance = 'primary',
  highlight,
  elevation = false,
  id,
  dataSelectorPrefix,
  dataAttributes,
  componentName,
  backgroundImage,
  enableOverlay = true,
  state,
  paddingLevel = 3,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  customRouterProps,
  href,
  allowOverflow = true,
  zIndex = 2,
  srText,
  localTheme,
}: ContainerProps): ReactElement => {
  const localComponentName = 'Container'
  const containerTheme = useLocalTheme(localComponentName, defaultTheme, localTheme)
  const highlightProp = !state && !backgroundImage && highlight

  return (
    <Styled.Container
      id={id}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName || localComponentName}
      state={state}
      dropShadow={elevation!}
      appearance={appearance!}
      highlight={highlightProp ? highlight : undefined}
      backgroundImage={backgroundImage}
      paddingLevel={calculatePadddingLevel(paddingLevel)}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      customRouterProps={customRouterProps}
      href={href}
      dataAttributes={dataAttributes}
      allowOverflow={backgroundImage ? false : allowOverflow}
      zIndex={zIndex}
      label={srText}
      containerTheme={containerTheme}
    >
      {enableOverlay && backgroundImage && (
        <Overlay show position='absolute' zIndex={1} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'overlay')} />
      )}
      <Styled.ContainerContent zIndex={zIndex}>{children}</Styled.ContainerContent>
    </Styled.Container>
  )
}

export default Container
