import type { HeadingProps } from '@vfuk/core-heading'

import type { LoaderSizeKeys } from '../Loader.types'

function calculateHeadingSize(size: LoaderSizeKeys): HeadingProps['size'] {
  switch (size) {
    case 1:
      return 1
    case 2:
      return 2
    case 3:
      return 4
  }
}

export default calculateHeadingSize
