export type MaxFooterHeight = { heights: Record<number, number>; height?: number }

export default function getMaxFooterHeight(cardRefs: (HTMLSpanElement | null)[]): MaxFooterHeight {
  if (!cardRefs?.length || cardRefs.every((ref) => ref === null)) return { heights: {} }

  const footerHeights = cardRefs.map(
    (ref) => (ref?.querySelector('[class*="FooterTextWrapper"]') as HTMLDivElement | null)?.offsetHeight ?? 0,
  )

  if (!footerHeights.length || footerHeights.every((height) => height === 0)) return { heights: {} }

  const maxFooterHeight = Math.max(...footerHeights)

  return {
    heights: footerHeights.reduce((acc, height, index) => (height ? { ...acc, [index]: height } : acc), {}),
    height: maxFooterHeight,
  }
}
