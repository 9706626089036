export const mockImageProps = {
  sm: {
    src: 'https://via.placeholder.com/110x110',
    srcSet: {
      x1: 'https://via.placeholder.com/100x100',
      x2: 'https://via.placeholder.com/100x100',
      x3: 'https://via.placeholder.com/100x100',
    },
    width: '100px',
  },
  md: {
    src: 'https://via.placeholder.com/210x210',
    srcSet: {
      x1: 'https://via.placeholder.com/200x200',
      x2: 'https://via.placeholder.com/200x200',
      x3: 'https://via.placeholder.com/200x200',
    },
    width: '300px',
  },
  lg: {
    src: 'https://via.placeholder.com/310x310',
    srcSet: {
      x1: 'https://via.placeholder.com/300x300',
      x2: 'https://via.placeholder.com/300x300',
      x3: 'https://via.placeholder.com/300x300',
    },
    width: '400px',
  },
  xl: {
    src: 'https://via.placeholder.com/410x410',
    srcSet: {
      x1: 'https://via.placeholder.com/400x400',
      x2: 'https://via.placeholder.com/400x400',
      x3: 'https://via.placeholder.com/400x400',
    },
    width: '500px',
  },
}
