import type { Theme } from '@vfuk/core-themes'

import type { IconPickerTheme } from './IconPicker.theme.types'

const iconPickerTheme = (theme: Theme): IconPickerTheme => {
  return {
    appearance: {
      primary: {
        iconAppearance: 'primary',
        iconInverse: false,
        inverse: {
          iconAppearance: 'primary',
          iconInverse: true,
        },
      },
    },
  }
}

export default iconPickerTheme
