import dayjs from 'dayjs'
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import errorMessages from '../../constants/errorMessages'

dayjs.extend(IsSameOrBefore)

function isMaxDate(date: dayjs.Dayjs, maxDate: Date): [boolean, string] {
  const dateFormat = dayjs(maxDate).add(1, 'day').format('DD/MM/YYYY')
  return [date.isSameOrBefore(maxDate, 'day'), `${errorMessages.MAX_DATE_EXCEPTION} ${dateFormat}`]
}

export default isMaxDate
