import React, { useMemo, useRef, useState } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useResizeObserver, useThrottle } from '@vfuk/core-helpers'

import * as Styled from './styles/SameHeightCardGrid.style'
import { getMaxFooterHeight, getMaxHeightIndexes, MaxFooterHeight } from './utils'

import type { SameHeightGridProps } from './SameHeightGrid.types'
import type { CardGridProps } from '../../CardGrid.types'
import type { FC } from 'react'

type Nullable<T> = T | null

const SameHeightGrid: FC<SameHeightGridProps> = ({ cards, rows, columns, dataSelectorPrefix }) => {
  const [maxFooterHeights, setMaxFooterHeights] = useState<MaxFooterHeight[]>([])

  const cardRefs = useRef<Nullable<HTMLSpanElement>[]>([])

  const cardRows: CardGridProps['cards'][] = useMemo(() => {
    return Array.from({ length: rows }, (_, i) => cards.slice(i * columns, i * columns + columns))
  }, [cardRefs.current, cards, rows, columns])

  const updateMaxFooterHeights = () => {
    setMaxFooterHeights(
      Array.from({ length: rows }, (_, i) => getMaxFooterHeight(cardRefs.current.slice(i * columns, i * columns + columns))),
    )
  }

  const throttledUpdate = useThrottle({ fn: updateMaxFooterHeights, delay: 150 }, [updateMaxFooterHeights])

  useResizeObserver(cardRefs.current, throttledUpdate)

  return (
    <Styled.CardGrid data-selector={getDataSelector(dataSelectorPrefix)}>
      {cardRows.map((row, rowIndex) => {
        const rowRefs = cardRefs.current.slice(rowIndex * columns, rowIndex * columns + columns)

        const maxHeightIndexes = getMaxHeightIndexes(rowRefs, row)
        const footer = maxFooterHeights[rowIndex] ?? getMaxFooterHeight(rowRefs)

        const isLastRow = rowIndex === rows - 1
        const isNotFull = isLastRow && row.length < columns

        return (
          <Styled.CardGridRow key={`card-list-grid-${rowIndex}`}>
            {row.map((card, cardIndex) => {
              const bottomPadding =
                !!Object.keys(footer.heights).length && !!footer.height
                  ? !footer.heights[cardIndex]
                    ? footer.height
                    : footer.height - footer.heights[cardIndex]
                  : undefined
              const index = rowIndex * columns + cardIndex

              return (
                <Styled.CardGridCell
                  key={`card-list-grid-cell-${rowIndex}-${cardIndex}`}
                  ref={(ref) => {
                    cardRefs.current[index] = ref
                  }}
                  shouldGrow={!maxHeightIndexes.includes(cardIndex)}
                  bottomPadding={bottomPadding}
                  columns={columns}
                  isNotFull={isNotFull}
                >
                  {card}
                </Styled.CardGridCell>
              )
            })}
          </Styled.CardGridRow>
        )
      })}
    </Styled.CardGrid>
  )
}

export default SameHeightGrid
