import {
  CONFIG_REQUEST_SUCCESS,
  CONFIG_REQUEST_FAIL,
  GLOBAL_LOADING,
  SET_TAGGING_SHEET,
} from "actions/configActions";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  analytics: { taggingSheet: [] },
};

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case CONFIG_REQUEST_SUCCESS:
      localStorage.setItem(
        `config-${payload?.appId || "appId"}`,
        JSON.stringify(payload)
      );
      return {
        ...state,
        data: payload,
      };

    case CONFIG_REQUEST_FAIL:
      return {
        ...state,
        error,
      };

    case SET_TAGGING_SHEET: {
      return {
        ...state,
        analytics: payload,
      };
    }
    case GLOBAL_LOADING:
      return {
        ...state,
        isLoading: payload ?? !state.isLoading,
      };
    default:
      return state;
  }
};
