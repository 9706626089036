import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import isNumber from 'lodash/isNumber'

import { Container } from 'styled-bootstrap-grid'

import type { StyledGridProps } from './Grid.style.types'

export const GridContainer = styled(Container)(
  (props: StyledGridProps): FlattenSimpleInterpolation => css`
    ${isNumber(props.maxWidth) &&
    `
    max-width: ${props.maxWidth}px;
  `}

    ${props.maxWidth === 'spring' &&
    `
    max-width: ${props.theme.gridConfig.springWidth}px;
  `}

  ${!props.center &&
    `
    margin: 0;
  `}
  `,
)
