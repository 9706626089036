import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Step.style'

import type { StepProps } from './Step.types'

const Step: FC<StepProps> = ({
  appearance,
  horizontalStepperTheme,
  inverse,
  isCompleted,
  isActive,
  onClick,
  title,
  srText,
  dataSelectorPrefix,
}: StepProps): ReactElement => {
  return (
    <Styled.StepWrapper
      appearance={appearance}
      horizontalStepperTheme={horizontalStepperTheme}
      isCompleted={isCompleted}
      isActive={isActive}
      inverse={inverse}
      data-selector={getDataSelector(dataSelectorPrefix)}
    >
      <Styled.Step onClick={onClick} as={onClick ? 'button' : 'div'} hasHover={onClick && isCompleted}>
        <Styled.Circle
          appearance={appearance}
          horizontalStepperTheme={horizontalStepperTheme}
          isCompleted={isCompleted}
          isActive={isActive}
          inverse={inverse}
        />
        <Styled.StepTitle
          appearance={appearance}
          horizontalStepperTheme={horizontalStepperTheme}
          isActive={isActive}
          inverse={inverse}
          data-title={title}
          data-selector={getDataSelector(dataSelectorPrefix, 'title')}
        >
          {title}
          {srText && <Styled.HiddenLabel>{srText}</Styled.HiddenLabel>}
        </Styled.StepTitle>
      </Styled.Step>
    </Styled.StepWrapper>
  )
}

export default Step
