import type { ReactNode } from 'react'
import React, { PureComponent } from 'react'
import type { BreakpointKeys } from '@vfuk/core-themes'

import { withBreakpoints } from './helpers/withBreakpoints'

import type { MatchMediaProps } from './MatchMedia.types'

export class MatchMedia extends PureComponent<MatchMediaProps> {
  get fullBreakpoint(): BreakpointKeys {
    const andAbove = this.props.andAbove ? 'AndAbove' : ''
    const andBelow = this.props.andBelow ? 'AndBelow' : ''
    return `${this.props.breakpoint}${andAbove || andBelow || ''}` as BreakpointKeys
  }

  public render(): ReactNode {
    if (!this.props.breakpoints![this.fullBreakpoint]) return null
    return <>{this.props.children}</>
  }
}

export default withBreakpoints(MatchMedia)
