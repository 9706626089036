import type { RefObject } from 'react'

function getCurrentFocusedElement(ref: RefObject<HTMLUListElement>, value: string | null): HTMLElement | undefined {
  return Array.from(ref.current?.querySelectorAll('[role="option"]') || []).find((item) => item.getAttribute('id') === value) as
    | HTMLElement
    | undefined
}

function scrollElementIntoView(menuRef: RefObject<HTMLUListElement>, value: string | null): void {
  if (menuRef.current && menuRef.current.scrollHeight > menuRef.current.clientHeight) {
    const currentFocusedElement = getCurrentFocusedElement(menuRef, value)
    if (currentFocusedElement) {
      const scrollBottom = menuRef.current.clientHeight + menuRef.current.scrollTop
      const elementBottom = currentFocusedElement.offsetTop + currentFocusedElement.offsetHeight

      if (elementBottom > scrollBottom) {
        menuRef.current.scrollTop = elementBottom - menuRef.current.clientHeight
      } else if (currentFocusedElement.offsetTop < menuRef.current.scrollTop) {
        menuRef.current.scrollTop = currentFocusedElement.offsetTop
      }
    }
  }
}

export default scrollElementIntoView
