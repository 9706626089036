import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { advancedSpacing, iconAppearance, spacing, typography } from '@vfuk/core-mixins'

import type { StyledSelectInputWithMenuProps } from './SelectInputWithMenu.style.types'

export const SelectInputWithMenuWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: relative;

    ul[class*='OverflowMenu'] {
      ${spacing('margin-top', 2)};
    }
  `,
)

export const SingleOptionContainer = styled.div(
  (props: StyledSelectInputWithMenuProps): FlattenSimpleInterpolation => css`
    display: flex;
    align-items: center;
    border-radius: ${props.theme.border.radius[2]};
    background-color: ${props.theme.color.monochrome1.default};
    min-width: 136px;
    width: 100%;
    ${advancedSpacing('padding', [2, 8, 2, 3])};
    ${typography.heading(1, false)};
    border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
  `,
)

export const SelectInputWithMenu = styled(Interaction)((props: StyledSelectInputWithMenuProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && props.theme.formFields.state[props.state]

  return css`
    display: flex;
    align-items: center;
    border-radius: ${props.theme.formFields.border.radius};
    background-color: ${props.theme.formFields.backgroundColor};
    min-width: 136px;
    width: 100%;
    ${advancedSpacing('padding', [2, 8, 2, 3])};
    ${typography.heading(1, false)};
    border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};

    ${props.state &&
    stateFromTheme &&
    css`
      color: ${stateFromTheme.color};
      background-color: ${stateFromTheme.backgroundColor};
      border-color: ${stateFromTheme.border.color};
      border-width: ${stateFromTheme.border.width};

      ${props.state !== 'disabled' &&
      css`
        ${spacing('padding-right', 11)};
      `}

      ${props.state === 'disabled' &&
      css`
        opacity: 1;
        cursor: not-allowed;
      `}
    `}
  `
})

export const IconsWrapper = styled.div(
  (): FlattenSimpleInterpolation => css`
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    top: 0;
    right: 0;
    pointer-events: none;
  `,
)

export const IconContainer = styled.div((props: StyledSelectInputWithMenuProps): FlattenSimpleInterpolation => {
  const stateFromTheme = props.state && props.theme.formFields.state[props.state]

  return css`
    ${spacing('padding-right', 3)};
    pointer-events: none;

    [class*='Icon'] {
      transition: transform 0.4s ease-out;
    }

    ${props.state === 'disabled' &&
    stateFromTheme &&
    css`
      [class*='Icon'] {
        * {
          ${iconAppearance(stateFromTheme.color)};
        }
      }
    `}

    ${props.open &&
    css`
      [class*='Icon'] {
        transform: rotate(180deg);
      }
    `}
  `
})
