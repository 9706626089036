import type { ReactElement, FC } from 'react'
import React from 'react'

import FunctionalPickerItem from '@vfuk/core-functional-picker-item'
import SimpleGrid from '@vfuk/core-simple-grid'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SmallPicker.style'

import type { SmallPickerProps } from './SmallPicker.types'

const SmallPicker: FC<SmallPickerProps> = ({
  label,
  selected,
  groupName,
  inverse,
  onChange,
  onBlur,
  items,
  columns,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: SmallPickerProps): ReactElement => {
  const componentName = 'SmallPicker'

  const defaultColumns = {
    sm: items.length <= 4 ? items.length : 5,
  }

  const pickerType = Array.isArray(selected) ? 'checkbox' : 'radio'

  const handleOnBlur = (event: React.FocusEvent<HTMLFieldSetElement>): void => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.Fieldset
      onBlur={handleOnBlur}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.Legend data-selector={getDataSelector(dataSelectorPrefix, 'legend')}>{label}</Styled.Legend>
      <SimpleGrid columns={{ ...defaultColumns, ...columns }} spacing={2}>
        {items?.map((pickerItem, index) => {
          const isSelected = pickerType === 'radio' ? selected === pickerItem.value : selected.includes(pickerItem.value)
          return (
            <Styled.PickerItemWrapper key={`${index + groupName}`}>
              <FunctionalPickerItem
                groupName={groupName}
                inverse={inverse}
                type={pickerType}
                onChange={onChange}
                value={pickerItem.value}
                title={pickerItem.title}
                id={pickerItem.id}
                state={pickerItem.state}
                key={index}
                srText={pickerItem.srText}
                checked={isSelected}
                size={1}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `item-${index}`)}
              />
            </Styled.PickerItemWrapper>
          )
        })}
      </SimpleGrid>
    </Styled.Fieldset>
  )
}

export default SmallPicker
