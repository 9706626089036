import { propsPluginType } from "@vf-djr/renderer";
import { omit } from "lodash";

const componentRenderingPlugin: propsPluginType = (
  { __props = {} },
  Element,
  { render } = {}
) => {
  if (!render) {
    console.error(
      "Missing the render initialisation property for the Component Rendering Plugin"
    );
    return __props;
  }
  const processableProps = omit(__props, "children");
  return Object.entries(processableProps).reduce(
    (props, [prop, value]) => ({
      ...props,
      [prop]: (() => {
        switch (true) {
          case typeof value === "object" && !!value?.__component:
            return render(value);
          case Array.isArray(value) &&
            value.some(arrItem => !!arrItem.__component):
            return value.map(render);
          default:
            return value;
        }
      })()
    }),
    {}
  );
};

export default componentRenderingPlugin;
