import type { Theme } from '@vfuk/core-themes'

import type { CalendarPickerTheme } from './CalendarPicker.theme.types'

const defaultTheme = (theme: Theme): CalendarPickerTheme => {
  return {
    iconAppearance: 'primary',
    appearance: {
      primary: {
        color: theme.color.monochrome6.default,
        hover: {
          backgroundColor: theme.color.secondary7.default,
        },
        selected: {
          backgroundColor: theme.color.success.default,
          focus: {
            color: theme.color.focus.default,
            boxShadow: theme.color.monochrome1.default,
          },
        },
        state: {
          disabled: {
            color: theme.color.monochrome4.disabled,
          },
        },
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          hover: {
            backgroundColor: theme.color.secondary7.default,
          },
          selected: {
            backgroundColor: theme.color.success.default,
            focus: {
              color: theme.color.focus.default,
              boxShadow: theme.color.monochrome1.default,
            },
          },
          state: {
            disabled: {
              color: theme.color.monochrome4.disabled,
            },
          },
        },
      },
    },
  }
}

export default defaultTheme
