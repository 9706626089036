/* eslint-disable max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import { InteractionStyles } from '@vfuk/core-interaction'
import ListItem from '@vfuk/core-list-item'
import { spacing, typography } from '@vfuk/core-mixins'

import type { StyledStateTextProps } from './StateText.style.types'

export const StateText = styled(ListItem)((props: Pick<StyledStateTextProps, 'theme' | 'state'>): FlattenSimpleInterpolation => {
  return css`
    ${typography.paragraph(1, false)};

    ${props.state === 'error' &&
    css`
      color: ${props.theme.formLabels.state.error.color};
    `}

    ${InteractionStyles.Interaction} {
      border: ${props.theme.formFields.border.width} ${props.theme.formFields.border.style} ${props.theme.formFields.border.color};
      display: inline-block;
      width: auto;

      ${props.state === 'error' &&
      css`
        color: ${props.theme.formLabels.state.error.color};
      `}
    }
  `
})

export const StateTextContainer = styled.div(
  (props: Pick<StyledStateTextProps, 'theme' | 'state' | 'multipleStateText'>): FlattenSimpleInterpolation => {
    return css`
      display: flex;
      ${typography.paragraph(1, false)};
      ${spacing('margin-top', 2)};

      ${!props.multipleStateText &&
      css`
        align-items: center;
      `}

      svg {
        ${spacing('margin-right', 1)};

        ${props.multipleStateText &&
        css`
          margin-top: 5px;
        `}
      }

      ${props.state === 'error' &&
      css`
        color: ${props.theme.formLabels.state.error.color};
      `}
    `
  },
)
