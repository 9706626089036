import type { FC, ReactElement } from 'react'
import React, { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

import Paragraph from '@vfuk/core-paragraph'
import Sandwich from '@vfuk/core-sandwich'
import { getDataSelector } from '@vfuk/core-base-props'

import checkVerticalJustification from './utils/checkVerticalJustification'

import * as Styled from './styles/CardSandwichSlot.style'

import type { CardSandwichSlotProps, GetSandwichJustificationsReturn } from './CardSandwichSlot.types'

const CardSandwichSlot: FC<CardSandwichSlotProps> = ({
  primaryPrice,
  secondaryPrice,
  subText,
  appearance = 'primary',
  verticalAlign = 'bottom',
  level = 1,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: CardSandwichSlotProps): ReactElement => {
  const componentName = 'CardSandwichSlot'

  const getSandwichVerticalJustifications = useMemo((): GetSandwichJustificationsReturn | undefined => {
    if (!secondaryPrice?.text) return

    return {
      primary: checkVerticalJustification(primaryPrice.text, secondaryPrice.text),
      secondary: checkVerticalJustification(secondaryPrice.text, primaryPrice.text),
    }
  }, [primaryPrice, secondaryPrice])

  return (
    <Styled.CardSandwichSlot
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.SandwichContainer verticalAlign={verticalAlign} alignSecondaryPrice={secondaryPrice?.align} hasSubText={!!subText}>
        <Styled.PriceWrapper
          hasSecondaryPrice={!isEmpty(secondaryPrice)}
          verticalJustification={getSandwichVerticalJustifications?.primary}
        >
          <Sandwich
            appearance={appearance === 'primary' ? 'alt1' : 'secondary'}
            level={2}
            size={level === 2 ? 1 : 2}
            {...primaryPrice}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'primary-price')}
          />
        </Styled.PriceWrapper>
        {!isEmpty(secondaryPrice) && (
          <Styled.PriceWrapper verticalJustification={getSandwichVerticalJustifications?.secondary}>
            <Sandwich
              appearance={appearance === 'primary' ? 'alt1' : 'secondary'}
              level={2}
              size={level === 2 ? 1 : 2}
              text={secondaryPrice!.text}
              {...secondaryPrice}
              dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'secondary-price')}
            />
          </Styled.PriceWrapper>
        )}
      </Styled.SandwichContainer>
      {subText && (
        <Paragraph size={1} appearance='secondary' dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'subtext')}>
          {subText}
        </Paragraph>
      )}
    </Styled.CardSandwichSlot>
  )
}

export default CardSandwichSlot
