import type { ReactElement, FC } from 'react'
import React, { memo } from 'react'

import FunctionalPickerItem from '@vfuk/core-functional-picker-item'
import SimpleGrid from '@vfuk/core-simple-grid'
import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/Picker.style'

import type { PickerProps } from './Picker.types'

const Picker: FC<PickerProps> = ({
  label,
  selected,
  groupName,
  inverse,
  onChange,
  onBlur,
  items,
  level = 1,
  columns,
  id,
  dataSelectorPrefix,
  dataAttributes,
}: PickerProps): ReactElement => {
  const componentName = 'Picker'

  const defaultColumns = {
    sm: items.length <= 2 ? items.length : 3,
    md: items.length <= 4 ? items.length : 5,
  }

  const pickerType = Array.isArray(selected) ? 'checkbox' : 'radio'

  const handleOnBlur = (event: React.FocusEvent<HTMLFieldSetElement>): void => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <Styled.Fieldset
      onBlur={handleOnBlur}
      id={id}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      {...dataAttributes}
    >
      <Styled.Legend data-selector={getDataSelector(dataSelectorPrefix, 'legend')}>{label}</Styled.Legend>
      <SimpleGrid columns={{ ...defaultColumns, ...columns }} spacing={2} verticalAlign='stretch'>
        {items?.map((pickerItem, index) => {
          const isSelected = pickerType === 'radio' ? selected === pickerItem.value : selected.includes(pickerItem.value)
          return (
            <Styled.PickerItemWrapper key={`${index + groupName}`}>
              <FunctionalPickerItem
                level={level}
                groupName={groupName}
                inverse={inverse}
                type={pickerType}
                onChange={onChange}
                value={pickerItem.value}
                title={pickerItem.title}
                subTitle={pickerItem.subTitle}
                id={pickerItem.id}
                state={pickerItem.state}
                key={index}
                srText={pickerItem.srText}
                checked={isSelected}
                dataSelectorPrefix={getDataSelector(dataSelectorPrefix, `item-${index}`)}
              />
            </Styled.PickerItemWrapper>
          )
        })}
      </SimpleGrid>
    </Styled.Fieldset>
  )
}

export default memo(Picker)
