import type { ImagePartial } from './getBreakpointFallback.types'

import getFallbackImage from '../getFallbackImage'

import type { Breakpoint, ImageProps } from '../../Image.types'

function getBreakpointFallback(breakpoint: Breakpoint, props: ImageProps): ImagePartial {
  const fallback = getFallbackImage(props)
  const imageData = props[breakpoint]

  if (!imageData?.src) {
    return {
      src: fallback!.src,
      width: fallback?.width || '100%',
      height: fallback?.height || 'auto',
    }
  }

  return {
    src: imageData?.src,
    width: imageData?.width || '100%',
    height: imageData?.height || 'auto',
  }
}

export default getBreakpointFallback
