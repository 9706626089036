import type { ReactElement, FC } from 'react'
import React from 'react'

import { useLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/InlineLink.theme'

import * as Styled from './styles/InlineLink.style'

import type { InlineLinkProps } from './InlineLink.types'

const InlineLink: FC<InlineLinkProps> = ({
  appearance = 'primary',
  htmlAttributes = {},
  id,
  dataAttributes,
  srText,
  inverse = false,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  children,
  href,
  customRouterProps,
  localTheme,
  dataSelectorPrefix,
}: InlineLinkProps): ReactElement => {
  const componentName = 'InlineLink'
  const inlineLinkTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  return (
    <Styled.InlineLink
      id={id}
      inlineLinkTheme={inlineLinkTheme}
      appearance={appearance}
      href={href}
      target={htmlAttributes!.target}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      inverse={inverse}
      label={srText}
      rel={htmlAttributes!.rel}
      componentName={componentName}
      dataAttributes={dataAttributes}
      customRouterProps={customRouterProps}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
    >
      {children}
    </Styled.InlineLink>
  )
}

export default InlineLink
