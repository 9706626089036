const OPW_DEV_MIDDLEWARE_URL =
  "https://r10-dev.digitalretail.vodafone.com/opw-middleware";
const OPW_TEST_MIDDLEWARE_URL =
  "https://r10-test.digitalretail.vodafone.com/opw-middleware";
const OPW_PREPROD_MIDDLEWARE_URL =
  "https://opw-preprod.digitalretail.vodafone.com/opw-middleware";
const OPW_PROD_MIDDLEWARE_URL =
  "https://opw-middleware.prod.digitalretail.vodafone.com";

const envURLs = [
  {
    key: "dev",
    matcher: /-dev/i,
    value: OPW_DEV_MIDDLEWARE_URL,
  },
  {
    key: "test",
    matcher: /-test/i,
    value: OPW_TEST_MIDDLEWARE_URL,
  },
  {
    key: "preprod",
    matcher: /-preprod/i,
    value: OPW_PREPROD_MIDDLEWARE_URL,
  },
  {
    key: "prod",
    matcher: /-prod/i,
    value: OPW_PROD_MIDDLEWARE_URL,
  },
];

const getEnvURL = (url = "") => {
  const segment = url.toLowerCase().split(".", 1)?.[0];
  return envURLs.find(({ matcher }) => segment?.match(matcher))?.value;
};

export function getMiddlewareBaseUrl(settings) {
  const appBuilderUrl = settings.ingressURL || settings.apiUrl;
  const baseURL = getEnvURL(appBuilderUrl) || OPW_TEST_MIDDLEWARE_URL;
  return `${baseURL}/app-builder`;
}
