import type { FocusEvent, FC } from 'react'
import React, { memo } from 'react'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/RangeSliderFieldSet.style'

import TopLabels from '../TopLabels'
import SliderIcon from '../SliderIcon'
import SliderTextInput from '../SliderTextInput'

import type { RangeSliderProps } from '../../RangeSlider.types'

const SliderWithTooltip = createSliderWithTooltip(Slider)
const RangeSliderWithTooltip = createSliderWithTooltip(Slider.Range)

const RangeSliderFieldSet: FC<RangeSliderProps> = ({
  className,
  min,
  max,
  value,
  showDots,
  topLabels,
  rangeLabels,
  onChange,
  onBlur,
  onInvalidInputEntry,
  allowNonIncrementValue = false,
  id,
  srLabel,
  prefix,
  suffix,
  state,
  appearance = 'primary',
  increment = 1,
  showTextInput = false,
  icons,
  dataSelectorPrefix,
  localTheme,
}) => {
  const isDualSlider = Array.isArray(value)

  const handleOnBlur = (event: FocusEvent<HTMLFieldSetElement>): void => {
    if (onBlur) onBlur(event)
  }

  const handleOnChange = (sliderValue: number | number[]): void => {
    onChange(sliderValue, 'slider')
  }

  const tipFormatter = (formatterValue: number): string => `${prefix || ''}${formatterValue}${suffix || ''}`

  return (
    <fieldset id={`${id}-fieldset`} onBlur={handleOnBlur} data-selector={getDataSelector(dataSelectorPrefix)}>
      {topLabels && <TopLabels labels={topLabels} dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'labels')} />}
      <Styled.RangeSlider appearance={appearance} state={state} rangeLabels={rangeLabels} rangeSliderTheme={localTheme!}>
        {icons?.left && (
          <SliderIcon
            icon={icons!.left!}
            min={min}
            max={max}
            value={value}
            increment={increment}
            position='left'
            onChange={onChange}
            state={state}
            srLabel={srLabel}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'left-icon')}
            rangeSliderTheme={localTheme!}
            appearance={appearance}
          />
        )}
        {showTextInput && isDualSlider && (
          <SliderTextInput
            onInvalidInputEntry={onInvalidInputEntry}
            allowNonIncrementValue={allowNonIncrementValue}
            min={min}
            max={max}
            increment={increment}
            value={value}
            prefix={prefix}
            suffix={suffix}
            id={`${id}-left-text-input`}
            state={state}
            onChange={onChange}
            textInputPosition='left'
            srLabel={srLabel.leftLabel!}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'slider-text-input')}
          />
        )}
        {isDualSlider ? (
          <RangeSliderWithTooltip
            className={className}
            min={min}
            max={max}
            step={increment}
            value={value as number[]}
            dots={showDots}
            marks={rangeLabels}
            onChange={handleOnChange}
            disabled={state === 'disabled'}
            tipFormatter={tipFormatter}
            ariaLabelGroupForHandles={[srLabel.leftLabel!, srLabel.rightLabel!]}
          />
        ) : (
          <SliderWithTooltip
            className={className}
            min={min}
            max={max}
            step={increment}
            value={value as number}
            dots={showDots}
            marks={rangeLabels}
            onChange={handleOnChange}
            disabled={state === 'disabled'}
            tipFormatter={tipFormatter}
            ariaLabelForHandle={srLabel.mainLabel}
          />
        )}
        {icons?.right && (
          <SliderIcon
            icon={icons!.right!}
            min={min}
            max={max}
            value={value}
            increment={increment}
            position='right'
            onChange={onChange}
            state={state}
            srLabel={srLabel}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'right-icon')}
            rangeSliderTheme={localTheme!}
            appearance={appearance}
          />
        )}
        {showTextInput && (
          <SliderTextInput
            onInvalidInputEntry={onInvalidInputEntry}
            allowNonIncrementValue={allowNonIncrementValue}
            min={min}
            max={max}
            increment={increment}
            value={value}
            prefix={prefix}
            suffix={suffix}
            id={`${id}-right-text-input`}
            state={state}
            onChange={onChange}
            textInputPosition='right'
            srLabel={isDualSlider ? srLabel.rightLabel! : srLabel.mainLabel}
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'slider-text-input')}
          />
        )}
      </Styled.RangeSlider>
    </fieldset>
  )
}

export default memo(RangeSliderFieldSet)
