/* eslint-disable complexity, sonarjs/cognitive-complexity */
import type { ReactNode } from 'react'
import React from 'react'
import { createPortal } from 'react-dom'
import FocusLock from 'react-focus-lock'

import Animate from '@vfuk/core-animate'
import Container from '@vfuk/core-container'
import Overlay from '@vfuk/core-overlay'
import { withLocalTheme } from '@vfuk/core-themes'
import { getDataSelector } from '@vfuk/core-base-props'

import defaultTheme from './themes/ModalRenderer.theme'
import * as Styled from './styles/ModalRenderer.style'

import calculateSize from './helpers/calculateSize'

import type { ModalRendererProps, AdditionalModalChildrenProps } from './ModalRenderer.types'
import type { ModalRendererTheme } from './themes/ModalRenderer.theme.types'

import Header from '../components/Header'

import Renderer from '../Renderer'

import type { RendererState } from '../Renderer.types'

import setInitialFocus from '../utils/setInitialFocus'
import { OverlayCloseSource } from '../constants/constants'

import CloseButton from '../components/CloseButton'

export class ModalRenderer extends Renderer<ModalRendererProps, RendererState> {
  public static defaultProps = {
    appearance: 'primary',
    size: 1,
    overlayBlur: false,
  }

  get additionalChildrenProps(): AdditionalModalChildrenProps {
    return {
      modalSize: this.props.size!,
    }
  }

  public render(): ReactNode {
    const closeButtonClick = this.getOnCloseHandler(OverlayCloseSource.MODAL_CROSS_CLICK)
    const { inverseIcon, containerAppearance } = this.props.localTheme!.appearance[this.props.appearance!]

    return createPortal(
      <Styled.ModalRenderer
        zIndex={this.props.zIndex}
        id={this.props.id}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        data-component-name={this.props.componentName}
        {...this.props.dataAttributes}
      >
        <Animate
          show={this.state.show}
          enter={{
            animations: this.props.animate ? ['scaleUp', 'fadeIn'] : ['none'],
            duration: this.props.animate ? 200 : 0,
            delay: 200,
            onDone: (): void => setInitialFocus(this.props.initialFocusId),
          }}
          exit={{
            animations: this.props.animate ? ['fadeOut'] : ['none'],
            duration: this.props.animate ? 200 : 0,
            onDone: this.onDoneCallback,
          }}
        >
          <Styled.ModalWrapper zIndex={this.props.zIndex}>
            <Styled.ContainerWrapper
              size={calculateSize(this.props.size)}
              fullScreenOnSM={this.props.fullScreenOnSM}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, 'content')}
            >
              <Container
                elevation
                paddingLevel={0}
                appearance={containerAppearance}
                dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'container')}
              >
                <Styled.ContentWrapper>
                  <FocusLock shards={this.props.focusEnabledRefs}>
                    {this.props.isClosable && !this.props.header && (
                      <CloseButton
                        srName={this.props.srName}
                        onClick={closeButtonClick}
                        inverse={inverseIcon}
                        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'close-button')}
                      />
                    )}
                    {this.props.header && (
                      <Header
                        header={this.props.header}
                        srName={this.props.srName}
                        isClosable={this.props.isClosable}
                        closeOnClick={closeButtonClick}
                        inverse={inverseIcon}
                        isSticky={Boolean(this.props.footer || this.props.header?.isSticky)}
                        dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'header')}
                        appearance={this.props.appearance}
                        localTheme={this.props.localTheme!}
                      />
                    )}
                    <Styled.ModalContent>
                      {React.Children.map(this.props.children, (child) => {
                        if (React.isValidElement(child)) {
                          return React.cloneElement(child, this.additionalChildrenProps)
                        }
                        return child
                      })}
                    </Styled.ModalContent>
                    {this.props.footer && (
                      <Styled.Footer
                        data-selector={getDataSelector(this.props.dataSelectorPrefix, 'footer')}
                        appearance={this.props.appearance!}
                        modalTheme={this.props.localTheme!}
                      >
                        {this.props.footer}
                      </Styled.Footer>
                    )}
                  </FocusLock>
                </Styled.ContentWrapper>
              </Container>
            </Styled.ContainerWrapper>
          </Styled.ModalWrapper>
        </Animate>
        <Animate
          show={this.state.show}
          enter={{
            animations: this.props.animate ? ['fadeIn'] : ['none'],
            duration: this.props.animate ? 400 : 0,
          }}
          exit={{
            animations: this.props.animate ? ['fadeOut'] : ['none'],
            duration: this.props.animate ? 200 : 0,
          }}
        >
          <Overlay
            show
            onClick={this.props.isClosable ? this.getOnCloseHandler(OverlayCloseSource.MODAL_OUTSIDE_CLICK) : undefined}
            position='fixed'
            zIndex={this.props.zIndex - 1}
            blur={this.props.overlayBlur}
            dataSelectorPrefix={getDataSelector(this.props.dataSelectorPrefix, 'overlay')}
          />
        </Animate>
      </Styled.ModalRenderer>,
      this.props.rootElement,
    )
  }
}

export default withLocalTheme<ModalRendererProps, ModalRendererTheme>(ModalRenderer, defaultTheme)
