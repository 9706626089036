import React, { FC } from 'react'

import { IconSpotifyProps } from './IconSpotify.types'
import * as Styled from './styles/IconSpotify.style'

import { nonThemeableRules } from '../../ruleSet'

const IconSpotify: FC<IconSpotifyProps> = ({
  size = nonThemeableRules.defaultSize as number as IconSpotifyProps['size'],
  isResponsive = false,
}: IconSpotifyProps) => {
  return (
    <Styled.IconWrapper size={size} isResponsive={isResponsive}>
      <svg fill='none' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0 38.4c0-13.441 0-20.162 2.616-25.296A24 24 0 0 1 13.104 2.616C18.238 0 24.96 0 38.4 0h43.2c13.441 0 20.162 0 25.296 2.616a23.999 23.999 0 0 1 10.488 10.488C120 18.238 120 24.96 120 38.4v43.2c0 13.441 0 20.162-2.616 25.296a23.998 23.998 0 0 1-10.488 10.488C101.762 120 95.041 120 81.6 120H38.4c-13.441 0-20.162 0-25.296-2.616a23.999 23.999 0 0 1-10.488-10.488C0 101.762 0 95.041 0 81.6V38.4Z'
          fill='#1A1414'
        />
        <path
          d='M85.459 55.115c-13.873-8.239-36.76-8.998-50.004-4.977a4.026 4.026 0 1 1-2.337-7.703c15.204-4.617 40.476-3.724 56.449 5.758a4.026 4.026 0 1 1-4.108 6.923Zm-.454 12.204a3.36 3.36 0 0 1-4.617 1.106c-11.566-7.11-29.205-9.17-42.888-5.015a3.362 3.362 0 0 1-4.188-2.237 3.36 3.36 0 0 1 2.238-4.184c15.633-4.743 35.065-2.447 48.351 5.718a3.357 3.357 0 0 1 1.104 4.612ZM79.739 79.04a2.678 2.678 0 0 1-3.689.89c-10.108-6.178-22.829-7.572-37.81-4.148a2.683 2.683 0 1 1-1.194-5.23c16.394-3.75 30.46-2.136 41.804 4.798a2.687 2.687 0 0 1 .889 3.69Zm-19.74-62.083c-23.771 0-43.043 19.27-43.043 43.04 0 23.773 19.27 43.043 43.042 43.043 23.77 0 43.043-19.27 43.043-43.042 0-23.768-19.27-43.04-43.043-43.04Z'
          fill='#1FD661'
        />
      </svg>
    </Styled.IconWrapper>
  )
}

export default IconSpotify
