const setHeight = (initialHeight: number, targetHeight: number, element: HTMLElement, duration: number, onEndAnimate: () => void): void => {
  // Get total difference in height
  const heightDifference = targetHeight - initialHeight
  let currentTime: number | null = null

  const animateHeight = (timestamp: number): void => {
    // Set currentTime to 0 if not already set
    if (!currentTime) currentTime = timestamp
    // Check how much time has elapsed
    const progress = timestamp - currentTime
    // Calculates a new height based on how much time is left in the animation
    const newHeight = Number((progress / duration) * heightDifference + initialHeight)
    // Sets new height inline
    element.setAttribute('style', `height: ${newHeight}px;`)

    // Check if animation has finished
    if (progress >= duration) {
      element.removeAttribute('style')
      onEndAnimate()
      return
    }

    // Run again if not finished
    window.requestAnimationFrame(animateHeight)
  }

  window.requestAnimationFrame(animateHeight)
}

export default setHeight
