import type { FlattenSimpleInterpolation } from 'styled-components'
import { css } from 'styled-components'

import type { Theme, SpacingKeys } from '@vfuk/core-themes'

import type { MixinResponsivePosition, Properties, ValidSpacingKeys } from './responsivePosition.types'

import respondTo from '../respondTo'
import createMixin from '../utils/createMixin'

/**
 * Responsive spacing mixin
 */
export default createMixin((theme: Theme, property: Properties, value: ValidSpacingKeys): FlattenSimpleInterpolation => {
  const IsValueNegative = value > 0 ? ' ' : ' -'
  const positiveValue = Math.abs(value) as SpacingKeys

  return css`
    ${property}:${IsValueNegative}${theme.spacing.responsive[positiveValue!].sm}px;

    ${respondTo.md(css`
      ${property}:${IsValueNegative}${theme.spacing.responsive[positiveValue!].md}px;
    `)}

    ${respondTo.lg(css`
      ${property}:${IsValueNegative}${theme.spacing.responsive[positiveValue!].lg}px;
    `)}

      ${respondTo.xl(css`
      ${property}:${IsValueNegative}${theme.spacing.responsive[positiveValue!].xl}px;
    `)}
  `
}) as MixinResponsivePosition
