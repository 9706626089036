import type { ReactElement, FC } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/FunctionalAvatar.style'

import type { FunctionalAvatarProps } from './FunctionalAvatar.types'

const FunctionalAvatar: FC<FunctionalAvatarProps> = ({
  size = 2,
  backgroundImage,
  backgroundColor,
  children,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
  href,
  id,
  dataSelectorPrefix,
  dataAttributes,
  componentName,
  srText,
  customRouterProps,
}: FunctionalAvatarProps): ReactElement => {
  return (
    <Styled.FunctionalAvatar
      size={size}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      href={href}
      id={id}
      dataSelectorPrefix={getDataSelector(dataSelectorPrefix)}
      componentName={componentName}
      dataAttributes={dataAttributes}
      label={srText}
      customRouterProps={customRouterProps}
    >
      <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
    </Styled.FunctionalAvatar>
  )
}

export default FunctionalAvatar
