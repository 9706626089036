import type { SnippetTheme } from './Snippet.theme.types'

const defaultTheme = (): SnippetTheme => {
  return {
    appearance: {
      primary: {
        buttonAppearance: 'primary',
      },
      secondary: {
        buttonAppearance: 'secondary',
      },
      alt1: {
        buttonAppearance: 'alt1',
      },
    },
  }
}

export default defaultTheme
