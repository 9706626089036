import type { FC, ReactElement } from 'react'
import React from 'react'

import { getDataSelector } from '@vfuk/core-base-props'
import { useLocalTheme } from '@vfuk/core-themes'
import Span from '@vfuk/core-span'

import defaultTheme from './themes/Sandwich.theme'
import * as Styled from './styles/Sandwich.style'

import type { SandwichProps } from './Sandwich.types'
import type { Appearance } from './themes/Sandwich.theme.types'

const Sandwich: FC<SandwichProps> = ({
  appearance = 'primary',
  negative = false,
  level = 1,
  inverse = false,
  justify = 'left',
  dataAttributes = {},
  size = 1,
  text,
  localTheme,
  unit,
  id,
  dataSelectorPrefix,
}: SandwichProps): ReactElement => {
  const componentName = 'Sandwich'
  const sandwichTheme = useLocalTheme(componentName, defaultTheme, localTheme)

  const { subTextWeight, signWeight, currencyWeight, mainTextWeight } = sandwichTheme.level[level]

  let themeAppearance = sandwichTheme.level[level].appearance[appearance]
  if (inverse) {
    themeAppearance = themeAppearance.inverse as Appearance
  }

  const { prefixAppearance, mainAppearance, mainSuffixAppearance, suffixAppearance, suffix2Appearance } = themeAppearance

  return (
    <Styled.Sandwich
      id={id}
      justify={justify!}
      {...dataAttributes}
      data-selector={getDataSelector(dataSelectorPrefix)}
      data-component-name={componentName}
      noPrefix={!text.prefix && !text.suffix}
    >
      {text.prefix && (
        <Styled.TextWrapper
          sandwichTheme={sandwichTheme}
          size={size}
          appearance={prefixAppearance}
          weight={subTextWeight}
          inverse={inverse}
          display='block'
          textType='supportingText'
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'prefix')}
        >
          {text.prefix}
        </Styled.TextWrapper>
      )}

      <Styled.MainContent size={size}>
        {negative && (
          <Styled.TextWrapper
            sandwichTheme={sandwichTheme}
            appearance={mainAppearance}
            display='inline'
            inverse={inverse}
            weight={signWeight}
            size={size}
            textType='sign'
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'negative-sign')}
          >
            -
          </Styled.TextWrapper>
        )}
        {unit && (
          <Styled.TextWrapper
            sandwichTheme={sandwichTheme}
            appearance={mainAppearance}
            display='inline'
            inverse={inverse}
            weight={currencyWeight}
            size={size}
            textType='currency'
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'unit')}
          >
            {unit}
          </Styled.TextWrapper>
        )}
        <Span
          appearance={mainAppearance}
          weight={mainTextWeight}
          display='inline'
          inverse={inverse}
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'main-text')}
        >
          {text.main}
        </Span>
        {text.mainSuffix && (
          <Styled.TextWrapper
            sandwichTheme={sandwichTheme}
            appearance={mainSuffixAppearance}
            display='inline'
            inverse={inverse}
            weight={subTextWeight}
            size={size}
            textType='supportingText'
            dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'main-suffix')}
          >
            {` ${text.mainSuffix}`}
          </Styled.TextWrapper>
        )}
      </Styled.MainContent>

      {text.suffix && (
        <Styled.TextWrapper
          sandwichTheme={sandwichTheme}
          size={size}
          appearance={suffixAppearance}
          weight={subTextWeight}
          display='block'
          inverse={inverse}
          textType='supportingText'
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'suffix')}
        >
          {text.suffix}
        </Styled.TextWrapper>
      )}

      {text.suffix2 && (
        <Styled.TextWrapper
          sandwichTheme={sandwichTheme}
          size={size}
          appearance={suffix2Appearance}
          weight={subTextWeight}
          display='block'
          inverse={inverse}
          textType='supportingText'
          dataSelectorPrefix={getDataSelector(dataSelectorPrefix, 'suffix2')}
        >
          {text.suffix2}
        </Styled.TextWrapper>
      )}
    </Styled.Sandwich>
  )
}

export default Sandwich
