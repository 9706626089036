/* eslint-disable max-len */
import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { typography } from '@vfuk/core-mixins'

import type { StyledLinkProps } from './Link.style.types'

export const Link = styled(Interaction)((props: StyledLinkProps): FlattenSimpleInterpolation => {
  return css`
    color: ${props.linkTheme.appearance[props.appearance].color};
    transition: 0.4s all ease;

    ${props.showUnderline &&
    `
      text-decoration: underline;
    `}

    ${props.inverse &&
    `
      color: ${props.linkTheme.appearance[props.appearance].inverse.color};
    `}

    ${props.size === 1 &&
    css`
      ${typography.paragraph(1, false)};
    `}

    ${props.size === 2 &&
    css`
      ${typography.paragraph(2, false)};
    `}

    ${props.size === 3 &&
    css`
      ${typography.heading(1, false)};
    `}

    ${props.size === 4 &&
    css`
      ${typography.heading(2, false)};
    `}

    &:hover {
      color: ${props.linkTheme.appearance[props.appearance].hover};
      text-decoration: underline;

      ${props.inverse &&
      css`
        color: ${props.linkTheme.appearance[props.appearance].inverse.hover};
      `}
    }

    &:focus {
      text-decoration: underline;
    }
  `
})
