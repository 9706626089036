import type { SearchInputTheme } from './SearchInput.theme.types'

const searchInputLocal = (): SearchInputTheme => {
  return {
    appearance: {
      primary: {
        closeIconAppearance: 'primary',
        searchIconAppearance: 'primary',
        inverseCloseIcon: false,
        inverseSearchIcon: false,
      },
    },
  }
}

export default searchInputLocal
