import type { Theme } from '@vfuk/core-themes'

import type { LinkTheme } from './Link.theme.types'

const defaultTheme = (theme: Theme): LinkTheme => {
  return {
    appearance: {
      primary: {
        color: theme.color.monochrome6.default,
        hover: theme.color.monochrome6.hover,
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          hover: theme.inverseColor.secondary7.default,
        },
      },
      secondary: {
        color: theme.color.monochrome6.default,
        hover: theme.color.monochrome6.hover,
        inverse: {
          color: theme.inverseColor.monochrome6.default,
          hover: theme.inverseColor.monochrome6.default,
        },
      },
    },
  }
}

export default defaultTheme
